<div class="uf-app-bar flat transparent">
  <div class="title">{{ context.project?.name }}</div>
  <button type="button" class="uf-action tertiary" (click)="close()">
    <uf-icon class="close" name="close"></uf-icon>
  </button>
</div>

<uf-panel class="grow">
  <div class="settings-items col">
    <ul>
      <li *ngIf="contentInfo">
        <a [class.disabled]="!updateAvailable" (click)="update()">
          <uf-icon name="data"></uf-icon>
          <span *ngIf="!updateAvailable">
            {{discoverTK.SettingsDataVersionLabel | translate:{version: contentInfo.name} }}
          </span>
          <span *ngIf="updateAvailable">
            {{discoverTK.SettingsUpdateToLabel | translate:{version: updateAvailable.name} }}
          </span>
          <uf-icon name="warning--solid" *ngIf="updateAvailable">
          </uf-icon>
        </a>
      </li>
      <li *ngIf="settingsConfig.hidePreviewMode !== true && previewAvailable">
        <a (click)="toggle()" [class.preview]="preview">
          <uf-icon name="preview"></uf-icon>
          <span>{{discoverTK.SettingsActionChangeMode | translate}}</span>
          <uf-icon [name]="preview ? 'd-checkbox--true' : 'd-checkbox'"></uf-icon>
        </a>
      </li>
      <li *ngIf="settingsConfig.hideRefreshApp !== true">
        <a (click)="reload()">
          <uf-icon name="refresh"></uf-icon>
          <span>{{sharedTermsTK.ActionRefresh | translate}}</span>
        </a>
      </li>
      <li *ngIf="showChangeProject">
        <a [routerLink]="['/login', 'projects']" [queryParams]="{autofill: false}">
          <uf-icon name="change"></uf-icon>
          <span>{{discoverTK.SettingsActionChangeProject | translate}}</span>
        </a>
      </li>
      <li *ngIf="settingsConfig.hideSyncForms !== true">
        <a [routerLink]="['/offline-forms']" (click)="close()" class="has-notification">
          <uf-icon name="sync"></uf-icon>
          <span>{{discoverTK.SettingsOfflineFormsLabel | translate}}</span>
          <uf-icon name="warning--solid" *ngIf="offlineSyncRequired">
          </uf-icon>
        </a>
      </li>
      <ng-template [usFeatureFlag]="'translations'">
        <li *ngIf="availableLanguages.length > 1">
          <a class="language" (click)="showChangeLanguage()">
            <img src="assets/svg/language-{{translations.currentLanguage}}.svg">
            <span>{{shellTK.ChangeLanguageTitle | translate}}</span>
          </a>
        </li>
      </ng-template>
    </ul>

    <footer>
      <ul>
        <li *ngIf="settingsConfig.hideMyProfile !== true && user">
          <a [routerLink]="[userProfilePath]">
            <uf-icon name="edit"></uf-icon>
            <span *ngIf="user.firstName || user.lastName">
              {{user.firstName}} {{user.lastName}}
            </span>
            <span *ngIf="!user.firstName && !user.lastName">
              {{user.username}}
            </span>
          </a>
        </li>
        <li *ngIf="context.project && context.project.privacyPolicyUrl">
          <a href="{{context.project.privacyPolicyUrl}}" ufTarget="_blank">
            <uf-icon name="privacyPolicy"></uf-icon>
            <span>{{discoverTK.PrivacyPolicyLabel | translate}}</span>
          </a>
        </li>
        <li *ngIf="settingsConfig.hideLogout !== true">
          <a (click)="auth.logout({askConfirmation: true})">
            <uf-icon name="login"></uf-icon>
            <span>{{sharedTermsTK.ActionLogout | translate}}</span>
          </a>
        </li>
      </ul>
      <div class="logo-wrap row">
        <uf-description-list class="small small-label white gap-left" [items]="versionInfo"></uf-description-list>
        <img src="{{config.unifii.productLogoSecondary}}" alt="Unifii" />
      </div>
    </footer>
  </div>
</uf-panel>