<uf-panel class="container bg-gray">
    <div class="uf-container-lg bg-solid">

        <div *ngIf="error" class="pad">
            <uf-message class="error gap" icon="errorSolid" [content]="error.message">
                <h3>{{error.code}}</h3>
            </uf-message>
        </div>

        <ng-container *ngIf="!error && definition && formData">

            <div class="uf-app-bar transparent">
                <a class="uf-button tertiary left align-left" (click)="back()">
                    <uf-icon name="arrowBack"></uf-icon>
                    {{sharedTK.ActionBack | translate}}
                </a>
            </div>

            <uf-form [definition]="definition" [(formData)]="formData" [config]="formConfig" (submit)="save($event)"
                (cancel)="back()"></uf-form>

            <footer>
                <uf-description-list class="pad small small-label" *ngIf="definition?.version != null">
                    <dt>{{commonTK.FormMetadataFieldDefinitionVersionLabel | translate }}:</dt>
                    <dd>{{definition.version}}</dd>
                </uf-description-list>
            </footer>

        </ng-container>

        <us-loading-spinner *ngIf="busy"></us-loading-spinner>
    </div>

</uf-panel>