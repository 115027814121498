import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@unifii/components';
import { BarcodeScannerModalProvider, ContextProvider, ExpressionParser, FeatureFlagProvider, Repository } from '@unifii/library/common';
import {
    CollectionItemComponentRegistry, DisplayModule, DisplayService, PageComponentRegistry, ViewComponentRegistry
} from '@unifii/library/smart-forms/display';
import { InputModule } from '@unifii/library/smart-forms/input';
import { UserProvisioningModule } from '@unifii/user-provisioning';

import { ChartComponent } from 'shell/common/chart/chart.component';
import { ContentLinkUrlComponent } from 'shell/common/content-link-url/content-link-url.component';
import { IconMessageComponent } from 'shell/common/icon-message.component';
import { LoadingSpinnerComponent } from 'shell/common/loading-spinner.component';
import { UsBarcodeScannerModalComponent, UsBarcodeScannerModalProvider } from 'shell/common/modals/barcode-scanner-modal.component';
import { ConfirmExtendedModalComponent } from 'shell/common/modals/confirm-extended-modal.component';
import { PdfViewerModalComponent } from 'shell/common/modals/pdf-viewer-modal.component';
import { PillComponent } from 'shell/common/pill.component';
import { SectionAccordionComponent } from 'shell/common/section-accordion.component';
import { UsCollectionItemComponentRegistry } from 'shell/content/collection-item-component-registry';
import { CollectionItemComponent } from 'shell/content/collection-item.component';
import { CollectionComponent } from 'shell/content/collection.component';
import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { ContentComponentSelector, ShellContentComponentSelector } from 'shell/content/content-component-selector';
import { ContentDataResolver, ShellContentDataResolver } from 'shell/content/content-data-resolver';
import { ContentNodeComponent } from 'shell/content/content-node.component';
import { IFrameComponent } from 'shell/content/iframe.component';
import { LinkListComponent } from 'shell/content/link-list.component';
import { LinkComponent } from 'shell/content/link.component';
import { UsPageComponentRegistry } from 'shell/content/page-component-registry';
import { PageComponent } from 'shell/content/page.component';
import { PdfViewerNodeComponent } from 'shell/content/pdf-viewer-node.component';
import { PdfViewerComponent } from 'shell/content/pdf-viewer.component';
import { ShellContentComponentFactory } from 'shell/content/shell-content-component-factory';
import { UsViewComponentRegistry } from 'shell/content/view-component-registry';
import { ViewComponent } from 'shell/content/view.component';
import { DrawerComponent } from 'shell/core/drawer.component';
import { HeaderComponent } from 'shell/core/header.component';
import { IconsComponent } from 'shell/core/icons.component';
import { MenuDrawerComponent } from 'shell/core/menu-drawer.component';
import { ShellComponent } from 'shell/core/shell.component';
import { ShellService } from 'shell/core/shell.service';
import { TitleConfig } from 'shell/core/title-config';
import { DashboardTableComponent } from 'shell/dashboard/dashboard-table.component';
import { DashboardComponent } from 'shell/dashboard/dashboard.component';
import { ShellFeatureFlagDirective } from 'shell/directives/feature-flag.directive';
import { MasterDetailDirective } from 'shell/directives/master-detail.directive';
import { ErrorService } from 'shell/errors/error.service';
import { Features } from 'shell/features';
import { ConflictModalComponent } from 'shell/form/conflict-modal.component';
import { FormTriggerComponent } from 'shell/form/form-trigger.component';
import { FormComponent } from 'shell/form/form.component';
import { ShellFormService } from 'shell/form/shell-form.service';
import { BreadcrumbsComponent } from 'shell/nav/breadcrumbs.component';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { NavItemComponent } from 'shell/nav/menu/nav-item.component';
import { NavComponent } from 'shell/nav/menu/nav.component';
import { NavigationService } from 'shell/nav/navigation.service';
import { NodeComponent } from 'shell/nav/node.component';
import { ContentSearcher } from 'shell/offline/content-searcher';
import { ContentUpdater } from 'shell/offline/content-updater.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { ContentDb, IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { OfflineManager } from 'shell/offline/offline-manager';
import { OnlineContentLoader } from 'shell/offline/online-content-loader.service';
import { SentryErrorHandler } from 'shell/sentry-error-handler';
import { LoadingIndicatorService } from 'shell/services/loading-indicator.service';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { ShellContextProvider } from 'shell/services/shell-context-provider';
import { ShellFeatureFlagProvider } from 'shell/services/shell-feature-flag-provider';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';
import { AppTitle } from 'shell/shell-constants';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { TableModuleComponent } from 'shell/table-detail/table-module.component';
import { CompanyDescriptionComponent } from 'shell/table/companies/company-description-list.component';
import { CompanyFormComponent } from 'shell/table/companies/company-form.component';
import { ContentLinkCellComponent } from 'shell/table/content-link-cell/content-link-cell.component';
import { TablePageComponent } from 'shell/table/table-page.component';
import { ChangeLanguageComponent } from 'shell/translations/change-language.component';

import { Config } from 'config';


const createTitleConfig = (config: Config): TitleConfig => {
    const prefix = config.env === 'prod' ? '' : config.env.toUpperCase();
    return {
        appTitle: AppTitle, // TODO: Add to environment.json
        prefix
    };
};

/**
 * This module will contain all the Unifii Framework components and will become
 * the basis for all future apps built on Unifii
 *
 * If you need to work on this, make sure
 */
@NgModule({
    imports: [
        RouterModule, InputModule, DisplayModule, ComponentsModule, UserProvisioningModule, TranslateModule
    ],
    declarations: [
        // Frame
        ShellComponent, DrawerComponent, MenuDrawerComponent, HeaderComponent, IconsComponent,

        // Navigation
        NavComponent, NavItemComponent, NodeComponent,

        // Common
        IconMessageComponent, ErrorMessageComponent, LoadingSpinnerComponent,
        BreadcrumbsComponent, ChartComponent, ConfirmExtendedModalComponent,
        ChangeLanguageComponent, CompanyDescriptionComponent, PdfViewerModalComponent,
        ContentLinkUrlComponent, UsBarcodeScannerModalComponent,

        // Directives
        MasterDetailDirective, ShellFeatureFlagDirective,

        // Content
        LinkListComponent, LinkComponent, CollectionComponent, CollectionItemComponent, ContentNodeComponent,
        PageComponent, ViewComponent, IFrameComponent, PdfViewerComponent, PdfViewerNodeComponent,

        // Forms
        FormComponent, FormTriggerComponent, CompanyFormComponent, ConflictModalComponent,

        // Bucket
        SectionAccordionComponent, PillComponent,

        // Dashboard
        DashboardComponent, DashboardTableComponent, TablePageComponent,

        // Table
        TableDetailComponent, TableModuleComponent, ContentLinkCellComponent,

    ],
    exports: [
        // Modules
        InputModule, DisplayModule, ComponentsModule, UserProvisioningModule, TranslateModule,

        // Frame
        ShellComponent, DrawerComponent, MenuDrawerComponent, HeaderComponent, IconsComponent,

        // Common
        IconMessageComponent, ErrorMessageComponent, LoadingSpinnerComponent,
        BreadcrumbsComponent, ChartComponent, ConfirmExtendedModalComponent,
        ChangeLanguageComponent, PdfViewerComponent, PdfViewerModalComponent,
        ContentLinkUrlComponent, UsBarcodeScannerModalComponent,

        // Directives,
        MasterDetailDirective, ShellFeatureFlagDirective,

        // Navigation
        NavComponent, NavItemComponent,

        // Bucket
        PillComponent
    ],
    providers: [
        // Framework
        UserAccessManager,
        ExpressionParser,
        ShellService,
        // TranslationsService,
        ErrorService,
        // Nav
        NavigationService,
        // Misc
        Repository,
        Features,
        LoadingIndicatorService,
        { provide: ErrorHandler, useClass: SentryErrorHandler },
        SavedUsersService,
        TenantSettingsService,

        // Content
        { provide: ViewComponentRegistry, useClass: UsViewComponentRegistry },
        { provide: PageComponentRegistry, useClass: UsPageComponentRegistry },
        { provide: CollectionItemComponentRegistry, useClass: UsCollectionItemComponentRegistry },
        { provide: ContentComponentFactory, useClass: ShellContentComponentFactory },
        { provide: ContentDataResolver, useClass: ShellContentDataResolver },

        // Offline Content
        { provide: TenantDb, useClass: IndexedDbWrapper },
        { provide: ContentDb, useClass: IndexedDbWrapper },
        OfflineManager,
        OnlineContentLoader,
        ContentUpdater,
        ContentSearcher,
        DisplayService,

        // Forms
        OfflineQueue,
        ShellFormService,
        { provide: BarcodeScannerModalProvider, useValue: UsBarcodeScannerModalProvider },

        //Title Service
        { provide: TitleConfig, useFactory: createTitleConfig, deps: [Config] },

        // Bucket
        { provide: ContextProvider, useClass: ShellContextProvider },
        { provide: ContentComponentSelector, useClass: ShellContentComponentSelector },
        { provide: FeatureFlagProvider, useClass: ShellFeatureFlagProvider }
    ]
})
export class ShellModule { }
