import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';

import { Authentication } from './authentication';


export interface EditedData {
    edited: boolean;
}

export const unsavedDataGuard: CanDeactivateFn<EditedData> = async component => {
    
    const modal = inject(ModalService);
    const translate = inject(TranslateService);
    const auth = inject(Authentication);

    if (component.edited && auth.isAuthenticated) {
        const confirm = await modal.openConfirm({
            title: translate.instant(ShellTranslationKey.UnsavedChangesModalTitle),
            message: translate.instant(ShellTranslationKey.UnsavedChangesModalMessage),
            confirmLabel: translate.instant(ShellTranslationKey.LeaveLabel),
            cancelLabel: translate.instant(ShellTranslationKey.DontLeaveLabel)
        });
        return !!confirm;
    }
    return true;
}
