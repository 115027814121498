import { DataPropertyDescriptor, RuntimeDefinition, RuntimePage } from '@unifii/library/common';
import {
    ClaimConfig, Company as CompanyInfo, Compound, FormData, TableDetail, TableSourceType, UserAuthProvider, UserInfo, UserStatus
} from '@unifii/sdk';

import { TableData } from 'shell/table/models';


export enum DiscoverContentType {
    Company = 'company',
    User = 'user',
    UserProfile = 'userProfile'
}

export interface AppContent {
    title: string;
}

export interface PageContent extends AppContent {
    page: RuntimePage;
}

export interface ViewContent extends AppContent {
    definition: RuntimeDefinition;
    compound: Compound;
}

export interface CollectionContent extends AppContent {
    definition: RuntimeDefinition;
    compounds: Compound[];
}

export interface CollectionItemContent extends AppContent {
    definition: RuntimeDefinition;
    compound: Compound;
}

export interface FormContent extends AppContent {
    definition: RuntimeDefinition;
    formData: FormData;
}

export interface CompanyContent extends AppContent {
    company?: CompanyInfo;
    claimConfig: ClaimConfig[];
}

export interface UserContent extends AppContent {
    userInfo: UserInfo;
    userAuthProviders: UserAuthProvider[];
    status: UserStatus | null;
}

export interface TableDetailData extends TableDetail, AppContent {
    sourceType: TableSourceType;
    propertyDescriptors: Map<string, DataPropertyDescriptor>;
    item: TableData;
    itemLink?: TableItemLink;
}

// Router link that links to source item
export interface TableItemLink {
    name: string;
    urlSegments: any[];
};
