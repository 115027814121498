import { Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { Modal, ModalData, ModalRuntime, ProgressComponent, SharedTermsTranslationKey } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { TranslationsService } from 'shell/services/translations.service';
import { ShellTranslationKey } from 'shell/shell.tk';


export interface ChangeLanguageModalData {
    languages: string[];
    current: string;
    guard?: boolean;
    userAction?: boolean;
    reload?: boolean;
}

@Component({
    templateUrl: './change-language.html',
    styleUrls: ['./change-language.less']
})
export class ChangeLanguageComponent implements Modal<ChangeLanguageModalData, boolean> {

    @HostBinding('class.uf-form-card') class = true;
    @ViewChild(ProgressComponent, { static: true }) private progressComponent: ProgressComponent;

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly languageLabels: Dictionary<string> = {
        language_en: 'English',
        language_it: 'Italiano',
        language_fr: 'Français',
        language_ru: 'Русский',
        language_ptbr: 'Português'
    };

    previousLanguage: string;
    loading = false;
    error: string | null;

    constructor(
        @Inject(ModalData) public data: ChangeLanguageModalData,
        public runtime: ModalRuntime<ChangeLanguageModalData, boolean>,
        public translations: TranslationsService
    ) {
        this.previousLanguage = this.data.current;
    }

    close() {
        this.runtime.close();
    }

    async confirm() {

        if (this.data.current == null) {
            this.error = ShellTranslationKey.ValidatorLanguageRequired;
            return;
        }

        this.error = null;

        if (this.data.current !== this.previousLanguage) {
            this.progressComponent.start();

            await new Promise((resolve, reject) => {
                // wait for progress to animate
                this.loading = true;
                setTimeout(() => resolve(undefined), 500);
            });

            await this.translations.use(this.data.current, this.data.userAction, this.data.reload);

            if (this.data.reload === false) {
                this.runtime.close(true);
            } else {
                return;
            }
        }

        this.runtime.close();
    }

    removeTranslationKeyInvalidCharacters(stringValue: string) {
        return stringValue.replace('-', '').toLowerCase();
    }
}