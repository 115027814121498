

import { Component, HostBinding, Inject, Input } from '@angular/core';

import { grow } from 'shell/core/drawer-animations';
import { DrawerComponent } from 'shell/core/drawer.component';
import { MenuDrawerComponent } from 'shell/core/menu-drawer.component';
import { Mode, ShellService } from 'shell/core/shell.service';

import { PushNotificationService } from 'capacitor/push-notification.service';

import { Config, Environment } from 'config';


@Component({
    selector: 'us-header',
    templateUrl: './header.html',
    styleUrls: ['./header.less'],
    animations: [grow]
})
export class HeaderComponent {

    @Input() leftDrawer: DrawerComponent;
    @Input() rightDrawer: DrawerComponent;
    @Input() menuDrawer: MenuDrawerComponent;
    @Input() title: string;

    @HostBinding('class.preview') get previewClass() { return this.mode === Mode.Preview; }
    @HostBinding('class.uf-app-bar') appBar = true;

    previewMode = Mode.Preview;
    disablePreviewToggle: boolean;

    constructor(
        public shell: ShellService,
        public notification: PushNotificationService,
        @Inject(Environment) private env: Config
    ) {
        this.disablePreviewToggle = this.env.unifii.preview === true;
    }

    get mode() {
        return this.shell.mode;
    }

    get notify(): boolean {
        return this.shell.getNotificationCount() > 0;
    }

}