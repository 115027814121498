import { Inject, Injectable, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ContextProvider, DataDescriptor, DataDescriptorService, DataPropertyDescriptor, FilterEntry, FormDefinitionMetadataIdentifiers, RuntimeDefinition,
    RuntimeDefinitionAdapter, RuntimePage, SharedTermsTranslationKey, UserInfoIdentifiers, WindowWrapper
} from '@unifii/library/common';
import { DisplayService } from '@unifii/library/smart-forms/display';
import {
    AstNode, ClaimConfig, Client, CompaniesClient, Company, Compound, ErrorType, FieldType, getUserStatus, isUUID, MeClient, NodeType, Option,
    PermissionAction, PublishedContent, Table, TableDetail, TableDetailModule, TableIdentifierFieldDescriptor, TableSourceType, TenantClient,
    UserAuthProvider, UsersClient, VisibleFilterDescriptor
} from '@unifii/sdk';

import {
    CollectionContent, CollectionItemContent, CompanyContent, FormContent, TableDetailData, UserContent, ViewContent
} from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellFormService } from 'shell/form/shell-form.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableFilterEntryFactory } from 'shell/table/table-filter-entry-factory';
import { TableModuleConfig, TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UserInputType } from 'discover/user-management/user-types';

import { Config } from 'config';


export interface ContentDataResolver {
    getView(identifier: string): Promise<ViewContent>;
    /**
     * @param identifier - backwards compatibility with id
     */
    getPage(identifier: string): Promise<RuntimePage>;
    getCollection(identifier: string): Promise<CollectionContent>;
    getCollectionItem(identifier: string, id: number): Promise<CollectionItemContent>;
    getTableData(identifier: string): Promise<{ tablePageConfig: TablePageConfig; filterEntries: FilterEntry[] }>;
    getTableDetailData(itemId: string, tableIdentifier: string, tablePageConfig?: TablePageConfig, source?: string): Promise<TableDetailData>;
    getForm(identifier: string, version?: number): Promise<RuntimeDefinition>;
    getFormData(bucket: string, id: string, hasRollingVersion?: boolean): Promise<FormContent>;
    getCompanyContent(id?: string): Promise<CompanyContent>;
    getUserContent(id: string): Promise<UserContent>;
    getProfileContent(): Promise<UserContent>;
}

export const ContentDataResolver = new InjectionToken<ContentDataResolver>('UfContentDataResolver');

/**
 * Resolves Data for content components
 *  - Checks permissions
 *  - Catches errors
 */
@Injectable()
export class ShellContentDataResolver implements ContentDataResolver {
    /**
     * Create new instance of formService multiple instances can cause timing issues when
     * bucket is set by mutliple active components
     */
    private formService: ShellFormService;

    constructor(
        private display: DisplayService,
        @Inject(PublishedContent) private content: PublishedContent,
        private errorService: ErrorService,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(WindowWrapper) private window: Window,
        private translate: TranslateService,
        private usersClient: UsersClient,
        private tenantClient: TenantClient,
        private companiesClient: CompaniesClient,
        private meClient: MeClient,
        private dataDescriptorService: DataDescriptorService,
        private tableFilterEntryFactory: TableFilterEntryFactory,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
        client: Client,
        offlineQueue: OfflineQueue,
    ) {
        this.formService = new ShellFormService(config, client, offlineQueue, auth, content, contextProvider, translate, errorService, runtimeDefinitionAdapter);
    }

    async getView(identifier: string): Promise<ViewContent> {
        try {
            const data = await this.display.getView(identifier) as { definition: RuntimeDefinition; compound: Compound };
            return { definition: data.definition, compound: data.compound, title: `${data.definition?.label}` };
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getPage(identifier: string): Promise<RuntimePage> {
        try {
            const response = await this.display.getPage(identifier);
            return response.page as RuntimePage;
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getCollection(identifier: string): Promise<CollectionContent> {
        try {
            const definition = await this.runtimeDefinitionAdapter.transform(await this.content.getCollectionDefinition(identifier));
            const compounds = await this.content.queryCollection(identifier);
            return { definition, compounds, title: definition.label };
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getCollectionItem(identifier: string, id: number): Promise<CollectionItemContent> {
        try {
            const data = await this.display.getCollectionItem(identifier, id as any as string) as { definition: RuntimeDefinition; compound: Compound};
            return { definition: data.definition, compound: data.compound, title: `${data.definition?.label}` };
        } catch (e) {
            throw this.errorService.createLoadError(identifier, e);
        }
    }

    async getForm(identifier: string, version?: number): Promise<RuntimeDefinition> {
        return this.formService.getFormDefinition(identifier, version);
    }

    async getFormData(bucket: string, id: string, hasRollingVersion = false): Promise<FormContent> {
        try {
            this.formService.bucket = bucket;
            const formData = await this.formService.getFormData(id);
            const identifier = formData._definitionIdentifier as string;
            const version = hasRollingVersion ? undefined : formData._definitionVersion;
            const definition = await this.getForm(identifier, version);

            return { definition, formData, title: definition.label };

        } catch (e) {
            const loadError = this.errorService.createLoadError(bucket, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getCompanyContent(id?: string): Promise<CompanyContent> {
        try {
            let company: Company | undefined;
            if (id) {
                company = await this.companiesClient.get(id);
            }

            let claimConfig: ClaimConfig[] = [];
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted) {
                claimConfig = await this.tenantClient.getCompanyClaims();
            }

            return { company, claimConfig, title: `${company?.name ?? this.translate.instant(SharedTermsTranslationKey.NewLabel)}` };
        } catch (e) {
            const loadError = this.errorService.createLoadError('company info', e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getUserContent(id: string): Promise<UserContent> {
        try {
            if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(+id), PermissionAction.Read).granted) {
                throw this.userDetailsForbiddenError;
            }

            const userInfo = await this.usersClient.get(id);
            const status = getUserStatus(userInfo);

            let userAuthProviders: UserAuthProvider[] = [];
            if (userInfo.isExternal) {
                userAuthProviders = await this.usersClient.getAuthProviders(id);
            }

            return { userInfo, status, userAuthProviders, title: `${userInfo.firstName} ${userInfo.lastName}` };
        } catch (e) {
            const loadError = this.errorService.createLoadError(id, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getProfileContent(): Promise<UserContent> {
        try {
            const userInfo = await this.meClient.getMe();
            const status = getUserStatus(userInfo);

            let userAuthProviders: UserAuthProvider[] = [];
            if (userInfo.isExternal) {
                userAuthProviders = await this.usersClient.getAuthProviders(userInfo?.id as string);
            }
            return { userInfo, status, userAuthProviders, title: `${userInfo.firstName} ${userInfo.lastName}` };
        } catch (e) {
            const loadError = this.errorService.createLoadError('"me"', e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getTableData(identifier: string): Promise<{ tablePageConfig: TablePageConfig; filterEntries: FilterEntry[] }> {
        try {
            const table = await this.content.getTable(identifier);
            // Check ACL for Bucket and BucketDocuments
            if (!this.canAccessTable(table)) {
                throw this.forbiddenError;
            }

            const tablePageConfig = await this.getTablePageConfig(table);
            const filterEntries = this.createFilterEntries(tablePageConfig.propertyDescriptors, table.visibleFilters, table.sourceType, table.identifier, table.filter);

            return { tablePageConfig, filterEntries };
        } catch (e) {
            const loadError = this.errorService.createLoadError(identifier, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    async getTableDetailData(itemId: string, tableIdentifier: string, tablePageConfig?: TablePageConfig, source?: string): Promise<TableDetailData> {
        try {

            let sourceType = tablePageConfig?.sourceType;
            let propertyDescriptors = tablePageConfig?.propertyDescriptors;
            let detail = tablePageConfig?.table.detail;

            // if content node doesnt have tablePageConfig, we need to retrieve everything
            if (!sourceType || !propertyDescriptors) {
                const tableData = await this.getTableData(tableIdentifier);
                const table = tableData.tablePageConfig.table;
                sourceType = table.sourceType;
                propertyDescriptors = tableData.tablePageConfig.propertyDescriptors;
                detail = table.detail;
                source = table.source;
            }

            if (!detail) {
                console.error(`Table "${tableIdentifier}" missing page detail`);
                throw this.notFoundError;
            }

            switch (sourceType) {
                case TableSourceType.Users: return await this.getUserDetailData(itemId, detail, propertyDescriptors);
                case TableSourceType.Company: return await this.getCompanyDetailData(itemId, detail, propertyDescriptors);
                case TableSourceType.Bucket: return await this.getBucketDetailData(itemId, source as string, detail, propertyDescriptors);
            }

        } catch (e) {
            const loadError = this.errorService.createLoadError(tableIdentifier, e);
            throw this.errorService.mergeError(e, loadError.message);
        }
    }

    private async getTablePageConfig(table: Table): Promise<TablePageConfig> {

        const properties = [
            ...(table.columns ?? []).filter(c => !isUUID(c.identifier)).map(cd => cd.identifier),
            ...(table.visibleFilters ?? []).map(vfo => vfo.identifier),
            ...(table.detail?.fields ?? [])
                .filter(tfd => tfd.type === 'Field')
                .map((fd: TableIdentifierFieldDescriptor) => fd.identifier)
                .filter(v => v != null) as string[]
        ];

        const dataDescriptor = await this.getDataDescriptor(table.sourceType, table.source, properties);
        if (dataDescriptor == null) {
            throw new Error(`Failed create dependencies for table: ${table.identifier}`);
        }

        if (dataDescriptor.skippedProperties && dataDescriptor.skippedProperties.length > 0) {
            console.warn(`DataDescriptor for ${table.sourceType}${table.source ? '[' + table.source + ']' : ''} skipped ${dataDescriptor.skippedProperties.length} properties`);
            for (const sp of dataDescriptor.skippedProperties) {
                console.warn(`${sp.identifier}: ${sp.name}`);
            }
        }

        const propertyDescriptorsMap = dataDescriptor.propertyDescriptorsMap;

        // TODO - Should this be done in DDE ?
        if (table.sourceType === TableSourceType.Users) {
            const units = propertyDescriptorsMap.get(UserInfoIdentifiers.Units);
            if (units) {
                units.type = FieldType.Hierarchy;
                units.icon = 'hierarchy';
            }
        }

        const config: TablePageConfig = {
            table,
            propertyDescriptors: propertyDescriptorsMap,
            sourceType: table.sourceType,
            isSearchable: dataDescriptor.isSearchable === true,
        };

        if (table.sourceType === TableSourceType.Users) {
            config.addOptions = this.getUserTableAddOptions();
        }

        if (table.sourceType === TableSourceType.Bucket) {
            config.bucket = table.source as string;
            config.addOptions = this.getBucketTableAddOptions(config.bucket, propertyDescriptorsMap.get(FormDefinitionMetadataIdentifiers.DefinitionIdentifier));

            const schema = await this.content.getBucket(table.source as string);
            config.hasRollingVersion = schema.hasRollingVersion;
        }

        //AddOptions for modules
        const modules = await Promise.all(table.detail?.modules.map(m => this.getTableModuleConfig(m)) ?? []);
        config.modules = modules;

        return config;
    }

    /**
     * TableModuleConfig contains info to add a new item to the TableModule's Table (aka TMT) that is linked to the parent Table page details.
     * This functionality is available only under the following conditions:
     * 1. TMT is of type Table and has canAdd flag enabled
     * 2. Permission Read for TMT's Table
     * 3. TMT's Table has sourceType FormData
     * 4. Permission Read for TMT's Table Schema
     * 5. TMT's Table has a filter with expression '$detail.<anyvalue>' and map to an existing schema field
     * 6. Permission Add for TMT's Table FormDataRepository
     * 7. Filter each FormDefinition of the Schema by Permission Read
     */
    private async getTableModuleConfig(module: TableDetailModule): Promise<TableModuleConfig> {

        const { identifier, filter } = module;

        // 1. TM is of type Table and has canAdd flag enabled
        if (module.type !== 'Table' || !module.canAdd || !filter) {
            return {};
        }

        // 2. ACL Read access to the TM's Table
        if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getTablePath(this.config.unifii.projectId, module.identifier), PermissionAction.Read).granted) {
            return {};
        }

        const moduleTable = await this.content.getTable(identifier);

        // 3. TM's Table is a FormData table
        if (moduleTable.sourceType !== TableSourceType.Bucket || !moduleTable.source) {
            return {};
        }

        // 4. Permission Read for TM's Table Schema
        const bucketDescriptor = await this.dataDescriptorService.getBucketDataDescriptor(moduleTable.source);
        if (!bucketDescriptor) {
            return {};
        }

        // 5. TM's Table has a filter with expression '$detail.<anyvalue>' and map to an existing schema field
        const filterLink = this.getFilterLink(filter, bucketDescriptor.propertyDescriptorsMap);
        if (!filterLink) {
            return {};
        }

        // 6. Permission Add for TM's Table FormDataRepository
        // 7. Filter each FormDefinition of the Schema by Permission Read
        const addOptions = this.getBucketTableAddOptions(moduleTable.source, bucketDescriptor.propertyDescriptorsMap.get(FormDefinitionMetadataIdentifiers.DefinitionIdentifier));

        if (!addOptions || addOptions.length === 0) {
            return {};
        }

        return { addOptions, filterLink };
    }

    private getBucketTableAddOptions(bucketId: string, definitionDescriptor?: DataPropertyDescriptor): Option[] | undefined {

        if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, bucketId), PermissionAction.Add).granted) {
            return;
        }

        return definitionDescriptor?.options?.filter(option =>
            this.auth.getGrantedInfoWithoutCondition(
                PermissionsFunctions.getFormPath(this.config.unifii.projectId, option.identifier),
                PermissionAction.Read
            ).granted
        ).sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }

    /** This need to respect the field identifier transformation done for the AstNode in the FilterEditor */
    private getFilterLink(filter: AstNode, properties: Map<string, DataPropertyDescriptor>): { identifier: string; expression: string } | undefined {

        if (!filter.args) {
            return;
        }

        // Find potential node as first 'complete' node with a valid $detail expression
        const node = filter.args.find(arg => {
            if (!arg.args || arg.args.length !== 2) {
                return false;
            }

            if (arg.args[1].type !== NodeType.Expression) {
                return false;
            }

            const nodeIdentifier = arg.args[0].value as string | undefined;
            const nodeExpression = arg.args[1].value as string | undefined;

            if (!nodeIdentifier || !nodeExpression) {
                return false;
            }

            if (!nodeExpression.startsWith('$detail.')) {
                return false;
            }

            return true;
        });

        // No potential node
        if (!node || !node.args) {
            return;
        }

        // Lookup for the potential node identifier Field among the available properties
        // Filter editor identifier transformation exceptions
        const originalIdentifier = node.args[0].value;
        const expression = node.args[1].value;
        let modifiedIdentifier;
        let dp: DataPropertyDescriptor | undefined;

        // DS field
        if (originalIdentifier.endsWith('._id')) {
            modifiedIdentifier = originalIdentifier.slice(0, -'._id'.length);
            dp = properties.get(modifiedIdentifier);
            if (dp && dp.sourceConfig && [FieldType.Choice, FieldType.Lookup].includes(dp.type)) {
                return { identifier: modifiedIdentifier, expression };
            }
        }
        // ZoneDateTime field
        if (originalIdentifier.endsWith('.value')) {
            modifiedIdentifier = originalIdentifier.slice(0, -'.value'.length);
            dp = properties.get(modifiedIdentifier);
            if (dp?.type === FieldType.ZonedDateTime) {
                return { identifier: modifiedIdentifier, expression };
            }
        }

        dp = properties.get(originalIdentifier);
        if (dp) {
            return { identifier: originalIdentifier, expression };
        }

        return;
    }

    private getUserTableAddOptions(): Option[] {
        const options: Option[] = [];
        if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted) {
            options.push({ identifier: UserInputType.Invite, name: this.translate.instant(SharedTermsTranslationKey.ActionInvite) });
        }
        if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Add).granted) {
            options.push({ identifier: UserInputType.Create, name: this.translate.instant(SharedTermsTranslationKey.ActionCreate) });
        }
        return options;
    }

    private createFilterEntries(
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        filters: VisibleFilterDescriptor[] = [],
        source: TableSourceType,
        tableIdentifier: string,
        staticFilter?: AstNode
    ): FilterEntry[] {
        return filters.map(f => this.tableFilterEntryFactory.create(f, propertyDescriptors, source, tableIdentifier, staticFilter))
            .filter(f => f != null) as FilterEntry[];
    }

    private async getDataDescriptor(type: TableSourceType, bucket?: string, properties?: string[]): Promise<DataDescriptor | undefined> {
        switch (type) {
            case TableSourceType.Users: return await this.dataDescriptorService.getUserDataDescriptor(properties);
            case TableSourceType.Company: return await this.dataDescriptorService.getCompanyDataDescriptor(properties);
            case TableSourceType.Bucket: return await this.dataDescriptorService.getBucketDataDescriptor(bucket as string, [FormDefinitionMetadataIdentifiers.DefinitionIdentifier, ...(properties ?? [])]);
            default: throw new Error('Could not result DataDescriptor type');
        }
    }

    private canAccessTable(table: Table): boolean {
        switch (table.sourceType) {
            case TableSourceType.Users:
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted;
            case TableSourceType.Company:
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.List).granted;
            case TableSourceType.Bucket: {
                return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketPath(this.config.unifii.projectId, table.source as string), PermissionAction.Read).granted &&
                    this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, table.source as string), PermissionAction.List).granted;
            };
            default: return true;
        }
    }

    private async getUserDetailData(id: string, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

        if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(+id), PermissionAction.Read).granted) {
            throw this.forbiddenError;
        }

        const item = await this.usersClient.get(id);
        let itemLink;

        if (this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+id), PermissionAction.Update, item, this.contextProvider.get()).granted) {
            itemLink = {
                name: this.translate.instant(SharedTermsTranslationKey.ActionEdit),
                urlSegments: ['../', id]
            };
        }

        return {
            sourceType: TableSourceType.Users,
            ...detail,
            item,
            propertyDescriptors,
            itemLink
        };
    }

    private async getCompanyDetailData(id: string, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

        const item = await this.companiesClient.get(id);
        let itemLink;

        if (this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(id), PermissionAction.Update, item, this.contextProvider.get()).granted) {
            itemLink = {
                name: this.translate.instant(SharedTermsTranslationKey.ActionEdit),
                urlSegments: ['../', id]
            };
        }

        return {
            ...detail,
            sourceType: TableSourceType.Company,
            item,
            propertyDescriptors,
            itemLink
        };
    }

    private async getBucketDetailData(id: string, bucket: string, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

        this.formService.bucket = bucket;
        const item = await this.formService.getFormData(id);

        return {
            ...detail,
            sourceType: TableSourceType.Bucket,
            item,
            propertyDescriptors,
            itemLink: {
                name: this.translate.instant(SharedTermsTranslationKey.ActionView),
                urlSegments: ['/', FormDataPath, bucket, id, { prevUrl: this.window.location.pathname }]
            }
        };
    }


    private get forbiddenError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorRequestForbidden), ErrorType.Forbidden);
    }

    private get userDetailsForbiddenError(): AppError {
        return new AppError(this.translate.instant(this.translate.instant(DiscoverTranslationKey.UserDetailsErrorUnauthorized)), ErrorType.Forbidden);
    }

    private get notFoundError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
    }

}
