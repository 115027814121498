import { HttpClientModule } from '@angular/common/http';
import { NgModule, RendererFactory2 } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContextProvider, FieldDescriptionServiceProvider, ThemeProvider, ThemeService, WindowWrapper } from '@unifii/library/common';
import { PageComponentRegistry, ViewComponentRegistry } from '@unifii/library/smart-forms/display';
import { FormComponentRegistry, FormDescriptionListRegistry } from '@unifii/library/smart-forms/input';
import { PermissionAction } from '@unifii/sdk';
import { FormPermissionController } from '@unifii/user-provisioning';

import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { InitStep } from 'shell/init-step';
import { PostUpdateHook } from 'shell/offline/post-update-hook';
import { ShellModule } from 'shell/shell.module';

import { AppUpdateService } from 'discover/app-update/app-update.service';
import { RequiredUpdateModalComponent } from 'discover/app-update/required-update-modal.component';
import { DirectoryTemplateComponent } from 'discover/components/directory-template/directory-template.component';
import { LoadingBarComponent } from 'discover/components/loading-bar.component';
import { SignInHistoryComponent } from 'discover/components/sign-in/sign-in-history.component';
import { SignInComponent } from 'discover/components/sign-in/sign-in.component';
import { ContentLinkDescriptionComponent } from 'discover/components/smart-form/content-link-description.component';
import { DiscoverContentLinkComponent } from 'discover/components/smart-form/content-link.component';
import { StepperSectionComponent } from 'discover/components/smart-form/section-stepper.component';
import { FileListDisplayComponent } from 'discover/content/file-list.component';
import { UdPageComponentRegistry } from 'discover/content/page-component-registry';
import { UdViewComponentRegistry } from 'discover/content/view-component-registry';
import { DiscoverContentComponentFactory } from 'discover/discover-content-node-factory';
import { DiscoverContext } from 'discover/discover-context';
import { ExternalService } from 'discover/external.service';
import { UdFormComponentRegistry } from 'discover/form-component-registry';
import { UdFormDescriptionListRegistry } from 'discover/form-description-list-registry';
import { DiscoverIconsComponent } from 'discover/icons/icons.component';
import { LogoutInitStep } from 'discover/logout-init-step';
import { MainComponent } from 'discover/main.component';
import { OfflineFormComponent } from 'discover/offline-forms/offline-form.component';
import { OfflineFormsListComponent } from 'discover/offline-forms/offline-forms-list.component';
// Discover
import { FakePostUpdateHook } from 'discover/offline/fake-post-update-hook';
import { UpdateProgressComponent } from 'discover/offline/update-progress.component';
import { DiscoverPinService } from 'discover/pin/discover-pin.service';
import { PasswordCheckComponent } from 'discover/pin/password-check.component';
import { PinCheckComponent } from 'discover/pin/pin-check.component';
import { PinSetupComponent } from 'discover/pin/pin-setup.component';
import { PinService } from 'discover/pin/pin-types';
import { DISCOVER_REPORT_COMPONENTS } from 'discover/reports';
import { DiscoverContextProvider } from 'discover/services/discover-context-provider';
import { DiscoverFieldDescriptionService } from 'discover/services/discover-field-description.service';
import { DiscoverSettingsComponent } from 'discover/settings/settings.component';
import { CompleteRegistrationComponent } from 'discover/user-access/complete-registration.component';
import { ContactInfoModalComponent } from 'discover/user-access/contact-info-modal.component';
import { LoginFormComponent } from 'discover/user-access/login/login-form.component';
import { LoginComponent } from 'discover/user-access/login/login.component';
import { SSOComponent } from 'discover/user-access/login/sso.component';
import { PasswordChangeComponent } from 'discover/user-access/password-change.component';
import { RequestPasswordChangeComponent } from 'discover/user-access/request-password-change.component';
import { SelectProjectComponent } from 'discover/user-access/select-project.component';
import { TenantSelectorComponent } from 'discover/user-access/tenant-selector.component';
import { UserAccessBoxComponent } from 'discover/user-access/user-access-box.component';
import { UserAccessComponent } from 'discover/user-access/user-access.component';
import { PinInputComponent } from 'discover/user-management/pin-input.component';
import { UserAddComponent } from 'discover/user-management/user-add.component';
import { UserCreateComponent } from 'discover/user-management/user-create.component';
import { UserDetailsComponent } from 'discover/user-management/user-details.component';
import {
    UserFormPermissionConfig, UserFormPermissionController, UserFormResourceType
} from 'discover/user-management/user-form-permission-controller';
import { UserInputComponent } from 'discover/user-management/user-input.component';
import { UserUploadCsvComponent } from 'discover/user-management/user-upload-csv.component';
import { UserUploadModalComponent } from 'discover/user-management/user-upload-modal.component';
import { UserUploadResultComponent } from 'discover/user-management/user-upload-result.component';

import { FingerPrintService } from 'capacitor/finger-print.service';


const createThemeProvider = (window: Window, rendererFactory: RendererFactory2): ThemeService => {
    const element = window.document.documentElement;
    return new ThemeService(element, rendererFactory);
};

@NgModule({
    imports: [
        RouterModule, HttpClientModule, ShellModule
    ],
    declarations: [
        MainComponent, DiscoverSettingsComponent, DiscoverIconsComponent, LoadingBarComponent,
        UserAccessBoxComponent, ContactInfoModalComponent, LoginComponent, LoginFormComponent,
        SSOComponent, UserAccessComponent, TenantSelectorComponent, SelectProjectComponent,
        CompleteRegistrationComponent, RequestPasswordChangeComponent, PasswordChangeComponent, UserCreateComponent,
        UserInputComponent, UserUploadCsvComponent, UserDetailsComponent,
        OfflineFormsListComponent, OfflineFormComponent, UpdateProgressComponent, UserAddComponent,
        DirectoryTemplateComponent, PasswordCheckComponent, RequiredUpdateModalComponent,
        PinSetupComponent, PinCheckComponent, UserUploadModalComponent, UserUploadResultComponent, SignInHistoryComponent, SignInComponent,
        PinInputComponent, FileListDisplayComponent, StepperSectionComponent, DiscoverContentLinkComponent, ContentLinkDescriptionComponent,
        DISCOVER_REPORT_COMPONENTS
    ],
    exports: [
        ShellModule,
        MainComponent, DiscoverSettingsComponent, DiscoverIconsComponent, LoadingBarComponent,
        UserAccessBoxComponent, ContactInfoModalComponent, LoginComponent, LoginFormComponent,
        SSOComponent, UserAccessComponent, TenantSelectorComponent, SelectProjectComponent,
        CompleteRegistrationComponent, RequestPasswordChangeComponent, PasswordChangeComponent, UserCreateComponent,
        UserInputComponent, UserUploadCsvComponent, UserDetailsComponent,
        OfflineFormsListComponent, OfflineFormComponent, UpdateProgressComponent,
        DirectoryTemplateComponent, PasswordCheckComponent, RequiredUpdateModalComponent,
        PinSetupComponent, PinCheckComponent, UserUploadModalComponent, UserUploadResultComponent, SignInHistoryComponent, SignInComponent,
        PinInputComponent, FileListDisplayComponent, StepperSectionComponent, DiscoverContentLinkComponent, ContentLinkDescriptionComponent,
        DISCOVER_REPORT_COMPONENTS
    ],
    providers: [
        // App Context
        DiscoverContext,
        { provide: ContextProvider, useClass: DiscoverContextProvider },
        // Init
        { provide: InitStep, useClass: LogoutInitStep, multi: true },
        // Guards
        AppUpdateService,
        ExternalService,
        // Theme
        { provide: ThemeProvider, useFactory: createThemeProvider, deps: [WindowWrapper, RendererFactory2] },
        // Content
        { provide: PageComponentRegistry, useClass: UdPageComponentRegistry },
        { provide: ViewComponentRegistry, useClass: UdViewComponentRegistry },
        { provide: ContentComponentFactory, useClass: DiscoverContentComponentFactory },
        { provide: FieldDescriptionServiceProvider, useClass: DiscoverFieldDescriptionService },
        // Forms
        { provide: FormComponentRegistry, useClass: UdFormComponentRegistry },
        { provide: FormDescriptionListRegistry, useClass: UdFormDescriptionListRegistry },
        // FormControllers
        { provide: UserFormPermissionConfig, useValue: { path: UserFormResourceType.Me, formAction: PermissionAction.Update } },
        { provide: FormPermissionController, useClass: UserFormPermissionController },
        // Auth
        FingerPrintService,
        { provide: PinService, useClass: DiscoverPinService },
        // Offline
        { provide: PostUpdateHook, useClass: FakePostUpdateHook },

    ]
})
export class DiscoverModule { }
