<div *ngIf="error">
    <uf-message class="col-1of1 error padded" icon="errorSolid" [content]="error.message">
        <h3>{{ error.code}}</h3>
    </uf-message>
</div>

<ng-template [ngIf]="tableConfig">
    <uf-expander [actionBtn]="true">
        <!-- header -->
        <div expanderHeader class="uf-app-bar medium accent">
            <div class="title">
                <span *ngIf="recordCount" class="record-count">
                    {{recordCount}} -
                </span>
                <span>
                    {{tableInfo.table.title}}
                </span>
                <a [routerLink]="['/', tableInfo.table.identifier]">
                    <ng-template [ngIf]="pageTable && pageTable.status.exhausted === false">
                        <uf-icon class="small" name="moreInfo"></uf-icon>
                    </ng-template>
                </a>
            </div>
        </div>
        <div expanderBody class="pad-none gap-none uf-box flat-md table-page">
            <uf-table #pageTable [config]="tableConfig" [datasource]="datasource" class="small-message list-md">
                <ng-container *ngFor="let descriptor of customColumns">
                    <ng-container *cellFor="descriptor.name; let item; let index = index; let column = column;">
                        <uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor">
                        </uf-custom-cell>
                    </ng-container>
                </ng-container>
            </uf-table>
            <div *ngIf="showSeeMoreRow"
                class="row center-all float-right pad-sm-sides">
                <div class="header">
                    <a [routerLink]="tableLink">
                        <span>
                            {{shellTK.DashboardBucketActionSeeMore | translate}}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </uf-expander>

</ng-template>
