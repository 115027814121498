import { Component, OnInit, Optional } from '@angular/core';
import { Breadcrumb, RuntimeDefinition } from '@unifii/library/common';
import { Compound, FieldType } from '@unifii/sdk';

import { CollectionComponent } from 'shell/content/collection.component';
import { CollectionItemContent } from 'shell/content/content-types';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';


@Component({
    templateUrl: './collection-item.html',
    styleUrls: ['./collection-item.less'],
    providers: [BreadcrumbsService]
})
export class CollectionItemComponent implements OnInit, CollectionItemContent {

    definition: RuntimeDefinition;
    compound: Compound;
    title: string;
    breadcrumbs: Breadcrumb[] = [];

    constructor(
        private breadcrumbsService: BreadcrumbsService,
        @Optional() private collectionComponent: CollectionComponent
    ) {
    }

    ngOnInit() {
        this.breadcrumbsService.title = this.title;
        if (this.collectionComponent != null) {
            this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
        }
    }

    get compoundLabel(): string | undefined {

        const field = this.definition.fields.find(f => f.type === FieldType.Text);

        if (field != null) {
            return this.compound[field.identifier as string];
        }
        return this.compound.id;
    }

}
