

import { getDocument, PDFDocumentProxy } from 'pdfjs-dist';
import { EventBus, NullL10n, PDFLinkService, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';

import { Component, ElementRef, HostBinding, HostListener, Inject, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RuntimeField, WindowWrapper } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';

import { ShellTranslationKey } from 'shell/shell.tk';

import { Config } from 'config';


export interface PDFAsset {
    url: string;
}

@Component({
    selector: 'us-pdf-viewer',
    templateUrl: './pdf-viewer.html',
    styleUrls: ['./pdf-viewer.less']
})
export class PdfViewerComponent implements ContentField, OnInit {

    @Input() content: PDFAsset[];
    @Input() field: RuntimeField;

    @HostBinding('class.uf-box') boxClass = true;
    @HostBinding('class.expanded') isExpanded = false;
    @ViewChild('pdfContainer', { static: true }) private pdfContainer: ElementRef<HTMLDivElement>;

    readonly shellTK = ShellTranslationKey;

    error: boolean;
    loading: boolean;

    private pdfDocument: PDFDocumentProxy;
    private pdfViewer: PDFViewer;
    private defaultScaleDelta = 1.1;
    private minScale = 0.15;
    private maxScale = 10.0;
    private defaultScaleValue = 'auto';
    private scrollableElement?: HTMLElement;

    constructor(@Inject(Config) private config: Config, @Inject(WindowWrapper) private window: Window, private sanitizer: DomSanitizer){}

    // prevent right-click
    @HostListener('contextmenu', ['$event'])
    onRightClick(event: Event) {
        event.preventDefault();
    }

    async ngOnInit() {
        this.scrollableElement = this.getScrollableElement(this.pdfContainer.nativeElement);

        if(!this.content[0]?.url){
            this.error = true;
        }

        let pdfUrl = this.content[0].url;

        // unsafe url must be converted to url string for pdf viewer
        if(this.config.unifii.offline) {
            pdfUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL,this.content[0].url) || '';
        }

        const container = this.pdfContainer.nativeElement;
        const pdfEvents = new EventBus();
        const linkService = new PDFLinkService({ eventBus: pdfEvents });

        try {
            this.loading = true;
            this.pdfDocument = await getDocument(pdfUrl).promise;
        } catch (error) {
            this.error = true;
            this.loading = false;
        }

        this.pdfViewer = new PDFViewer({
            container,
            eventBus: pdfEvents,
            linkService,
            l10n: NullL10n,
            useOnlyCssZoom: true,
            textLayerMode: 0,
        });

        linkService.setViewer(this.pdfViewer);
        this.pdfViewer.setDocument(this.pdfDocument);

        pdfEvents.on('pagesinit', () => {
            this.pdfViewer.currentScaleValue = this.defaultScaleValue.toString();
            this.pdfViewer.container.scrollTop = 0;
            this.loading = false;
            this.minScale = Math.min(this.pdfViewer.currentScale, this.minScale);
        });
    }

    protected get pagesCount() {
        return this.pdfDocument?.numPages;
    };

    protected get page() {
        return this.pdfViewer?.currentPageNumber;
    };

    toggleExpandedMode(){
        this.isExpanded = !this.isExpanded;

        if(!this.scrollableElement){
            return;
        }

        // disable scroll when expanded
        if (this.isExpanded){
            this.scrollableElement.style.overflow = 'hidden';
            this.scrollableElement.scroll({top: 0});
        } else {
            this.scrollableElement.style.overflow = 'auto';
        }
    }

    nextPage() {
        this.pdfViewer.nextPage();
    }

    previousPage() {
        this.pdfViewer.previousPage();

        if(this.page === 1){
            this.pdfViewer.container.scrollTop = 0;
        }
    }

    zoomIn() {
        let newScale = this.pdfViewer.currentScale;
        newScale = Number((newScale * this.defaultScaleDelta).toFixed(2));
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(this.maxScale, newScale);
        this.pdfViewer.currentScaleValue = newScale.toString();
    }

    zoomOut() {
        let newScale = this.pdfViewer.currentScale;
        newScale = Number((newScale / this.defaultScaleDelta).toFixed(2));
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(this.minScale, newScale);
        this.pdfViewer.currentScaleValue = newScale.toString();
    }

    private getScrollableElement(element?: HTMLElement): HTMLElement | undefined {
        if (!element) {
            return;
        }

        const computedStyle = this.window.getComputedStyle(element);
        if (computedStyle.overflow === 'auto' || computedStyle.overflowY === 'auto') {
            return element;
        }

        return this.getScrollableElement(element.parentElement ?? undefined);
    }
}
