import { first, firstValueFrom, interval, Subscription } from 'rxjs';

import { Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, CommonTranslationKey, ContextProvider, FilterEntry, FilterValue, ModalService, RuntimeDefinition, SharedTermsTranslationKey,
    ToastService
} from '@unifii/library/common';
import { FormConfiguration, FormFunctions, FormSettings } from '@unifii/library/smart-forms';
import { PrintConfig, PrintFormModalComponent, SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { Dictionary, FormData, generateUUID, ParentFormData, PermissionAction, TransitionTrigger, UserContext } from '@unifii/sdk';

import { FormContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { FormTriggerComponent, FormTriggerData } from 'shell/form/form-trigger.component';
import { SaveOutput, SaveResult, ShellFormService } from 'shell/form/shell-form.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { FormDataState } from 'shell/offline/forms/interfaces';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { FormDataPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverContext } from 'discover/discover-context';
import { TriggerFormContextProvider } from 'discover/trigger-form-context-provider';

import { Config } from 'config';

import { ConflictModalAction, ConflictModalComponent } from './conflict-modal.component';


interface ParentFormInfo {
    label: string;
    routerLink: any[];
}

/**
 * FormComponent
 * This class is created by the ContentNodeComponent, which is responsible for:
 *  - Catching any load errors
 *  - Catching any basic ACL errors
 *  - Show loading Skeleton
 *  - Resolving Data, FormData and Definition
 *
 * to route to this component using the ContentNode use the following commands
 * [`/[bucket],[id]
 * eg: /abc/123
 */
@Component({
    templateUrl: './form.html',
    styleUrls: ['./form.less'],
    providers: [BreadcrumbsService]
})
export class FormComponent implements OnInit, OnDestroy, EditedData, FormContent {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly commonTK = CommonTranslationKey;

    // Status
    reloading: boolean;
    newSubmission: boolean;
    formLabel: string;
    isDisabled: boolean;
    busy: boolean;
    triggerError: AppError;
    user: UserContext | undefined;
    breadcrumbs: Breadcrumb[] = [];
    prevUrl?: string;
    title: string;

    // Form
    definition: RuntimeDefinition;
    formData: FormData;
    formConfig: FormConfiguration;
    printConfig: PrintConfig | undefined;
    parentInfo: ParentFormInfo;

    private _formComponent: UfFormComponent | null;
    private _edited: boolean;
    private changesSubscriptions = new Subscription();
    private revisionSubscription: Subscription | undefined;

    constructor(
        private route: ActivatedRoute,
        private context: DiscoverContext,
        private navigation: NavigationService,
        private modalService: ModalService,
        private toastService: ToastService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private router: Router,
        private element: ElementRef<HTMLElement>,
        private breadcrumbsService: BreadcrumbsService,
        @Inject(Config) private config: Config,
        private formService: ShellFormService,
        private offlineQ: OfflineQueue,
        private ngZone: NgZone,
        @Inject(FormSettings) private settings: FormSettings,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(Authentication) private auth: Authentication,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<FormData, FilterValue, FilterEntry>,
        @Optional() @Inject(TablePageConfig) private tableConfig: TablePageConfig,
        @Optional() private detailPage: TableDetailComponent
    ) { }

    get bucketLabel(): string | undefined {
        return this.navigation.current?.name;
    }

    ngOnInit() {
        this.busy = false;
        this.user = this.contextProvider.get().user;
        this.isDisabled = this.getDisabledStatus(this.config.unifii.projectId, this.definition.bucket as string, this.formData);
        this.formLabel = this.getFormLabel(this.formData);
        this.formService.bucket = this.definition.bucket as string;
        this.newSubmission = this.formData == null;
        this.prevUrl = this.route.snapshot.params.prevUrl;

        this.updateTitle();
        this.applyURLParamsAutofill();

        this.formConfig = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            optionalSubmitButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionSubmit)
        };

        this.settings.uploader = this.formService.getFileUploader(this.formData?.id || '');

        if (this.formData == null) {
            this.formData = { id: generateUUID() };
        }

        this.initConflictDetection();
        this.setParentInfo();
    }

    ngOnDestroy() {
        this.unsubscribeToFormChanges();
        this.revisionSubscription?.unsubscribe();
    }

    @ViewChild(UfFormComponent, { static: false }) set formComponent(v: UfFormComponent) {

        if (v == null || this.formComponent) {
            return;
        }
        this._formComponent = v;

        this.subscribeToFormChanges();
    }

    get formComponent(): UfFormComponent {
        return this._formComponent as UfFormComponent;
    }

    get edited(): boolean {
        return this._edited;
    }

    set edited(v: boolean) {
        this._edited = v;
        this.updateTitle();
    }

    private get breadcrumbTitle() {
        let title = this.newSubmission ? this.translate.instant(SharedTermsTranslationKey.NewLabel) :
            this.formData?._seqId || this.title;
        if (this.edited) {
            title = title + ' *';
        }
        return title;
    }

    back() {
        if (this.prevUrl) {
            this.router.navigateByUrl(this.prevUrl);
            return;
        }

        if (this.tableConfig) {
            this.router.navigate(['..'], { relativeTo: this.route });
            return;
        }

        this.router.navigate(['/']);
    }

    async print() {
        const logo: string | undefined = this.context?.project?.logo?.url;

        const summary = await this.modalService.openFit<null, boolean>(PrintFormModalComponent, null);
        if (summary !== undefined) {
            this.printConfig = {
                definition: this.definition,
                data: JSON.parse(JSON.stringify(this.formData)),
                logoUrl: logo,
                uploader: this.settings.uploader,
                summary
            };
        }
    }

    async save(args: SubmitArgs) {

        if (this.busy) {
            return;
        }

        const path = this.newSubmission ?
            PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, this.formService.bucket) :
            PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, this.formService.bucket, args.data.id as string);

        const action = this.newSubmission ?
            PermissionAction.Add :
            PermissionAction.Update;

        if (!this.auth.getGrantedInfo(path, action, args.data, this.contextProvider.get()).granted) {
            this.toastService.info(this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized));
            return;
        }

        let saveOutput: SaveOutput | undefined;

        try {
            this.busy = true;
            this.unsubscribeToFormChanges();

            saveOutput = await this.formService.save(args.data, this.definition);

            if (saveOutput.result === SaveResult.Failed) {
                const error = this.errorService.createSaveError('form');
                this.toastService.error(error.message);
                this.busy = false;
                this.subscribeToFormChanges();
                return;
            }

            if (saveOutput.result === SaveResult.Conflict) {
                this.busy = false;
                const result = await this.onConflictDetected();
                if (result !== 'Save') {
                    this.subscribeToFormChanges();
                }
                return;
            }

            args.done(saveOutput.data);
            this.edited = false;
            this.busy = false;

            if (saveOutput.result === SaveResult.Queued) {
                this.toastService.warning(this.translate.instant(ShellTranslationKey.FormFeedbackQueued));
                this.back();
                return;
            }

            if (this.tableManager) {
                this.tableManager.updateItem?.next({ item: saveOutput.data, key: 'id' });
            }

            if (this.detailPage && saveOutput.data) {
                this.detailPage.updateDetails(saveOutput.data);
            }

            this.toastService.success(this.translate.instant(ShellTranslationKey.FormFeedbackSaved));

            if (!saveOutput.data) {
                this.back();
                return;
            }

            for (const trigger of (args.triggers || [])) {
                await this.openTargetForm(trigger, saveOutput.data);
            }

            if (FormFunctions.canKeepEditingOnNext(this.definition.fields, saveOutput.data, this.user?.roles)) {
                this.subscribeToFormChanges();
                return;
            }

            this.back();

        } catch (e) {
            if (saveOutput?.data == null && args.triggers?.length) {
                this.triggerError = this.errorService.createError('Form trigger failed', e);
            } else {
                const error = this.errorService.createSaveError('form', e);
                this.toastService.error(error.message);
            }

        } finally {
            this.busy = false;
        }
    }

    private updateTitle() {
        this.breadcrumbsService.title = this.breadcrumbTitle;
        if (!this.prevUrl && this.tableConfig) {
            this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
        }
    }

    /** Override the Definition fields autofill based on the params */
    private applyURLParamsAutofill() {

        if (!this.newSubmission) {
            return;
        }

        const autofillsParamMap = this.route.snapshot.paramMap;
        const autofills = {} as Dictionary<any>;
        for (const k of autofillsParamMap.keys) {
            autofills[k] = autofillsParamMap.get(k);
        }

        // TODO Remove the filter as prevUrl can be a field identifier for autofill
        delete autofills.prevUrl;

        FormFunctions.amendDefinitionAutofills(this.definition, autofills);
    }

    private initConflictDetection() {
        if (!this.formData || this.isDisabled || this.newSubmission) {
            return;
        }

        this.revisionSubscription?.unsubscribe();
        this.revisionSubscription = interval(15000).subscribe(async () => {
            const latestRevision = await this.formService.getFormDataRevision(this.formData.id as string);
            if (latestRevision && this.formData?._rev !== latestRevision) {
                this.revisionSubscription?.unsubscribe();
                this.onConflictDetected();
            }
        });
    }

    private async onConflictDetected(): Promise<ConflictModalAction> {
        const result = await this.modalService.openMedium(ConflictModalComponent, undefined, { guard: true });

        switch (result) {

            case 'Save':
                await this.offlineQ.save(this.formData, this.definition, { status: FormDataState.Conflicted });
                this.edited = false;
                this.back();
                return result;

            case 'Discard':
                const formData = await this.formService.getFormData(this.formData.id as string);

                if (!this.definition.hasRollingVersion) {
                    // Only reload the FormData, the Definition don't need update
                    this.formData = formData;
                    this.edited = false;
                    return result;
                }

                const definition = await this.formService.getFormDefinition(this.definition.identifier);

                this.reloading = true;
                await firstValueFrom(this.ngZone.onStable.pipe(first()));
                this.ngZone.runTask(() => {
                    this.ngOnDestroy();
                    this.formData = formData;
                    this.definition = definition;
                    this.ngOnInit();
                    this.edited = false;
                    this.reloading = false;
                });
                return result;

            default:
                return result;
        }
    }

    private async setParentInfo() {
        if (this.formData?._parent == null) {
            return;
        }
        this.parentInfo = await this.getParentInfo(this.formData._parent);
    }

    private getDisabledStatus(projectId: string, bucket: string, formData?: FormData): boolean {
        if (!formData) {
            return !this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(projectId, bucket), PermissionAction.Add).granted;
        }
        return !this.auth.getGrantedInfo(PermissionsFunctions.getBucketDocumentPath(projectId, bucket, formData.id as string), PermissionAction.Update, formData, this.contextProvider.get()).granted;
    }

    private subscribeToFormChanges() {
        this.unsubscribeToFormChanges();

        // Guarantee we start from a fresh 'falsy' status
        this.edited = false;

        if (!this._formComponent) {
            return;
        }

        if (this._formComponent.rootControl) {
            this.changesSubscriptions.add(this._formComponent.rootControl.valueChanges.subscribe(() => {
                if (this._formComponent?.rootControl.dirty) {
                    this.edited = true;
                }
            }));
        }

        // scroll to active section on Next Flag
        this.changesSubscriptions.add(this._formComponent.workflow.updated.subscribe(formData => {

            const nextSections = FormFunctions.targetSectionNextCondition(this.definition.fields, formData._action, formData._state);

            if (!nextSections.length) {
                return;
            }

            setTimeout(() => {
                FormFunctions.scrollToActiveSection(this.element.nativeElement);
            }, 0);
        }));
    }

    private unsubscribeToFormChanges() {
        this.changesSubscriptions.unsubscribe();
        this.changesSubscriptions = new Subscription();
    }

    private openTargetForm(trigger: TransitionTrigger, formData: FormData) {
        return this.modalService.openFit<FormTriggerData, boolean>(
            FormTriggerComponent,
            (Object.assign({}, trigger, { parentBucket: this.formService.bucket })) as FormTriggerData,
            undefined,

            [{
                provide: ContextProvider,
                useValue: new TriggerFormContextProvider(this.contextProvider, formData)
            }]
        );
    }

    private async getParentInfo(parent: ParentFormData): Promise<{ label: string; routerLink: any[] }> {

        const seqId = parent.seqId || parent.id;
        const routerLink = ['/', FormDataPath, parent.bucket, parent.id];

        try {
            if (parent.definitionIdentifier) {
                const definition = await this.formService.getFormDefinition(parent.definitionIdentifier);

                return {
                    label: `${definition.label} - ${seqId}`,
                    routerLink
                };
            }
        } catch (e) { }

        return {
            label: seqId,
            routerLink
        };
    }

    private getFormLabel(formData: FormData): string {

        if (formData == null) {
            return `${this.definition.label} - ${this.translate.instant(SharedTermsTranslationKey.NewLabel)}`;
        }

        if (formData?._seqId) {
            return `${this.definition.label} - ${formData._seqId}`;
        }

        return `${this.definition.label}`;
    }

}
