import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { ProjectInfo, TenantClient } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { Features } from 'shell/features';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';


@Component({
    selector: 'ud-select-project',
    templateUrl: 'select-project.html'
})
export class SelectProjectComponent implements OnInit {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    control: UfControl;
    projects: ProjectInfo[] = [];

    constructor(
        private tenantClient: TenantClient,
        private features: Features,
        private route: ActivatedRoute,
        @Inject(Authentication) public auth: Authentication,
        private savedUsers: SavedUsersService,
        private errorService: ErrorService,
        private translate: TranslateService,
        private context: DiscoverContext,
        private offlineManager: OfflineManager,
        private accessManager: UserAccessManager
    ) { }

    ngOnInit() {

        this.control = new UfControl(ValidatorFunctions.required(this.translate.instant(DiscoverTranslationKey.SelectProjectErrorProjectRequired)));
        this.projects = this.auth.allowedProjects;

        if (!this.projects.length) {
            this.accessManager.showError(this.errorService.createError(this.translate.instant(DiscoverTranslationKey.SelectProjectErrorNoProjects)));
        }

        if (this.route.snapshot.queryParams.autofill !== 'false') {
            this.autofillProject();
        }

        const { projectId } = this.route.snapshot.params;
        if (projectId) {
            this.control.setValue(projectId);
        }
    }

    async submit() {

        this.accessManager.showError(null);

        if (this.control.invalid) {
            this.control.setSubmitted();
            return;
        }

        try {
            this.context.project = await this.tenantClient.getProject(this.control.value);
            this.savedUsers.updateProject(this.auth.userInfo, this.control.value);

            if (this.features.offline) {
                // Close previous project DB reference
                this.offlineManager.projectChanged();
            }

            this.accessManager.grant();

        } catch (error) {
            this.accessManager.showError(this.errorService.createLoadError('project', error));
        }
    }

    logout() {
        this.auth.logout();
    }

    private autofillProject() {

        const userProject = this.savedUsers.userProject(this.auth.userInfo);

        if (userProject && this.projects.find(p => p.id === userProject)) {
            this.control.setValue(userProject);
        }
    }

}
