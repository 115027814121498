import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { generateUUID, Progress } from '@unifii/sdk';

import { ContentIndexer } from 'shell/offline/content-indexer';
import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { ContentPackage } from 'shell/offline/offline-model';
import { PostUpdateHook } from 'shell/offline/post-update-hook';


@Injectable()
export class FakePostUpdateHook implements PostUpdateHook {

    run(nextContentDB: IndexedDbWrapper, _content?: ContentPackage): Observable<Progress> {

        return new Observable<Progress>(observer => {

            console.log('Fake Index Start');

            const indexer = new ContentIndexer(nextContentDB);
            const entries = this.generateEntries();

            let done = 0;
            let promise = Promise.resolve();

            for (const entry of entries) {
                promise = promise.then(() => {
                    // console.log('Fake Index added', entry.key);
                    observer.next({ done: ++done, total: entries.length });
                    return indexer.index(entry.key, entry.text);
                });
            }

            promise.then(() => {
                console.log('Fake Index Done');
                observer.complete();
            });
        });
    }

    private generateEntries(): { key: string; text: string }[] {

        const quantity = { entries: 50, keys: 100, tokens: 2000, textMaxSize: 50 };

        const tokens = (Array.apply(null, { length: quantity.tokens }) as any[]).map(_ =>
            Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 25));

        const keys = (Array.apply(null, { length: quantity.keys }) as any[]).map(generateUUID);

        const entries = (Array.apply(null, { length: quantity.entries }) as any[]).map(() => ({
            key: keys[Math.floor(Math.random() * (keys.length - 1))],
            text: (Array.apply(null, { length: Math.floor(Math.random() * quantity.textMaxSize) }) as any[])
                .map(() => tokens[Math.floor(Math.random() * (tokens.length - 1))]).join(' ')
        }));

        return entries;

    }
}
