<ng-template [ngIf]="!reloading">
    <uf-panel class="container bg-gray" *ngIf="!printConfig">
        <div class="uf-container-lg bg-solid">

            <div *ngIf="triggerError" class="pad">
                <uf-message class="error gap" icon="errorSolid"
                    [content]="triggerError.message">
                    <h3>{{triggerError.code}}</h3>
                </uf-message>
            </div>

            <ng-container *ngIf="!triggerError">

                <div class="uf-app-bar transparent">
                    <uf-description-list *ngIf="formData?.id" class="grow stacked form-id">
                        <dt>{{commonTK.FormMetadataFieldIdLabel | translate}}</dt>
                        <dd>{{formData.id}}</dd>
                    </uf-description-list>

                    <a *ngIf="prevUrl" class="uf-button tertiary left align-left" (click)="back()">
                        <uf-icon name="arrowBack"></uf-icon>
                        {{sharedTK.ActionBack | translate}}
                    </a>
                    <uf-breadcrumbs *ngIf="breadcrumbs.length" class="small grow left gap-sm-left"
                        [breadcrumbs]="breadcrumbs" [minimize]="true">
                    </uf-breadcrumbs>

                    <button *ngIf="definition" class="uf-action default print-btn" type="button" (click)="print()">
                        <uf-icon name="print"></uf-icon>
                    </button>
                </div>

                <p *ngIf="parentInfo" class="body-copy gap-none-top gap-none-bottom pad-sides small">
                    {{ shellTK.FormOriginatedBy | translate }} <a
                        [routerLink]="parentInfo.routerLink">{{parentInfo.label}}</a>
                </p>

                <uf-form [definition]="definition" [(formData)]="formData" [config]="formConfig" [disabled]="isDisabled"
                    (submit)="save($event)" (cancel)="back()">
                </uf-form>

                <footer>
                    <uf-description-list class="pad small small-label" *ngIf="definition?.version != null">
                        <dt>{{commonTK.FormMetadataFieldDefinitionVersionLabel | translate }}:</dt>
                        <dd>{{definition.version}}</dd>
                    </uf-description-list>
                </footer>

            </ng-container>

            <us-loading-spinner *ngIf="busy"></us-loading-spinner>

        </div>

    </uf-panel>

    <uf-panel class="container" *ngIf="printConfig">
        <uf-print-form [config]="printConfig" (done)="printConfig = undefined"></uf-print-form>
    </uf-panel>
</ng-template>