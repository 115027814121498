import { Subject } from 'rxjs';

import { Inject, Injectable, NgZone } from '@angular/core';
import { WindowWrapper } from '@unifii/library/common';

import { DeviceService } from 'capacitor/device.service';


const AndroidOptions = 'toolbarcolor=#ffffff,hideurlbar=yes,hidenavigationbuttons=yes,lefttoright=yes,zoom=no,location=yes,hardwareback=no';
const IOSOptions = 'toolbartranslucent=false,toolbarposition=top,toolbarcolor=#ffffff,hidenavigationbuttons=yes,lefttoright=yes,location=no';

// cordova-plugin-inappbrowser
@Injectable()
export class InAppBrowserService {

    loadStart = new Subject<string>();

    private inAppBrowser: any;

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private device: DeviceService,
        private zone: NgZone
    ) { }

    get isAvaliable(): boolean {
        return this.window.cordova && this.window.cordova.InAppBrowser || false;
    }

    close() {

        if (!this.inAppBrowser) {
            return;
        }

        this.inAppBrowser.close();
    }

    /** Gets cordova device information */
    open(url: string) {

        // stop double opening browser windows
        if (this.inAppBrowser) {
            console.warn('InAppBrowser Already Open...closing old one');
            this.close();
        }

        // TODO add InAppBroswer to unifii sdk
        this.inAppBrowser = this.window.cordova.InAppBrowser.open(url, '_blank', this.inAppBrowserOptions);

        this.inAppBrowser.addEventListener('loadstart', (event: { url: string }) => {
            this.zone.run(() => {
                this.loadStart.next(event.url);
            });
        });

        this.inAppBrowser.addEventListener('exit', () => {
            this.inAppBrowser = null;
        });

    }

    clearCache(): Promise<void>{

        // stop double opening browser windows
        if (this.inAppBrowser) {
            console.warn('InAppBrowser Already Open...closing old one');
            this.close();
        }

        const options = this.inAppBrowserOptions + ',clearcache=true,hidden=yes';

        // open blank url to clear browser cache, then close.
        this.inAppBrowser = this.window.cordova.InAppBrowser.open('', '_blank', options);

        return new Promise((resolve, reject) => {

            // on load, close;
            this.inAppBrowser.addEventListener('loadstart', () => {
                this.zone.run(() => {
                    this.close();
                    // hidden inappbrowser doesnt report close, so presume close is successful
                    // https://github.com/apache/cordova-plugin-inappbrowser/issues/649
                    this.inAppBrowser = null;
                    resolve();
                });
            });

            // resolve regardless in 5 seconds
            setTimeout(() => {
                reject('InAppBrowser Timeout');
            }, 5000);

        });

    }

    private get inAppBrowserOptions(): string {

        if (this.device.isCapacitorAndroid()) {
            return AndroidOptions;
        }

        return IOSOptions;
    }
}
