<uf-panel class="container" [class.scroll-all]="table">
    <div class="header-pane uf-app-bar flat stretch">
        <button class="uf-action" (click)="print()">
            <uf-icon name="print"></uf-icon>
        </button>
        <button class="uf-action" (click)="showFilters = !showFilters">
            <uf-icon name="filter"></uf-icon>
        </button>
    </div>

    <div *ngIf="dateFilterConfigs" class="uf-grid gap-bottom pad-sides">
        <div class="col-lg-6" *ngIf="dateFilterConfigs.startDate || this.dateFilterConfigs.endDate">
            <div class="uf-grid gap-bottom">
                <uf-date *ngIf="dateFilterConfigs.startDate" class="col-6"
                    [label]="shellTK.ReportFilterStartDateLabel | translate" format="d MMM yyyy" [value]="start"
                    (valueChange)="startChange($event)"></uf-date>
                <uf-date *ngIf="dateFilterConfigs.endDate" class="col-6"
                    [label]="shellTK.ReportFilterEndDateLabel | translate" format="d MMM yyyy" [value]="end"
                    (valueChange)="endChange($event)"></uf-date>
            </div>
            <div *ngIf="dateFilterConfigs.startDate && this.dateFilterConfigs.endDate && this.dateFilterConfigs.presetRanges"
                class="row space-children wrap">
                <button type="button" class="uf-button tertiary x-small"
                    *ngFor="let preset of dateIntervalPresets; let i = index"
                    (click)="dateIntervalPresetSelected(i)">{{preset.label}}
                </button>
            </div>
        </div>
        <div *ngIf="dateFilterConfigs.intervals" class="col-lg-3">
            <uf-radio [label]="shellTK.ReportFilterDateIntervalsLabel | translate" [columns]="incrementOptions.length"
                valueProperty="identifier" [options]="incrementOptions" [columns]="3"
                (valueChange)="incrementChange($event)" [value]="interval">
            </uf-radio>
        </div>
    </div>

    <div class="uf-grid condensed column-gap-sm pad-sides gap-bottom" *ngIf="!error">
        <div class="col-12">
            <uf-filter-display [manager]="filterManager" [value]="filterValues" (valueChange)="filtersChange()">
            </uf-filter-display>
        </div>

        <us-report *ngFor="let reportConfig of reportConfigs" class="col-lg-{{reportConfig.width}}"
            [reportConfig]="reportConfig">
        </us-report>
    </div>

</uf-panel>

<uf-drawer [(open)]="showFilters" #right>
    <uf-filter-inputs *ngIf="filterManager" [manager]="filterManager" [value]="filterValues"
        (valueChange)="filtersChange()" (onClose)="showFilters = false"></uf-filter-inputs>
</uf-drawer>