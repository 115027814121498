<uf-panel class="container" ufMasterDetail>
    <div class="grid--fixed">
        <div class="col-1of1" *ngIf="label">
            <h3 style="text-align: center">{{label}}</h3>
        </div>
        <div class="ud-card-list col-1of1">
            <ng-template ngFor [ngForOf]="cards" let-card>
                <a *ngIf="card.accessible" class="ud-product-card--centered" [routerLink]="card.id">
                    <div class="ud-image--no-margin--fixed-ratio--16by9" *ngIf="card.url">
                        <img [attr.src]="card.url" [attr.alt]="card.label" />
                    </div>
                    <div class="ud-product-card-label" *ngIf="card.label">
                        <h4>{{card.label}}</h4>
                    </div>
                </a>
                <div *ngIf="!card.accessible" class="ud-product-card--centered">
                    <div class="ud-image--no-margin--fixed-ratio--16by9" *ngIf="card.url">
                        <img [attr.src]="card.url" [attr.alt]="card.label" />
                    </div>
                    <div class="ud-product-card-label" *ngIf="card.label">
                        <h4>{{card.label}}</h4>
                    </div>
                </div>

            </ng-template>
        </div>
    </div>
</uf-panel>
<router-outlet></router-outlet>
