<div class="uf-app-bar flat">
    <div class="img-wrap">
        <img *ngIf="img" [attr.src]="img" />
    </div>
    <a class="uf-action tertiary" (click)="close()">
        <uf-icon name="close"></uf-icon>
    </a>
</div>

<uf-panel class="grow" [forceReflow]="forceReflow">
    <!-- home menu -->
    <a class="home" [class.active]="isHomeActive" [routerLink]="['/']">
        <uf-icon name="home"></uf-icon>
        {{structure?.name || (shellTK.MenuLinkHomeLabel | translate)}}
    </a>
    <!-- structure children -->
    <us-nav-item *ngFor="let node of structure?.children" [node]="node"></us-nav-item>
</uf-panel>