import { Inject, Injectable } from '@angular/core';
import { Client, RequestAnalytics } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverOptions } from 'discover/discover-options';

import { Config } from 'config';


export interface RiskMatrixConfig {
    colour: string;
    risk: number;
}
export interface RiskMatrixEntry {
    id: string;
    formNumber: string;
    locations: string;
    riskType: string;
    riskSource: string;
    riskOwner: string;
    unmitigatedRating: number;
    mitigatedRating: number;
    formState: string;
}
@Injectable()
export class RiskMatrixService {

    constructor(
        @Inject(Config) private config: DiscoverOptions,
        private context: DiscoverContext,
        private client: Client,
    ) { }

    async getConfig(): Promise<RiskMatrixConfig[][] | undefined> {
        const configUrl = this.url(['config']);
        if (configUrl) {
            return await this.client.get(configUrl);
        }
        return;
    }

    async getMitigatedOccurences(filters: Record<string, unknown> = {}): Promise<number[][] | undefined> {
        const matrixUrl = this.url(['mitigated-occurrences']);
        if (matrixUrl) {
            return await this.client.get(matrixUrl, { params: filters });
        }
        return;
    }

    async getUnmitigatedOccurences(filters: Record<string, unknown> = {}): Promise<number[][] | undefined> {
        const matrixUrl = this.url(['unmitigated-occurrences']);
        if (matrixUrl) {
            return await this.client.get(matrixUrl, { params: filters });
        }
        return;
    }

    async getEntries(filters: Record<string, unknown> = {}, analytics?: RequestAnalytics): Promise<RiskMatrixEntry[] | undefined> {
        const tableUrl = this.url(['entries']);
        if (tableUrl) {
            return await this.client.get(tableUrl, {params: filters, analytics});
        }
        return;
    }

    // TODO Split configuration guard from url composer logic
    private url(parts: string[] = []): string | undefined {

        if (!this.config.unifii.reportingApiUrl) {
            throw new Error('No reportingApiUrl provided');
        }

        if (this.config.unifii.reportingApiUrl && this.context.project && this.config.unifii.tenant) {
            parts = parts.map(p => encodeURIComponent(p));
            parts.unshift('risk-report');
            if (this.config.unifii.preview) {
                parts.unshift('preview');
            }
            parts.unshift(this.context.project.id);
            parts.unshift(this.config.unifii.tenant);
            parts.unshift(this.config.unifii.reportingApiUrl);

            return parts.join('/');
        }
        return;
    }

}
