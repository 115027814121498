import { lastValueFrom } from 'rxjs';
import { toArray } from 'rxjs/operators';

import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    ActionMultiplicity, CommonTranslationKey, ContextProvider, FileSizePipe, ModalService, MomentDatePipe, SharedTermsTranslationKey, TableComponent,
    TableConfig, TableDataSource, TableRowContext
} from '@unifii/library/common';
import { Error, ErrorType, FormData, PermissionAction, Progress } from '@unifii/sdk';

import { FormDataState, FormInfo } from 'shell/offline/forms/interfaces';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { Config } from 'config';


interface UploadResult {
    info: FormInfo;
    title: string;
    message: string;
}

interface FormInfoWithFormData extends FormInfo {
    data: FormData;
}

class OfflineFormDataSource extends TableDataSource<FormInfoWithFormData> {

    sorted: boolean;
    filtered: boolean;

    constructor(private offlineQ: OfflineQueue) {
        super();
    }

    async load() {
        try {
            let infos = await lastValueFrom(this.offlineQ.list().pipe(toArray()));
            infos = infos.sort((a, b) => a.storedAt.getTime() - b.storedAt.getTime());
            const formDatas = await Promise.all(infos.map(info => this.offlineQ.getData(info.id)));
            const data = infos.map((i, index) => Object.assign({}, i, { data: formDatas[index] }) as FormInfoWithFormData);
            this.stream.next({ data });
        } catch (error) {
            this.stream.next({ error });
        }
    }
}

@Component({
    selector: 'ud-offline-forms-list',
    templateUrl: './offline-forms-list.html',
    styleUrls: ['./offline-forms-list.less'],
    providers: [BreadcrumbsService]
})
export class OfflineFormsListComponent {

    @ViewChild(TableComponent, { static: true }) table: TableComponent<FormInfoWithFormData>;

    readonly discoverTK = DiscoverTranslationKey;

    uploadings = new Map<FormInfoWithFormData, { progress: Progress; controller: AbortController }>();
    errors = new Map<FormInfoWithFormData, any>();
    completeds = new Map<FormInfoWithFormData, void>();

    tableConfig: TableConfig<FormInfoWithFormData>;
    datasource: OfflineFormDataSource;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modal: ModalService,
        private offlineQ: OfflineQueue,
        private momentDate: MomentDatePipe,
        private fileSize: FileSizePipe,
        private translate: TranslateService,
        private breadcrumbsService: BreadcrumbsService,
        @Inject(Authentication) private auth: Authentication,
        @Inject(Config) private config: Config,
        @Inject(ContextProvider) private contextProvider: ContextProvider
    ) {

        this.breadcrumbsService.title = this.translate.instant(this.discoverTK.OfflineFormsTitle);

        this.tableConfig = {
            id: 'offline-forms',
            columns: [{
                name: 'label',
                label: this.translate.instant(CommonTranslationKey.FormMetadataFieldDefinitionIdentifierLabel),
                value: info => info.form.label
            }, {
                name: 'storedAt',
                label: this.translate.instant(CommonTranslationKey.FormMetadataFieldCreatedAtLabel),
                value: info => this.momentDate.transform(info.storedAt, 'd/MM/yyyy h:mm a')
            }, {
                name: 'status',
                label: this.translate.instant(DiscoverTranslationKey.OfflineFormsStatusLabel),
                value: info => info.state
            }, {
                name: 'size',
                label: this.translate.instant(DiscoverTranslationKey.OfflineFormsSizeLabel),
                value: info => this.fileSize.transform(info.size)
            }, {
                name: 'syc',
                label: this.translate.instant(DiscoverTranslationKey.OfflineFormsSyncLabel)
            }],
            actions: [{
                label: this.translate.instant(SharedTermsTranslationKey.ActionUpload),
                icon: 'upload',
                action: rows => this.upload((rows as TableRowContext<FormInfoWithFormData>[]).map(r => r.$implicit)),
                predicate: row => {
                    const action = row.$implicit.data._rev ? PermissionAction.Add : PermissionAction.Update;
                    return !this.isCompleted(row.$implicit) &&
                    !this.isUploading(row.$implicit) &&
                    !this.isConflict(row.$implicit) &&
                    this.auth.getGrantedInfo(
                        PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, row.$implicit.data.bucket),
                        action,
                        row.$implicit.data,
                        this.contextProvider.get()
                    ).granted;
                }
            }, {
                label: `${this.translate.instant(SharedTermsTranslationKey.ActionView)} / ${this.translate.instant(SharedTermsTranslationKey.ActionEdit)}`,
                icon: 'edit',
                action: row => this.router.navigate([(row as TableRowContext<FormInfoWithFormData>).$implicit.id], { relativeTo: this.route }),
                predicate: row => !this.isCompleted(row.$implicit) && !this.isUploading(row.$implicit) && !this.isConflict(row.$implicit),
                multiplicity: ActionMultiplicity.Single
            }, {
                label: this.translate.instant(SharedTermsTranslationKey.ActionView),
                icon: 'view',
                action: row => this.router.navigate([(row as TableRowContext<FormInfoWithFormData>).$implicit.id], { relativeTo: this.route }),
                predicate: row => this.isConflict(row.$implicit),
                multiplicity: ActionMultiplicity.Single
            }, {
                label: this.translate.instant(SharedTermsTranslationKey.ActionDelete),
                icon: 'delete',
                action: rows => this.remove((rows as TableRowContext<FormInfoWithFormData>[]).map(row => row.$implicit)),
                predicate: row => !this.isCompleted(row.$implicit) && !this.isUploading(row.$implicit)
            }, {
                label: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
                icon: 'close',
                action: row => this.cancel((row as TableRowContext<FormInfoWithFormData>).$implicit),
                predicate: row => !this.isCompleted(row.$implicit) && this.progress(row.$implicit) > 0,
                multiplicity: ActionMultiplicity.Single
            }],
            selectable: true
        };

        this.reload();
    }

    reload() {
        this.datasource = new OfflineFormDataSource(this.offlineQ);
    }

    progress(info: FormInfoWithFormData): number {

        if (this.completeds.has(info)) {
            return 1;
        }

        const uploadInfo = this.uploadings.get(info);

        if (uploadInfo) {
            return uploadInfo.progress.done / uploadInfo.progress.total;
        }

        return 0;
    }

    isCompleted(info: FormInfoWithFormData): boolean {
        return this.completeds.has(info);
    }

    isFailed(info: FormInfoWithFormData): boolean {
        return this.errors.has(info);
    }

    isUploading(info?: FormInfoWithFormData): boolean {
        return info ? this.uploadings.has(info) : this.uploadings.size > 0;
    }

    isPending(info: FormInfoWithFormData): boolean {
        return !this.isFailed(info) && !this.isCompleted(info) && !this.isUploading(info) && info.status === FormDataState.Pending;
    }

    isConflict(info: FormInfoWithFormData): boolean {
        return !this.isFailed(info) && !this.isCompleted(info) && !this.isUploading(info) && info.status === FormDataState.Conflicted;
    }

    cancel(info: FormInfoWithFormData) {
        const uploadReference = this.uploadings.get(info);
        if (uploadReference) {
            uploadReference.controller.abort();
        }
        this.uploadings.delete(info);
        this.table.refresh();
    }

    private async remove(infos: FormInfoWithFormData[]) {
        const result = await this.modal.openConfirm();
        if (!result) {
            return;
        }

        for (const info of infos) {
            this.table.deleteItem(this.table.indexOf(info));
            this.offlineQ.delete(info.id).then(() => { });
        }
    }

    private async upload(infos: FormInfoWithFormData[]) {

        const uploadPromise = infos
            .filter(info => !this.isCompleted(info) && !this.isUploading(info))
            .map(info => this.startUpload(info));

        const results = (await Promise.all(uploadPromise)).filter(r => r != null) as UploadResult[];
        this.table.refresh();
        this.offlineQ.emitDeletion();
        for (const result of results) {
            await this.modal.openAlert({
                title: result.title,
                message: result.message
            });
        }
    }

    private async startUpload(info: FormInfoWithFormData): Promise<UploadResult | undefined> {

        try {
            if (this.errors.has(info)) {
                this.errors.delete(info);
            }

            const controller = new AbortController();
            const progress: Progress = { total: info.size, done: 0 };
            const uploadReference = { progress, controller };

            this.uploadings.set(info, uploadReference);

            await this.offlineQ.upload(info.id, { progressCallback: prog => {
                progress.done = prog.done;
                this.table.refresh();
            }, signal: controller.signal, revision: info.data._rev });

            this.completeds.set(info, undefined);

        } catch (e) {
            const error = e as Error;
            if (error.type === ErrorType.AbortError) {
                // User aborted, not considered an error
                return;
            }

            if (error.type === ErrorType.Conflict) {
                await this.offlineQ.save(info.data, info.form, { skipNotify: true, status: FormDataState.Conflicted});
                this.reload();
                return {
                    info,
                    title: this.translate.instant(ShellTranslationKey.ConflictModalTitle),
                    message: this.translate.instant(ShellTranslationKey.ConflictModalMessage)
                };
            }

            this.errors.set(info, error);
            return {
                info,
                title: error.code || this.translate.instant(SharedTermsTranslationKey.Error),
                message: error.message || this.translate.instant(DiscoverTranslationKey.OfflineFormsErrorUpload)
            };
        } finally {
            this.uploadings.delete(info);
            this.table.refresh();
        }

        return;
    }

}
