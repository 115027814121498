<div *ngIf="error">
    <uf-message class="col-1of1 error padded" icon="errorSolid" [content]="error.message">
        <h3>{{error.code}}</h3>
    </uf-message>
</div>

<ng-template [ngIf]="tableConfig">
    <uf-expander [actionBtn]="true">
        <!-- header -->
        <div expanderHeader class="uf-app-bar medium accent">
            <a class="title" *ngIf="tableLink" [routerLink]="tableLink">
                <span *ngIf="recordCount && showCount" class="record-count">
                    {{recordCount}} -
                </span>
                <span>
                    {{title}}
                </span>
                <uf-icon class="small" name="moreInfo"></uf-icon>
            </a>
        </div>
        <div expanderBody class="pad-none gap-none uf-box flat-md table-page">
            <!-- TODO remove ?? null from datasource input once uf-table accept undefined as datasource value -->
            <uf-table #pageTable [config]="tableConfig" [datasource]="dataSource ?? null" class="small-message list-md">
                <ng-container *ngFor="let descriptor of customColumns">
                    <ng-container *cellFor="descriptor.name; let item; let index = index; let column = column;">
                        <uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor">
                        </uf-custom-cell>
                    </ng-container>
                </ng-container>
            </uf-table>
            <div *ngIf="showSeeMoreRow || module.moduleConfig?.addOptions?.length"
                class="row center-all float-right pad-sm-sides">
                <div class="header">
                    <a *ngIf="showSeeMoreRow" [routerLink]="tableLink">
                        <span>
                            {{shellTK.DashboardBucketActionSeeMore | translate}}
                        </span>
                    </a>

                </div>
                <button *ngIf="module.moduleConfig?.addOptions?.length" (click)="addLinked()"
                    [title]="sharedTK.ActionAdd | translate" class="uf-action primary gap-left">
                    <uf-icon name="add"></uf-icon>
                </button>
            </div>
        </div>
    </uf-expander>

</ng-template>
