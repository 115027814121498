import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DescriptionListItem, FieldDescriptionServiceProvider } from '@unifii/library/common';
import { ClaimConfig, Company } from '@unifii/sdk';
import { UserInfoKey } from '@unifii/user-provisioning';

import { DiscoverFieldDescriptionService } from 'discover/services/discover-field-description.service';


/**
 * CompanyDescriptoin provides
 * an easy way of displaying locked (disabled) fields in pages with company inputs
 */
@Component({
    selector: 'ud-company-description',
    template: '<uf-description-list class="responsive" [items]="items"></uf-description-list>',
    styles: [`:host { display: block; }`]
})
export class CompanyDescriptionComponent implements OnChanges {

    @Input() company: Company;
    @Input() filterBy: string[];
    @Input() claimConfig: ClaimConfig[] = [];

    items: DescriptionListItem[];

    constructor(
        @Inject(FieldDescriptionServiceProvider) private descriptionService: DiscoverFieldDescriptionService,
    ) { }

    ngOnChanges(_: SimpleChanges): void {
        this.items = this.getDescriptionListItems(this.company, this.claimConfig, this.filterBy);
    }

    getDescriptionListItems(company: Company, claimConfig: ClaimConfig[], filterBy?: (UserInfoKey | string)[]): DescriptionListItem[] {
        const properties = filterBy ?? Object.keys(company);

        const descriptions = properties.map(p => this.descriptionService.createCompanyDescription(company[p as keyof Company], {identifier: p}, claimConfig, company));
        return descriptions.filter(d => d != null) as DescriptionListItem[];
    }


}

