import { DataSourceLoader, SourceConfig } from '@unifii/library/common';
import { Compound, DataSeed, Query, UsersClient } from '@unifii/sdk';


export class UserOptionsLoader implements DataSourceLoader {

    sourceConfig: SourceConfig;

    private seeds: DataSeed[];

    constructor(
        private identifier: string,
        private name: string,
        private usersClient: UsersClient,
    ) { }

    async getOptions(): Promise<DataSeed[]> {
        return [...this.seeds];
    }


    async search(query?: string): Promise<DataSeed[]> {
        await this.load(query);
        return this.seeds;
    }

    async findAllBy(match: string): Promise<DataSeed[]> {
        console.warn('datasource does not support findByAll, falling back on search');
        return this.search(match);
    }

    async get(id: string): Promise<DataSeed | null> {
        const collectionItem = await this.usersClient.get(id);
        return this.mapToSeed(collectionItem);
    }

    mapToSeed(result?: Compound): DataSeed | null {

        if (!result) {
            return null;
        }

        return {
            _id: result[this.identifier],
            _display: result[this.name]
        };
    }

    private async load(q?: string) {

        this.seeds = [];

        const query = new Query();
        if (q && q.length) {
            query.q(q);
        }

        const results = await this.usersClient.query(query);

        this.seeds = results.map(result => this.mapToSeed(result)).filter(seed => seed != null) as DataSeed[];
    }
}
