<us-shell>
  <us-header #header [menuDrawer]="menu" [rightDrawer]="right" [title]="shell.title">
  </us-header>
  <us-menu-drawer #menu>
    <us-nav [img]="imgUrl"></us-nav>
  </us-menu-drawer>
  <us-drawer #right icon="settings" (closed)="shell.closeRightDrawer()">
    <ud-settings></ud-settings>
  </us-drawer>
  <router-outlet></router-outlet>
</us-shell>
<ud-icons></ud-icons>
<uf-progress class="large"></uf-progress>