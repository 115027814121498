import { Component, Inject, OnInit } from '@angular/core';
import { PublishedContent, StructureNode } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';

import { PDFAsset } from './pdf-viewer.component';


@Component({
    templateUrl: './pdf-viewer-node.html'
})
export class PdfViewerNodeComponent implements OnInit {

    pdfAsset: PDFAsset;

    constructor(
        @Inject(PublishedContent) private content: PublishedContent,
        private navService: NavigationService,
    ) { }

    async ngOnInit() {

        const { id } = this.navService.current as StructureNode;

        if (!id) {
            return;
        }

        const url = await this.content.getAssetUrl(id.toString());

        this.pdfAsset = { url };

    }
}
