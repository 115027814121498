import { DataPropertyDescriptor, DateWeekDayAndTimeDisplayFormat, DateWeekDayDisplayFormat, FieldDisplayPipe } from '@unifii/library/common';
import { ContentLinkFormData, Dictionary, FieldType, FormData } from '@unifii/sdk';


export const getBucketColumnRenderableValue = (
    formData: FormData,
    dataPropertyDescriptor: DataPropertyDescriptor,
    fieldDisplayPipe: FieldDisplayPipe,
): string | ContentLinkFormData | undefined => {
    const value = childValueAccessor(dataPropertyDescriptor.identifier, formData);
    const format = getFormatByFieldType(dataPropertyDescriptor.type);
    if (dataPropertyDescriptor.type === FieldType.Link) {
         return value as ContentLinkFormData;
    }
    return fieldDisplayPipe.transform(value, dataPropertyDescriptor.type, dataPropertyDescriptor.options, format) ?? undefined;
};

/**
 * Recursively attempts to access child propertie values
 */
const childValueAccessor = (property: string, data: Dictionary<any>): any =>
    property.split('.').reduce((s, prop) => accessUnknownMember(prop, s), data);


const accessUnknownMember = (prop: string, data: Dictionary<any>): any => {
    try {
        return data[prop];
    } catch (e) { }
    return;
};

const getFormatByFieldType = (type: FieldType): string | undefined => {
    switch (type) {
        case FieldType.Date: return DateWeekDayDisplayFormat;
        case FieldType.DateTime:
        case FieldType.ZonedDateTime: return DateWeekDayAndTimeDisplayFormat;
        default: return;
    }
};