import { Compound } from 'jsep';

import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService, RuntimeField, StepperComponent, StepperTemplate, UfControlGroup } from '@unifii/library/common';
import { FormContainerField, ScopeManager, WorkflowService } from '@unifii/library/smart-forms';
import { FieldType } from '@unifii/sdk';

import { ShellTranslationKey } from 'shell/shell.tk';


const StepperCssModifier = 'stepper-form';

@Component({
    selector: 'ud-section-stepper',
    templateUrl: './section-stepper.html'
})
export class StepperSectionComponent implements FormContainerField, AfterViewInit, OnDestroy {

    @ViewChild(StepperComponent) private stepperComponent: StepperComponent;

    readonly fieldTypes = FieldType;
    readonly orientation = StepperTemplate.Horizontal;

    field: RuntimeField;
    scope: Compound;
    control: UfControlGroup;
    contentChange: EventEmitter<any>;
    disabled: boolean;

    private pageContainer: HTMLElement | null;

    constructor(
        private scopeManager: ScopeManager,
        private elementRef: ElementRef,
        private workflowService: WorkflowService,
        private modal: ModalService,
        private translate: TranslateService,
        private router: Router
    ) { }

    get activeIndex(): number {
        return this.stepperComponent?.active ?? 0;
    }

    ngAfterViewInit() {
        // Add custom CSS class so styles can be modified via CSS
        this.pageContainer = this.getPageContainer(this.elementRef.nativeElement);
        if (this.pageContainer != null) {
            this.pageContainer.classList.add(StepperCssModifier);
        }
    }

    ngOnDestroy() {
        if (this.pageContainer != null){
            this.pageContainer.classList.remove(StepperCssModifier);
        }
    }

    // WorkflowService affect ActionGroup
    isVisible(child: RuntimeField): boolean {
        // SmartForm context, workflow lead
        return this.workflowService.isVisible(child);
    }

    getControl(field: RuntimeField): UfControlGroup {
        return this.scopeManager.getControl(field) as UfControlGroup;
    }

    submit() {
        const transition = (this.field.transitions ?? [])[0];
        if (transition == null) {
            return;
        }
        this.workflowService.trigger({ source: this.field, transition });
    }

    async routeToHome() {
        if (this.control.dirty) {
            const canLeave = await this.modal.openConfirm({
                title: this.translate.instant(ShellTranslationKey.UnsavedChangesModalTitle),
                message: this.translate.instant(ShellTranslationKey.UnsavedChangesModalMessage),
                confirmLabel: this.translate.instant(CommonTranslationKey.YesLabel),
                cancelLabel: this.translate.instant(CommonTranslationKey.NoLabel)
            });
            if (!canLeave) {
                return;
            }
        }
        this.router.navigate(['/']);
    }

    private getPageContainer(element?: HTMLElement): HTMLElement | null {
        if (element == null) {
            return null;
        }
        if (element.className?.includes('uf-container-lg')) {
            return element;
        }
        return this.getPageContainer(element.parentElement as HTMLElement);
    }
}
