<uf-panel class="container">

    <!-- Emty pane required for table tool bar -->
    <div class="header-pane"></div>

    <uf-table class="content-pane accent" [config]="tableConfig" [datasource]="datasource">

        <!-- Special cell for uploading state-->
        <div *cellFor="'syc'; let info">
            <!-- Ready to Sync -->
            <div *ngIf="isPending(info)">
                <div>{{discoverTK.OfflineFormsWaitingSyncLabel | translate}}</div>
            </div>
            <!-- Conflict Detected -->
            <div *ngIf="isConflict(info)">
                <div>{{discoverTK.OfflineFormsConflictLabel | translate}}</div>
            </div>
            <!-- Upload completed -->
            <div *ngIf="isCompleted(info)" class="success">
                <uf-icon name="tick--solid"
                    [title]="discoverTK.OfflineFormsActionUploadSuccess | translate" class="small"></uf-icon>
            </div>
            <!-- Upload failed -->
            <div *ngIf="isFailed(info)" class="error-icon">
                <uf-icon name="warning--solid"
                    [title]="discoverTK.OfflineFormsActionUploadFail | translate" class="small"></uf-icon>
            </div>
            <!-- Uploading -->
            <div *ngIf="isUploading(info)" class="uploading">
                <div>{{progress(info) | percent: '.0-0'}}</div>
                <uf-icon (click)="cancel(info)" name="close" class="small"></uf-icon>
            </div>
        </div>

    </uf-table>

</uf-panel>