import add from 'date-fns/add';
import format from 'date-fns/format';

import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, FnsDatetime } from '@unifii/library/common';
import { TenantClient } from '@unifii/sdk';

import { Config } from 'config';

import { ErrorService } from '../errors/error.service';


/**
 * Tenant settings gets reused at multiple times during user access, it is important that
 * tenant settings are refreshed on app start and only after cache has expired
 */
@Injectable()
export class TenantSettingsService {

    private cacheExpiry: string; // set on successfully update
    private cacheLifetime: { minutes: number } = { minutes: 10 };  // Amount of minutes before settings should be updated

    constructor(
        private tenantClient: TenantClient,
        private errorService: ErrorService,
        private translate: TranslateService,
        @Inject(Config) private config: Config,
    ) {
        if (this.config.env === 'dev') {
            this.cacheLifetime = { minutes: 0 };
        }
    }

    async sync() {

        // Tenant settings are old, try update them.
        if (this.config.unifii.tenant != null && this.config.unifii.tenantSettings != null) {

            if (!this.cacheExpiry || this.cacheExpiry < this.getDatetime()) {
                await this.updateTenantSettings();
            }
            return;
        }

        // Tenant setting required to continue
        if (this.config.unifii.tenant != null) {
            await this.updateTenantSettings();
            return;
        }

        throw new Error('Tenant tettings sync not successfull');

    }

    async setTenant(tenant: string) {
        this.config.unifii.tenant = tenant.toLowerCase();

        try {
            const settings = await this.tenantClient.getSettings();
            if (!settings) {
                throw this.errorService.createError(this.errorService.invalidClientErrorMessage);
            }

            this.config.unifii.tenantSettings = settings;
        } catch (error) {

            this.config.unifii.tenant = undefined;

            const notFoundError = this.errorService.createNotFoundError(this.translate.instant(CommonTranslationKey.CompanyLabel));
            throw this.errorService.mergeError(error, notFoundError.message);
        }
    }

    private async updateTenantSettings() {
        this.config.unifii.tenantSettings = await this.tenantClient.getSettings();
        this.cacheExpiry = this.getDatetime(this.cacheLifetime);
    }

    private getDatetime(offset?: { minutes: number }): string {

        if (offset != null) {
            return format(add(new Date(), offset), FnsDatetime);
        }

        return format(new Date(), FnsDatetime);
    }

}
