import { Permission, PermissionAction } from '@unifii/sdk';


// Permissions that include any key word
const ANY_PERMISSIONS = [PermissionAction.Read, PermissionAction.Update, PermissionAction.Retrieve, PermissionAction.Delete];

const normalizePermissions = (permissions: Permission[]): Permission[] =>

    permissions.reduce((normalized, permission) => {

        if (splitRequired(permission)) {

            const additional = createAdditionalPermission(permission);
            permission.actions = permission.actions.filter(action => !additional.actions.includes(action as PermissionAction));
            normalized.push(permission, additional);
        } else {
            normalized.push(permission);
        }

        return normalized;
    }, [] as Permission[]);

const splitRequired = (permission: Permission): boolean => {

    // Requires additional permission
    const paths = ['users', 'projects'];
    const path = permission.path.join('/');

    // temporary name until i better understand purpose of white list
    const containsAnyPermissions = permission.actions.some(p => ANY_PERMISSIONS.includes(p as PermissionAction));

    return paths.includes(path) && containsAnyPermissions;

};

const createAdditionalPermission = (permission: Permission): Permission => {

    const path = [...permission.path, '?'];
    const actions = permission.actions.filter(action => ANY_PERMISSIONS.includes(action));

    return {
        path, actions
    };
};

/** /me (current user) */
const getMePath = (): string[] =>
    ['me'];

/** /roles */
const getRolesPath = (): string[] =>
    ['roles'];

/** /company-claims */
const getCompanyClaimsPath = (): string[] =>
    ['company-claims'];

/** /default-claims (aka user-claims) */
const getDefaultClaimsPath = (): string[] =>
    ['default-claims'];

/** /users */
const getUsersPath = (): string[] =>
    ['users'];

/** /user/:userId */
const getUserPath = (userId?: number): string[] =>
    [...getUsersPath(), `${userId ? userId : '?'}`];

/** /companies */
const getCompaniesPath = (): string[] =>
    ['companies'];

/** /company/:companyId */
const getCompanyPath = (companyId?: string): string[] =>
    [...getCompaniesPath(), `${companyId ? companyId : '?'}`];

/** /hierarchies */
const getHierarchiesPath = (): string[] =>
    ['units'];

/** /hierarchies/? */
const getHierarchyUnitPath = (): string[] =>
    [...getHierarchiesPath(), '?'];

/** /projects  */
const getProjectsPath = (): string[] =>
    ['projects'];

/** /projects/:projectId */
const getProjectPath = (projectId: string): string[] =>
    [...getProjectsPath(), `${projectId}`];

/** /projects/:projectId/structure */
const getStructurePath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'structure'];

/** /projects/:projectId/collections */
const getCollectionsPath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'collections'];

/** /projects/:projectId/collections/:collectionIdentifier */
const getCollectionPath = (projectId: string, collectionIdentifier: string): string[] =>
    [...getCollectionsPath(projectId), collectionIdentifier];

/** /projects/:projectId/collections/:collectionIdentifier/items */
const getCollectionItemsPath = (projectId: string, collectionIdentifier: string): string[] =>
    [...getCollectionPath(projectId, collectionIdentifier), 'items'];

/** /projects/:projectId/collections/:collectionIdentifier/items/:collectionItemId */
const getCollectionItemPath = (projectId: string, collectionIdentifier: string, collectionItemId: string): string[] =>
    [...getCollectionItemsPath(projectId, collectionIdentifier), collectionItemId];

/** /projects/:projectId/buckets */
const getBucketsPath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'buckets'];

/** /projects/:projectId/buckets/:bucketId */
const getBucketPath = (projectId: string, bucketId: string): string[] =>
    [...getBucketsPath(projectId), bucketId];

/** /projects/:projectId/buckets/:bucketId/revisions */
const getBucketRevisionsPath = (projectId: string, bucketId: string): string[] =>
    [...getBucketsPath(projectId), bucketId, 'revisions'];

/** /projects/:projectId/buckets/:bucketId/docs */
const getBucketDocumentsPath = (projectId: string, bucketId: string): string[] =>
    [...getBucketsPath(projectId), bucketId, 'docs'];

/** /projects/:projectId/buckets/:bucketId/docs/:documentId */
const getBucketDocumentPath = (projectId: string, bucketId: string, documentId: string): string[] =>
    [...getBucketDocumentsPath(projectId, bucketId), documentId];

/** /projects/:projectId/views */
const getViewsPath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'views'];

/** /projects/:projectId/views/:viewId */
const getViewPath = (projectId: string, viewId: string): string[] =>
    [...getViewsPath(projectId), viewId];

/** /projects/:projectId/pages */
const getPagesPath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'pages'];

/** /projects/:projectId/pages/:pageId */
const getPagePath = (projectId: string, pageId: string): string[] =>
    [...getPagesPath(projectId), `${pageId}`];

/** /projects/:projectId/forms */
const getFormsPath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'forms'];

/** /projects/:projectId/forms/:formId */
const getFormPath = (projectId: string, formId: string): string[] =>
    [...getFormsPath(projectId), formId];

/** /projects/:projectId/tables */
const getTablesPath = (projectId: string): string[] =>
    [...getProjectPath(projectId), 'tables'];

/** /projects/:projectId/tables/:tableIdentifier */
const getTablePath = (projectId: string, tableIdentifier: string): string[] =>
    [...getTablesPath(projectId), tableIdentifier];

export const PermissionsFunctions = {
    normalizePermissions, getRolesPath, getCompanyClaimsPath, getDefaultClaimsPath, getUsersPath, getUserPath,
    getMePath, getCompaniesPath, getCompanyPath, getHierarchiesPath, getHierarchyUnitPath,
    getProjectsPath, getProjectPath, getStructurePath, getCollectionsPath, getCollectionPath, getCollectionItemsPath, getCollectionItemPath,
    getBucketPath, getBucketRevisionsPath, getBucketDocumentsPath, getBucketDocumentPath,
    getViewsPath, getViewPath, getPagesPath, getPagePath, getFormsPath, getFormPath, getTablesPath, getTablePath
};

// projects/:projectId/external-data-sources
// function getExternalDataSourcesPath = (projectId: number): string[] =>
    // [...getProjectPath(projectId), 'external-data-sources'];

// projects/:projectId/external-data-sources/:externalDataSourceId
// function getExternalDataSourcePath = (projectId: number, externalDataSourceId: string): string[] =>
    // [...getExternalDataSourcesPath(projectId), externalDataSourceId];
