import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddActionConfig, OptionsModalComponent } from '@unifii/components';
import {
    CellDisplayDescriptor, Context, ContextProvider, ExpressionParser, HierarchyUnitProvider, ModalService, SharedTermsTranslationKey, SortStatus,
    TableComponent, TableConfig, TableDataSource, WindowWrapper
} from '@unifii/library/common';
import { AstNode, Client, CompaniesClient, Dictionary, FormData, Option, PermissionAction, Table, TableSourceType, UsersClient } from '@unifii/sdk';

import { TableDisplayMode } from 'shell/content/content-node.component';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellFormService } from 'shell/form/shell-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath, NewItemPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailContextProvider } from 'shell/table-detail/table-detail-context-provider';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { checkShowCount, getTableCustomColumnsDisplayDescriptors } from 'shell/table/table-functions';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

import { Config } from 'config';

import { TableModule } from './table-detail.component';


@Component({
    selector: 'us-table-module',
    templateUrl: './table-module.html',
    styleUrls: ['../dashboard/dashboard-table.less'],
    providers: [ShellFormService]
})
export class TableModuleComponent implements OnInit {

    @ViewChild(TableComponent) pageTable: TableComponent<TableData>;

    @Input() module: TableModule;
    @Input() item: TableData;
    @Input() detailContextProvider: TableDetailContextProvider;

    readonly shellTK = ShellTranslationKey;
    readonly sharedTK = SharedTermsTranslationKey;

    title: string;
    tableConfig: TableConfig<any>;
    customColumns: CellDisplayDescriptor[] = [];
    tableLink: any[];
    error: AppError | undefined;
    dataSource?: TableDataSource<TableData>;
    recordCount?: number;

    protected showCount: boolean;

    constructor(
        private usersClient: UsersClient,
        private expParser: ExpressionParser,
        private formService: ShellFormService,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        @Inject(WindowWrapper) private window: Window,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        @Inject(HierarchyUnitProvider) private hierarchyUnitProvider: HierarchyUnitProvider,
        private router: Router,
        private client: Client,
        private errorService: ErrorService,
        private modalService: ModalService,
        private translate: TranslateService,
        private tableColumnFactory: TableColumnFactory<FormData>
    ) { }

    get showSeeMoreRow() {
        return this.tableLink && this.pageTable && this.pageTable.status.exhausted === false;
    }

    async ngOnInit() {

        const { detailModule, pageConfig } = this.module;

        try {
            // Guard for non matching roles with those requested by the TableModule
            if (detailModule.roles?.length && !detailModule.roles.some(r => this.auth.userInfo?.roles?.includes(r))) {
                return;
            }

            const { table, propertyDescriptors } = pageConfig;

            this.dataSource = this.createDataSource(table, detailModule.filter);

            if (detailModule.title) {
                this.title = detailModule.title;
            } else {
                this.title = table.title;
            }

            this.customColumns = getTableCustomColumnsDisplayDescriptors(propertyDescriptors, table.columns);

            this.tableConfig = {
                columns: this.tableColumnFactory.create(table.columns ?? [], propertyDescriptors, table.sourceType, false),
                pageSize: detailModule.limit ?? 5,
                rowAction: (item: TableData) => {
                    if (this.canRouteToItem(item, table.sourceType, table.source)) {
                        this.routeToItem(detailModule.identifier, item.id as string, table);
                    }
                }
            };

            this.tableLink = [table.identifier];

            this.loadPageCount(table);

        } catch (e) {
            console.error('TableModuleComponent.ngOnInit error: ', e);
            this.error = this.errorService.mergeError(e, this.errorService.unknownErrorMessage);
        }
    }

    /** Based on TableModule.canAdd and TableModule.filter */
    async addLinked() {

        const { moduleConfig, pageConfig } = this.module;

        if (!moduleConfig?.addOptions || !pageConfig.bucket || !moduleConfig?.filterLink) {
            return;
        }

        const expressionValue = this.expParser.resolve(
            moduleConfig.filterLink.expression,
            this.detailContextProvider.get() as Context,
            undefined,
            `TableDetailModule: failed to parse ${moduleConfig.filterLink.expression}`
        );

        if (!expressionValue) {
            return;
        }

        let selected: Option;

        if (moduleConfig.addOptions.length === 1) {
            selected = moduleConfig.addOptions[0];
        } else {
            selected = await this.modalService.openMedium<AddActionConfig, Option>(OptionsModalComponent, {
                label: this.translate.instant(ShellTranslationKey.FormBucketDialogAddFormTitle),
                options: moduleConfig.addOptions
            });
        }

        if (!selected) {
            return;
        }

        // console.log('Selected', selected);
        const params: Dictionary<string> = {};
        params.$definition = selected.identifier;
        params[moduleConfig.filterLink.identifier as string] = expressionValue;
        params.prevUrl = this.window.location.pathname;
        // console.log('Add params', params);
        this.router.navigate([FormDataPath, pageConfig.bucket, NewItemPath, params]);
    }

    private createDataSource(table: Table, moduleFilter?: AstNode): TableDataSource<TableData> {
        const factory = new TableInputManagerFactory(this.detailContextProvider, this.expParser, undefined, this.hierarchyUnitProvider);
        const tableInputManager = factory.create(table, moduleFilter);

        let inputFilters;
        const sort = SortStatus.fromString(table?.defaultSort) || undefined;
        if (sort) {
            inputFilters = { sort };
        }

        switch (table.sourceType) {
            case TableSourceType.Users:
                return new UsersTableDataSource(this.usersClient, table.identifier, tableInputManager, inputFilters);
            case TableSourceType.Company:
                const companiesClient = new CompaniesClient(this.client);
                return new CompanyTableDataSource(companiesClient, table.identifier, tableInputManager, inputFilters);
            case TableSourceType.Bucket: {
                this.formService.bucket = table.source as string;
                const showCount = checkShowCount(this.config, table);
                return new BucketTableDataSource({
                    shellFormService: this.formService,
                    tableIdentifier: table.identifier,
                    tableInputManager,
                    tableInputs: inputFilters,
                    showCount
                });
            };
        }
    }

    private routeToItem(identifier: string, id: string, table: Table) {
        const params: { mode?: TableDisplayMode; prevUrl: string } = { prevUrl: this.window.location.pathname };

        if (table.detail) {
            params.mode = TableDisplayMode.Detail;
        }

        this.router.navigate([identifier, id, params]);
    }

    private canRouteToItem(item: TableData, tableSource: TableSourceType, bucket?: string): boolean {
        switch (tableSource) {
            case TableSourceType.Bucket: return this.auth.getGrantedInfo(
                PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket as string, item.id as string),
                PermissionAction.Read,
                item,
                this.contextProvider.get()
            ).granted;
            case TableSourceType.Company: return this.auth.getGrantedInfo(
                PermissionsFunctions.getCompanyPath(item.id),
                PermissionAction.Read,
                item,
                this.contextProvider.get()
            ).granted;
            case TableSourceType.Users: return this.auth.getGrantedInfo(
                PermissionsFunctions.getUserPath(item.id ? +item.id : undefined),
                PermissionAction.Read,
                item,
                this.contextProvider.get()
            ).granted;
        }
    }

    private async loadPageCount(table: Table) {
        this.showCount = !!table.showCount;
        if (this.dataSource && this.config.unifii.tenantSettings?.features.indexing) {
            this.tableConfig.exhaustAhead = true;
            this.recordCount = await this.dataSource.count;
        }
    }
}
