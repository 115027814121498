import { AppContext, ContextProvider } from '@unifii/library/common';
import { FormData } from '@unifii/sdk';


export interface TriggerFormContext extends AppContext {
    parentForm?: FormData;
}

export class TriggerFormContextProvider implements ContextProvider {

    constructor(
        private contextProvider: ContextProvider,
        private parentFormData: FormData
    ) { }

    get(): TriggerFormContext {

        const appContext: AppContext = this.contextProvider.get();

        return Object.assign(appContext, {
            parentForm: this.parentFormData
        });
    }
}