import { Component, HostBinding } from '@angular/core';
import { Modal, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';


export type ConflictModalAction = 'Close' | 'Discard' | 'Save';

@Component({
    selector: 'us-conflict-modal',
    templateUrl: './conflict-modal.html'
})
export class ConflictModalComponent implements Modal<void, ConflictModalAction> {

    @HostBinding('class.uf-form-card') cardClass = true;

    protected readonly sharedTK = SharedTermsTranslationKey;
    protected readonly shellTK = ShellTranslationKey;

    constructor(
        public runtime: ModalRuntime<void, ConflictModalAction>
    ) { }

    apply(action: ConflictModalAction) {
        this.runtime.close(action);
    }
}
