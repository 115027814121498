import { Inject, Injectable } from '@angular/core';
import { FeatureFlagProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { Config } from 'config';


@Injectable()
export class ShellFeatureFlagProvider implements FeatureFlagProvider {

    constructor(@Inject(Config) private config: Config) { }

    async get(): Promise<Dictionary<boolean>> {
        const obj = Object.assign({}, this.config.unifii.tenantSettings?.features, this.config.flags);
        // obj.translations = false;
        return obj;
    }
}