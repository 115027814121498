import { InjectionToken } from '@angular/core';
import { ConnectionOptionsInterface, Dictionary, ProjectContentOptionsInterace, TenantSettings, Theme } from '@unifii/sdk';


export interface Config {
    version: string;
    env: string;
    useHashUrls: boolean; // TODO remove base it on cordova
    sentryDSN?: string;
    unifii: UnifiiConfig;
    productVersion?: string; // Used for products used by Discover (R&S, Woolworths, etc)
    /**
     * @deprecated Theme replaced with themeConfig
     */
    theme?: Theme;
    themeConfig?: {
        cssVariables?: Theme;
        disableProjectTheme?: boolean;
        disableProjectLogo?: boolean;
        disableTenantLogo?: boolean;
    };
    flags?: Dictionary<boolean>; // enable features
}

export interface UnifiiConfig extends ConnectionOptionsInterface, ProjectContentOptionsInterace {
    tenant?: string;
    tenantSettings?: TenantSettings;
    offline?: boolean;
    maxImageWidth?: number;
    productLogo?: string | null; // if set to null will be hidden
    productLogoSecondary?: string | null; // if set to null will be hidden
    productBackground?: string;
    projectLogoUrl?: string;
    privacyPolicyUrl?: string;
    privacyPolicyLabelTk?: string;
    termsOfUseMessageTk?: string;
    reportingApiUrl?: string;
    translationsUrl: string;
}

export const Config = new InjectionToken<Config>('Config');

export const Environment = new InjectionToken<Config>('Environment');