import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';

import { AppError } from 'shell/errors/errors';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { UnifiiLoginPath, UnifiiPasswordChangePath, UserAccessRootPath } from 'discover/discover-constants';

import { Config } from 'config';


/**
 * Wrapper for the authenticaion process,
 * contains a router-outlet for steps in the authentication process
 * Contains html boilder plate and has request handler for
 * displaying progress and errors in the UI (requestHandler)
 *
 * Benefits: Creates a better UI experience, minimises reload flashes,
 * reduces code duplication
 */
@Component({
    selector: 'ud-user-access',
    templateUrl: './user-access.html',
})
export class UserAccessComponent implements OnInit, OnDestroy {

    error: AppError | null;
    message: string | null;
    backButtonRoute: string | null;

    private subscription = new Subscription();

    constructor(
        private accessManager: UserAccessManager,
        private savedUserSevice: SavedUsersService,
        private config: Config,
        router: Router
    ) {
        this.subscription.add(router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(() => {
            this.enableBackButton(router.routerState.snapshot);
        }));
    }

    ngOnInit() {
        this.subscription.add(this.accessManager.errorEvent.subscribe(error => this.error = error));
        this.subscription.add(this.accessManager.deniedEvent.subscribe(({ message }) => { this.message = message || null; }));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // Show back button on unifii login screen, other pages have routing actions handled by custom buttons
    private enableBackButton(route: RouterStateSnapshot) {

        const url = route.url.split(';')[0];

        if (url === `/${UserAccessRootPath}/${UnifiiLoginPath}` &&
            ((this.savedUserSevice.enabled && this.savedUserSevice.users.length) || this.config.unifii.tenantSettings?.authProviders?.length)) {
            this.backButtonRoute = `/${UserAccessRootPath}`;
            return;
        }

        if (url === `/${UserAccessRootPath}/${UnifiiPasswordChangePath}`) {
            this.backButtonRoute = `/${UserAccessRootPath}/${UnifiiLoginPath}`;
            return;
        }

        this.backButtonRoute = null;
    }

}
