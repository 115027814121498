import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RuntimePage, SharedTermsTranslationKey } from '@unifii/library/common';

import { PageContent } from './content-types';


@Component({
    templateUrl: './page.html'
})
export class PageComponent implements PageContent, OnInit {

    readonly sharedTK = SharedTermsTranslationKey;

    page: RuntimePage;
    title: string;

    protected prevUrl: string;

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.prevUrl = this.route.snapshot.params.prevUrl;
    }

    protected back(url: string) {
        this.router.navigateByUrl(url);
    }

}
