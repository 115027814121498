import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    Breadcrumb, FilterEntry, FilterEntryAdapter, FilterManager, FilterSerializer, FilterValue, HierarchyUnitProvider, RuntimeDefinition, RuntimeField,
    SharedTermsTranslationKey, SortStatus, TableComponent, TableConfig, TableConfigColumn, WindowWrapper
} from '@unifii/library/common';
import { DataSourceType, Dictionary, FieldOption, FieldType } from '@unifii/sdk';

import { ShellFormService } from 'shell/form/shell-form.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { FormDataPath, TableSearchMinLength } from 'shell/shell-constants';

import { ReportFilterSerialzier } from 'discover/reports/report-filter-serializer';
import { findField } from 'discover/reports/report-functions';
import { ReportCustomFilerConfig, ReportService } from 'discover/reports/report-service';
import { RiskMatrixDatasource } from 'discover/reports/risk-matrix/risk-matrix-datasource';
import { RiskMatrixConfig, RiskMatrixEntry, RiskMatrixService } from 'discover/reports/risk-matrix/risk-matrix-service';


const formStates = [
    'AnalysisCompleted',
    'RiskMitigated',
    'FurtherMitigation'
];

const riskAssessmentFormStates = [
    'RiskAnalysisCompleted',
    'HighRiskAnalysisCompleted',
    'RiskAnalysisApproved',
    'HighRiskAnalysisApproved',
    'ActionsCreated',
    'ActionsCreatedHighRisk',
    'ActionsCompleted',
    'ActionsCompletedHighRisk',
    'RiskMitigated',
    'HighRiskMitigated',
    'RiskUnMitigated',
    'HighRiskUnmitigated',
    'FurtherControlsDefined',
    'FurtherControlsDefinedHighRisk',
    'FurtherControlsApproved',
    'FurtherControlsApprovedHighRisk',
    'FurtherActionsCreated',
    'FurtherActionsCreatedHighRisk',
    'FurtherActionsCompleted',
    'FurtherActionsCompletedHighRisk',
    'RiskReMitigated',
    'HighRiskRemitigated'
];

interface CellLabels {
    rows: string[];
    columns: string[];
}
interface DisplayMatrix {
    title: string;
    yAxisLabel?: string;
    xAxisLabel?: string;
    labels?: CellLabels;
    data: any;
}

@Component({
    templateUrl: './risk-matrix.html',
    styleUrls: ['./risk-matrix.less'],
    providers: [RiskMatrixService, ReportService, BreadcrumbsService, ShellFormService],
})
export class RiskMatrixComponent implements OnInit, OnDestroy {

    @ViewChild(TableComponent) table: TableComponent<RiskMatrixEntry>;

    readonly mitigatedRowFieldId = 'consequenceMitigated';
    readonly mitigatedColumnFieldId = 'likelihoodMitigated';
    readonly unmitigatedRowFieldId = 'consequenceUnmitigated';
    readonly unmitigatedColumnFieldId = 'likelihoodUnmitigated';

    tableConfig: TableConfig<RiskMatrixEntry>;
    datasource: RiskMatrixDatasource;

    formDefinition: RuntimeDefinition;

    gridData: DisplayMatrix[];
    config: RiskMatrixConfig[][] | undefined;
    gridSize: number;

    showFilters = false;
    filterManager: FilterManager<FilterValue, FilterEntry>;
    filterValues: Dictionary<FilterValue> = {}; // TODO look risk matrix filter fields
    currentFilterValues: Dictionary<FilterValue>;
    firstEntry = true;
    filter: any = {};
    sort: SortStatus | null;

    breadcrumbs: Breadcrumb[] = [];

    error: any;

    coloursLookup: Dictionary<string> = {
        blue: '#03a9f4',
        green: '#8bc34a',
        yellow: '#ffeb3b',
        orange: '#ff9800',
        red: '#f44336'
    };

    private isManagementMatrix: boolean;
    private hasHierarchies: boolean;
    private subscriptions = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private riskMatrixService: RiskMatrixService,
        private breadcrumbsService: BreadcrumbsService,
        @Inject(WindowWrapper) private window: Window,
        private nav: NavigationService,
        private formService: ShellFormService,
        @Inject(HierarchyUnitProvider) hierarchyUnitProvider: HierarchyUnitProvider,
        @Inject(FilterEntryAdapter) private filterEntryAdapter: FilterEntryAdapter,
        @Inject(FilterSerializer) filterSerializer: FilterSerializer<FilterValue, FilterEntry>,
        private reportService: ReportService
    ) {
        this.breadcrumbsService.title = 'Risk Matrix';
        this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
        this.filterManager = new FilterManager([], hierarchyUnitProvider, new ReportFilterSerialzier(filterSerializer));
    }

    async ngOnInit() {
        const node = this.nav.current;

        // Configuring functionality based on tags
        this.isManagementMatrix = !!(node && node.tags && node.tags.length && node.tags.find(t => t === 'risk-management-matrix'));
        this.hasHierarchies = !!(node && node.tags && node.tags.length && node.tags.find(t => t === 'risk-matrix-hierarchy'));

        await this.loadFormDefinition();
        await this.loadConfig();
        await this.initFilters();
        this.initTable();

        this.subscriptions.add(this.route.params.subscribe(_ => this.routeChanged()));
    }

    async ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    filtersChange() {
        const serialized = this.filterManager.serializeAll(this.filterValues);
        this.router.navigate([{ ...serialized }], { relativeTo: this.route });
    }

    reload(filters: Dictionary<string | null>) {
        this.datasource = new RiskMatrixDatasource(
            this.riskMatrixService,
            filters,
            this.sort || undefined
        );
    }

    async routeChanged() {
        const params = Object.assign({}, this.route.snapshot.params);

        const nextFilters = await this.filterManager.deserializeAll(params);

        const changed = JSON.stringify(nextFilters) !== JSON.stringify(this.currentFilterValues);

        this.filterValues = nextFilters;

        this.currentFilterValues = JSON.parse(JSON.stringify(nextFilters));

        if (changed || this.firstEntry) {
            this.loadData();
        }

        this.firstEntry = false;
    }

    private async loadFormDefinition() {
        const formIdentifier = this.isManagementMatrix ? 'hs-rm' : 'risk-assessment';
        try {
            this.formDefinition = await this.formService.getFormDefinition(formIdentifier);
        } catch (e) {
            console.error(e);
        }
    }

    private async loadConfig() {
        const config = await this.riskMatrixService.getConfig();
        if (config && config.length) {
            this.config = config;
            this.gridSize = config.length;
        }
        this.config = config ? config.reverse() : undefined;
    }

    private async initFilters() {
        try {
            await this.createFilterEntries();
            await this.routeChanged();
        } catch (e) {
            console.error(e);
            this.error = e.message || this.translate.instant(SharedTermsTranslationKey.ErrorUnknown);
        }
    }

    private async createFilterEntries() {
        const configs = await this.getFilterConfigs();

        for (const config of configs) {
            const loader = this.reportService.createFilterLoader(config.loader);
            // TODO Fix the create signature to accept directly a Loader
            const filterEntry = this.filterEntryAdapter.transform({
                type: this.reportService.getFilterType(config.type, loader),
                identifier: config.identifier,
                label: config.label,
                options: config.options,
                loader,
                translateService: this.translate,
                searchMinLength: TableSearchMinLength
            });

            if (filterEntry) {
                this.filterManager.add(filterEntry);
            }
        }
    }

    private async loadData() {
        try {
            const filters = this.filterManager.serializeAll(this.filterValues);

            this.reload(filters);
            const mitigatedData = await this.riskMatrixService.getMitigatedOccurences(filters);
            const unmitigatedData = await this.riskMatrixService.getUnmitigatedOccurences(filters);
            this.createDisplayData(mitigatedData, unmitigatedData);
        } catch (e) {
            console.error(e);
        }
    }

    private createDisplayData(mitigatedData?: number[][], unmitigatedData?: number[][]) {
        if (this.formDefinition) {
            this.gridData = this.createDisplayMatrix(this.formDefinition, mitigatedData, unmitigatedData);
        }
    }

    private createDisplayMatrix(formDefinition: RuntimeDefinition, mitigatedData?: number[][], unmitigatedData?: number[][]): DisplayMatrix[] {
        const diagrams: DisplayMatrix[] = [];

        const unmitigatedRowField = findField(formDefinition.fields, this.unmitigatedRowFieldId);
        const unmitigatedColumnField = findField(formDefinition.fields, this.unmitigatedColumnFieldId);
        if (unmitigatedData && unmitigatedRowField && unmitigatedColumnField) {
            diagrams.push(this.createDiagram(unmitigatedRowField, unmitigatedColumnField, 'Unmitigated', unmitigatedData));
        }

        const mitigatedRowField = findField(formDefinition.fields, this.mitigatedRowFieldId);
        const mitigatedColumnField = findField(formDefinition.fields, this.mitigatedColumnFieldId);
        if (mitigatedData && mitigatedRowField && mitigatedColumnField) {
            diagrams.push(this.createDiagram(mitigatedRowField, mitigatedColumnField, 'Mitigated', mitigatedData));
        }

        return diagrams;
    }

    private createDiagram(rowField: RuntimeField, columnField: RuntimeField, title: string, data: number[][]): DisplayMatrix {
        return {
            title,
            yAxisLabel: columnField.label,
            xAxisLabel: rowField.label,
            labels: this.createLabels(rowField, columnField),
            data: data.reverse()
        };
    }

    private createLabels(rowField: RuntimeField, columnField: RuntimeField): CellLabels | undefined {
        if (rowField.options && columnField.options) {
            return {
                rows: rowField.options.map(option => option.name),
                columns: columnField.options.map(option => option.name).reverse()
            };
        }
        return;
    }

    private initTable() {

        const bucket = this.isManagementMatrix ? 'risk-management' : 'risk-assessment';

        const columns: TableConfigColumn<RiskMatrixEntry>[] = [
            { name: 'formNumber', label: 'Form Number' },
            { name: 'riskTitle', label: 'Risk Title' },
        ];

        if (this.hasHierarchies) {
            columns.push({ name: 'hierarchy', label: 'Hierarchy' });
        } else {
            columns.push({ name: 'location', label: 'Location' });
        }

        columns.push(
            { name: 'riskSource', label: 'Risk Source' },
            { name: 'riskOwner', label: 'Risk Owner' },
            { name: 'unmitigatedRiskRating', label: 'Unmitigated Rating' },
            { name: 'mitigatedRiskRating', label: 'Mitigated Rating' },
            { name: 'formState', label: 'State' }
        );

        this.tableConfig = {
            id: 'urs-risk-matrix',
            columns,
            pageSize: 100,
            columnToggles: false,
            row: {
                link: (entry: RiskMatrixEntry) =>
                    ['/', FormDataPath, bucket, entry.id, { prevUrl: this.window.location.pathname }]
            }
        };
    }

    private async getFilterConfigs(): Promise<ReportCustomFilerConfig[]> {
        const options = await this.getOptions(this.isManagementMatrix);
        const configs = [{
            identifier: 'locations',
            label: 'Locations',
            type: FieldType.Lookup,
            loader: {
                type: DataSourceType.Collection,
                id: 'locations',
                identifierProperty: 'id',
                nameProperty: 'location'
            }
        }, {
            identifier: 'riskTypes',
            label: 'Risk Types',
            type: FieldType.MultiChoice,
            options: options.riskType
        }, {
            identifier: 'riskSources',
            label: 'Risk Sources',
            type: FieldType.MultiChoice,
            options: options.riskSource
        }, {
            identifier: 'formNumber',
            label: 'Risk Number',
            type: FieldType.Text
        }, {
            identifier: 'dateIdentified',
            label: 'Date Identified',
            type: FieldType.Date,
            range: true
        }, {
            identifier: 'riskOwnerUsername',
            label: 'Risk Owner',
            type: FieldType.Choice,
            loader: {
                type: DataSourceType.Users
            }
        }, {
            identifier: 'unmitigatedRiskRating',
            label: 'Unmitigated Risk Rating',
            type: FieldType.Number,
            range: true
        }, {
            identifier: 'mitigatedRiskRating',
            label: 'Mitigated Risk Rating',
            type: FieldType.Number,
            range: true
        }];

        // Swap filters based on tag TODO filters will be customized on back end in future
        if (!this.isManagementMatrix) {
            configs.splice(1, 0, {
                identifier: 'riskCategories',
                label: 'Risk Categories',
                type: FieldType.MultiChoice,
                options: options.riskCategory
            });
        }
        if (this.hasHierarchies) {
            configs.splice(0, 1, {
                identifier: 'hierarchy',
                label: 'Hierarchy',
                type: FieldType.Hierarchy
            });
        }

        return configs;
    }

    private async getOptions(isManagementMatrix?: boolean): Promise<{ riskCategory: FieldOption[]; riskType: FieldOption[]; riskSource: FieldOption[]; state: FieldOption[] }> {
        const options = {
            riskCategory: [] as FieldOption[],
            riskType: [] as FieldOption[],
            riskSource: [] as FieldOption[],
            state: [] as FieldOption[]
        };

        const formIdentifier = isManagementMatrix ? 'hs-rm' : 'risk-assessment';

        let definition;
        try {
            definition = await this.formService.getFormDefinition(formIdentifier);
        } catch (e) {
            console.error(`Failed to load form ${formIdentifier}`, e);
        }

        if (definition) {
            options.riskCategory = findField(definition.fields, 'riskCategory')?.options || [];
            options.riskSource = findField(definition.fields, 'riskSource')?.options || [];
            options.riskType = findField(definition.fields, 'riskType')?.options || [];
        }

        // TODO make RM filters configurable
        if (isManagementMatrix) {
            options.state = riskAssessmentFormStates.map(state => ({ identifier: state, name: state }));
        } else {
            options.state = formStates.map(state => ({ identifier: state, name: state }));
        }

        return options;
    }
}