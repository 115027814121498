import { EventEmitter, Injectable, Output } from '@angular/core';


@Injectable()
export class LoadingIndicatorService {

    @Output() loading: EventEmitter<boolean> = new EventEmitter();

    add() {
        this.loading.emit(true);
    }

    remove() {
        this.loading.emit(false);
    }

}
