import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerComponent, TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterEntry, FilterValue, SharedTermsTranslationKey, TableDataSource } from '@unifii/library/common';

import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { NewItemPath } from 'shell/shell-constants';

import { Config } from 'config';

import { TableData } from './models';
import { checkShowCount } from './table-functions';
import { TablePageConfig } from './table-page-config';


@Component({
    template: `
        <div ufMasterDetail class="table-page">
            <uf-table-container ufSyncRoute (addItem)="add($event)" (dataSourceChange)="updateCount($event)" class="accent list-md">
                <div *ngIf="showRecordCount" title class="title">{{recordCount}} Results</div>
                <ng-container info>
                    <uf-breadcrumbs *ngIf="breadcrumbs.length > 1" class="small grow left gap-sm-left" [breadcrumbs]="breadcrumbs" [minimize]="true"></uf-breadcrumbs>
                    <a *ngIf="prevUrl" (click)="back(prevUrl)" class="uf-button tertiary left align-left">
                        <uf-icon name="arrowBack"></uf-icon>
                        {{sharedTK.ActionBack | translate}}
                    </a>
                </ng-container>
            </uf-table-container>
        </div>
        <router-outlet></router-outlet>
    `,
    styleUrls: ['./table-page.less'],
    providers: [BreadcrumbsService]
})
export class TablePageComponent implements AfterViewInit {


    @ViewChild(TableContainerComponent) private tableContainerComponent?: TableContainerComponent<TableData, FilterValue, FilterEntry>;

    readonly sharedTK = SharedTermsTranslationKey;

    protected prevUrl?: string;
    protected breadcrumbs: Breadcrumb[];
    protected recordCount: number | null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breadcrumbsService: BreadcrumbsService,
        private config: Config,
        @Inject(TablePageConfig) private tablePageConfig: TablePageConfig,
        @Inject(TableContainerManager) private manager: TableContainerManager<TableData, FilterValue, FilterEntry>,
    ) {
        const { params } = this.route.snapshot;
        this.prevUrl = params.prevUrl;

        this.breadcrumbsService.title = this.tablePageConfig.table.title;
        this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
    }

    get showRecordCount() {
        return checkShowCount(this.config, this.tablePageConfig.table);
    }

    ngAfterViewInit() {
        if (this.showRecordCount && this.tableContainerComponent?.dataSource) {
            this.updateCount(this.tableContainerComponent.dataSource);
        }
    }

    async add(identifier?: string) {
        if (this.manager.addActionCallback != null) {
            this.manager.addActionCallback(identifier);
        } else {
            this.router.navigate([NewItemPath], { relativeTo: this.route });
        }
    }

    back(prevUrl: string) {
        this.router.navigateByUrl(prevUrl);
    }

    async updateCount(dataSource: TableDataSource<TableData> | null) {
        if (this.showRecordCount && dataSource) {
            this.recordCount = await dataSource.count || null;
        }
    }

}
