import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProgressComponent, ThemeProvider, ThemeService, WindowWrapper } from '@unifii/library/common';
import { Theme } from '@unifii/sdk';

import { ShellService } from 'shell/core/shell.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { OfflineManager } from 'shell/offline/offline-manager';

import { DiscoverContext } from 'discover/discover-context';
import { PinService } from 'discover/pin/pin-types';

import { Config } from 'config';

import { AppUpdateService } from './app-update/app-update.service';


@Component({
    selector: 'ud-main',
    templateUrl: './main.html'
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(ProgressComponent) private progressBar: ProgressComponent;

    imgUrl: string;

    private destroyed = new Subject<void>();
    private subscriptions = new Subscription();

    constructor(
        @Inject(Config) private config: Config,
        private context: DiscoverContext,
        public shell: ShellService,
        private offlineQ: OfflineQueue,
        private offlineManager: OfflineManager,
        @Inject(WindowWrapper) private window: Window,
        @Inject(PinService) private pinService: PinService,
        private appUpdate: AppUpdateService,
        @Inject(ThemeProvider) private themeService: ThemeService
    ) { }

    ngOnInit() {

        if (this.theme && !this.config.themeConfig?.disableProjectTheme) {
            this.themeService.theme = this.theme;
        }

        if (this.config.unifii.projectLogoUrl) {
            this.imgUrl = this.config.unifii.projectLogoUrl;
        }

        this.pinService.init();
        this.appUpdate.init();
        this.initOfflineQ();

        // clean up offline content (background async run)
        this.offlineManager.cleanUp();

        // reset offline content notifications
        this.shell.reset('OfflineContent');

        // Update app progress
        this.subscriptions.add(this.shell.busyEvents.subscribe(e => {

            if (this.progressBar != null && e) {
                this.progressBar.start();
            } else if (this.progressBar != null) {
                this.progressBar.complete();
            }
        }));
    }

    ngAfterViewInit(): void {
        /** Guard incase for angular universal */
        if (!this.window.document) {
            return;
        }
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();

        this.themeService.theme = this.config?.themeConfig?.cssVariables ?? this.config?.theme ?? {};

        this.subscriptions.unsubscribe();
    }

    private initOfflineQ() {

        this.subscriptions.add(this.offlineQ.additions.pipe(takeUntil(this.destroyed))
            .subscribe(() => this.shell.notify('OfflineQ')));

        this.subscriptions.add(this.offlineQ.deletions.pipe(takeUntil(this.destroyed))
            .subscribe(() => this.shell.done('OfflineQ')));

        this.offlineQ.count().then(count => this.shell.reset('OfflineQ', count));
        this.offlineQ.prune();
    }

    private get theme(): Theme | undefined {

        if (this.context.project) {
            return this.context.project.theme;
        }
        return;
    }

}
