import { Observable, Subject } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppContext, Context, ExpressionParser, ModalService, Repository, SharedTermsTranslationKey } from '@unifii/library/common';
import {
    AstNode, Client, ErrorType, MeClient, NodeType, Permission, PermissionAction, ProjectInfo, QueryOperators, TokenStorage, TokenStorageInterface,
    UserInfo
} from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication, LoginInfo, LogoutArgs, PermissionGrantedResult } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { SSOService } from 'shell/services/sso.service';
import { UserAccessManager } from 'shell/services/user-access-manager';
import { Resources } from 'shell/shell-constants';
import { Resource } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';


const UserKey = 'UfUser';
const UserPermissionsKey = 'UfUserPermissions';
const AllowedProjectsKey = 'DiscoverAllowedProjects'; // Old key used
const PermissionRefusedLog = 'PermissionRefusedLog';

/** TODO create ClientError class in library */
export interface ClientError {
    type: any;
    message?: string;
    status?: number;
    data?: any;
}

interface UserPermissionInfo extends Omit<Permission, 'path'> {
    path: string;
    pathRegEx: RegExp;
}

@Injectable()
export class ShellAuthenticationService implements Authentication {

    oldPassword: string | undefined;

    private _logouts = new Subject<void>();
    private _userPermissionsInfo: UserPermissionInfo[] | null;
    private _allowedProjects: ProjectInfo[] | null;

    constructor(
        private repo: Repository,
        @Inject(TokenStorage) private tokenStorage: TokenStorageInterface,
        private client: Client,
        private meClient: MeClient,
        private modalService: ModalService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private accessManager: UserAccessManager,
        private ssoService: SSOService,
        private savedUsersService: SavedUsersService,
        private expressionParser: ExpressionParser,
    ) { }

    get isAuthenticated(): boolean {
        return this.tokenStorage.token != null;
    }

    get userInfo(): UserInfo | null {
        return this.repo.load(UserKey);
    }

    set userInfo(u: UserInfo | null) {
        this.repo.store(UserKey, u);
    }

    get allowedProjects(): ProjectInfo[] {

        if (this._allowedProjects == null) {

            if (!this.isAuthenticated) {
                this._allowedProjects = [];
            } else {
                let projects = (this.repo.load(AllowedProjectsKey) || []) as ProjectInfo[];

                // Restrict by claim 'ProjectId'
                const allowed = this.getClaimValues('ProjectId');
                if (allowed.length) {
                    projects = projects.filter(p => allowed.includes(p.id));
                }

                // Restrict by ACLs
                this._allowedProjects = projects.filter(p => {
                    const result = this.getGrantedInfoWithoutCondition(PermissionsFunctions.getProjectPath(p.id), PermissionAction.Read).granted;
                    // console.log(PermissionsFunctions.getProjectPath(p.id), result);
                    return result;
                });
            }
        }

        return this._allowedProjects;
    }

    set allowedProjects(v: ProjectInfo[]) {
        this.repo.store(AllowedProjectsKey, v || []);
        this._allowedProjects = null;
    }

    get userPermissionsInfo() {

        if (this._userPermissionsInfo == null) {

            this._userPermissionsInfo = this.userPermissions.map(permission => {
                let exp = '^' + permission.path.map(step => {
                    if (step === '?') {
                        return '[^\\\/]*';
                    }
                    if (step === '*') {
                        return '[^$]+$';
                    }
                    return step;
                }).join(`\\\/`);

                exp = exp.endsWith('$') ? exp + '' : exp + '$';
                const pathRegEx = new RegExp(exp, 'g');

                return Object.assign({}, permission, {
                    path: permission.path.join('/'),
                    pathRegEx
                });
            });
        }

        return this._userPermissionsInfo;
    }

    get userPermissions(): Permission[] {
        return this.repo.load(UserPermissionsKey) || [];
    }

    set userPermissions(p: Permission[]) {
        this.repo.store(UserPermissionsKey, p);
        this._userPermissionsInfo = null;
    }

    get canAccessPreview(): boolean {
        const user = this.userInfo;
        return (user && user.roles && user.roles.includes('Previewer')) || false;
    }

    get isPermissionRefusedLogEnabled() {
        return this.repo.load(PermissionRefusedLog) === true;
    }

    get logouts(): Observable<void> {
        return this._logouts;
    }

    getClaimValues(type: string): string[] {
        if (this.userInfo?.claims == null) {
            return [];
        }

        return this.userInfo.claims.filter(c => c.type === type).map(c => c.value);
    }

    async login({ authCode, username, password, redirectUri, providerId, rememberMe }: LoginInfo): Promise<void> {


        if ((username == null || password == null) && authCode == null) {
            throw new AppError('Insufficient login information');
        }

        try {

            if (username != null && password != null) {
                await this.client.authenticate(username, password);
            } else {
                await this.client.authenticateWithCode(authCode as string, redirectUri, providerId);
            }

            this.userInfo = await this.meClient.getMe();

            if (this.userInfo.changePasswordOnNextLogin) {
                throw this.getPasswordChangeError();
            }

            const permissions = await this.meClient.getPermissions();
            this.userPermissions = PermissionsFunctions.normalizePermissions(permissions);

            // store provider type for reference when logging out
            this.ssoService.authenticatedProviderId = providerId;

            // ask to remember unifii details
            await this.savedUsersService.rememberUser(this.userInfo, rememberMe, providerId);


        } catch (error) {

            if (error.data && error.data.passwordChangeRequired) {
                throw error;
            }

            const authError = this.getAuthError(error);

            throw authError || error;
        }
    }

    async logout(args: LogoutArgs = {}): Promise<boolean> {

        const { askConfirmation } = args;

        if (askConfirmation) {
            const consent = await this.modalService.openConfirm({
                title: this.translate.instant(ShellTranslationKey.LogOutModalTitle),
                message: this.translate.instant(ShellTranslationKey.LogOutModalMessage),
                cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
                confirmLabel: this.translate.instant(ShellTranslationKey.LogOutModalActionConfirm)
            });

            if (!consent) {
                return false;
            }
        }

        this.accessManager.deny(args);
        this._logouts.next();
        return true;
    }

    async clear() {

        if (this.ssoService.authenticatedProviderId != null) {
            await this.ssoService.logout();
        }

        // Clear token storage
        this.tokenStorage.token = null;
        this.tokenStorage.expiresAt = null;
        await this.tokenStorage.setRefreshToken(null);

        // clear user info
        this.userInfo = null;
        this.allowedProjects = [];
    }

    getGrantedInfo(path: string[], action: PermissionAction, target: any, context: AppContext, field?: string): PermissionGrantedResult {
        return this.checkPermissions(path, action, { skipCondition: false, target, context, field });
    }

    getGrantedInfoWithoutCondition(path: string[], action: PermissionAction, field?: string): PermissionGrantedResult {
        return this.checkPermissions(path, action, { skipCondition: true, field});
    }

    private checkPermissions(path: string[], action: PermissionAction, options: {skipCondition: boolean; target?: any; context?: AppContext; field?: string} ): PermissionGrantedResult {

        const res = this.lookupResource(Resources, path);
        if (!res || !res.actions) {
            console.warn(`AuthService.checkPermissions path ${path.join('/')} not available`);
        }

        if (res?.actions?.find(a => a.name === action) == null) {
            console.warn(`AuthService.checkPermissions action ${action} not defined for path ${path.join('/')}`);
        }

        const pathString = path.join('/');

        // filter
        const matches = this.userPermissionsInfo.filter(permissionInfo => {
            permissionInfo.pathRegEx.lastIndex = 0;
            const matchPath = permissionInfo.pathRegEx.test(pathString);
            const matchAction = permissionInfo.actions.includes(action);

            if (!matchPath || !matchAction) {
                return false;
            }

            let matchCondition: boolean;

            const temporaryContext = Object.assign({}, options.context ?? {}) as any as Context;
            const conditionExpression = this.expressify(temporaryContext, permissionInfo.condition);

            if (!conditionExpression || options.skipCondition) {
                matchCondition = true;
            } else {
                if (options.target == null || options.context == null) {
                    matchCondition = false;
                } else {
                    const result = this.expressionParser.resolve(conditionExpression, temporaryContext, options.target);
                    /** UNIFII-5417 null indicates an exception in the execution, in this case the condition must be considered matched */
                    matchCondition = result === true || result == null;
                }
            }

            if (!matchCondition) {
                return false;
            }

            const fields = permissionInfo.fields ?? [];
            if (options.field && fields.length > 0) {
                return fields.includes(options.field);
            }

            return true;
        });

        // Permission not matching, granted failed
        if (matches.length === 0) {
            if (this.isPermissionRefusedLogEnabled) {
                console.warn(`[Permission refused] path: "${path.join('/')}" action: "${action}"`);
            }
            return { granted: false };
        }

        // Permission matched and no specific field check possible, return standard granted result
        let fieldsUnion: string[] | undefined;
        if (!matches.find(m => ((m.fields ?? []).length === 0))) {
            const fieldsSet = new Set<string>();
            for (const m of matches) {
                for (options.field of (m.fields ?? [])) {
                    fieldsSet.add(options.field);
                }
            }
            fieldsUnion = [...fieldsSet];
        }
        return { granted: true, fields: fieldsUnion };
    }

    private expressify(context: Context, ast?: AstNode): string | null {

        if (!ast) {
            return null;
        }

        if (ast.type === NodeType.Combinator) {
            if (ast.args) {
                const exps = ast.args.map(a => this.expressify(context, a)).filter(exp => exp != null);
                return exps.join(` ${ast.op === QueryOperators.And ? '&&' : '||'} `);
            }
        } else if (ast.args && ast.type === NodeType.Operator) {

            const isIdentifierOnly = [QueryOperators.Has, QueryOperators.Hasnt].includes(ast.op as QueryOperators);
            const identifier = ast.args.find(node => node.type === NodeType.Identifier);
            const rawValue = ast.args.find(node => node.type === NodeType.Value);

            let expression = null;

            if (isIdentifierOnly && identifier && !rawValue) {

                switch(ast.op) {
                    case QueryOperators.Has:
                        expression = `${identifier.value} != null`;
                        break;
                    case QueryOperators.Hasnt:
                        expression = `${identifier.value} == null`;
                        break;
                    default:
                        expression = 'true';
                }

            } else if (identifier && rawValue) {

                let safeIdentifierExistsCheck = '';
                const parts = (identifier.value as string).split('.');
                if (parts.length > 1) {
                    parts.pop();
                    safeIdentifierExistsCheck = parts.map((_, i) => `${parts.slice(0,i+1).join('.')} != null`).join(' && ');
                }

                switch (ast.op) {
                    case QueryOperators.Equal:
                        const rightSideValue = typeof rawValue.value === 'string' ? `'${rawValue.value}'` : rawValue.value;
                        expression = `${identifier.value} == ${rightSideValue}`;
                        break;

                    case QueryOperators.In:
                    case QueryOperators.Descendants: // remove 'descs' case once backend implement operator switch to 'in'
                        const arrayVariableName = [...Array(20)].map(() => 'abcefgh'.charAt(Math.floor(Math.random() * 7))).join('');
                        const arrayValue = Array.isArray(rawValue.value) ? rawValue.value : [rawValue.value];
                        context[arrayVariableName] = arrayValue;

                        expression = `$${arrayVariableName}.indexOf(${identifier.value}) >= 0`;
                        break;

                    default:
                        expression = 'true';
                        break;
                }

                if (expression != null && safeIdentifierExistsCheck.length > 0) {
                    expression = `${safeIdentifierExistsCheck} && ${expression}`;
                }
            }

            return expression;
        }

        return null;
    }

    private lookupResource(resource: Resource, path: string[]): Resource | undefined {

        if (!path.length) {
            return resource;
        }

        if (!resource.children) {
            return;
        }

        const nextResource = resource.children.find(c => c.segment === path[0] || c.segment === '?');

        if (!nextResource) {
            return;
        }

        path = [...path];
        path.shift();
        return this.lookupResource(nextResource, path);
    }

    private getPasswordChangeError(): ClientError {
        return {
            type: ErrorType.Unauthorized,
            status: 403,
            data: { passwordChangeRequired: true },
            message: this.translate.instant(ShellTranslationKey.AuthenticationPasswordChangeRequiredLabel)
        };
    }

    private getAuthError(res: Response): ClientError | Response | null {

        try {
            const error: ClientError = {
                type: res.type,
                status: res.status,
                data: res.json(),
            };

            let fallbackMessage = this.errorService.unknownErrorMessage;

            if (ErrorType[error.type] != null) {
                fallbackMessage = ErrorType[error.type] + ': ' + fallbackMessage;
            }

            error.message = error.data.error_description || (error as Response).statusText || fallbackMessage;
            return error;
        } catch (SyntaxError) {
            return null;
        }
    }
}
