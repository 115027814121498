<uf-stepper [orientation]="orientation" (completed)="submit()">
    <ng-container *ngFor="let group of field.fields">
        <uf-step *ngIf="group.type === fieldTypes.Group" [label]="group.label ?? ''" [control]="getControl(group)">
            <div content class="gap-sides gap-top grid">
                <ng-container *ngFor="let field of group.fields">
                    <ng-template [ngIf]="field.identifier" [ngIfElse]="nonContent">
                        <uf-field [field]="field" [(content)]="scope[field.identifier]" [scope]="scope">
                        </uf-field>
                    </ng-template>
                    <ng-template #nonContent>
                        <uf-field [field]="field" [scope]="scope">
                        </uf-field>
                    </ng-template>
                    <uf-break-after *ngIf="field?.breakAfter"></uf-break-after>
                </ng-container>
            </div>
            <div actions>
                <a (click)="routeToHome()" class="uf-button tertiary" type="button">Cancel</a>
            </div>
        </uf-step>
    </ng-container>
</uf-stepper>
