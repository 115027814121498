import { Injectable, InjectionToken, Type } from '@angular/core';
import { TableSourceType } from '@unifii/sdk';

import { CollectionItemComponent } from 'shell/content/collection-item.component';
import { CollectionComponent } from 'shell/content/collection.component';
import {
    CollectionContent, CollectionItemContent, CompanyContent, FormContent, PageContent, UserContent, ViewContent
} from 'shell/content/content-types';
import { PageComponent } from 'shell/content/page.component';
import { ViewComponent } from 'shell/content/view.component';
import { FormComponent } from 'shell/form/form.component';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { CompanyFormComponent } from 'shell/table/companies/company-form.component';
import { TablePageComponent } from 'shell/table/table-page.component';

import { UserDetailsComponent } from 'discover/user-management/user-details.component';


export interface SelectorArgs {
    tags?: string[];
}

export interface ContentComponentSelector {
    getPageComponent(identifier?: string): Type<PageContent>;
    getViewComponent(identifier?: string): Type<ViewContent>;
    getFormComponent(identifier?: string): Type<FormContent>;
    getTableComponent(source?: TableSourceType): Type<any>; // Table pages can be anything as settings and logic existings in the table manager class
    getTableDetailComponent(identifier?: string, id?: string): Type<TableDetailComponent>;
    getCollectionComponent(identifier?: string): Type<CollectionContent>;
    getCollectionItemComponent(identifier?: string): Type<CollectionItemContent>;
    getCompanyComponent(id?: string): Type<CompanyContent>;
    getUserComponent(id?: string): Type<UserContent>;
    getCustom(identifier?: string, args?: SelectorArgs): Type<any> | null;
}

export const ContentComponentSelector = new InjectionToken<ContentComponentSelector>('UfContentComponentSelector');


@Injectable()
export class ShellContentComponentSelector implements ContentComponentSelector {

    getPageComponent(_identifier?: string): Type<PageContent> {
        return PageComponent;
    }

    getViewComponent(_identifier?: string): Type<ViewContent> {
        return ViewComponent;
    }

    getFormComponent(_identifier?: string): Type<FormContent> {
        return FormComponent;
    }

    getCollectionComponent(_identifier?: string): Type<CollectionContent> {
        return CollectionComponent;
    }

    getCollectionItemComponent(_identifier?: string): Type<CollectionItemContent> {
        return CollectionItemComponent;
    }

    getTableComponent(_tableSourceType?: TableSourceType): Type<TablePageComponent> {
        return TablePageComponent;
    }

    getTableDetailComponent(_identifier?: string, _id?: string): Type<TableDetailComponent> {
        return TableDetailComponent;
    }

    getCompanyComponent(_id?: string): Type<CompanyContent> {
        return CompanyFormComponent;
    }

    getUserComponent(_id?: string): Type<UserContent> {
        return UserDetailsComponent;
    }

    getCustom(_identifier?: string, _args?: SelectorArgs): Type<any> | null {
        throw new Error('Method not implemented.');
    }

}
