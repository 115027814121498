import { Component, Inject, OnInit } from '@angular/core';
import { Compound, ImageProfile, PublishedContent } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';


interface Link {
    label: string;
    href?: string;
    link?: string;
    imgUrl?: string;
}

@Component({
    selector: 'ud-directory-template',
    templateUrl: './directory-template.html',
    styleUrls: ['./directory-template.less']
})
export class DirectoryTemplateComponent implements OnInit {

    logo: string | undefined;
    description: string | undefined;
    cards: Link[] = [];
    compound: Compound; // Set by data Resolver

    constructor(
        private nav: NavigationService,
        @Inject(PublishedContent) private content: PublishedContent
    ) { }

    ngOnInit() {
        this.logo = this.getImage(this.compound.icon, { width: 600 });
        this.description = this.compound.description;
        if(this.compound.buttons) {
            this.cards = (this.compound.buttons as any[]).map(b => this.toCard(b)).filter(card => card != null) as Link[];
        }
    }

    private toCard(button: any): Link | undefined {

        if (!button) {
            return;
        }

        const safeLinkValue = button.link ? (button.link as string).trim() : undefined;

        const link = this.getLink(safeLinkValue) || undefined;

        if (link) {
            const segments = link.split('/');
            const nodeId = segments.length > 2 ? segments[2] : null;
            const node = this.nav.getNode(nodeId);
            if (node && !this.nav.canAccessNode(node)) {
                return;
            }
        }

        const href = this.getHref(safeLinkValue) || undefined;
        const imgUrl = this.getImage(button.icon, { width: 300 }) || undefined;
        const label = button.label;

        return { label, href, link, imgUrl };
    }

    private getLink(link: string | undefined): string | null {

        if (link != null && !link.startsWith('https://') && !link.startsWith('http://')) {
            return link;
        }

        return null;
    }

    private getHref(href: string | undefined): string | null {

        if (href != null && (href.startsWith('https://') || href.startsWith('http://'))) {
            return href;
        }

        return null;
    }

    private getImage(profile: ImageProfile[] | undefined, options: { width: number }): string | undefined {

        if (profile == null || profile[0] == null || profile[0].url == null) {
            return;
        }

        return this.content.buildImageUrl(profile[0], options);
    }
}
