<form class="uf-grid" (ngSubmit)="resetPassword()">

    <ng-template [ngIf]="successMessage">
        <uf-blockquote class="success col-12 small" icon="successSolid" [content]="successMessage">
            <h3>{{ discoverTK.RequestPasswordChangeFeedbackEmailSentHeading | translate }}</h3>
            <br>
            <uf-description-list [items]="successContacts"></uf-description-list>
        </uf-blockquote>
    </ng-template>

    <uf-text *ngIf="!successMessage" class="col-12" [label]="commonTK.UsernameLabel | translate" name="username"
        autocomplete="username" [control]="control">
    </uf-text>


    <div class="col-12 col center-all gap-lg-top">
        <button *ngIf="!successMessage" class="uf-button primary" type="submit">
            {{discoverTK.ActionRequestPasswordChange | translate}}
        </button>
    </div>

</form>