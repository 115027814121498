<ud-user-access-box>
    <ng-container content>

        <ng-template [ngIf]="loadError">
            <uf-message class="error small gap-top" icon="errorSolid" [content]="loadError.message"></uf-message>
            <div class="col-1of1 col center-all">
                <button *ngIf="!isViaLink" class="uf-button tertiary" [disabled]="busy" (click)="logout()">
                    {{sharedTermsTK.ActionLogout | translate}}
                </button>
            </div>
        </ng-template>

        <ng-template [ngIf]="user || !isViaLink">
            <p *ngIf="isViaLink">
                <!-- {{discoverTK.PasswordChangeDescription | translate:{name: user.firstName || user.username} }} -->
            </p>
            <p *ngIf="!isViaLink">
                {{discoverTK.PasswordChangeDirectMessage | translate}}
            </p>

            <div class="grid">
                <form class="col-1of1 grid" (ngSubmit)="submit()">

                    <div class="col-1of1">
                        <uf-create-password [control]="control" [config]="passwordConfig"
                            [submitted]="control.submitted">
                        </uf-create-password>
                    </div>

                    <div *ngIf="error" class="col-1of1">
                        <uf-message class="error" icon="errorSolid" [content]="error.message">
                        </uf-message>
                    </div>

                    <div class="col-1of1 col center-all">
                        <button class="uf-button primary" [disabled]="busy" type="submit">
                            {{discoverTK.PasswordChangeActionChangePassword | translate}}
                        </button>
                    </div>

                    <div *ngIf="!isViaLink" class="col-1of1 col center-all">
                        <button type="button" class="uf-button tertiary" (click)="logout()">
                            {{sharedTermsTK.ActionLogout | translate}}
                        </button>
                    </div>

                </form>
            </div>

        </ng-template>

    </ng-container>
</ud-user-access-box>