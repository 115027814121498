import { Component } from '@angular/core';
import { Modal, ModalRuntime } from '@unifii/library/common';

import { DiscoverTranslationKey } from 'discover/discover.tk';


@Component({
    templateUrl: './user-upload-modal.html'
})
export class UserUploadModalComponent implements Modal<any, boolean> {

    discoverTk = DiscoverTranslationKey;

    constructor(
        public runtime: ModalRuntime<any, boolean>
    ) {

    }
}