<div #pdfContainer class="container">
    <div></div>
    <us-loading-spinner *ngIf="loading"></us-loading-spinner>
    <uf-icon *ngIf="error" class="pdf-error large" name="document-error"></uf-icon>
</div>
<div class="uf-app-bar flat accent medium">
    <button (click)="previousPage()" [title]="shellTK.PreviousPageLabel | translate" class="uf-action">
        <uf-icon name="arrowUp"></uf-icon>
    </button>
    <button (click)="nextPage()" [title]="shellTK.NextPageLabel | translate" class="uf-action">
        <uf-icon name="arrowDown"></uf-icon>
    </button>
    <div class="title"><span *ngIf="!error">{{page}}/{{pagesCount}}</span></div>
    <button (click)="zoomIn()" [title]="shellTK.ZoomInLabel | translate" class="uf-action">
        <uf-icon name="add"></uf-icon>
    </button>
    <button (click)="zoomOut()" [title]="shellTK.ZoomOutLabel | translate" class="uf-action">
        <uf-icon name="minus"></uf-icon>
    </button>
    <button (click)="toggleExpandedMode()"
        [title]="shellTK.FullscreenLabel | translate" class="uf-action expand-btn">
        <uf-icon [name]="isExpanded ? 'fullscreen-close' : 'fullscreen-open'"></uf-icon>
    </button>
</div>