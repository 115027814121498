import { DataSourceLoader, SourceConfig } from '@unifii/library/common';
import { DataSeed, TenantClient } from '@unifii/sdk';


export class UserClaimOptionsLoader implements DataSourceLoader {

    sourceConfig: SourceConfig;

    private seeds: DataSeed[];

    constructor(
        private claimId: string,
        private tenantClient: TenantClient
    ) { }

    async getOptions(): Promise<DataSeed[]> {
        return [...this.seeds];
    }

    async search(query?: string): Promise<DataSeed[]> {
        if (this.seeds == null) {
            await this.load();
        }
        return query ? this.seeds.filter(seed => seed._display.includes(query)) : this.seeds;
    }

    async findAllBy(match: string): Promise<DataSeed[]> {
        console.warn('datasource does not support findByAll, falling back on search');
        return this.search(match);
    }

    async get(id: string): Promise<DataSeed | null> {
        if (this.seeds == null) {
            await this.load();
        }
        return this.seeds.find(seed => seed._id === id) ?? null;
    }

    mapToSeed(result?: { id: string; display: string }): DataSeed | null {
        if (!result) {
            return null;
        }

        return {
            _id: result.id,
            _display: result.display
        };
    }

    private async load() {
        const result = await this.tenantClient.getUserClaim(this.claimId);
        this.seeds = result?.options?.map(option => this.mapToSeed(option)) as DataSeed[];
    }

}