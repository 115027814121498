<uf-panel class="container" (scrollBottom)="pageTable.load()" [bottomThreshold]="150" *ngIf="tableConfig && datasource">
    <div class="header-pane uf-app-bar flat stretch">
        <uf-breadcrumbs [breadcrumbs]="breadcrumbs">
        </uf-breadcrumbs>
        <button class="uf-action" (click)="showFilters = !showFilters" title="Filters">
            <uf-icon name="filter"></uf-icon>
        </button>
    </div>

    <div class="uf-grid condensed column-gap-sm pad-sides">
        <div class="col-12">
            <uf-filter-display [manager]="filterManager" [value]="filterValues" (valueChange)="filtersChange()">
            </uf-filter-display>
        </div>
        <div class="col-lg-6" *ngFor="let diagram of gridData">
            <h2>{{ diagram.title }}</h2>
            <div class="matrix-container">
                <div class="matrix" *ngIf="config && diagram.data && diagram.labels">
                    <div class="maxtrix-y-axis-label" [ngStyle]="{flex: 0.5}">
                        <div class="label" [ngStyle]="{flex: gridSize}">
                            <span>{{ diagram.yAxisLabel }}</span>
                        </div>
                        <div [ngStyle]="{flex: 2}"></div>
                    </div>
                    <div class="matrix-body" [ngStyle]="{flex: gridSize + 1.5}">
                        <div class="matrix-row" [ngStyle]="{flex: 1}" *ngFor="let row of diagram.data; let i = index">
                            <div class="label" [ngStyle]="{flex: 1.5}">
                                <span>{{ diagram.labels.columns[i] }}</span>
                            </div>
                            <div class="cell"
                                [ngStyle]="{flex: 1, backgroundColor: coloursLookup[config[i][j].colour] || config[i][j].colour}"
                                *ngFor="let column of row; let j = index">
                                <span class="small-number">{{ config[i][j].risk }}</span>
                                <span class="large-number">{{ column || '' }}</span>
                            </div>
                        </div>
                        <div class="matrix-row labels" [ngStyle]="{flex: 1.5}">
                            <div [ngStyle]="{flex: 1.5}"></div>
                            <div [ngStyle]="{flex: 1}" *ngFor="let label of diagram.labels.rows" class="label vertical">
                                <span>{{ label }}</span>
                            </div>
                        </div>
                        <div class="matrix-x-axis-label" [ngStyle]="{flex: 0.5}">
                            <div [ngStyle]="{flex: 2}"></div>
                            <div class="label" [ngStyle]="{flex: gridSize}">
                                <span>{{ diagram.xAxisLabel }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 pad-top">
            <uf-table #pageTable [config]="tableConfig" [datasource]="datasource" [sort]="sort" class="accent">
            </uf-table>
        </div>
    </div>

</uf-panel>

<uf-drawer [(open)]="showFilters" #right>
    <uf-filter-inputs *ngIf="filterManager" [manager]="filterManager" [value]="filterValues"
        (valueChange)="filtersChange()" (onClose)="showFilters = false"></uf-filter-inputs>
</uf-drawer>