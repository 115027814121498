<uf-panel class="container">
    <div class="uf-container-md">
        <div class="uf-grid condensed">

            <br>

            <div class="col-12 gap-lg-top gap-sides" *ngIf="logo">
                <img class="logo" [src]="logo" alt="{{logo}}" />
            </div>

            <div class="col-12 gap-sides body-copy" *ngIf="description" [markdownRenderer]="description">
            </div>
        </div>

        <div class="uf-grid directory-template row-gap-lg">
            <ng-container *ngFor="let card of cards">
                <a *ngIf="card.href" [attr.href]="card.href" ufTarget="_blank" class="uf-card centered">
                    <div class="uf-img-16x9">
                        <img *ngIf="card.imgUrl" [src]="card.imgUrl">
                    </div>
                    <div class="uf-card-title">
                        <h4 *ngIf="card.label">{{card.label}}</h4>
                    </div>
                </a>

                <a *ngIf="card.link" [routerLink]="card.link" class="uf-card centered">
                    <div class="uf-img-16x9">
                        <img *ngIf="card.imgUrl" [src]="card.imgUrl">
                    </div>
                    <div class="uf-card-title">
                        <h4 *ngIf="card.label">{{card.label}}</h4>
                    </div>
                </a>
            </ng-container>
        </div>

    </div>

</uf-panel>
