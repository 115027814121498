import { Observable, Subject } from 'rxjs';

import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { RouteInfo } from '@unifii/sdk';

import { DeviceService } from './device.service';


export interface PushNotificationData {
    title: string;
    message: string;
    route?: RouteInfo;
    action: boolean;
}

@Injectable()
export class PushNotificationService {

    registrationId: string;

    private _notifications = new Subject<PushNotificationData>();

    constructor(
        private zone: NgZone,
        private device: DeviceService
    ) { }

    /**
     * Initialises push notifications and returns a promise with registrationId
     */
    async init(): Promise<string | null> {

        if (!(this.device.isCapacitorAndroid() && Capacitor.isPluginAvailable('PushNotifications'))) {
            return Promise.resolve(null);
        }

        return new Promise(resolve => {


            PushNotifications.requestPermissions().then(result => {

                if (result.receive !== 'granted') {
                    return resolve(null);
                }

                PushNotifications.register();
            });

            PushNotifications.addListener('registration', (token) => {
                this.registrationId = token.value;

                this.zone.run(() => {
                    resolve(token.value);
                });
            });

            PushNotifications.addListener('registrationError', (error) => {
                console.error('Push notification error', error);
            });

            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                this.zone.run(() => {

                    // android must parse to json
                    if (this.device.isCapacitorAndroid() && notification.data.route) {
                        notification.data.route = JSON.parse(notification.data.route);
                    }

                    this._notifications.next({
                        title: notification.title || '',
                        message: notification.body || '',
                        route: notification.data.route || '',
                        action: false,
                    });
                });
            });

            PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                this.zone.run(() => {

                    // android must parse to json
                    if (this.device.isCapacitorAndroid() && notification.notification.data.route) {
                        notification.notification.data.route = JSON.parse(notification.notification.data.route);
                    }

                    this._notifications.next({
                        title: notification.notification.title || '',
                        message: notification.notification.body || '',
                        route:notification.notification.data.route || '',
                        action: true,
                    });
                });
            });

        });
    }

    /**
     * Emits received notifications
     */
    get notifications(): Observable<PushNotificationData> {
        return this._notifications;
    }

    /**
     * For testing purposes
     */
    emit(notification: PushNotificationData) {
        this._notifications.next(notification);
    }

}
