<uf-panel class="container bg-solid">

    <div class="header-pane" *ngIf="breadcrumbs.length">
        <uf-breadcrumbs class="small left" [breadcrumbs]="breadcrumbs" [minimize]="true">
        </uf-breadcrumbs>
    </div>

    <uf-collection-item *ngIf="definition && compound" [definition]="definition" [compound]="compound">
    </uf-collection-item>

</uf-panel>