import { Component, Inject, Input, OnInit } from '@angular/core';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { PinService } from 'discover/pin/pin-types';


@Component({
    selector: 'ud-pin-input',
    templateUrl: './pin-input.html',
})
export class PinInputComponent implements OnInit {

    @Input() pinTimeout: number;

    readonly discoverTK = DiscoverTranslationKey;

    constructor(
        @Inject(PinService) public pinService: PinService,
    ) { }

    ngOnInit() {
        if (this.pinService.isEnabled()) {
            this.pinTimeout = this.pinService.getPinDetails().timeout;
        }
    }

    async resetPin() {
        await this.pinService.showPinReset();
    }

}

