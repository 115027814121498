import { ChartType } from 'chart.js';
import { format } from 'date-fns';

import { Inject, Injectable } from '@angular/core';
import { FilterLoader, FilterType, FnsDate } from '@unifii/library/common';
import { Client, DataSourceType, FieldType, Option, PermissionAction, PublishedContent, TenantClient, UsersClient } from '@unifii/sdk';

import { CollectionOptionsLoader } from 'shell/common/filters/collection-options-loader';
import { UserClaimOptionsLoader } from 'shell/common/filters/user-claim-options-loader';
import { UserOptionsLoader } from 'shell/common/filters/user-options-loader';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

import { DiscoverContent } from 'discover/discover-content.service';
import { DiscoverOptions } from 'discover/discover-options';

import { Config } from 'config';


export interface ReportConfig {
    identifier: string;
    title: string;
    chartType: ChartType | 'table';
    width: number;
    dateFilters?: ReportDateFilterConfig;
    customFilters?: ReportCustomFilerConfig[];
    xAxis?: ReportAxisConfig;
    yAxis?: ReportAxisConfig;
    legend?: ReportLegendConfig;
    datalabels?: ReportDatalabelsConfig;
}

export interface ReportAxisConfig {
    label?: string;
    stacked?: boolean;
    ticks?: { min?: number; max?: number; precision?: number }; // TODO min, max not inline with chartjs 3.x types
}

export interface ReportLegendConfig {
    position: 'left' | 'right' | 'top' | 'bottom';
    align: 'start' | 'end';
    display: boolean;
}

export interface ReportDatalabelsConfig {
    content?: DataLabelContent;
    display?: boolean | 'auto';
    align?: 'left' | 'right' | 'top' | 'bottom' | 'center' | 'start' | 'end';
    anchor?: 'center' | 'start' | 'end';
    clamp?: boolean;
    offset?: number;
}

export enum DataLabelContent {
    DatasetLabel = 'dataset-label',
    DataLabel = 'data-label',
    Value = 'value',
    X = 'x',
    Y = 'y',
    R = 'r'
}

export interface ReportDateFilterConfig {
    startDate?: boolean;
    endDate?: boolean;
    presetRanges?: boolean;
    intervals?: boolean;
}
export interface ReportCustomFilerConfig { // TODO extend FilterEntry in the future
    identifier: string;
    label: string;
    type: FieldType;
    range?: boolean;
    options?: Option[];
    loader?: ReportDataSourceLoaderConfig; // TODO change to loaderConfig
}

export interface ReportDataSourceLoaderConfig {
    type: DataSourceType;
    id?: string;
    identifierProperty?: string;
    nameProperty?: string;
}

export interface ReportData {
    labels: (string | { value: string })[];
    datasets: ReportDataset[];
}

export interface ReportDataset {
    label?: string;
    labels?: string[];
    tooltips?: string[] | string[][];
    data: any[];
    color?: string | string[];
    tension?: number;
}

@Injectable()
export class ReportService {

    constructor(
        @Inject(Config) private config: DiscoverOptions,
        @Inject(PublishedContent) private content: DiscoverContent,
        private client: Client,
        private tenantClient: TenantClient,
        private usersClient: UsersClient,
        private auth: Authentication,
    ) { }

    async getConfig(reportId: string): Promise<ReportConfig | undefined> {

        const configUrl = this.url([reportId, 'config']);
        if (configUrl) {
            return await this.client.get(configUrl);
        }
        return;
    }

    async getData(reportId: string, filters: any): Promise<ReportData | undefined> {

        const dataUrl = this.url([reportId, 'data']);
        if (dataUrl) {
            return await this.client.get(dataUrl, { params: { ...filters, today: format(new Date(), FnsDate) } });
        }
        return;
    }

    createFilterLoader(loaderConfig?: ReportDataSourceLoaderConfig): FilterLoader |  undefined {

        switch (loaderConfig?.type) {
            case DataSourceType.Collection: return new CollectionOptionsLoader(
                loaderConfig.id as string,
                loaderConfig.identifierProperty as string,
                loaderConfig.nameProperty as string,
                this.content
            );
            case DataSourceType.Users:
                if (!this.auth?.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted) {
                    return;
                }
                return new UserOptionsLoader(
                    loaderConfig.identifierProperty as string,
                    loaderConfig.nameProperty as string,
                    this.usersClient
                );
            case DataSourceType.UserClaims: return new UserClaimOptionsLoader(
                loaderConfig.id as string,
                this.tenantClient
            );
            default: return;
        }
    }

    // TODO remove this duplicate once improved filters implementation in Report
    getFilterType(type: FieldType, loader?: FilterLoader): FilterType {
        switch (type) {
            case FieldType.Text:
            case FieldType.MultiText:
            case FieldType.Phone:
            case FieldType.Email:
            case FieldType.Website:
                return FilterType.Text;

            case FieldType.Date:
                return FilterType.DateRange;

            case FieldType.Time:
                return FilterType.TimeRange;

            case FieldType.DateTime:
                return FilterType.DatetimeRange;

            case FieldType.ZonedDateTime:
                return FilterType.ZonedDatetimeRange;

            case FieldType.Hierarchy:
                return FilterType.HierarchyUnit;

            case FieldType.Number:
                return FilterType.NumberRange;

            case FieldType.Cost:
                return FilterType.Cost;

            case FieldType.Bool:
                return FilterType.Bool;

            case FieldType.Choice:
                return loader ? FilterType.DataSeed : FilterType.Choice;

            case FieldType.MultiChoice:
                return loader ? FilterType.DataSeedArray : FilterType.OptionArray;

            case FieldType.Lookup:
                return FilterType.DataSeedArray;

            default:
                throw new Error(`property type ${type} not recognized`);
        }
    }

    private url(parts: string[] = []): string | undefined {

        if (!this.config.unifii.reportingApiUrl) {
            throw new Error('No reportingApiUrl provided');
        }

        if (this.config.unifii.reportingApiUrl && this.config.unifii.projectId && this.config.unifii.tenant) {
            parts = parts.map(p => encodeURIComponent(p));
            parts.unshift('charts');
            if (this.config.unifii.preview) {
                parts.unshift('preview');
            }
            parts.unshift(this.config.unifii.projectId);
            parts.unshift(this.config.unifii.tenant);
            parts.unshift(this.config.unifii.reportingApiUrl);

            return parts.join('/');
        }
        return;
    }

}
