import { UfControlGroup } from '@unifii/library/common';
export interface AddUserModalInfo {
    control: UfControlGroup;
    inputType: UserInputType;
    hasError?: boolean;
}

export enum UserInputType {
    Create = 'create',
    Invite = 'invite'
}