import { InjectionToken } from '@angular/core';


/** Override availability of items in Settings menu */
export interface SettingsConfig {

    hidePreviewMode?: boolean;
    hideRefreshApp?: boolean;
    hideChangeProject?: boolean;
    hideSyncForms?: boolean;
    hideMyProfile?: boolean;
    hideLogout?: boolean;
}

export const SettingsConfig = new InjectionToken<SettingsConfig>('SettingsConfig');
