import { Inject, Injectable } from '@angular/core';

import { ContentDb, IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { ContentStores } from 'shell/offline/offline-model';
import { getTokens } from 'shell/offline/text-tokenizer';


@Injectable()
export class ContentSearcher {

    constructor(@Inject(ContentDb) private contentDb: IndexedDbWrapper) { }

    async lookup(terms: string): Promise<string[]> {

        // Get text tokens
        const tokens = getTokens(terms);

        const entries: Map<string, number> = new Map<string, number>();


        for (const token of tokens) {
            const keys = await this.contentDb.get<string[]>(ContentStores.Indexes, token) || [];

            let entry: number;
            for (const key of keys) {
                entry = entries.get(key) || 0;
                entry++;
                entries.set(key, entry);
            }
        }

        return Array.from(entries).sort((a, b) => a[1] - b[1]).map(i => i[0]);
    }
}
