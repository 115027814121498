import { Dictionary } from '@unifii/sdk';

export const ReportColourCommon: Dictionary<string> = {
    1: '#146734',
    2: '#D5AA4D',
    3: '#9F3715',
    4: '#51657F',
    5: '#838382',
    6: '#C2B4A3',
    7: '#D5AA4D',
    8: '#EADBAB',
    9: '#B18272',
    10: '#575756',
    11: '#640D0D',
    12: '#4480AB',
    13: '#9DB5C5',
    14: '#585EAA',
    15: '#8490C8'
};

export const ReportColour: Dictionary<string> = {
    blue: '#0037F1',
    green: '#8BC34A',
    darkGreen: '#008837',
    yellow: '#FFEB3B',
    orange: '#FF9800',
    red: '#F44336',
    purple: '#7F3AB7',
    darkBlue: '#3F51B5',
};