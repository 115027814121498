import { Injectable, Type } from '@angular/core';
import { FormComponentRegistryOptions, FormField } from '@unifii/library/smart-forms';
import { UfFormComponentRegistry } from '@unifii/library/smart-forms/input';
import { FieldType } from '@unifii/sdk';

import { DiscoverContentLinkComponent } from 'discover/components/smart-form/content-link.component';
import { StepperSectionComponent } from 'discover/components/smart-form/section-stepper.component';


@Injectable()
export class UdFormComponentRegistry extends UfFormComponentRegistry {

    get(type: FieldType, options?: FormComponentRegistryOptions): Type<FormField> {

        if (type === FieldType.Section && options?.tags?.includes('stepper') && options?.isActive) {
            return StepperSectionComponent;
        }

        if (type === FieldType.Link && !options?.isActive) {
            return DiscoverContentLinkComponent;
        }

        return super.get(type, options);
    }
}
