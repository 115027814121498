import {
    ClientGetOptions, Compound, ContentType, Definition, Dictionary, ImageProfile, Page, PublishedContent, Query, Schema, Structure, Table
} from '@unifii/sdk';

import { Features } from 'shell/features';

import { DiscoverContext } from './discover-context';


export class DiscoverContent implements PublishedContent {

    constructor(
        private onlineContent: PublishedContent,
        private offlineContent: PublishedContent,
        private context: DiscoverContext,
        private features: Features
    ) { }

    getView(identifier: string, options?: ClientGetOptions): Promise<Compound> {
        return this.service.getView(identifier, options);
    }

    getViewDefinition(identifier: string, options?: ClientGetOptions): Promise<Definition> {
        return this.service.getViewDefinition(identifier, options);
    }
    /**
     * @param identifier - backwards compatibility with id
     */
    getPage(identifier: string, options?: ClientGetOptions): Promise<Page> {
        return this.service.getPage(identifier, options);
    }

    queryPages(query?: Query, options?: ClientGetOptions ): Promise<Page[]> {
        return this.service.queryPages(query, options);
    }

    getCollectionDefinition(identifier: string, options?: ClientGetOptions): Promise<Definition> {
        return this.service.getCollectionDefinition(identifier, options);
    }

    getCollections(options?: ClientGetOptions): Promise<Definition[]> {
        return this.service.getCollections(options);
    }

    queryCollection(identifier: string, query?: Query, options?: ClientGetOptions): Promise<Compound[]> {
        return this.service.queryCollection(identifier, query, options);
    }

    getCollectionItem(identifier: string, id: string, options?: ClientGetOptions): Promise<Compound> {
        return this.service.getCollectionItem(identifier, id, options);
    }

    getBucket(identifier: string, options?: ClientGetOptions): Promise<Schema> {
        return this.service.getBucket(identifier, options);
    }

    queryForms(query?: Query, options?: ClientGetOptions): Promise<Definition[]> {
        return this.service.queryForms(query, options);
    }

    getForm(identifier: string, version?: any, options?: ClientGetOptions): Promise<Definition> {
        console.warn('DiscoverContent.getForm - deprecated, use DiscoverFormService instead');
        return this.service.getForm(identifier, version, options);
    }

    getStructure(options?: ClientGetOptions): Promise<Structure> {
        return this.service.getStructure(options);
    }

    queryTables(query?: Query, options?: ClientGetOptions): Promise<Table[]> {
        return this.service.queryTables(query, options);
    }

    getAssetUrl(id: string): Promise<string>{
        return this.service.getAssetUrl(id);
    }

    buildImageUrl(imageProfile: ImageProfile, options?: { width?: number }): string | undefined {
        return this.service.buildImageUrl(imageProfile,options);
    }

    /** TODO Remove hack once the API correctly return a null detail instead of the mocked one */
    async getTable(id: string, options?: ClientGetOptions): Promise<Table> {
        const table = await this.service.getTable(id, options);
        if (table.detail && !table.detail.title) {
            delete table.detail;
        }
        return table;
    }

    async getIdentifiers(options?: ClientGetOptions): Promise<Dictionary<{ type: ContentType }>> {
        return this.service.getIdentifiers(options);
    }

    private get service(): PublishedContent {
        if (this.useOfflineService) {
            return this.offlineContent;
        }
        return this.onlineContent;
    }

    private get useOfflineService(): boolean {
        return this.features.offline && this.context.project?.offline === true;
    }
}
