import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Client, Dictionary, Interceptor, MeClient, TenantClient, UserInfo, UsersClient } from '@unifii/sdk';
import { UserControlService, UserFieldLabelService, UserInfoKey, UserValidatorService } from '@unifii/user-provisioning';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { SdkInterceptor } from 'shell/sdk-interceptor';
import { Authentication } from 'shell/services/authentication';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { Config } from 'config';


@Component({
    selector: 'ud-complete-registration',
    templateUrl: 'complete-registration.html',
    providers: [UserControlService]
})
export class CompleteRegistrationComponent implements OnInit {

    readonly discoverTK = DiscoverTranslationKey;
    readonly controlKeys = UserInfoKey;

    form: UfControlGroup;
    labels: Dictionary<string>;
    linkError: AppError;
    error: AppError | null;
    busy = true;

    private token: string;
    private user: UserInfo;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        @Inject(Authentication) private authentication: Authentication,
        private errorService: ErrorService,
        private translate: TranslateService,
        @Inject(Interceptor) private interceptor: SdkInterceptor,
        @Inject(Config) private config: Config,
        private userControlService: UserControlService,
        private userValidatorService: UserValidatorService,
        private userFieldLabelService: UserFieldLabelService,
        private ufb: UfFormBuilder
    ) {
        this.labels = this.userFieldLabelService.labelDictionary;
    }

    async ngOnInit() {
        try {
            const { tenant, id, token } = this.route.snapshot.queryParams;
            if (!tenant || !id || !token) {
                throw new Error();
            }

            this.token = token;

            // Create new client without token storage
            const client = new Client(this.config.unifii, undefined, this.interceptor);
            const tenantClient = new TenantClient(client);
            const usersClient = new UsersClient(client);

            this.config.unifii.tenant = tenant;
            this.config.unifii.tenantSettings = await tenantClient.getSettings();

            this.user = await usersClient.get(id, this.token);

            // Clear username if it's an invitation_GUID
            if (this.user.username.startsWith('invitation_')) {
                this.user.username = '';
            }

            this.form = this.getControls(this.user);

        } catch (e) {
            this.linkError = this.errorService.createError(
                this.translate.instant(DiscoverTranslationKey.CompleteRegistrationErrorInvalidLink)
            );
        } finally {
            this.busy = false;
        }
    }

    async submit() {
        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        try {
            this.error = null;
            this.busy = true;

            // Create new client without token storage
            const client = new Client(this.config.unifii, undefined, this.interceptor);
            const meClient = new MeClient(client);

            // patch user with form values
            const user = Object.assign({}, this.user, this.form.getRawValue()) as UserInfo;

            await meClient.update(user, { token: this.token, id: user.id as string });
            await this.authentication.login({ username: user.username, password: user.password as string });
            this.router.navigate(['/login', 'projects']);

        } catch (error) {
            this.error = error;
        } finally {
            this.busy = false;
        }
    }

    private getControls(userInfo: UserInfo): UfControlGroup {

        return this.ufb.group({
            [UserInfoKey.Email]: this.getControl(UserInfoKey.Email, userInfo),
            [UserInfoKey.Username]: this.getControl(UserInfoKey.Username, userInfo),
            [UserInfoKey.FirstName]: this.getControl(UserInfoKey.FirstName, userInfo),
            [UserInfoKey.LastName]: this.getControl(UserInfoKey.LastName, userInfo),
            [UserInfoKey.Phone]: this.getControl(UserInfoKey.Phone, userInfo),
            [UserInfoKey.Password]: [
                null,
                ValidatorFunctions.compose([
                    ValidatorFunctions.custom(v => !v || v.length >= 12, this.translate.instant(CommonTranslationKey.CreatePasswordErrorMinLength12)),
                    ValidatorFunctions.custom(v => !v || v.length < 256, this.translate.instant(CommonTranslationKey.CreatePasswordErrorMaxLength256)),
                    ValidatorFunctions.custom(v => !ValidatorFunctions.isEmpty(v), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired))
                ])
            ],
        });
    }

    private getControl(key: UserInfoKey, userInfo: UserInfo) {
        return this.ufb.control({
            value: (userInfo as { [key: string]: any })[key],
            disabled: this.getDisabledState(key, userInfo)
        }, this.getValidator(key));
    }

    private getDisabledState(key: UserInfoKey, userInfo: UserInfo) {
        switch (key) {
            case UserInfoKey.Email: return true;
            case UserInfoKey.Username: return !userInfo.canChangeUsername;
            default: return this.userControlService.isDisabled(key);
        }
    }

    private getValidator(key: UserInfoKey) {
        switch (key) {
            case UserInfoKey.Email: return this.userValidatorService.validators.email;
            case UserInfoKey.Username: return this.userValidatorService.validators.username;
            case UserInfoKey.Password: return this.userValidatorService.validators.password;
            default: return;
        }
    }

}
