<div class="uf-app-bar">
    <button type="button" class="uf-action tertiary" (click)="close(false)">
        <uf-icon name="close" [title]="sharedTermsTK.ActionClose | translate"></uf-icon>
    </button>
    <h3>{{data.title}}</h3>
</div>

<div class="uf-grid pad grow">
    <div class="col-12">
        <ng-template [ngIf]="data.content">
            <div #content></div>
        </ng-template>
        <ng-template [ngIf]="data.message && !data.content">
            <div [markdownRenderer]="data.message"></div>
        </ng-template>
    </div>
</div>

<div class="uf-form-actions">
    <button class="uf-button tertiary small" type="button" (click)="close(false)">
        {{data.cancelLabel}}
    </button>
    <button class="uf-button primary small" type="button" (click)="close(true)" ufAutofocus>
        {{data.confirmLabel}}
    </button>
</div>