import { IndexedDbWrapper } from 'shell/offline/indexeddb-wrapper';
import { ContentStores } from 'shell/offline/offline-model';
import { getTokens } from 'shell/offline/text-tokenizer';


export class ContentIndexer {

    constructor(private db: IndexedDbWrapper) { }

    index(key: string, text: string): Promise<void> {

        // Get text tokens
        const tokens = getTokens(text);
        // console.log('Index', key, tokens);

        // Read token from indexes
        const changeds: { token: string; keys: string[] }[] = [];

        return this.db.tx(ContentStores.Indexes, async s => {
            for (const token of tokens) {

                let keys: any[] = await this.db.promisify(s.get(token));
                keys = keys || [];

                if (keys.find(k => k === key) == null) {
                    // Key not present for this token, add it
                    keys.push(key);
                    // console.log('To add', token, keys);
                    changeds.push({ token, keys });
                }
            }

            for (const changed of changeds) {
                await this.db.promisify(s.put(changed.keys, changed.token));
            }
            // console.log('Indexes updated for', key, text);

        }, 'readwrite');
    }

}
