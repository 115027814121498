<svg>
    <style type="text/css">
        .white {
            fill: #FFFFFF;
        }
    </style>
    <defs>
        <symbol id="attachment">
            <path d="M25.7,3.6c-2.1-2.1-5.6-2.1-7.7,0L7.2,14.4c-1.5,1.5-1.5,3.8,0,5.3c0.7,0.7,1.6,1.1,2.7,1.1s1.9-0.4,2.7-1.1l7.3-7.3
		c0.5-0.5,0.5-1.2,0-1.6c-0.5-0.5-1.2-0.5-1.6,0L10.8,18c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-1-0.4c-0.6-0.6-0.6-1.4,0-2L19.6,5.3
		C20.8,4,22.8,4,24,5.3c0.6,0.6,0.9,1.4,0.9,2.2S24.6,9.1,24,9.7L11.5,22.2c-1.9,1.9-5.1,1.9-7,0s-1.9-5.1,0-7c0,0,0,0,0,0l9.8-9.8
		c0.5-0.5,0.5-1.2,0-1.6c-0.5-0.5-1.2-0.5-1.6,0l-9.8,9.8l0,0C0,16.4,0,21,2.9,23.9C4.3,25.3,6.1,26,8,26c1.6,0,3.2-0.5,4.5-1.6
		c0.1-0.1,0.2-0.1,0.3-0.2l12.9-12.9C27.8,9.2,27.8,5.7,25.7,3.6z" />
        </symbol>
        <symbol id="calendar">
            <path d="M21.4,4.2h-2.2v-1c0-0.6-0.5-1.2-1.2-1.2s-1.2,0.5-1.2,1.2v1h-5.8v-1c0-0.6-0.5-1.2-1.2-1.2C9.3,2,8.8,2.5,8.8,3.1v1H6.6
                c-1.9,0-3.5,1.6-3.5,3.5v14.9c0,1.9,1.6,3.5,3.5,3.5h14.9c1.9,0,3.5-1.6,3.5-3.5V7.7C24.9,5.7,23.4,4.2,21.4,4.2z M22.6,22.5
                c0,0.6-0.5,1.2-1.2,1.2H6.6c-0.6,0-1.2-0.5-1.2-1.2V7.7c0-0.6,0.5-1.2,1.2-1.2h2.2v1c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2
                v-1h5.8v1c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2v-1h2.2c0.6,0,1.2,0.5,1.2,1.2V22.5z" />
            <g>
                <g>
                    <rect x="14" y="14.9" width="5.8" height="5.8" />
                </g>
            </g>
        </symbol>
        <symbol id="change">
            <path
                d="M7.1,14.5c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.4-0.4,0.4-1.1,0-1.6l-2.1-2.2h17.5c0.6,0,1.2-0.5,1.2-1.2
                s-0.5-1.2-1.2-1.2H6.5l2.1-2.1c0.4-0.4,0.4-1.1,0-1.6c-0.4-0.4-1.1-0.4-1.6,0L3,8.9C2.6,9.3,2.6,10,3,10.4L7.1,14.5z" />
            <path
                d="M25,17.6l-4.1-4.1c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6l2.1,2.1H3.9c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2
                h17.5l-2.1,2.1c-0.4,0.4-0.4,1.1,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3l4.1-4.1C25.4,18.7,25.4,18,25,17.6z" />
        </symbol>
        <symbol id="document-error">
            <path d="M9,28C4.04,28,0,23.96,0,19S4.04,10,9,10s9,4.04,9,9-4.04,9-9,9Zm0-16c-3.86,0-7,3.14-7,7s3.14,7,7,7,7-3.14,7-7-3.14-7-7-7Z"/><path d="M8.99,22.52c-.72,0-1.27,.57-1.27,1.27s.55,1.29,1.27,1.29,1.29-.57,1.29-1.29-.55-1.27-1.29-1.27Z"/><polygon points="10.28 13.24 7.72 13.24 8.15 21.56 9.85 21.56 10.28 13.24"/><path d="M27.93,8.63s-.05-.08-.07-.12c-.03-.06-.06-.12-.1-.17L20.75,.34s-.02-.01-.03-.02c-.07-.07-.15-.13-.24-.18-.03-.02-.05-.04-.09-.06-.12-.05-.26-.09-.4-.09H9c-.55,0-1,.45-1,1V9.06c.33-.03,.66-.06,1-.06s.67,.02,1,.05V2h9v7c0,.55,.45,1,1,1h6v12h-7.46c-.22,.7-.52,1.37-.89,2h9.35c.55,0,1-.45,1-1V9c0-.13-.03-.25-.07-.37Zm-6.93-.63V3.66l3.8,4.34h-3.8Z"/>
        </symbol>
        <symbol id="email">
            <path d="M26,5.3c0-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.3-0.6-0.4c-0.6-0.4-1.3-0.6-2-0.6H4.7c-1.9,0-3.5,1.6-3.5,3.5v13.1
                c0,1.9,1.6,3.5,3.5,3.5h18.5c1.9,0,3.5-1.6,3.5-3.5V7.4C26.8,6.6,26.5,5.9,26,5.3z M22.2,6.3L14,12.5L6.1,6.3H22.2z M23.3,21.7H4.7
                c-0.6,0-1.2-0.5-1.2-1.2V7.4c0-0.1,0-0.1,0-0.2l9.7,7.7c0.2,0.2,0.5,0.2,0.7,0.2c0.2,0,0.5-0.1,0.7-0.2l9.7-7.4v13
                C24.4,21.2,23.9,21.7,23.3,21.7z" />
        </symbol>
        <symbol id="hamburger">
            <g>
                <path
                    d="M24.4,22.6H3.6c-0.7,0-1.3-0.6-1.3-1.3S2.9,20,3.6,20h20.7c0.7,0,1.3,0.6,1.3,1.3S25.1,22.6,24.4,22.6z" />
            </g>
            <g>
                <path
                    d="M24.4,15.3H3.7c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3h20.6c0.7,0,1.3,0.6,1.3,1.3S25.1,15.3,24.4,15.3z" />
            </g>
            <g>
                <path d="M24.4,8H3.6C2.9,8,2.3,7.4,2.3,6.7s0.6-1.3,1.3-1.3h20.7c0.7,0,1.3,0.6,1.3,1.3S25.1,8,24.4,8z" />
            </g>
        </symbol>
        <symbol id="menuItemExpand">
            <path d="M25,13H15V3a1,1,0,0,0-2,0V13H3a1,1,0,0,0,0,2H13V25a1,1,0,0,0,2,0V15H25a1,1,0,0,0,0-2Z" />
        </symbol>

        <symbol id="menuItemCollapse">
            <path d="M3,15a1,1,0,0,1,0-2H25a1,1,0,0,1,0,2Z" />
        </symbol>
        <symbol id="home">
            <path d="M26.5,10.3L14.7,2.2c-0.4-0.3-0.9-0.3-1.3,0L1.5,10.3C1,10.7,0.8,11.5,1.2,12c0.4,0.5,1.1,0.7,1.6,0.3l1.3-0.9v13.5
                c0,0.6,0.5,1.2,1.2,1.2h17.4c0.6,0,1.2-0.5,1.2-1.2V11.4l1.3,0.9c0.2,0.1,0.4,0.2,0.7,0.2c0.4,0,0.7-0.2,1-0.5
                C27.2,11.4,27,10.7,26.5,10.3z M21.6,23.7H6.4V9.8L14,4.6l7.6,5.2V23.7z" />
        </symbol>
        <symbol id="image">
            <path
                d="M21.5,3h-15C4.6,3,3,4.6,3,6.5v15C3,23.4,4.6,25,6.5,25h15c1.9,0,3.5-1.6,3.5-3.5v-15C25,4.6,23.4,3,21.5,3z M6.5,5.3h15
                c0.6,0,1.2,0.5,1.2,1.2v8.2L21.1,13c-0.2-0.2-0.5-0.4-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.4L17,15.9L9.5,7.6C9.3,7.4,9,7.2,8.7,7.2l0,0
                c-0.3,0-0.6,0.1-0.8,0.4l-2.6,2.8V6.5C5.3,5.9,5.9,5.3,6.5,5.3z M21.5,22.7h-15c-0.6,0-1.2-0.5-1.2-1.2v-7.8L8.7,10l7.4,8.3
                c0.2,0.2,0.5,0.4,0.8,0.4l0,0c0.3,0,0.6-0.1,0.8-0.4l2.5-2.8l2.4,2.5v3.5C22.7,22.1,22.1,22.7,21.5,22.7z" />
        </symbol>
        <symbol id="login">
            <g>
                <g>
                    <path d="M14,25.5c-6.3,0-11.4-5.1-11.4-11.4c0-4.5,2.7-8.6,6.8-10.4c0.6-0.3,1.3,0,1.5,0.6s0,1.3-0.6,1.5C7.1,7.3,5,10.5,5,14.1
                                c0,5,4.1,9,9,9s9-4.1,9-9c0-3.6-2.1-6.9-5.5-8.3C17,5.6,16.7,4.9,17,4.3c0.2-0.6,0.9-0.9,1.5-0.6c4.2,1.8,6.9,5.9,6.9,10.4
                                C25.4,20.4,20.3,25.5,14,25.5z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M14,15.2c-0.6,0-1.2-0.5-1.2-1.2V4.8c0-0.6,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v9.3C15.2,14.7,14.6,15.2,14,15.2z" />
                </g>
            </g>
        </symbol>
        <symbol id="phone">
            <path d="M21.1,26.3L21.1,26.3c-3.3,0-8-2.7-12.4-7c0,0,0,0,0,0c-4.3-4.4-7-9.1-7-12.3c0-2.5,3.4-5.3,5.1-5.3c0.3,0,1.2,0,3.3,3.1
				c0.5,0.8,1.9,3,1.9,3.8c0,0,0,0,0,0c0,0.8-0.3,1.3-0.6,1.8c-0.2,0.4-0.4,0.7-0.5,1.2c0.1,0.4,0.9,1.6,2.4,3.1l0,0
				c1.5,1.5,2.7,2.3,3.1,2.4c0.4,0,0.8-0.2,1.2-0.5c0.5-0.3,1-0.6,1.8-0.6c0,0,0,0,0.1,0c0.8,0,3,1.4,3.8,1.9c3.1,2.1,3.1,3,3.1,3.3
				c0,1-0.9,2.3-1.7,3.2C24,25.1,22.6,26.3,21.1,26.3z M10.2,17.9c3.9,3.8,8.3,6.4,10.9,6.4l0,0c0.5,0,1.3-0.5,2.1-1.3
				c0.7-0.7,1-1.4,1.1-1.7c-0.2-0.3-0.8-0.9-2.1-1.7c-1.4-0.9-2.4-1.4-2.8-1.6c-0.2,0-0.4,0.1-0.7,0.3c-0.5,0.3-1.2,0.7-2.2,0.7h0
				c-1.3,0-3.4-1.9-4.5-3l0,0c-1.1-1.1-3-3.2-3-4.5c0-1,0.4-1.7,0.7-2.2C9.9,9,10,8.8,10,8.6C9.9,8.3,9.3,7.2,8.4,5.8
				C7.6,4.6,7,4,6.7,3.7C6.4,3.9,5.7,4.2,5,4.9C4.2,5.6,3.7,6.4,3.7,6.9C3.8,9.6,6.4,14,10.2,17.9z M16.5,17L16.5,17L16.5,17z
				 M10,8.7L10,8.7L10,8.7z M6.6,3.7L6.6,3.7L6.6,3.7z" />
        </symbol>
        <symbol id="refresh">
            <g>
                <path
                    d="M23.6,15.4c-0.6-0.2-1.3,0.2-1.4,0.8c-1,3.7-4.3,6.3-8.2,6.3c-3.4,0-6.3-2-7.7-4.9l0.9,0.4c0.6,0.2,1.3,0,1.5-0.6
                            c0.2-0.6,0-1.3-0.6-1.5l-3.5-1.4c-0.6-0.2-1.3,0-1.5,0.6l-1.4,3.5c-0.2,0.6,0,1.3,0.6,1.5c0.1,0.1,0.3,0.1,0.4,0.1
                            c0.5,0,0.9-0.3,1.1-0.7l0.3-0.8c1.7,3.7,5.5,6.3,9.8,6.3c4.9,0,9.2-3.3,10.4-8C24.6,16.2,24.2,15.5,23.6,15.4z" />
                <path d="M25.7,8c-0.6-0.2-1.3,0-1.5,0.6l-0.3,0.8c-1.7-3.7-5.5-6.3-9.8-6.3c-4.9,0-9.2,3.3-10.4,8c-0.2,0.6,0.2,1.3,0.8,1.4
                            c0.6,0.2,1.3-0.2,1.4-0.8c1-3.7,4.3-6.3,8.2-6.3c3.4,0,6.3,2,7.7,4.9l-0.9-0.4c-0.6-0.2-1.3,0-1.5,0.6c-0.2,0.6,0,1.3,0.6,1.5
                            l3.5,1.4c0.1,0.1,0.3,0.1,0.4,0.1c0.5,0,0.9-0.3,1.1-0.7l1.4-3.5C26.5,9,26.2,8.3,25.7,8z" />
            </g>
        </symbol>
        <symbol id="search">
            <path d="M25.7,24l-7.2-7.1l0,0c1.3-1.6,2-3.5,2-5.7c0-5.1-4.1-9.2-9.2-9.2S2,6.1,2,11.2s4.1,9.2,9.2,9.2c2.1,0,4-0.7,5.6-1.9
		c0,0,0,0,0,0l7.2,7.1c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3C26.1,25.2,26.1,24.5,25.7,24z M11.2,18.1
		c-3.8,0-6.9-3.1-6.9-6.9s3.1-6.9,6.9-6.9s6.9,3.1,6.9,6.9S15.1,18.1,11.2,18.1z" />
        </symbol>
        <symbol id="settings">
            <g>
                <g>
                    <path
                        d="M16.8,26.4h-5.5c-0.6,0-1-0.5-1-1v-2.8c-0.6-0.3-1.2-0.6-1.8-1L6,23c-0.2,0.1-0.5,0.2-0.8,0.1C5,23,4.8,22.8,4.6,22.6
                                l-2.8-4.8c-0.3-0.5-0.1-1.1,0.4-1.4L4.6,15c0-0.4-0.1-0.8-0.1-1.1c0-0.3,0-0.7,0.1-1l-2.3-1.3c-0.5-0.3-0.7-0.9-0.4-1.4l2.8-4.8
                                C4.8,5.2,5,5,5.3,4.9C5.5,4.8,5.8,4.9,6,5l2.3,1.3c0.6-0.4,1.2-0.8,1.9-1.1V2.6c0-0.6,0.5-1,1-1h5.5c0.6,0,1,0.5,1,1v2.6
                                c0.7,0.3,1.3,0.7,1.9,1.1L22,5c0.2-0.1,0.5-0.2,0.8-0.1s0.5,0.2,0.6,0.5l2.8,4.8c0.3,0.5,0.1,1.1-0.4,1.4L23.4,13
                                c0,0.3,0.1,0.7,0.1,1c0,0.4,0,0.8-0.1,1.1l2.3,1.3c0.5,0.3,0.7,0.9,0.4,1.4l-2.8,4.8c-0.1,0.2-0.4,0.4-0.6,0.5
                                c-0.3,0.1-0.5,0-0.8-0.1l-2.4-1.4c-0.6,0.4-1.2,0.7-1.8,1v2.7C17.8,25.9,17.3,26.4,16.8,26.4z M12.2,24.4h3.5v-2.4
                                c0-0.4,0.3-0.8,0.7-1c0.9-0.3,1.7-0.8,2.4-1.4c0.3-0.3,0.8-0.3,1.2-0.1l2.1,1.2l1.7-3l-2.1-1.2c-0.4-0.2-0.6-0.7-0.5-1.1
                                c0.1-0.5,0.1-1,0.1-1.5c0-0.4,0-0.9-0.1-1.3c-0.1-0.4,0.1-0.8,0.5-1.1l2-1.2l-1.7-3l-2,1.1c-0.4,0.2-0.9,0.2-1.2-0.1
                                c-0.7-0.6-1.6-1.1-2.5-1.4c-0.4-0.1-0.7-0.5-0.7-1V3.6h-3.5v2.3c0,0.4-0.3,0.8-0.7,1c-0.9,0.3-1.7,0.8-2.5,1.4
                                C8.7,8.6,8.3,8.7,7.9,8.5l-2-1.2l-1.7,3l2,1.2c0.4,0.2,0.6,0.6,0.5,1.1c-0.1,0.5-0.1,0.9-0.1,1.3c0,0.5,0,1,0.1,1.5
                                c0.1,0.4-0.1,0.9-0.5,1.1l-2.1,1.2l1.7,3L8,19.5c0.4-0.2,0.8-0.2,1.2,0.1c0.7,0.6,1.5,1.1,2.4,1.4c0.4,0.1,0.7,0.5,0.7,1V24.4z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M14,19.1c-2.8,0-5.1-2.3-5.1-5.1s2.3-5.1,5.1-5.1s5.1,2.3,5.1,5.1S16.8,19.1,14,19.1z M14,10.9c-1.7,0-3.1,1.4-3.1,3.1
                                s1.4,3.1,3.1,3.1S17,15.7,17,14S15.7,10.9,14,10.9z" />
                </g>
            </g>
        </symbol>
        <symbol id="sign-out">
            <path d="M24.8627 13.9682C24.8632 13.9526 24.8604 13.9371 24.8546 13.9226C24.8489 13.9082 24.8402 13.895 24.8292 13.884C24.8182 13.873 24.805 13.8643 24.7906 13.8585C24.7761 13.8528 24.7606 13.85 24.745 13.8505H24.0864C24.0706 13.8501 24.055 13.8528 24.0403 13.8585C24.0257 13.8642 24.0123 13.8728 24.0011 13.8838C23.9898 13.8948 23.9808 13.9079 23.9747 13.9224C23.9686 13.9369 23.9654 13.9525 23.9655 13.9682C23.9655 14.0003 23.9782 14.031 24.0009 14.0537C24.0235 14.0764 24.0543 14.0891 24.0864 14.0891H24.745C24.7607 14.0891 24.7763 14.086 24.7908 14.0799C24.8053 14.0737 24.8184 14.0648 24.8294 14.0535C24.8404 14.0422 24.849 14.0289 24.8547 14.0142C24.8604 13.9996 24.8632 13.9839 24.8627 13.9682V13.9682Z" />
            <path d="M21 11.0568C21.0321 11.0568 21.0628 11.0441 21.0855 11.0214C21.1082 10.9987 21.1209 10.968 21.1209 10.9359V10.2773C21.121 10.2615 21.1178 10.246 21.1117 10.2315C21.1056 10.217 21.0966 10.2038 21.0853 10.1929C21.0741 10.1819 21.0607 10.1733 21.0461 10.1676C21.0314 10.1618 21.0158 10.1591 21 10.1595C20.9845 10.1591 20.969 10.1618 20.9545 10.1676C20.94 10.1734 20.9268 10.182 20.9158 10.193C20.9048 10.2041 20.8961 10.2172 20.8904 10.2317C20.8846 10.2462 20.8819 10.2617 20.8823 10.2773V10.9359C20.8819 10.9516 20.8846 10.9673 20.8903 10.9819C20.8961 10.9966 20.9047 11.0099 20.9156 11.0212C20.9266 11.0325 20.9397 11.0414 20.9542 11.0476C20.9687 11.0537 20.9843 11.0568 21 11.0568V11.0568Z" />
            <path d="M19.0177 10.78C18.9845 10.8054 18.9614 10.8417 18.9527 10.8826C18.9439 10.9234 18.9501 10.9661 18.97 11.0028C18.9949 11.0365 19.0313 11.0601 19.0723 11.0688C19.1134 11.0776 19.1562 11.0711 19.1927 11.0505C19.2114 11.0398 19.2276 11.0254 19.2403 11.008C19.253 10.9907 19.2619 10.9708 19.2664 10.9498C19.2709 10.9288 19.2709 10.907 19.2665 10.886C19.262 10.865 19.2531 10.8451 19.2404 10.8278C19.2156 10.794 19.1792 10.7705 19.1381 10.7617C19.0971 10.7529 19.0543 10.7594 19.0177 10.78V10.78Z" />
            <path d="M17.9137 12.1068C17.8953 12.0958 17.8748 12.0887 17.8535 12.0859C17.8322 12.0831 17.8106 12.0848 17.79 12.0908C17.7694 12.0967 17.7502 12.1069 17.7337 12.1206C17.7172 12.1343 17.7038 12.1513 17.6941 12.1705C17.6831 12.1889 17.6759 12.2093 17.6732 12.2306C17.6704 12.2519 17.6721 12.2735 17.678 12.2941C17.684 12.3147 17.6942 12.3339 17.7079 12.3504C17.7216 12.3669 17.7386 12.3804 17.7578 12.39C17.7763 12.4006 17.7967 12.4074 17.8179 12.4099C17.8391 12.4124 17.8605 12.4106 17.881 12.4047C17.9015 12.3988 17.9206 12.3888 17.9371 12.3753C17.9536 12.3619 17.9673 12.3452 17.9773 12.3264C17.9941 12.2881 17.997 12.2452 17.9853 12.2051C17.9737 12.1649 17.9483 12.1302 17.9137 12.1068V12.1068Z" />
            <path d="M17.7705 15.6418C17.7519 15.6521 17.7356 15.666 17.7224 15.6826C17.7093 15.6993 17.6996 15.7184 17.694 15.7389C17.6884 15.7593 17.6869 15.7807 17.6896 15.8017C17.6924 15.8228 17.6993 15.8431 17.71 15.8614C17.7197 15.8806 17.7332 15.8975 17.7497 15.9112C17.7662 15.925 17.7853 15.9351 17.8059 15.9411C17.8265 15.9471 17.8482 15.9487 17.8694 15.946C17.8907 15.9432 17.9112 15.9361 17.9296 15.925C17.9484 15.915 17.9651 15.9014 17.9785 15.8848C17.992 15.8683 18.002 15.8492 18.0079 15.8287C18.0138 15.8082 18.0156 15.7868 18.0131 15.7656C18.0106 15.7444 18.0038 15.724 17.9932 15.7055C17.9707 15.669 17.9354 15.6423 17.8942 15.6305C17.853 15.6187 17.8089 15.6228 17.7705 15.6418V15.6418Z" />
            <path d="M24.0355 12.0591C23.998 12.0799 23.9704 12.1147 23.9584 12.1558C23.9465 12.1969 23.9513 12.2411 23.9718 12.2787C23.9821 12.2976 23.9961 12.3143 24.0129 12.3278C24.0298 12.3413 24.0491 12.3513 24.0698 12.3573C24.0906 12.3632 24.1123 12.3649 24.1337 12.3623C24.1551 12.3598 24.1758 12.353 24.1946 12.3423C24.2137 12.3327 24.2307 12.3192 24.2444 12.3027C24.2581 12.2862 24.2683 12.267 24.2743 12.2464C24.2802 12.2258 24.2819 12.2042 24.2791 12.1829C24.2764 12.1617 24.2692 12.1412 24.2582 12.1228C24.2325 12.09 24.1972 12.0663 24.1571 12.0548C24.1171 12.0434 24.0746 12.0449 24.0355 12.0591Z" />
            <path d="M19.0177 17.0291C18.9994 17.0676 18.9957 17.1115 19.0074 17.1525C19.0191 17.1935 19.0454 17.2288 19.0813 17.2518C19.1198 17.2702 19.1637 17.2739 19.2048 17.2621C19.2458 17.2504 19.2811 17.2241 19.3041 17.1882C19.3205 17.1494 19.3231 17.1061 19.3115 17.0656C19.3 17.0251 19.2749 16.9898 19.2404 16.9655C19.2217 16.9548 19.201 16.948 19.1796 16.9455C19.1582 16.9429 19.1364 16.9446 19.1157 16.9505C19.095 16.9565 19.0756 16.9665 19.0588 16.98C19.042 16.9935 19.028 17.0102 19.0177 17.0291Z" />
            <path d="M22.7436 11.0345C22.7817 11.0527 22.8251 11.0562 22.8655 11.0445C22.906 11.0328 22.9408 11.0066 22.9632 10.9709C22.9815 10.9324 22.9852 10.8885 22.9735 10.8475C22.9618 10.8065 22.9355 10.7712 22.8995 10.7482C22.8613 10.7313 22.8183 10.7285 22.7782 10.7401C22.7381 10.7518 22.7033 10.7771 22.68 10.8118C22.6616 10.8503 22.658 10.8942 22.6697 10.9352C22.6814 10.9762 22.7077 11.0115 22.7436 11.0345V11.0345Z" />
            <path d="M20.8727 17.7228C20.8723 17.7383 20.875 17.7538 20.8808 17.7683C20.8866 17.7828 20.8952 17.796 20.9062 17.807C20.9173 17.818 20.9304 17.8267 20.9449 17.8324C20.9594 17.8382 20.9749 17.8409 20.9905 17.8405C21.0062 17.8409 21.0218 17.8382 21.0365 17.8324C21.0511 17.8267 21.0645 17.8181 21.0758 17.8071C21.087 17.7962 21.096 17.783 21.1021 17.7686C21.1082 17.7541 21.1114 17.7385 21.1114 17.7228V17.0641C21.1114 17.0321 21.0986 17.0013 21.076 16.9786C21.0533 16.9559 21.0225 16.9432 20.9905 16.9432C20.9747 16.9432 20.9592 16.9463 20.9447 16.9525C20.9302 16.9586 20.917 16.9675 20.9061 16.9788C20.8951 16.9901 20.8865 17.0034 20.8808 17.0181C20.875 17.0327 20.8723 17.0484 20.8727 17.0641V17.7228Z" />
            <path d="M22.6641 17.1818C22.6741 17.2007 22.6877 17.2173 22.7043 17.2308C22.7208 17.2442 22.7399 17.2542 22.7604 17.2602C22.7809 17.2661 22.8023 17.2679 22.8235 17.2653C22.8447 17.2628 22.8651 17.2561 22.8836 17.2455C22.9026 17.2352 22.9193 17.2212 22.9328 17.2044C22.9463 17.1876 22.9563 17.1682 22.9622 17.1475C22.9681 17.1267 22.9699 17.105 22.9673 17.0836C22.9647 17.0622 22.9579 17.0415 22.9473 17.0228C22.9373 17.0039 22.9236 16.9873 22.9071 16.9738C22.8905 16.9604 22.8715 16.9504 22.851 16.9444C22.8305 16.9385 22.809 16.9367 22.7879 16.9393C22.7667 16.9418 22.7462 16.9485 22.7277 16.9591C22.6913 16.9816 22.6645 17.0169 22.6527 17.0582C22.641 17.0994 22.645 17.1435 22.6641 17.1818V17.1818Z" />
            <path d="M23.9878 15.7214C23.9673 15.759 23.9625 15.8031 23.9744 15.8443C23.9863 15.8854 24.014 15.9201 24.0514 15.9409C24.0899 15.9593 24.1338 15.963 24.1748 15.9512C24.2158 15.9395 24.2511 15.9132 24.2741 15.8773C24.2923 15.8393 24.2959 15.7959 24.2841 15.7554C24.2724 15.7149 24.2462 15.6802 24.2105 15.6578C24.172 15.6394 24.1281 15.6357 24.0871 15.6474C24.0461 15.6592 24.0108 15.6855 23.9878 15.7214V15.7214Z" />
            <path d="M21 9.06818C20.0296 9.06724 19.0808 9.35386 18.2732 9.89185C17.4656 10.4298 16.8356 11.1951 16.4627 12.0909L16.9559 12.5968C17.2436 11.7528 17.7883 11.0201 18.5136 10.5014C19.2388 9.98267 20.1083 9.70403 21 9.70454C22.1305 9.70538 23.2145 10.1549 24.0139 10.9543C24.8133 11.7537 25.2628 12.8376 25.2636 13.9682C25.2628 15.0987 24.8133 16.1827 24.0139 16.9821C23.2145 17.7815 22.1305 18.231 21 18.2318C20.1223 18.2304 19.2663 17.9583 18.5489 17.4527C17.8314 16.9471 17.2874 16.2325 16.9909 15.4064L16.4945 15.8932C16.873 16.7748 17.5013 17.5264 18.3019 18.0551C19.1026 18.5838 20.0405 18.8664 21 18.8682C22.299 18.8665 23.5444 18.3497 24.463 17.4311C25.3815 16.5126 25.8983 15.2672 25.9 13.9682C25.8983 12.6691 25.3815 11.4238 24.463 10.5052C23.5444 9.58665 22.299 9.06986 21 9.06818V9.06818Z" />
            <path d="M24.1818 14.875L21.0573 13.8187C21.0176 13.8055 20.9744 13.8084 20.9369 13.8269C20.8993 13.8454 20.8706 13.8778 20.8568 13.9173L20.0487 16.2432C20.0354 16.2833 20.0382 16.327 20.0566 16.3651C20.075 16.4031 20.1076 16.4324 20.1473 16.4469C20.1641 16.45 20.1814 16.45 20.1982 16.4469C20.2312 16.4466 20.2634 16.4365 20.2907 16.4178C20.3179 16.399 20.3389 16.3726 20.3509 16.3419L21.105 14.1623L24.08 15.171C24.1201 15.184 24.1637 15.1806 24.2013 15.1615C24.2389 15.1424 24.2674 15.1092 24.2805 15.0691C24.2918 15.0303 24.2879 14.9886 24.2696 14.9526C24.2512 14.9165 24.2199 14.8888 24.1818 14.875V14.875Z" class="brand-accent" />
            <path d="M14.1909 12.32H12.0909V4.29548C12.0909 4.21109 12.0574 4.13016 11.9977 4.07049C11.938 4.01082 11.8571 3.97729 11.7727 3.97729H2.22728C2.19038 3.97734 2.15377 3.9838 2.11909 3.99639V3.99639C2.10334 4.00187 2.08839 4.00935 2.07456 4.01866L2.03954 4.05048H2.02364C1.99888 4.0749 1.97939 4.10414 1.96637 4.13639V4.15866C1.95232 4.19414 1.94478 4.23187 1.94409 4.27002V20.4559C1.94478 20.4941 1.95232 20.5318 1.96637 20.5673C1.96464 20.5778 1.96464 20.5886 1.96637 20.5991C1.97704 20.6239 1.99214 20.6465 2.01092 20.6659L2.03319 20.6914C2.05667 20.7127 2.08356 20.7299 2.11274 20.7423V20.7423L10.0195 23.9655C10.058 23.9807 10.0991 23.9883 10.1405 23.9877C10.2039 23.9879 10.266 23.9691 10.3186 23.9337C10.3618 23.9045 10.3972 23.8651 10.4216 23.8191C10.446 23.773 10.4587 23.7217 10.4586 23.6696V20.7646H11.7727C11.8571 20.7646 11.938 20.731 11.9977 20.6714C12.0574 20.6117 12.0909 20.5308 12.0909 20.4464V15.68H14.1114V17.3187L17.4714 14.0414L14.1909 10.6814V12.32ZM9.81591 23.2273L2.54546 20.2682V4.77275L9.81591 7.73502V23.2273ZM11.4546 20.16H10.4523V15.68H11.4546V20.16ZM11.4546 12.3168H10.4523V7.5123C10.4517 7.44911 10.4323 7.38753 10.3966 7.3354C10.3609 7.28327 10.3105 7.24294 10.2518 7.21957L3.85 4.61048H11.4546V12.3168Z" class="brand-accent" />
            
        </symbol>
        <symbol id="sign-in">
            <path d="M26.3868 4.18411V4.16184C26.3738 4.12959 26.3543 4.10035 26.3296 4.07593V4.07593L26.2946 4.04411L26.2532 4.02184H26.2341C26.1994 4.00925 26.1628 4.00279 26.1259 4.00275H16.5455C16.4611 4.00275 16.3802 4.03627 16.3205 4.09594C16.2608 4.15561 16.2273 4.23654 16.2273 4.32093V11.7727L16.8637 12.4091V4.61366H24.4682L18.0664 7.22275C18.0077 7.24612 17.9573 7.28645 17.9216 7.33858C17.8859 7.39071 17.8665 7.45229 17.8659 7.51547V20.1632H16.8637V15.5464L16.2273 16.1827V20.4814C16.2273 20.5658 16.2608 20.6467 16.3205 20.7064C16.3802 20.766 16.4611 20.7996 16.5455 20.7996H17.8659V23.7046C17.8659 23.7567 17.8786 23.808 17.903 23.8541C17.9274 23.9001 17.9628 23.9395 18.0059 23.9687C18.0586 24.004 18.1207 24.0229 18.1841 24.0227C18.2255 24.0233 18.2666 24.0157 18.305 24.0005L26.2118 20.7773C26.241 20.7649 26.2679 20.7477 26.2914 20.7264L26.3137 20.7009C26.3324 20.6815 26.3475 20.6589 26.3582 20.6341C26.3654 20.6246 26.3708 20.6138 26.3741 20.6023C26.3882 20.5668 26.3957 20.5291 26.3964 20.4909V4.29547C26.3998 4.25808 26.3966 4.22038 26.3868 4.18411V4.18411ZM25.7728 20.2682L18.5023 23.2273V7.73184L25.7728 4.77275V20.2682Z" class="brand-accent"/>
            <path d="M17.5286 14L14.2514 10.6495V12.2882H11.1364C11.5647 13.3672 11.5647 14.5691 11.1364 15.6482H14.1654V17.2868L17.5286 14Z" class="brand-accent"/>
            <path d="M6.14408 10.5796C6.12369 10.5837 6.10433 10.5919 6.08715 10.6036C6.06996 10.6154 6.05531 10.6305 6.04404 10.6479C6.03277 10.6654 6.02512 10.685 6.02154 10.7055C6.01795 10.726 6.01851 10.747 6.02317 10.7673L6.68181 13.8091H4.36862C4.32643 13.8091 4.28597 13.8259 4.25613 13.8557C4.2263 13.8856 4.20953 13.926 4.20953 13.9682C4.20953 14.0104 4.2263 14.0509 4.25613 14.0807C4.28597 14.1105 4.32643 14.1273 4.36862 14.1273H6.87908C6.90294 14.127 6.92643 14.1214 6.94787 14.1109C6.96932 14.1005 6.9882 14.0854 7.00317 14.0669C7.01774 14.0482 7.02812 14.0265 7.0336 14.0035C7.03909 13.9804 7.03957 13.9565 7.03499 13.9332L6.33499 10.7005C6.33048 10.68 6.32195 10.6605 6.30989 10.6433C6.29783 10.6261 6.28248 10.6115 6.26473 10.6002C6.24698 10.589 6.22718 10.5814 6.20647 10.5778C6.18577 10.5743 6.16456 10.5749 6.14408 10.5796Z" class="brand-accent"/>
            <path d="M6.87908 9.06818C5.90968 9.06692 4.96169 9.35323 4.15506 9.89088C3.34842 10.4285 2.71938 11.1934 2.34754 12.0886C1.97569 12.9839 1.87775 13.9693 2.0661 14.9202C2.25445 15.8711 2.72062 16.7448 3.40565 17.4307C4.09067 18.1167 4.96375 18.584 5.91443 18.7736C6.86511 18.9631 7.85067 18.8665 8.7464 18.4958C9.64212 18.1251 10.4078 17.4971 10.9465 16.6911C11.4852 15.8852 11.7727 14.9376 11.7727 13.9682C11.771 12.6702 11.2551 11.4258 10.3379 10.5075C9.42075 9.58908 8.17702 9.07155 6.87908 9.06818V9.06818ZM6.87908 18.2318C6.03555 18.2331 5.21059 17.9841 4.5086 17.5164C3.80661 17.0486 3.25914 16.3832 2.93546 15.6042C2.61178 14.8253 2.52644 13.9678 2.69023 13.1403C2.85403 12.3128 3.2596 11.5525 3.85562 10.9556C4.45165 10.3587 5.21134 9.95197 6.03858 9.78694C6.86582 9.62191 7.72342 9.70597 8.50287 10.0285C9.28232 10.351 9.94858 10.8975 10.4174 11.5988C10.8861 12.3001 11.1364 13.1246 11.1364 13.9682C11.1355 15.0976 10.6869 16.1806 9.88889 16.9799C9.09086 17.7791 8.00851 18.2293 6.87908 18.2318V18.2318Z" />
            <path d="M10.6336 13.8505H9.97179C9.94057 13.8505 9.91062 13.8629 9.88855 13.8849C9.86647 13.907 9.85406 13.937 9.85406 13.9682C9.85405 13.9997 9.86636 14.03 9.88835 14.0526C9.91034 14.0752 9.94028 14.0883 9.97179 14.0891H10.6336C10.6651 14.0883 10.6951 14.0752 10.7171 14.0526C10.739 14.03 10.7513 13.9997 10.7513 13.9682C10.7513 13.937 10.7389 13.907 10.7169 13.8849C10.6948 13.8629 10.6648 13.8505 10.6336 13.8505V13.8505Z" />
            <path d="M6.87906 10.1596C6.86349 10.1592 6.84798 10.1619 6.8335 10.1677C6.81902 10.1734 6.80586 10.1821 6.79484 10.1931C6.78382 10.2041 6.77516 10.2173 6.7694 10.2318C6.76364 10.2462 6.7609 10.2617 6.76134 10.2773V10.936C6.76091 10.9517 6.76364 10.9673 6.76937 10.982C6.77509 10.9966 6.7837 11.01 6.79467 11.0213C6.80565 11.0325 6.81877 11.0415 6.83326 11.0476C6.84776 11.0537 6.86333 11.0569 6.87906 11.0569C6.91113 11.0569 6.94188 11.0441 6.96456 11.0215C6.98723 10.9988 6.99997 10.968 6.99997 10.936V10.2773C6.99998 10.2616 6.99683 10.246 6.99072 10.2315C6.9846 10.217 6.97565 10.2039 6.96437 10.1929C6.9531 10.182 6.93975 10.1733 6.92509 10.1676C6.91044 10.1619 6.89479 10.1592 6.87906 10.1596V10.1596Z" />
            <path d="M3.78638 13.8505H3.12456C3.09334 13.8505 3.0634 13.8629 3.04132 13.8849C3.01924 13.907 3.00684 13.937 3.00684 13.9682C3.00683 13.9997 3.01913 14.03 3.04112 14.0526C3.06311 14.0752 3.09305 14.0883 3.12456 14.0891H3.78638C3.81789 14.0883 3.84784 14.0752 3.86983 14.0526C3.89182 14.03 3.90412 13.9997 3.90411 13.9682C3.90411 13.937 3.89171 13.907 3.86963 13.8849C3.84755 13.8629 3.8176 13.8505 3.78638 13.8505V13.8505Z" />
            <path d="M5.09091 11.0505C5.10888 11.0392 5.1244 11.0244 5.13656 11.007C5.14872 10.9896 5.15727 10.9699 5.16172 10.9492C5.16617 10.9284 5.16642 10.907 5.16245 10.8861C5.15849 10.8653 5.15039 10.8454 5.13864 10.8278C5.12135 10.8006 5.09637 10.7792 5.06688 10.7663C5.03739 10.7533 5.00472 10.7495 4.97303 10.7552C4.94134 10.7609 4.91207 10.7759 4.88893 10.7983C4.86578 10.8207 4.84983 10.8494 4.84308 10.8809C4.83633 10.9124 4.83911 10.9452 4.85104 10.9751C4.86298 11.005 4.88354 11.0306 4.91011 11.0488C4.93668 11.067 4.96806 11.0769 5.00026 11.0772C5.03245 11.0775 5.06401 11.0682 5.09091 11.0505V11.0505Z" />
            <path d="M3.64317 12.39C3.66192 12.4007 3.68261 12.4075 3.70402 12.41C3.72544 12.4126 3.74715 12.4109 3.76789 12.4049C3.78862 12.399 3.80797 12.389 3.8248 12.3755C3.84163 12.362 3.8556 12.3453 3.86589 12.3264C3.88271 12.2881 3.88555 12.2452 3.87392 12.2051C3.86229 12.1649 3.83692 12.1302 3.80226 12.1068C3.78386 12.0958 3.76338 12.0887 3.74211 12.0859C3.72083 12.0831 3.69921 12.0848 3.6786 12.0908C3.65799 12.0967 3.63884 12.1069 3.62234 12.1206C3.60584 12.1343 3.59235 12.1513 3.58271 12.1705C3.57201 12.1888 3.56507 12.2091 3.56232 12.2301C3.55957 12.2511 3.56105 12.2725 3.56668 12.293C3.57232 12.3134 3.58198 12.3326 3.59512 12.3492C3.60825 12.3659 3.62459 12.3798 3.64317 12.39Z" />
            <path d="M3.65907 15.6418C3.62165 15.6626 3.59396 15.6974 3.58204 15.7385C3.57012 15.7796 3.57493 15.8238 3.59544 15.8614C3.60573 15.8803 3.6197 15.897 3.63653 15.9105C3.65336 15.924 3.67271 15.934 3.69344 15.9399C3.71418 15.9459 3.73589 15.9476 3.75731 15.945C3.77872 15.9425 3.79941 15.9357 3.81816 15.925C3.83701 15.915 3.85365 15.9014 3.8671 15.8848C3.88056 15.8683 3.89055 15.8492 3.89649 15.8287C3.90243 15.8082 3.90418 15.7868 3.90166 15.7656C3.89914 15.7444 3.89238 15.724 3.8818 15.7055C3.8593 15.669 3.82398 15.6423 3.78277 15.6305C3.74157 15.6187 3.69745 15.6228 3.65907 15.6418Z" />
            <path d="M10.0832 12.3423C10.102 12.3323 10.1187 12.3186 10.1321 12.3021C10.1456 12.2856 10.1556 12.2665 10.1615 12.246C10.1674 12.2255 10.1692 12.2041 10.1667 12.1829C10.1642 12.1617 10.1574 12.1413 10.1468 12.1228C10.1211 12.09 10.0858 12.0662 10.0458 12.0548C10.0058 12.0434 9.96319 12.0449 9.92409 12.0591C9.90525 12.0691 9.88861 12.0828 9.87515 12.0993C9.8617 12.1158 9.8517 12.1349 9.84577 12.1554C9.83983 12.1759 9.83807 12.1974 9.8406 12.2185C9.84312 12.2397 9.84987 12.2601 9.86046 12.2787C9.87075 12.2976 9.88472 12.3143 9.90155 12.3278C9.91838 12.3413 9.93773 12.3513 9.95847 12.3572C9.9792 12.3631 10.0009 12.3649 10.0223 12.3623C10.0437 12.3598 10.0644 12.3529 10.0832 12.3423V12.3423Z" />
            <path d="M5.12911 16.9655C5.11036 16.9548 5.08967 16.948 5.06825 16.9455C5.04684 16.9429 5.02513 16.9446 5.00439 16.9505C4.98365 16.9565 4.96431 16.9665 4.94748 16.98C4.93065 16.9935 4.91668 17.0102 4.90638 17.0291C4.88802 17.0676 4.88436 17.1115 4.89608 17.1525C4.9078 17.1935 4.93409 17.2288 4.97002 17.2518C5.00805 17.27 5.05143 17.2736 5.09191 17.2618C5.13239 17.2501 5.16714 17.2239 5.18956 17.1882C5.20755 17.1499 5.21134 17.1065 5.20027 17.0657C5.18919 17.0249 5.16397 16.9894 5.12911 16.9655Z" />
            <path d="M8.7882 10.7482C8.76011 10.7329 8.72815 10.7263 8.69631 10.7292C8.66447 10.732 8.63418 10.7442 8.60924 10.7642C8.5843 10.7842 8.56582 10.8111 8.55612 10.8415C8.54642 10.872 8.54592 10.9046 8.55471 10.9354C8.56349 10.9661 8.58115 10.9936 8.60548 11.0143C8.6298 11.035 8.65971 11.0481 8.69145 11.0519C8.72319 11.0557 8.75534 11.0501 8.78388 11.0356C8.81241 11.0212 8.83605 10.9987 8.85184 10.9709C8.86951 10.9323 8.87282 10.8886 8.86115 10.8478C8.84948 10.807 8.82361 10.7716 8.7882 10.7482Z" />
            <path d="M6.87906 16.9432C6.86333 16.9432 6.84776 16.9463 6.83326 16.9525C6.81877 16.9586 6.80565 16.9675 6.79467 16.9788C6.7837 16.9901 6.77509 17.0034 6.76937 17.0181C6.76364 17.0327 6.76091 17.0484 6.76134 17.0641V17.7227C6.7609 17.7383 6.76364 17.7538 6.7694 17.7683C6.77516 17.7828 6.78382 17.796 6.79484 17.807C6.80586 17.818 6.81902 17.8266 6.8335 17.8324C6.84798 17.8382 6.86349 17.8409 6.87906 17.8405C6.89479 17.8409 6.91044 17.8382 6.92509 17.8324C6.93975 17.8267 6.9531 17.8181 6.96437 17.8071C6.97565 17.7962 6.9846 17.783 6.99072 17.7685C6.99683 17.7541 6.99998 17.7385 6.99997 17.7227V17.0641C6.99997 17.032 6.98723 17.0013 6.96456 16.9786C6.94188 16.9559 6.91113 16.9432 6.87906 16.9432V16.9432Z" />
            <path d="M8.61633 16.9655C8.57986 16.988 8.55311 17.0233 8.54134 17.0645C8.52957 17.1057 8.53362 17.1498 8.5527 17.1882C8.56314 17.2068 8.57715 17.2231 8.59392 17.2363C8.61068 17.2495 8.62988 17.2593 8.65042 17.265C8.67095 17.2708 8.69242 17.2724 8.71359 17.2699C8.73477 17.2674 8.75524 17.2607 8.77383 17.2502C8.79243 17.2398 8.80878 17.2258 8.82196 17.209C8.83515 17.1923 8.84489 17.1731 8.85066 17.1525C8.85642 17.132 8.85808 17.1105 8.85554 17.0893C8.85301 17.0682 8.84633 17.0477 8.83588 17.0291C8.8259 17.0103 8.81223 16.9936 8.79569 16.9802C8.77915 16.9667 8.76007 16.9567 8.73959 16.9508C8.71911 16.9448 8.69764 16.9431 8.67647 16.9456C8.6553 16.9481 8.63485 16.9549 8.61633 16.9655Z" />
            <path d="M10.0991 15.6578C10.0606 15.6394 10.0167 15.6357 9.97565 15.6475C9.93464 15.6592 9.89932 15.6855 9.87633 15.7214C9.86574 15.7399 9.85899 15.7604 9.85646 15.7815C9.85394 15.8027 9.8557 15.8242 9.86164 15.8446C9.86757 15.8651 9.87757 15.8842 9.89102 15.9007C9.90448 15.9173 9.92112 15.931 9.93996 15.9409C9.97847 15.9593 10.0223 15.963 10.0634 15.9512C10.1044 15.9395 10.1397 15.9132 10.1627 15.8773C10.1795 15.8391 10.1824 15.7961 10.1707 15.756C10.1591 15.7158 10.1337 15.6811 10.0991 15.6578V15.6578Z" />
        </symbol>
        <symbol id="fullscreen-close">
            <path d="M26.71,1.29c-.39-.39-1.02-.39-1.41,0l-7.29,7.29V3c0-.55-.45-1-1-1s-1,.45-1,1V11c0,.13,.03,.26,.08,.38,.1,.24,.3,.44,.54,.54,.12,.05,.25,.08,.38,.08h8c.55,0,1-.45,1-1s-.45-1-1-1h-5.59l7.29-7.29c.39-.39,.39-1.02,0-1.41Z"/><path d="M12,15H4c-.55,0-1,.45-1,1s.45,1,1,1h5.59L1.29,25.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l8.29-8.29v5.59c0,.55,.45,1,1,1s1-.45,1-1v-8c0-.55-.45-1-1-1Z"/>
        </symbol>
        <symbol id="fullscreen-open">
            <path d="M26.92,1.62c-.1-.24-.3-.44-.54-.54-.12-.05-.25-.08-.38-.08h-8c-.55,0-1,.45-1,1s.45,1,1,1h5.59l-7.29,7.29c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29s.51-.1,.71-.29l7.29-7.29v5.59c0,.55,.45,1,1,1s1-.45,1-1V2c0-.13-.03-.26-.08-.38Z"/><path d="M10.29,16.29l-7.29,7.29v-5.59c0-.55-.45-1-1-1s-1,.45-1,1v8c0,.13,.03,.26,.08,.38,.1,.24,.3,.44,.54,.54,.12,.05,.25,.08,.38,.08H10c.55,0,1-.45,1-1s-.45-1-1-1H4.41l7.29-7.29c.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0Z"/>
        </symbol>
        <symbol id="sort-a-z">
            <g>
                <g>
                    <path
                        d="M21.3,20.3h-7.2c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h7.2c0.6,0,1.2,0.5,1.2,1.2C22.5,19.8,22,20.3,21.3,20.3z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M26.1,15h-12c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2h12c0.6,0,1.2,0.5,1.2,1.2C27.2,14.5,26.7,15,26.1,15z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M26.1,9.8h-12c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h12c0.6,0,1.2,0.5,1.2,1.2S26.8,9.8,26.1,9.8z" />
                </g>
            </g>
            <path
                d="M10.9,21.2c-0.5-0.5-1.2-0.5-1.6,0l-2.1,2.1V1.9c0-0.6-0.5-1.2-1.2-1.2S4.8,1.2,4.8,1.9v21.4l-2.1-2.1
                        c-0.5-0.5-1.2-0.5-1.6,0s-0.5,1.2,0,1.6L5.1,27c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3l4.1-4.1C11.3,22.4,11.3,21.7,10.9,21.2z" />
        </symbol>
        <symbol id="sort-z-a">
            <g>
                <g>
                    <path
                        d="M21.2,20.5H14c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h7.2c0.6,0,1.2,0.5,1.2,1.2S21.9,20.5,21.2,20.5z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M26,15.3H14c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h12c0.6,0,1.2,0.5,1.2,1.2S26.6,15.3,26,15.3z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M26,10H14c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.2,1.2-1.2h12c0.6,0,1.2,0.5,1.2,1.2S26.7,10,26,10z" />
                </g>
            </g>
            <path
                d="M10.8,5.1L6.7,1C6.2,0.6,5.5,0.6,5,1L1,5.1C0.5,5.6,0.5,6.3,1,6.7c0.5,0.5,1.2,0.5,1.6,0l2.1-2.1V26c0,0.6,0.5,1.2,1.2,1.2
                        s1.2-0.5,1.2-1.2V4.7l2.1,2.1C9.3,7,9.6,7.1,9.9,7.1s0.6-0.1,0.8-0.3C11.2,6.3,11.2,5.6,10.8,5.1z" />

        </symbol>
        <symbol id="sort">
            <g>
                <g>
                    <path
                        d="M21.3,20.4h-7.2c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h7.2c0.6,0,1.2,0.5,1.2,1.2S22,20.4,21.3,20.4z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M26.1,15.2h-12c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h12c0.6,0,1.2,0.5,1.2,1.2S26.7,15.2,26.1,15.2z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M26.1,9.9h-12c-0.6,0-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2h12c0.6,0,1.2,0.5,1.2,1.2S26.7,9.9,26.1,9.9z" />
                </g>
            </g>
            <path
                d="M9.2,21.4l-2.1,2.1V4.6l2.1,2.1C9.4,6.9,9.7,7,10,7s0.6-0.1,0.8-0.3c0.5-0.5,0.5-1.2,0-1.6L6.8,1C6.3,0.5,5.6,0.5,5.1,1
                        L1,5C0.5,5.5,0.5,6.2,1,6.6c0.5,0.5,1.2,0.5,1.6,0l2.2-2v18.9l-2.1-2.1c-0.5-0.5-1.2-0.5-1.6,0s-0.5,1.2,0,1.6l4.1,4.1
                        c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3l4.1-4.1c0.5-0.5,0.5-1.2,0-1.6C10.4,20.9,9.7,20.9,9.2,21.4z" />

        </symbol>
        <symbol id="time">
            <g>
                <g>
                    <path d="M14,27C6.8,27,1,21.2,1,14S6.8,1,14,1s13,5.8,13,13S21.2,27,14,27z M14,3.4C8.1,3.4,3.4,8.1,3.4,14S8.1,24.6,14,24.6
                                S24.6,19.9,24.6,14S19.9,3.4,14,3.4z" />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M18.8,17.5c-0.1,0-0.3,0-0.4-0.1l-4.8-2.6c-0.2-0.1-0.3-0.3-0.4-0.5l-2.9-8.5c-0.2-0.5,0.1-0.9,0.6-1.1
                                c0.5-0.2,0.9,0.1,1.1,0.6l2.8,8.2l4.5,2.4c0.4,0.2,0.6,0.8,0.4,1.2C19.5,17.3,19.1,17.5,18.8,17.5z" />
                </g>
            </g>
        </symbol>
        <symbol id="preview">
            <path
                d="M27.5,13.6c-0.7-1.7-1.7-3.3-3-4.7c-2.8-3.1-6.4-4.8-10.4-4.8S6.4,5.7,3.6,8.8c-1.3,1.4-2.3,3-3,4.7c-0.1,0.3-0.1,0.6,0,0.9
                c0.8,1.7,1.8,3.3,3.1,4.7c2.8,3.1,6.5,4.8,10.5,4.8c2,0,3.9-0.4,5.7-1.3L18,20.9c-1.3,0.5-2.6,0.7-4,0.7c-3.4,0-6.3-1.3-8.7-3.9
                c-1-1.1-1.8-2.3-2.5-3.6c0.6-1.3,1.5-2.6,2.5-3.6c2.4-2.6,5.3-3.9,8.7-3.9s6.3,1.3,8.6,3.9c1,1.1,1.8,2.3,2.4,3.6
                c-0.9,1.8-2.1,3.4-3.5,4.7l1.7,1.7c0.4-0.4,0.8-0.8,1.2-1.2c1.3-1.4,2.3-3,3-4.7C27.6,14.1,27.6,13.9,27.5,13.6z" />
            <path d="M14,19.8c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6c3.3,0,6,2.7,6,6v0C20,17.2,17.3,19.8,14,19.8z M14,10.3c-2,0-3.6,1.6-3.6,3.6
                s1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6c0,0,0,0,0,0C17.6,11.9,16,10.3,14,10.3z" />
            <path d="M22.3,23.4c-0.3,0-0.6-0.1-0.8-0.3L17,18.6c-0.5-0.5-0.5-1.2,0-1.7c0.5-0.5,1.2-0.5,1.7,0l4.5,4.5c0.5,0.5,0.5,1.2,0,1.6
                C22.9,23.3,22.6,23.4,22.3,23.4L22.3,23.4z" />
        </symbol>
        <symbol id="tick--solid">
            <path d="M14,26.8C6.9,26.8,1.2,21.1,1.2,14S6.9,1.2,14,1.2S26.8,6.9,26.8,14C26.8,21.1,21.1,26.8,14,26.8z" />
            <path class="white"
                d="M9.9,23.4c-0.3,0-0.6-0.1-0.8-0.3l-4.1-4.1c-0.5-0.5-0.5-1.2,0-1.7c0.5-0.5,1.2-0.5,1.7,0l3.2,3.2L20.2,6.5
                    c0.4-0.5,1.1-0.6,1.6-0.3c0.5,0.4,0.6,1.1,0.3,1.6c0,0,0,0,0,0l-11.3,15C10.6,23.2,10.3,23.3,9.9,23.4L9.9,23.4z" />
        </symbol>
        <symbol id="d-checkbox--false">
            <path d="M22.9,26.4H5.1c-1.9,0-3.5-1.6-3.5-3.5V5.1c0-1.9,1.6-3.5,3.5-3.5h17.8c1.9,0,3.5,1.6,3.5,3.5v17.8
                C26.4,24.8,24.8,26.4,22.9,26.4z M5.1,3.9c-0.6,0-1.2,0.5-1.2,1.2v17.8c0,0.6,0.5,1.2,1.2,1.2h17.8c0.6,0,1.2-0.5,1.2-1.2V5.1
                c0-0.6-0.5-1.2-1.2-1.2H5.1z" />
            <path d="M8,21.1c-0.6,0-1.2-0.5-1.2-1.2c0-0.3,0.1-0.6,0.3-0.8l12-12c0.5-0.5,1.2-0.5,1.7,0c0.5,0.5,0.5,1.2,0,1.7l-12,12
                C8.6,21,8.3,21.1,8,21.1z" />
            <path d="M20,21.1c-0.3,0-0.6-0.1-0.8-0.3l-12-12c-0.5-0.5-0.5-1.2,0-1.7c0.5-0.5,1.2-0.5,1.7,0l12,12c0.5,0.5,0.5,1.2,0,1.6
                C20.6,21,20.3,21.1,20,21.1z" />
        </symbol>
        <symbol id="d-checkbox">
            <path d="M22.9,26.4H5.1c-1.9,0-3.5-1.6-3.5-3.5V5.1c0-1.9,1.6-3.5,3.5-3.5h17.8c1.9,0,3.5,1.6,3.5,3.5v17.8
                C26.4,24.8,24.8,26.4,22.9,26.4z M5.1,3.9c-0.6,0-1.2,0.5-1.2,1.2v17.8c0,0.6,0.5,1.2,1.2,1.2h17.8c0.6,0,1.2-0.5,1.2-1.2V5.1
                c0-0.6-0.5-1.2-1.2-1.2H5.1z" />
        </symbol>
        <symbol id="d-checkbox--true">
            <path d="M22.9,26.4H5.1c-1.9,0-3.5-1.6-3.5-3.5V5.1c0-1.9,1.6-3.5,3.5-3.5h17.8c1.9,0,3.5,1.6,3.5,3.5v17.8
                C26.4,24.8,24.8,26.4,22.9,26.4z" />
            <path class="white"
                d="M9.9,23.4c-0.3,0-0.6-0.1-0.8-0.3l-4.1-4.1c-0.5-0.5-0.5-1.2,0-1.7c0.5-0.5,1.2-0.5,1.7,0l3.2,3.2L20.2,6.5
                c0.4-0.5,1.1-0.6,1.6-0.3c0.5,0.4,0.6,1.1,0.3,1.6c0,0,0,0,0,0l-11.3,15C10.6,23.2,10.3,23.3,9.9,23.4L9.9,23.4z" />
        </symbol>
        <symbol id="warning--solid">
            <path d="M14,26.8C6.9,26.8,1.2,21.1,1.2,14S6.9,1.2,14,1.2S26.8,6.9,26.8,14C26.8,21.1,21.1,26.8,14,26.8z" />
            <path class="white"
                d="M12.4,20.8c0-0.6,0.1-1,0.4-1.3c0.3-0.3,0.7-0.4,1.2-0.4s0.9,0.1,1.2,0.4s0.4,0.7,0.4,1.2c0,0.5-0.1,1-0.4,1.3
                    c-0.3,0.3-0.7,0.5-1.2,0.5s-0.9-0.1-1.2-0.4S12.4,21.3,12.4,20.8z M15,17.2h-2L12.5,5.8h3.1L15,17.2z" />
        </symbol>
        <symbol id="clear">
            <path d="M14,27C6.8,27,1,21.2,1,14S6.8,1,14,1s13,5.8,13,13C27,21.2,21.2,27,14,27z M14,3.4C8.1,3.4,3.4,8.1,3.4,14
                S8.1,24.6,14,24.6S24.6,19.9,24.6,14C24.6,8.1,19.9,3.4,14,3.4z" />
            <path d="M8,21.1c-0.6,0-1.2-0.5-1.2-1.2c0-0.3,0.1-0.6,0.3-0.8l12-12c0.5-0.5,1.2-0.5,1.7,0c0.5,0.5,0.5,1.2,0,1.7l-12,12
                C8.6,21,8.3,21.1,8,21.1z" />
            <path d="M20,21.1c-0.3,0-0.6-0.1-0.8-0.3l-12-12c-0.5-0.5-0.5-1.2,0-1.7c0.5-0.5,1.2-0.5,1.7,0l12,12c0.5,0.5,0.5,1.2,0,1.6
                C20.6,21,20.3,21.1,20,21.1z" />
        </symbol>
        <symbol id="data">
            <path d="M24.5,6h-8.2c-0.6,0-1.2-0.5-1.2-1.2V4.7c0-1.9-1.6-3.5-3.5-3.5H3.5C1.6,1.2,0,2.7,0,4.7v18.7c0,1.9,1.6,3.5,3.5,3.5h21
                c1.9,0,3.5-1.6,3.5-3.5V9.5C28,7.5,26.4,6,24.5,6z M3.5,3.5h8.2c0.6,0,1.2,0.5,1.2,1.2v0.1c0,0.4,0.1,0.8,0.2,1.2H3.5
                C3.1,6,2.7,6,2.3,6.2V4.7C2.3,4,2.9,3.5,3.5,3.5z M25.7,23.3c0,0.6-0.5,1.2-1.2,1.2h-21c-0.6,0-1.2-0.5-1.2-1.2V9.5
                c0-0.6,0.5-1.2,1.2-1.2h21c0.6,0,1.2,0.5,1.2,1.2V23.3z" />
        </symbol>
        <symbol id="download">
            <path
                d="M19.1,13.3L15.3,17V1.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3V17l-3.7-3.7l-1.7,1.6l6.7,6.7l6.7-6.7L19.1,13.3z" />
            <path d="M20.4,27H7.6c-2,0-3.6-1.6-3.6-3.6V13.1c0-2,1.6-3.6,3.6-3.6h3.8v2.3H7.6c-0.7,0-1.3,0.6-1.3,1.3v10.3
                    c0,0.7,0.6,1.3,1.3,1.3h12.7c0.7,0,1.3-0.6,1.3-1.3V13.1c0-0.7-0.6-1.3-1.3-1.3h-3.8V9.5h3.8c2,0,3.6,1.6,3.6,3.6v10.3
                    C24,25.4,22.4,27,20.4,27z" />
        </symbol>
        <symbol id="download--inverted">
            <path d="M28,28H0V0h28V28z M19.1,13.3L15.3,17V1.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3V17l-3.7-3.7l-1.7,1.6l6.7,6.7l6.7-6.7
                L19.1,13.3z M24,23.4V13.1c0-2-1.6-3.6-3.6-3.6h-3.8v2.3h3.8c0.7,0,1.3,0.6,1.3,1.3v10.3c0,0.7-0.6,1.3-1.3,1.3H7.6
                c-0.7,0-1.3-0.6-1.3-1.3V13.1c0-0.7,0.6-1.3,1.3-1.3h3.8V9.5H7.6c-2,0-3.6,1.6-3.6,3.6v10.3c0,2,1.6,3.6,3.6,3.6h12.7
                C22.4,27,24,25.4,24,23.4L24,23.4z" />
        </symbol>
        <symbol id="sync">
            <path d="M20,26.8H4.8c-2,0-3.6-1.6-3.6-3.6V8c0-2,1.6-3.6,3.6-3.6H14V7H4.8c-0.6,0-1,0.5-1,1v15.2c0,0.6,0.5,1,1,1H20
                c0.6,0,1-0.5,1-1v-8.9h2.6v8.9C23.6,25.2,22,26.8,20,26.8z" />
            <path
                d="M17.4,1.2v2.3h5.3L11.2,15c-0.5,0.5-0.5,1.3,0,1.9c0.5,0.5,1.3,0.5,1.9,0c0,0,0,0,0,0L24.5,5.4v5.3h2.3V1.2H17.4z" />
        </symbol>
        <symbol id="upload">
            <path d="M20.4,27H7.6c-2,0-3.6-1.6-3.6-3.6V13.1c0-2,1.6-3.6,3.6-3.6h3.8v2.3H7.6c-0.7,0-1.3,0.6-1.3,1.3v10.3
                c0,0.7,0.6,1.3,1.3,1.3h12.7c0.7,0,1.3-0.6,1.3-1.3V13.1c0-0.7-0.6-1.3-1.3-1.3h-3.8V9.5h3.8c2,0,3.6,1.6,3.6,3.6v10.3
                C24,25.4,22.4,27,20.4,27z" />
            <path
                d="M8.9,8.6l3.7-3.7v15.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V4.9l3.7,3.7L20.7,7L14,0.3L7.3,7L8.9,8.6z" />
        </symbol>
        <symbol id="upload--inverted">
            <path
                d="M28,28H0V0h28V28z M24,23.4V13.1c0-2-1.6-3.6-3.6-3.6h-3.8v2.3h3.8c0.7,0,1.3,0.6,1.3,1.3v10.3c0,0.7-0.6,1.3-1.3,1.3H7.6
                c-0.7,0-1.3-0.6-1.3-1.3V13.1c0-0.7,0.6-1.3,1.3-1.3h3.8V9.5H7.6c-2,0-3.6,1.6-3.6,3.6v10.3c0,2,1.6,3.6,3.6,3.6h12.7
                C22.4,27,24,25.4,24,23.4z M8.9,8.6l3.7-3.7v15.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V4.9l3.7,3.7L20.7,7L14,0.3L7.3,7L8.9,8.6z" />
        </symbol>
        <symbol id="edit">
            <path
                d="M10.6,21.8c-0.2,0-0.5-0.1-0.6-0.3L6.5,18c-0.3-0.3-0.3-0.9,0-1.2L20.1,3.2c0.3-0.3,0.9-0.3,1.2,0c0,0,0,0,0,0l3.5,3.5
                c0.3,0.3,0.3,0.9,0,1.2L11.2,21.5C11.1,21.7,10.9,21.8,10.6,21.8z M8.4,17.4l2.3,2.3L23,7.3L20.7,5L8.4,17.4z" />
            <path
                d="M3.9,24.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.1,0-0.2,0-0.2l1.4-4.8c0.1-0.5,0.6-0.7,1.1-0.6c0.1,0,0.3,0.1,0.4,0.2l3.5,3.5
                c0.3,0.3,0.3,0.9,0,1.2c-0.1,0.1-0.2,0.2-0.4,0.2l-4.9,1.3C4.1,24.9,4,24.9,3.9,24.9z M5.8,20.8l-0.6,1.9l2-0.5L5.8,20.8z" />
        </symbol>
        <symbol id="inspect">
            <path
                d="M27.1,23l-6.4-6.4c2.8-3.7,2.2-9-1.5-11.8c-2.8-2.2-6.8-2.4-9.8-0.4c-0.1,0-0.1,0-0.2,0H1.8C1.4,4.4,1.2,4.6,1.2,5
                c0,0.3,0.3,0.6,0.6,0.6H8C7.4,6.2,6.9,6.8,6.5,7.6H1.8c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6H6c-0.2,0.7-0.4,1.4-0.4,2.1H1.8
                c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h3.9c0,0.7,0.2,1.4,0.4,2.1H1.8c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h4.7
                C6.9,16,7.4,16.7,8,17.3H1.8c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h7.4c0.1,0,0.1,0,0.2,0c2.9,2,6.8,1.9,9.7-0.2l6.4,6.4
                c0.5,0.5,1.2,0.5,1.7,0C27.5,24.2,27.5,23.4,27.1,23L27.1,23z M14,17.5c-1.3,0-2.5-0.4-3.5-1.1H14c0.6,0,1.2-0.5,1.2-1.2
                s-0.5-1.2-1.2-1.2H8.5c-0.2-0.5-0.4-1-0.5-1.5h6c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2H8c0.1-0.5,0.3-1,0.5-1.5H14
                c0.6,0,1.2-0.5,1.2-1.2S14.7,6.5,14,6.5h-3.5C13.2,4.5,17,5.2,19,7.9s1.3,6.5-1.4,8.5C16.5,17.2,15.3,17.6,14,17.5L14,17.5z" />
        </symbol>
    </defs>
</svg>