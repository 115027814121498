import { PermissionAction } from '@unifii/sdk';

import { Resource, ResourceType } from './shell-model';


export const AppTitle = 'Unifii';

export const UserInfoPath = `user-info`;
export const UserProfilePath = `user-profile`;
export const CreateUserPath = `create-user`;
export const InviteUserPath = `invite-user`;
export const FormDataPath = `form-data`;
export const NewItemPath = 'new'; // form, company
export const PagePath = '~page';


export const MobileScreenWidth = 768;
export const TableSearchMinLength = 3;

export const Resources: Resource = {
    segment: '',
    name: ResourceType.Resources,
    allowsCondition: false,
    actions: [],
    children: [
      {
        name: ResourceType.Roles,
        segment: 'roles',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Roles'
          }
        ],
        children: []
      },
      {
        name: ResourceType.UserClaims,
        segment: 'default-claims',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List User Claims'
          }
        ],
        children: [
          {
            name: ResourceType.UserClaim,
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read User Claim'
              }
            ],
            children: []
          }
        ]
      },
      {
        name: ResourceType.CompanyClaims,
        segment: 'company-claims',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Company Claims'
          }
        ],
        children: [
          {
            name: ResourceType.CompanyClaim,
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Company Claim'
              }
            ],
            children: []
          }
        ]
      },
      {
        name: ResourceType.Users,
        segment: 'users',
        allowsCondition: true,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Users'
          },
          {
            name: PermissionAction.Add,
            description: 'Add User'
          },
          {
            name: PermissionAction.Invite,
            description: 'Invite Users'
          }
        ],
        children: [
          {
            name: ResourceType.User,
            segment: '?',
            allowsCondition: true,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read User'
              },
              {
                name: PermissionAction.Update,
                description: 'Update User'
              },
              {
                name: PermissionAction.Delete,
                description: 'Delete User'
              }
            ],
            children: [
              {
                name: ResourceType.UserPermissions,
                segment: 'permissions',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.Read,
                    description: 'Read User Permissions'
                  }
                ],
                children: []
              }
            ]
          }
        ]
      },
      {
        name: ResourceType.Companies,
        segment: 'companies',
        allowsCondition: true,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Companies'
          },
          {
            name: PermissionAction.Add,
            description: 'Add Company'
          }
        ],
        children: [
          {
            name: ResourceType.Company,
            segment: '?',
            allowsCondition: true,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Company'
              },
              {
                name: PermissionAction.Update,
                description: 'Update Company'
              },
              {
                name: PermissionAction.Delete,
                description: 'Delete Company'
              }
            ],
            children: []
          }
        ]
      },
      {
        name: ResourceType.Projects,
        segment: 'projects',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Projects'
          }
        ],
        children: [
          {
            name: ResourceType.Project,
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Project'
              },
              {
                name: PermissionAction.Retrieve,
                description: 'Retrieve Offline Content'
              }
            ],
            children: [
              {
                name: ResourceType.Structure,
                segment: 'structure',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.Read,
                    description: 'Read Structure'
                  }
                ],
                children: []
              },
              {
                name: ResourceType.Collections,
                segment: 'collections',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Collections'
                  }
                ],
                children: [
                  {
                    name: ResourceType.Collection,
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Collection Definition'
                      }
                    ],
                    children: [
                      {
                        name: ResourceType.CollectionItems,
                        segment: 'items',
                        allowsCondition: true,
                        actions: [
                          {
                            name: PermissionAction.List,
                            description: 'List Collection Items'
                          }
                        ],
                        children: [
                          {
                            name: ResourceType.CollectionItem,
                            segment: '?',
                            allowsCondition: true,
                            actions: [
                              {
                                name: PermissionAction.Read,
                                description: 'Read Collection Item'
                              }
                            ],
                            children: []
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                name: ResourceType.Views,
                segment: 'views',
                allowsCondition: true,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Views'
                  }
                ],
                children: [
                  {
                    name: ResourceType.View,
                    segment: '?',
                    allowsCondition: true,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read View'
                      }
                    ],
                    children: []
                  }
                ]
              },
              {
                name: ResourceType.Pages,
                segment: 'pages',
                allowsCondition: true,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Pages'
                  }
                ],
                children: [
                  {
                    name: ResourceType.Page,
                    segment: '?',
                    allowsCondition: true,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Page'
                      }
                    ],
                    children: []
                  }
                ]
              },
              {
                name: ResourceType.Forms,
                segment: 'forms',
                allowsCondition: true,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Forms'
                  }
                ],
                children: [
                  {
                    name: ResourceType.Form,
                    segment: '?',
                    allowsCondition: true,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Form'
                      }
                    ],
                    children: []
                  }
                ]
              },
              {
                name: ResourceType.FormDataRepositories,
                segment: 'buckets',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Form Data Repositories'
                  }
                ],
                children: [
                  {
                    name: ResourceType.FormDataRepository,
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Form Data Repository Schema'
                      }
                    ],
                    children: [
                      {
                        name: ResourceType.FormDataRepositoryDocuments,
                        segment: 'docs',
                        allowsCondition: true,
                        actions: [
                          {
                            name: PermissionAction.List,
                            description: 'List Form Data Repository Documents'
                          },
                          {
                            name: PermissionAction.Add,
                            description: 'Add Form Data Repository Document'
                          }
                        ],
                        children: [
                          {
                            name: ResourceType.FormDataRepositoryDocument,
                            segment: '?',
                            allowsCondition: true,
                            actions: [
                              {
                                name: PermissionAction.Read,
                                description: 'Read Form Data Repository Document'
                              },
                              {
                                name: PermissionAction.Update,
                                description: 'Update Form Data Repository Document'
                              }
                            ],
                            children: []
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                name: ResourceType.FormDataTransactionLog,
                segment: 'bucketlog',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'Query Form Data Transaction Log'
                  }
                ],
                children: [
                  {
                    name: ResourceType.FormDataTranslationLogDocument,
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Document Revision from Form Data Transaction Log'
                      }
                    ],
                    children: []
                  }
                ]
              },
              {
                name: ResourceType.ExternalDataSources,
                segment: 'external-data-sources',
                allowsCondition: false,
                actions: [],
                children: [
                  {
                    name: ResourceType.ExternalDataSource,
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Execute Data Source'
                      }
                    ],
                    children: []
                  }
                ]
              },
              {
                name: ResourceType.Tables,
                segment: 'tables',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.List,
                    description: 'List Tables'
                  }
                ],
                children: [
                  {
                    name: ResourceType.Table,
                    segment: '?',
                    allowsCondition: false,
                    actions: [
                      {
                        name: PermissionAction.Read,
                        description: 'Read Table'
                      }
                    ],
                    children: []
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: ResourceType.TicketProviders,
        segment: 'ticket-providers',
        allowsCondition: false,
        actions: [],
        children: [
          {
            name: ResourceType.TicketProviderIntegration,
            segment: '?',
            allowsCondition: false,
            actions: [],
            children: [
              {
                name: ResourceType.TicketProvider,
                segment: '?',
                allowsCondition: false,
                actions: [
                  {
                    name: PermissionAction.Read,
                    description: 'Get a ticket'
                  }
                ],
                children: []
              }
            ]
          }
        ]
      },
      {
        name: ResourceType.Me,
        segment: 'me',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.Update,
            description: 'Update me'
          }
        ],
        children: []
      },
      {
        name: ResourceType.Units,
        segment: 'units',
        allowsCondition: false,
        actions: [
          {
            name: PermissionAction.List,
            description: 'List Units'
          }
        ],
        children: [
          {
            name: ResourceType.Unit,
            segment: '?',
            allowsCondition: false,
            actions: [
              {
                name: PermissionAction.Read,
                description: 'Read Unit'
              }
            ],
            children: []
          }
        ]
      }
    ]
};