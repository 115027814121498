import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Error, ErrorType, Interceptor } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { Authentication } from 'shell/services/authentication';


@Injectable()
export class SdkInterceptor implements Interceptor {

    private _auth: Authentication;

    constructor(
        // TODO Decouple dependency loop AuthService <- Client <- SdkInterceptor <- AuthService
        // @Inject(Authentication) private authentication: Authentication
        private injector: Injector,
        private errorService: ErrorService,
        private router: Router
    ) { }

    intercept(source: Promise<any>): Promise<any> {

        return source.catch((error: Error) => {

            if (error.type === ErrorType.Unauthorized) {

                // Pass in current url so user app can restart in the same place
                const grantedRedirectURL = this.router.url;
                return this.auth.logout({ grantedRedirectURL }).then(() => null);
            }

            if (!error.message) {
                error.message = this.getMessage(error.type);
            }
            throw error;
        });
    }

    private getMessage(type: ErrorType): string | undefined {

        switch (type) {
            case ErrorType.Forbidden: return this.errorService.forbiddenRequestErrorMessage;
            case ErrorType.Unknown: return this.errorService.unknownErrorMessage;
            case ErrorType.NotFound: return this.errorService.notFoundRequestErrorMessage;
            default: return undefined;
        }
    }

    get auth(): Authentication {

        if (this._auth == null) {
            this._auth = this.injector.get(Authentication);
        }
        return this._auth;
    }


}
