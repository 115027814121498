import { animate, style, transition, trigger } from '@angular/animations';
import { Component, forwardRef, Inject, OnInit, Optional, SkipSelf, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddActionConfig, OptionsModalComponent } from '@unifii/components';
import { MessageLevel, ModalService } from '@unifii/library/common';
import { ContentType, ErrorType, Option, StructureNode, StructureNodeType, Table, TableSourceType } from '@unifii/sdk';

import { ContentComponentFactory } from 'shell/content/content-component-factory';
import { AppContent } from 'shell/content/content-types';
import { ShellService } from 'shell/core/shell.service';
import { AppError } from 'shell/errors/errors';
import { ErrorMessageComponent } from 'shell/nav/error-message.component';
import { NavigationService } from 'shell/nav/navigation.service';
import { ContentDetails } from 'shell/services/content-details';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { NewItemPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';
import { TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverContentType } from './content-types';


interface ContentWrapper<T> {
    component: T;
    type: ContentType | StructureNodeType | DiscoverContentType;
    node?: StructureNode;
}

export enum TableDisplayMode {
    Detail = 'detail'
}

export const fade = trigger('fade', [
    transition(':leave', [
        animate('400ms ease', style({ opacity: 0 }))
    ]),
]);


/**
 * Manage dinamic content inside Structure navigation
 * Load the Content associated with a StructureNode
 * Following node/contents are allowed:
 *
 *  HomePage  (Empty, View, Page, Dashboard, Custom)
 *  Node (View, Page, Collection)
 *  Use ComponentSelector to lookup for the right Component
 */
@Component({
    template: `<div class="skeleton-container" *ngIf="!component" [ngClass]="skeletonClassName" @fade></div>`,
    styleUrls: ['./content-node.less'],
    animations: [fade]
})
export class ContentNodeComponent<T> implements OnInit, EditedData {

    type: ContentType | StructureNodeType | DiscoverContentType;
    skeletonClassName: string;
    component: T | ErrorMessageComponent; // public so skeleton knows to hide itself

    constructor(
        private container: ViewContainerRef,
        private nav: NavigationService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private contentDetails: ContentDetails,
        private modalService: ModalService,
        private router: Router,
        public shell: ShellService,
        @Inject(ContentComponentFactory) private contentFactory: ContentComponentFactory,
        @SkipSelf() @Optional() @Inject(forwardRef(() => ContentNodeComponent)) public parent?: ContentWrapper<any>,
        @Optional() @Inject(TablePageConfig) private tablePageConfig?: TablePageConfig
    ) { }

    async ngOnInit() {
        if (this.node && !this.canAccessNode(this.parent?.node ?? this.node)) {
            this.createErrorComponent(this.unauthorizedError);
            return;
        }

        try {

            const { params } = this.route.snapshot;

            // if route is includes /new and has more than 1 option, select and route
            if (this.route.routeConfig?.path === NewItemPath &&
                !params.$definition &&
                this.tablePageConfig?.addOptions &&
                this.tablePageConfig.addOptions.length > 1) {
                return this.routeToSelectedOption(this.tablePageConfig.addOptions);
            }

            this.type = this.route.snapshot.data?.contentType; // type can be set directly on route
            const bucket = this.getValidBucket(params);
            const definitionIdentifier = await this.getValidDefinitionIdentifier(params);
            const id = await this.getValidId(params);
            const hasRollingVersion = this.tablePageConfig?.hasRollingVersion ?? false;
            const mode = params.mode;

            if (this.type == null) {
                this.type = await this.getContentType(this.node?.type, this.parent, definitionIdentifier, mode);
            }

            this.skeletonClassName = this.getSkeletonClassName(this.type, definitionIdentifier, this.node?.tags);

            this.component = await this.contentFactory.create(this.container, this.type, {
                identifier: definitionIdentifier,
                id,
                bucket,
                tags: this.node?.tags,
                hasRollingVersion,
                parentData: this.parent?.component instanceof TableDetailComponent ? this.parent.component : this.tablePageConfig,
            });

            if(!this.node?.nodeId){
                this.shell.setTitle((this.component as AppContent).title);
            }

        } catch (e) {
            if (this.node?.nodeId === '0') {
                this.createHomePage();
            } else {
                console.warn(e);
                this.createErrorComponent(e);
            }
        }
    }

    get edited(): boolean {
        // TODO add strict types
        return (this.component as any)?.edited === true;
    }

    private getParentParam(param: string): string | undefined {
        return this.route.snapshot.parent?.paramMap.get(param) ?? undefined;
    }

    private canAccessNode(node: StructureNode): boolean {
        if (node.nodeId === '0') {
            // Home page is always accessible
            return true;
        }
        return this.nav.getNodeAccessInfo(node)?.matchACLs === true;
    }

    private getValidBucket(params: Params): string | undefined {

        if (params.bucket) {
            return params.bucket;
        }

        return this.tablePageConfig?.bucket;
    }


    private async getValidDefinitionIdentifier(params: Params): Promise<string | undefined> {

        // form-data new item
        if (this.type === ContentType.Form && params.id === NewItemPath) {
            return params.$definition;
        }

        //  collection items require parents identifier
        if (this.parent?.type === ContentType.Collection) {
            return this.getParentParam('identifier');
        }

        if (this.parent?.type === ContentType.Table) {

            if (params.$definition) {
                return params.$definition;
            }

            // get first option identifier
            if (this.route.routeConfig?.path === NewItemPath && this.tablePageConfig?.addOptions) {
                return this.tablePageConfig?.addOptions[0].identifier;
            }

            return this.getParentParam('identifier');
        }

        if (this.node?.definitionIdentifier) {
            return this.node.definitionIdentifier;
        }

        return params.identifier;
    }

    private async routeToSelectedOption(options: Option[]) {

        const label = this.translate.instant(ShellTranslationKey.FormBucketDialogAddFormTitle);

        const selected = await this.modalService.openMedium<AddActionConfig, Option>(OptionsModalComponent, { label, options });

        if (selected != null) {
            this.router.navigate([{ $definition: selected.identifier }], { relativeTo: this.route });
            return;
        }

        // go back if no selected option
        const { prevUrl } = this.route.snapshot.params;
        if (prevUrl) {
            this.router.navigateByUrl(prevUrl);
            return;
        }

        this.router.navigate(['..'], { relativeTo: this.route });
    }

    private async getValidId(params: Params): Promise<string | number | undefined> {

        // id is formIdentifier not formData
        if (this.parent?.type === ContentType.Table && await this.contentDetails.getContentType(params.id) === ContentType.Form) {
            return;
        }

        // form-data new item
        if (this.type === ContentType.Form && params.id === NewItemPath) {
            return;
        }

        if (this.node?.id) {
            return this.node.id;
        }

        return params.id;

    }

    private async getContentType(
        structureNodeType?: StructureNodeType,
        parent?: ContentWrapper<any>,
        identifier?: string,
        mode?: TableDisplayMode
    ): Promise<ContentType | DiscoverContentType | StructureNodeType> {

        if (structureNodeType != null) {
            switch (structureNodeType) {
                case StructureNodeType.Dashboard: return StructureNodeType.Dashboard;
                case StructureNodeType.IFrame: return StructureNodeType.IFrame;
                case StructureNodeType.PdfViewer: return StructureNodeType.PdfViewer;
                case StructureNodeType.View: return ContentType.View;
                case StructureNodeType.Page: return ContentType.Page;
                case StructureNodeType.Collection: return ContentType.Collection;
                case StructureNodeType.CollectionItem: return ContentType.CollectionItem;
                case StructureNodeType.FormBucket: return ContentType.Table;
                case StructureNodeType.Form: return ContentType.Form;
                default: return StructureNodeType.Custom;
            }
        }

        if (parent?.type === ContentType.Collection) {
            return ContentType.CollectionItem;
        }

        if (parent?.type === ContentType.Table) {

            if (mode === TableDisplayMode.Detail) {
                return ContentType.Detail;
            }

            switch (this.tablePageConfig?.sourceType) {
                case TableSourceType.Company: return DiscoverContentType.Company;
                case TableSourceType.Users: return DiscoverContentType.User;
                default: return ContentType.Form;
            }
        }

        if (identifier) {

            const nodeType = await this.contentDetails.getContentType(identifier);

            if (nodeType) {
                return nodeType;
            }

        }
        throw this.notFoundError;
    }

    private createErrorComponent(error: AppError) {
        this.component = this.createComponent();
        this.component.error = error;
        this.component.message = error.message;
    }

    private createHomePage() {
        this.component = this.createComponent();
        this.component.title = this.translate.instant(ShellTranslationKey.ContentNodeHomePageTitle);
        this.component.message = this.translate.instant(ShellTranslationKey.ContentNodeHomePageMessage);
        this.component.level = MessageLevel.Info;
    }

    private createComponent(): ErrorMessageComponent {
        const ref = this.container.createComponent(ErrorMessageComponent, { index: 0, injector: this.container.injector });
        return ref.instance;
    }

    private getSkeletonClassName(type: ContentType | StructureNodeType | DiscoverContentType, identifier?: string, tags?: string[]): string {
        /**
         * Since detail is loaded in a resolver, when it's loading it's parent route we show the detail skeleton
         * if it's not detail we don't show anything because it's going to load the children route and then it's going to show the proper skeleton
         */
        const activeChildRoute = this.route.snapshot.children[0];
        if (activeChildRoute != null) {
            const mode = activeChildRoute?.params?.mode;
            if (mode === TableDisplayMode.Detail) {
                return 'uf-container detail';
            }
            return '';
            // TODO confirm that no skeleton should show when there are no children
            // if (type === ContentType.Table) {
            //     return '';
            // }
        }

        if ((identifier === 'directory-template' || (tags || []).includes('directory')) || type === ContentType.Collection) {
            return 'uf-container-md directory-template';
        }

        switch (type) {
            case ContentType.Table:
                return 'container table';
            case ContentType.Page:
            case ContentType.CollectionItem:
                return 'page-wrap page';
            case ContentType.View:
                return 'grid--fixed body-copy page';
            case ContentType.Form:
            case DiscoverContentType.User:
            case DiscoverContentType.UserProfile:
            case DiscoverContentType.Company:
                return 'uf-container-lg form';
            case ContentType.Detail:
                return 'uf-container detail';
            case StructureNodeType.Dashboard:
                return 'uf-container-md dashboard';
            default:
                return 'skeleton';
        }
    }

    get node(): StructureNode | undefined {
        if (this.parent == null) {
            return this.nav.current ?? undefined;
        }
        return;
    }

    private get unauthorizedError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized), ErrorType.Unauthorized);
    }

    private get notFoundError(): AppError {
        return new AppError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
    }

}
