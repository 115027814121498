import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Breadcrumb, CommonTranslationKey, ContextProvider, DataPropertyDescriptor, DescriptionListItem, FieldDescriptionServiceProvider, FieldDisplayPipe,
    SharedTermsTranslationKey, TemplateStringParser
} from '@unifii/library/common';
import {
    ClaimConfig, Company, ContentLinkFormData, DetailModule, FormData, PermissionAction, TableDetailModule, TableFieldDescriptor,
    TableIdentifierFieldDescriptor, TableSourceType, TenantClient, UserInfo
} from '@unifii/sdk';
import { UserDescriptionService, UserDescriptionServiceProvider } from '@unifii/user-provisioning';

import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { TableDetailData, TableItemLink } from 'shell/content/content-types';
import { ShellService } from 'shell/core/shell.service';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { getBucketColumnRenderableValue } from 'shell/services/schema-field-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableData } from 'shell/table/models';
import { TableModuleConfig, TablePageConfig } from 'shell/table/table-page-config';

import { DiscoverFieldDescriptionService } from 'discover/services/discover-field-description.service';

import { TableDetailContextProvider } from './table-detail-context-provider';


export interface TableModule {
    detailModule: TableDetailModule;
    pageConfig: TablePageConfig;
    moduleConfig?: TableModuleConfig;
}

interface TableContentLinkItem {
    type: 'link';
    term?: string;
    content?: ContentLinkFormData;
};

type TableDescriptionListItem = DescriptionListItem & {
    type: 'description';
};

type TableListItem = TableDescriptionListItem | TableContentLinkItem;

type TableDetailContent = {
    rows: (TableListItem | undefined)[];
    heading?: string;
};


@Component({
    templateUrl: './table-detail.html',
    styleUrls: ['./table-detail.less'],
    providers: [BreadcrumbsService]
})
export class TableDetailComponent implements TableDetailData, OnInit {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly shellTk = ShellTranslationKey;
    readonly commonTK = CommonTranslationKey;

    tableModules: TableModule[] = [];
    detailContextProvider: TableDetailContextProvider;

    // Set by route resolver
    // TODO: convert to template once in console
    sourceType: TableSourceType;
    propertyDescriptors: Map<string, DataPropertyDescriptor>;
    item: TableData;
    title: string;
    modules: DetailModule[];
    fields: TableFieldDescriptor[];
    itemLink?: TableItemLink | undefined;


    protected breadcrumbs: Breadcrumb[];
    protected prevUrl: string;
    protected tableDetailsContent: TableDetailContent[] = [];

    private userClaims: ClaimConfig[];
    private companyClaims: ClaimConfig[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fieldDisplayPipe: FieldDisplayPipe,
        @Inject(TablePageConfig) public tablePageConfig: TablePageConfig,
        private shell: ShellService,
        private stringParser: TemplateStringParser,
        private breadcrumbsService: BreadcrumbsService,
        @Inject(Authentication) private auth: Authentication,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private tenantClient: TenantClient,
        @Inject(FieldDescriptionServiceProvider) private descriptionService: DiscoverFieldDescriptionService,
        @Inject(UserDescriptionServiceProvider) private userDescriptionService: UserDescriptionService,
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver
    ) { }

    async ngOnInit() {

        this.detailContextProvider = new TableDetailContextProvider(this.contextProvider, this.item);  // Monkey-patch ContextProvider

        if (this.sourceType === TableSourceType.Company) {
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted) {
                this.companyClaims = await this.tenantClient.getCompanyClaims();
            }
        } else if (this.sourceType === TableSourceType.Users) {
            if (this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted) {
                this.userClaims = await this.tenantClient.getUserClaims();
            }
        }

        this.refreshDetails();

        // Set title
        const context = Object.assign({ self: null, root: {} }, this.detailContextProvider.get());
        const title = this.stringParser.parse(this.title, context, this.item);
        this.breadcrumbsService.title = title;
        this.shell.setTitle(title);

        let index = 0;
        const tableModules: TableModule[] = [];
        for (const module of this.modules) {
            try {
                // Catch permissions forbidden access to silently fail the single TableModule
                module.title = module.title?.trim() ?? undefined;
                const tableData = await this.dataResolver.getTableData(module.identifier);
                const pageConfig = tableData.tablePageConfig;
                const moduleConfig = this.tablePageConfig.modules ? this.tablePageConfig.modules[index] : undefined;
                tableModules.push({detailModule: module, pageConfig, moduleConfig});
            } catch (e) {
                console.error('error', e);
            } finally {
                index++;
            }
        }
        this.tableModules = tableModules;

        this.prevUrl = this.route.snapshot.params.prevUrl;

        if (!this.prevUrl) {
            this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
        }
    }

    back(url: string) {
        this.router.navigateByUrl(url);
    }

    updateDetails(formData: FormData) {
        this.item = formData;
        this.refreshDetails();
    }

    private refreshDetails() {
        this.tableDetailsContent = (this.fields ?? []).reduce((d, field) => {
            if (field.type === 'Heading') {
                d.push({ heading: field.value, rows: [] });
            } else {
                if (!d.length) {
                    d.push({ rows: [] });
                }
                const lastHeader = d[d.length - 1];
                lastHeader.rows.push(this.toDescription(field));
            }
            return d;
        }, [] as TableDetailContent[]);
    }

    private toDescription(descriptor: TableIdentifierFieldDescriptor): TableDescriptionListItem | TableContentLinkItem | undefined {

        switch (this.sourceType) {

            case TableSourceType.Bucket:
                const dataPropertyDescriptor = this.propertyDescriptors.get(descriptor.identifier);
                if (dataPropertyDescriptor == null || !dataPropertyDescriptor.asDisplay) {
                    return;
                }

                const term = descriptor.label ?? dataPropertyDescriptor.label;
                const value = getBucketColumnRenderableValue(this.item as FormData, dataPropertyDescriptor, this.fieldDisplayPipe);

                if (typeof value !== 'object') {
                    return {
                        type: 'description',
                        term,
                        description: value
                    } as TableDescriptionListItem;
                } else {
                    return {
                        type: 'link',
                        term,
                        content: value,
                    } as TableContentLinkItem;
                }

            case TableSourceType.Users:
                const userPropertyValue = (this.item as UserInfo)[descriptor.identifier as keyof UserInfo];
                // TODO available this.propertyDescriptors to use instead
                return { type: 'description', ...this.userDescriptionService.createUserDescription(userPropertyValue, descriptor, this.userClaims, this.item as UserInfo)};

            case TableSourceType.Company:
                const companyPropertyValue = (this.item as Company)[descriptor.identifier as keyof Company];
                // TODO available this.propertyDescriptors to use instead
                return { type: 'description', ...this.descriptionService.createCompanyDescription(companyPropertyValue, descriptor, this.companyClaims, this.item as Company)};
        }
    }
}
