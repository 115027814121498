import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

import { PDFAsset } from 'shell/content/pdf-viewer.component';


export interface PdfViewerModalData {
    url: string;
    title: string;
}

@Component({
    templateUrl: './pdf-viewer-modal.html',
    styleUrls: ['./pdf-viewer-modal.less']
})
export class PdfViewerModalComponent implements Modal<PdfViewerModalData, undefined> {

    @HostBinding('class.uf-form-card') class = true;

    protected content: PDFAsset[];
    protected readonly sharedTermsTK = SharedTermsTranslationKey;

    constructor(
        public runtime: ModalRuntime<PdfViewerModalData, undefined>,
        @Inject(ModalData) public data: PdfViewerModalData
    ) {
        this.content = [{url: data.url}];
     }

    close() {
        this.runtime.close();
    }
}
