<uf-panel class="container locked">
    <div class="enter-pin-screen">
        <div class="pin-input-wrapper grid--fixed">
            <div class="pin-input push-bottom col-1of2">
                <span class="message"></span>
                <div [@shake]="error? 'yes': 'no'" class="pin-hint">
                    <span class="title">{{discoverTK.PINCheckEnterPINLabel | translate}}</span>
                </div>
                <div [ngClass]="{'error': error}" class="pin-input-field active">
                    <span class="blink" *ngIf="blink && pin.length === 0">|</span>
                    <span class="number" *ngFor="let char of pin.split('')">
                        <span [@hide] class="character">{{char}}</span>
                        <uf-icon (@show.done)="numberAdded()" [@show] name="radioDot"></uf-icon>
                    </span>
                    <div class="backspace hover-dot" (click)="remove()" *ngIf="pin.length !== 0">
                        <uf-icon name="backspace"></uf-icon>
                    </div>
                </div>
                <div class="error-text">{{error}}</div>
                <div class="keypad">
                    <div class="item-wrapper hover-dot" (click)="add(1)">
                        <div class="item">
                            <span class="title">1</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(2)">
                        <div class="item">
                            <span class="title">2</span>
                            <span class="subtitle">ABC</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(3)">
                        <div class="item">
                            <span class="title">3</span>
                            <span class="subtitle">DEF</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(4)">
                        <div class="item">
                            <span class="title">4</span>
                            <span class="subtitle">GHI</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(5)">
                        <div class="item">
                            <span class="title">5</span>
                            <span class="subtitle">JKL</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(6)">
                        <div class="item">
                            <span class="title">6</span>
                            <span class="subtitle">MNO</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(7)">
                        <div class="item">
                            <span class="title">7</span>
                            <span class="subtitle">PQRS</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(8)">
                        <div class="item">
                            <span class="title">8</span>
                            <span class="subtitle">TUV</span>
                        </div>
                    </div>
                    <div class="item-wrapper hover-dot" (click)="add(9)">
                        <div class="item">
                            <span class="title">9</span>
                            <span class="subtitle">WXYZ</span>
                        </div>
                    </div>
                    <div class="item-wrapper"></div>
                    <div class="item-wrapper hover-dot" (click)="add(0)">
                        <div class="item">
                            <span class="title">0</span>
                        </div>
                    </div>
                    <div class="item-wrapper"></div>
                </div>
                <div class="close-buttons">
                    <span (click)="logout()">{{sharedTermsTK.ActionLogout | translate}}</span>
                    <span (click)="forgotPin()">{{discoverTK.PINCheckForgotPINLabel | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</uf-panel>