import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, ViewChildren } from '@angular/core';
import { DataPropertyInfoService } from '@unifii/library/common';
import {
    ColumnDescriptor, PublishedContent, StructureNode, StructureNodeBucketOptions, StructureNodeType, Table, TableSourceType, VisibleFilterDescriptor
} from '@unifii/sdk';

import { AppError } from 'shell/errors/errors';
import { NavigationService } from 'shell/nav/navigation.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableNodeInfo } from 'shell/shell-model';

import { Config } from 'config';

import { DashboardTableComponent } from './dashboard-table.component';


const fade = trigger('fade', [
    transition(':leave', [
        animate('400ms ease', style({ opacity: 0 }))
    ]),
]);

@Component({
    selector: 'us-dashboard',
    templateUrl: './dashboard.html',
    styleUrls: ['./dashboard.less', '../content/content-node.less'],
    animations: [fade]
})
export class DashboardComponent implements OnDestroy {

    @ViewChildren(DashboardTableComponent) dashboardTables: DashboardTableComponent[] | null = null;

    error: AppError | undefined;
    tableInfos: TableNodeInfo[] = [];
    ready = false;

    private subscriptions = new Subscription();

    constructor(
        private nav: NavigationService,
        @Inject(PublishedContent) private content: PublishedContent,
        private dataPropertyInfoService: DataPropertyInfoService,
        @Inject(Config) private config: Config
    ) {
        // Load node when component initialized
        this.load(this.nav.current as StructureNode);
        // React to navigation change, needed when navigate between dashoard nodes or children
        this.subscriptions.add(
            this.nav.changes
                .pipe(filter(node => node?.type === StructureNodeType.Dashboard))
                .subscribe(node => this.load(node as StructureNode))
        );
    }

    ngOnDestroy() {
       this.subscriptions.unsubscribe();
    }

    refreshBuckets() {
        if (this.dashboardTables) {
            for (const db of this.dashboardTables) {
                db.reload();
            }
        }
    }

    private async load(node: StructureNode) {
        try {

            const items: TableNodeInfo[] = [];

            for (const bo of node.bucketOptions as StructureNodeBucketOptions[]) {

                if (bo.identifier) {
                    // New Format StructureNodeBucketOptions.identifier link to Table)
                    if (!PermissionsFunctions.getTablePath(this.config.unifii.projectId, bo.identifier)) {
                        continue;
                    }
                    const table = await this.content.getTable(bo.identifier);

                    if (table.sourceType !== TableSourceType.Bucket) {
                        continue;
                    }

                    items.push({ table, pageSize: bo.pageSize });
                } else {
                    // Legacy Format (StructureNodeBucketOptions.nodeId link to StructureNode.nodeId)
                    const bucketNode = this.nav.findNode(n => n.type === StructureNodeType.FormBucket && n.nodeId === bo.nodeId);

                    if (!bucketNode) {
                        continue;
                    }

                    if (!this.nav.canAccessNode(bucketNode)) {
                        continue;
                    }

                    const table = await this.getTable(bucketNode);

                    if (!table) {
                        continue;
                    }

                    if (table.sourceType !== TableSourceType.Bucket) {
                        continue;
                    }

                    items.push({ table, pageSize: bo.pageSize });
                }
            }

            this.tableInfos = items;

        } catch (error) {
            this.error = error;
        } finally {
            this.ready = true;
        }
    }

    private async getTable(node: StructureNode): Promise<Table | undefined> {

        // Used to keep the Table id as configuration of the Form Data
        if (node.id && node.definitionIdentifier) {
            // Check ACL
            if (!PermissionsFunctions.getTablePath(this.config.unifii.projectId, node.definitionIdentifier)) {
                return;
            }

            return this.content.getTable(node.definitionIdentifier);
        }

        // Map ColumDescriptor array with with retro-compatibility
        const columns = (node.columns || this.dataPropertyInfoService.formDefinitionReferences.map(f => f.identifier))
            .map(i => ({ identifier: i } as ColumnDescriptor));

        const visibleFilters: VisibleFilterDescriptor[] | undefined = node.userFilterOptions;

        return {
            title: node.name as any as string,
            identifier: null as any as string,
            name: node.name,
            sourceType: TableSourceType.Bucket,
            source: node.definitionIdentifier,
            columns,
            defaultSort: node.defaultSort,
            visibleFilters,
            filter: node.filter
        } as Table;
    }

}