<ud-user-access-box>
    <ng-container content>

        <uf-blockquote *ngIf="linkError" class="error gap-top small" icon="errorSolid" [content]="linkError.message">
        </uf-blockquote>

        <ng-container *ngIf="form">
            <p>{{discoverTK.CompleteRegistrationDescription | translate}}</p>

            <div class="grid">
                <form class="col-1of1 grid" [formGroup]="form" (ngSubmit)="submit()">

                    <uf-text class="col-1of1" [label]="labels[controlKeys.Email]" [formControlName]="controlKeys.Email">
                    </uf-text>

                    <uf-text class="col-1of1" [label]="labels[controlKeys.Username]"
                        [formControlName]="controlKeys.Username">
                        <span class="suffix">*</span>
                    </uf-text>

                    <uf-text class="col-1of1" [label]="labels[controlKeys.FirstName]"
                        [formControlName]="controlKeys.FirstName">
                        <span class="suffix">*</span>
                    </uf-text>

                    <uf-text class="col-1of1" [label]="labels[controlKeys.LastName]"
                        [formControlName]="controlKeys.LastName">
                        <span class="suffix">*</span>
                    </uf-text>

                    <uf-text class="col-1of1" [label]="labels[controlKeys.Phone]" [formControlName]="controlKeys.Phone">
                    </uf-text>

                    <uf-password-advanced class="col-1of1" [label]="labels[controlKeys.Password]"
                        [formControlName]="controlKeys.Password">
                        <span class="suffix">*</span>
                    </uf-password-advanced>

                    <div *ngIf="error" class="col-1of1">
                        <uf-blockquote class="error" icon="errorSolid"
                            [content]="error.message || (discoverTK.CompleteRegistrationActionRegisterFail | translate)">
                        </uf-blockquote>
                    </div>

                    <uf-password-indicator [formControlName]="controlKeys.Password"
                        [style.visibility]="!form.controls[controlKeys.Password].value ? 'hidden' : 'visible'"
                        class="col-1of1">
                    </uf-password-indicator>

                    <div class="col-1of1 col center-all">
                        <button class="uf-button primary" [disabled]="busy" type="submit">
                            {{discoverTK.CompleteRegistrationActionRegister | translate}}
                        </button>
                    </div>

                </form>
            </div>

        </ng-container>

    </ng-container>
</ud-user-access-box>