import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';
import { AppInfo } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';


@Component({
    templateUrl: './required-update-modal.html'
})
export class RequiredUpdateModalComponent implements Modal<AppInfo, null> {

    @HostBinding('class.uf-form-card') cardClass = true;

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    constructor(
        public runtime: ModalRuntime<AppInfo, null>,
        @Inject(ModalData) public data: AppInfo,
    ) {

    }

}
