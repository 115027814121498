import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey } from '@unifii/library/common';
import { Error as sdkError, ErrorType } from '@unifii/sdk';

import { ShellTranslationKey } from 'shell/shell.tk';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { AppError, LoadError, NotFoundError, NullReferenceError, SaveError } from './errors';


@Injectable()
export class ErrorService {

    readonly unknownErrorMessage: string;
    readonly unhandledErrorMessage: string;
    readonly unauthorizedRequestErrorMessage: string;
    readonly forbiddenRequestErrorMessage: string;
    readonly notFoundRequestErrorMessage: string;

    readonly noAllowedProjectsErrorMessage: string;
    readonly invalidClientErrorMessage: string;
    readonly invalidUsernameOrPasswordErrorMessage: string;

    readonly passwordChangeInvalidLinkErrorMessage: string;
    readonly passwordChangeGenericErrorMessage: string;
    readonly passwordChangeInvalidPasswordErrorMessage: string;
    readonly passwordChangeCannotBeSameErrorMessage: string;

    readonly offlineFormSaveErrorMessage: string;
    readonly offlineFormLoadErrorMessage: string;

    constructor(private translate: TranslateService) {

        this.unknownErrorMessage = this.translate.instant(SharedTermsTranslationKey.ErrorUnknown);
        this.unhandledErrorMessage = this.translate.instant(ShellTranslationKey.ErrorUnhandled);
        this.unauthorizedRequestErrorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized);
        this.forbiddenRequestErrorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestForbidden);
        this.notFoundRequestErrorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestNotFound);

        // login
        this.noAllowedProjectsErrorMessage = this.translate.instant(ShellTranslationKey.ErrorNoAllowedProjects);
        this.invalidClientErrorMessage = this.translate.instant(ShellTranslationKey.ErrorInvalidClient);
        this.invalidUsernameOrPasswordErrorMessage = this.translate.instant(CommonTranslationKey.LoginErrorInvalidUsernameOrPassword);

        // password
        this.passwordChangeInvalidLinkErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidLink);
        this.passwordChangeGenericErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorChangePassword);
        this.passwordChangeInvalidPasswordErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidPassword);
        this.passwordChangeCannotBeSameErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorCannotBeSame);

        // offline forms
        this.offlineFormSaveErrorMessage = this.translate.instant(DiscoverTranslationKey.OfflineFormErrorSave);
        this.offlineFormLoadErrorMessage = this.translate.instant(DiscoverTranslationKey.OfflineFormErrorLoad);
    }

    createError(message: string, originalError?: sdkError): AppError {
        if (originalError) {
            return this.mergeError(originalError, message);
        }

        return new AppError(message);
    }

    createNullReferenceError(argument: string, originalError?: sdkError): NullReferenceError {

        const message = this.translate.instant(ShellTranslationKey.ErrorNullReference, { argument });

        if (originalError != null && originalError.type !== ErrorType.Unknown) {
            return this.mergeError(originalError, message, ErrorType.NullReference);
        }

        return new NullReferenceError(message);
    }

    createNotFoundError(argument: string, originalError?: sdkError): NotFoundError {

        const message = this.translate.instant(ShellTranslationKey.ErrorNotFound, { argument });

        if (originalError != null && originalError.type !== ErrorType.Unknown) {
            return this.mergeError(originalError, message, ErrorType.NotFound);
        }

        return new NotFoundError(message);
    }

    createLoadError(argument: string, originalError?: sdkError): LoadError {

        const message = this.translate.instant(ShellTranslationKey.ErrorLoad, { argument });

        if (originalError != null && originalError.type !== ErrorType.Unknown) {
            return this.mergeError(originalError, message);
        }

        return new LoadError(message);
    }

    createSaveError(argument: string, originalError?: sdkError): SaveError {

        const message = this.translate.instant(ShellTranslationKey.ErrorSave, { argument });

        if (originalError != null && originalError.type !== ErrorType.Unknown) {
            return this.mergeError(originalError, message);
        }

        return new SaveError(message);
    }

    /** Helper function to ensures an error message is provided */
    mergeError(error: sdkError, alternativeMessage: string, alternativeType?: ErrorType): AppError {

        // Delete empty string
        if (error && !error.message) {
            delete error.message;
        }

        const alternativeError: AppError = {
            type: alternativeType || ErrorType.Unknown,
            message: alternativeMessage
        };

        return { ...alternativeError, ...error };
    }
}
