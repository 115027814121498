import { Injectable } from '@angular/core';


// import { Repository } from './repository';


@Injectable()
export class Features {
    constructor(/* private repo: Repository */) { }

    get offline(): boolean {
        return true;
        // return this.repo.load('UfFeatureOffline') === true;
    }
}