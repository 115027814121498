import { Subscription } from 'rxjs';

import { Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CommonTranslationKey, FeatureFlagService, ModalService, SharedTermsTranslationKey, ToastService, UfControl, UfControlGroup
} from '@unifii/library/common';
import { Claim, Dictionary, FieldWidth, PermissionAction, UserCreateResult, UsersClient } from '@unifii/sdk';
import {
    ClaimSourceType, FormPermissionController, UserFieldLabelService, UserInfoKey, UserInputControlKey, UserProvisioningTranslationKey,
    UsersCreateUploadFormController, UsersInviteUploadFormController
} from '@unifii/user-provisioning';

import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';
import { EditedData } from 'shell/services/unsaved-data-guard';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import {
    UserFormPermissionConfig, UserFormPermissionController, UserFormResourceType
} from 'discover/user-management/user-form-permission-controller';
import { UserInputType } from 'discover/user-management/user-types';
import { UserUploadModalComponent } from 'discover/user-management/user-upload-modal.component';


export enum UserCsvUploadType {
    BASIC = 'basic',
    ADVANCED = 'advanced'
}

@Component({
    templateUrl: './user-upload-csv.html',
    providers: [
        { provide: FeatureFlagService, useClass: ShellFeatureFlagService }
    ]
})
export class UserUploadCsvComponent implements EditedData, OnInit, OnDestroy, OnDestroy {

    edited: boolean;

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly commonTK = CommonTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly userProvisioningTK = UserProvisioningTranslationKey;
    protected readonly userInfoControlKeys = UserInfoKey;
    protected readonly userInputControlKey = UserInputControlKey;
    protected readonly userCsvUploadType = UserCsvUploadType;
    protected readonly fieldWidth = FieldWidth;
    protected readonly claimSource = ClaimSourceType.User;
    protected readonly formController: UsersInviteUploadFormController | UsersCreateUploadFormController;

    protected error: any;
    protected uploading: boolean;
    protected uploadResult: any;
    protected inputType: UserInputType;
    protected uploadType = UserCsvUploadType.ADVANCED;
    protected abortController: AbortController = new AbortController();
    protected form: UfControlGroup;
    protected labelDictionary: Dictionary<string>;
    protected allowedRoles: string[] | undefined;

    private subscriptions = new Subscription();

    get saveButtonLabel(): string {
        return this.inputType === UserInputType.Invite ? this.translate.instant(SharedTermsTranslationKey.ActionInvite) : this.translate.instant(SharedTermsTranslationKey.ActionCreate);
    }

    get csvTemplatePath(): string {
        if (this.inputType === UserInputType.Invite) {
            return this.uploadType === UserCsvUploadType.BASIC ? 'assets/csv/invite basic.csv' : 'assets/csv/invite advanced.csv';
        } else {
            return this.uploadType === UserCsvUploadType.BASIC ? 'assets/csv/create basic.csv' : 'assets/csv/create advanced.csv';
        }
    }

    constructor(
        public context: DiscoverContext,
        private router: Router,
        private route: ActivatedRoute,
        private usersClient: UsersClient,
        private translate: TranslateService,
        private toastService: ToastService,
        private modalService: ModalService,
        private userFieldLabelService: UserFieldLabelService,
        private inviteFormController: UsersInviteUploadFormController,
        private createFormController: UsersCreateUploadFormController,
        @Inject(FormPermissionController) private permissionCtrl: UserFormPermissionController,
    ) {
        this.inputType = this.route.snapshot.data.inputType;
        this.uploadType = this.route.snapshot.data.uploadType;
        this.labelDictionary = this.userFieldLabelService.labelDictionary;

        const userFormPermissionConfig = inject(UserFormPermissionConfig);
        userFormPermissionConfig.action = this.inputType === UserInputType.Invite ? PermissionAction.Invite : PermissionAction.Add;
        userFormPermissionConfig.resourceType = UserFormResourceType.Users;

        if (this.inputType === UserInputType.Invite) {
            this.formController = this.inviteFormController;
        } else {
            this.formController = this.createFormController;
        }
    }

    get fileControl(): UfControl {
        return this.form.get(UserInputControlKey.File) as UfControl;
    }

    get claimsControl(): UfControlGroup | undefined {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup | undefined;
    }

    get unitsControl(): UfControl {
        return this.form.get(UserInfoKey.Units) as UfControl;
    }

    get unitPathsControl(): UfControl {
        return this.form.get(UserInfoKey.UnitPaths) as UfControl;
    }
    get rolesControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Roles) as UfControl | undefined;
    }

    get companyControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Company) as UfControl | undefined;
    }

    get changePasswordOnNextLoginControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.ChangePasswordOnNextLogin) as UfControl | undefined;
    }

    get canChangeUsernameControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.CanChangeUsername) as UfControl | undefined;
    }

    async ngOnInit() {
        this.form = await this.formController.buildRoot(this.uploadType);
        this.allowedRoles = this.permissionCtrl.allowedRoles();
        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            this.edited = true;
        }));


        this.subscriptions.add(this.fileControl.valueChanges.subscribe((v) => {
            if (v.length > 0) {
                this.fileControl.disable({ emitEvent: false });
            } else {
                this.fileControl.enable({ emitEvent: false });
            }
        }));
    }

    ngOnDestory(){
        this.subscriptions.unsubscribe();
    }

    async upload() {
        if (this.uploading) {
            return;
        }

        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        // Save
        let responseData: UserCreateResult = {
            successCount: 0,
            errors: []
        };

        const data = await this.formController.toDataModel(this.form);
        let uploadModalTimeout;

        try {
            uploadModalTimeout = setTimeout(() => this.modalService.openFit(UserUploadModalComponent, undefined, { guard: true }).then(result => {
                if (result === false) {
                    this.abortController.abort();
                }
            }), 1000); // Only show if it's taking longer than 1 second

            this.uploading = true;

            if (this.inputType === UserInputType.Create) {
                responseData = await this.usersClient.bulkAddCsv(data.file, data.additionalData, {signal: this.abortController.signal});
            } else if (this.inputType === UserInputType.Invite) {
                responseData = await this.usersClient.bulkInviteCsv(data.file, data.additionalData, {signal: this.abortController.signal});
            }
            this.uploading = false;

            clearTimeout(uploadModalTimeout);
            this.modalService.closeLatest();

            if (!responseData.errors || !responseData.errors.length) {
                this.edited = false;
                if (this.inputType === UserInputType.Create) {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersCreateSuccessToast, { count: responseData.successCount }));
                } else {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersInviteSuccessToast, { count: responseData.successCount }));
                }
                this.back();
            } else {
                this.uploadResult = responseData;
                if (this.inputType === UserInputType.Create) {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersCreateFailedToast, { count: responseData.successCount, total: responseData.errors.length + responseData.successCount }));
                } else {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersInviteFailedToast, { count: responseData.successCount, total: responseData.errors.length + responseData.successCount }));
                }
            }
        } catch (e) {
            if (this.abortController.signal.aborted) {
                this.abortController = new AbortController();
                this.toastService.error(this.translate.instant(CommonTranslationKey.UploadCancelledMessage));
            } else {
                this.toastService.error(this.translate.instant(SharedTermsTranslationKey.ErrorGenericMessage));
            }

            this.error = e;

            if (uploadModalTimeout) {
                clearTimeout(uploadModalTimeout);
            }
            this.modalService.closeLatest();
            this.uploading = false;
        }
    }

    back(canLeave?: boolean) {
        this.edited = canLeave ? false : this.edited;
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    claimsChange(claims: Claim[]) {
        if (this.claimsControl) {
            this.claimsControl.setValue(claims);
            this.edited = true;
        }
    }

    removeFile() {
        this.fileControl.setValue([]);
    }

    ngOnDestroy() {
        this.abortController.abort();
    }
}
