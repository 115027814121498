import { Inject, Injectable } from '@angular/core';
import { FeatureFlagProvider, FeatureFlagService } from '@unifii/library/common';
import { FeatureFlagKey } from '@unifii/sdk';
import { ShellFeatureFlagKey } from 'shell/shell-model';


@Injectable()
export class ShellFeatureFlagService extends FeatureFlagService {

    constructor(@Inject(FeatureFlagProvider) provider: FeatureFlagProvider) {
        super(provider);
    }

    isEnabled(key: ShellFeatureFlagKey): Promise<boolean> {
        return super.isEnabled(key as any as FeatureFlagKey);
    }
}