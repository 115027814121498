import { Inject, Injectable } from '@angular/core';
import {
    columnDescriptorToCellDisplayDescriptor, DataPropertyDescriptor, ExpressionParser, FieldDisplayPipe, isCustomColumn, TableConfigColumn,
    TemplateStringParser, WindowWrapper
} from '@unifii/library/common';
import { ColumnDescriptor, TableSourceType } from '@unifii/sdk';

import { MobileScreenWidth } from 'shell/shell-constants';

import { TableData } from './models';
import { getTablePropertyDisplayValue } from './table-functions';


/**
 * @description
 * TableManagerUtils is a colleciton used by TableManager classes
 */
@Injectable({
    providedIn: 'root'
})
export class TableColumnFactory<T> {

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private displayPipe: FieldDisplayPipe,
        private expressionParser: ExpressionParser,
        private templateStringParser: TemplateStringParser
    ) { }

    create(
        columnDescriptors: ColumnDescriptor[],
        propertyDescriptors: Map<string, DataPropertyDescriptor>,
        sourceType: TableSourceType,
        isPageTable: boolean
    ): TableConfigColumn<T>[] {
        return columnDescriptors.map(column => {

            const config: TableConfigColumn<T> = {
                name: column.identifier,
                hidden: this.isMobileScreen ? column.hideOnMobile : column.hideOnDesktop
            };

            const dataDescriptor = propertyDescriptors.get(column.identifier);
            if (dataDescriptor == null && isCustomColumn(columnDescriptorToCellDisplayDescriptor(column))) {
                config.label = column.heading;
                config.sortable = false;
                return config;
            }

            if (dataDescriptor == null || !dataDescriptor.asDisplay) {
                return;
            }

            config.label = column.heading ?? dataDescriptor?.label;
            config.sortable = isPageTable && dataDescriptor?.asSort === true;
            config.value = (item: T) => getTablePropertyDisplayValue(
                this.expressionParser,
                this.templateStringParser,
                this.displayPipe,
                sourceType,
                item as TableData,
                column.identifier,
                dataDescriptor.type,
                dataDescriptor.options,
                column.defaultFormat,
                column.itemTemplate ?? dataDescriptor.itemTemplate
            );

            return config;
        }).filter(config => config != null) as TableConfigColumn<T>[];
    }


    private get isMobileScreen() {
        return this.window.innerWidth <= MobileScreenWidth;
    }

}
