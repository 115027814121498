<div class="shell-body">
    <ng-content select="us-header"></ng-content>
    <div class="shell-content" [class.push-down]="header">
        <ng-content></ng-content>
    </div>
</div>
<ng-content select="us-menu-drawer"></ng-content>
<ng-content select="us-drawer"></ng-content>
<ng-content select="us-drawer[right]"></ng-content>

<us-icons></us-icons>