<div class="content-pane">
    <div class="uf-container-lg">
        <div class="uf-grid row-gap-lg">
            <div class="uf-box col-12">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ discoverTK.UsersUploadResultStatusTitle | translate }}</div>
                </div>
                <div class="uf-grid pad">
                    <div class="col-12">
                        <uf-description-list [items]="uploadStatus" class="small-label"></uf-description-list>
                    </div>
                </div>
            </div>
            <div class="col-12 uf-box" *ngIf="form?.controls?.length || uploadResult.errors.length">
                <div class="uf-app-bar accent medium">
                    <div class="title">{{ discoverTK.UsersUploadResultFailedEntriesTitle | translate }}</div>
                </div>
                <div class="uf-grid">
                    <div class="col-12 pad-bottom">
                        <p class="small pad-sides" *ngIf="form?.controls?.length">
                            {{ discoverTK.UsersUploadResultFailedEntriesMessage | translate }}
                        </p>
                        <div class="pad-sides pad-top"
                            *ngIf="uploadResult && uploadResult.errors && uploadResult.errors.length && !form?.controls?.length">
                            <table class="uf-table break-word">
                                <thead>
                                    <tr>
                                        <th *ngIf="csvUpload">
                                            {{ discoverTK.UsersUploadResultLineNumberLabel | translate }}</th>
                                        <th *ngIf="!csvUpload">
                                            {{ discoverTK.UsersUploadResultUserNumberLabel | translate }}</th>
                                        <th>{{ sharedTermsTK.Error | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let error of uploadResult.errors; let i = index">
                                        <td *ngIf="csvUpload">{{ error.index + 2 }}</td>
                                        <td *ngIf="!csvUpload">{{ error.index + 1 }}</td>
                                        <td class="error-message">{{ error.errorMessage }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ng-container *ngIf='form?.controls?.length'>
                            <div class="col space-children margin--top user-card-display">
                                <div class="uf-card margin--horizontal--large"
                                    *ngFor="let control of form.controls; let i = index">
                                    <div class="pad">
                                        <button type="button" class="uf-action tertiary small float-right"
                                            (click)="removeUser(i)" [title]="sharedTermsTK.ActionRemove | translate">
                                            <uf-icon name="delete"></uf-icon>
                                        </button>
                                        <button type="button" class="uf-action tertiary small float-right"
                                            (click)="editUser(i)" [title]="sharedTermsTK.ActionEdit | translate">
                                            <uf-icon name="edit"></uf-icon>
                                        </button>
                                        <uf-description-list class="small-label">
                                            <ng-container *ngIf="inputType === userInputType.Create">
                                                <dt>{{ commonTK.UsernameLabel | translate }}</dt>
                                                <dd>{{ control?.value?.username }}</dd>
                                            </ng-container>
                                            <dt>{{ commonTK.FirstNameLabel | translate }}</dt>
                                            <dd>{{ control?.value?.firstName }}</dd>
                                            <dt>{{ commonTK.LastNameLabel | translate }}</dt>
                                            <dd>{{ control?.value?.lastName }}</dd>
                                            <dt>{{ commonTK.EmailLabel | translate }}</dt>
                                            <dd>{{ control?.value?.email }}</dd>
                                            <dt>{{ commonTK.PhoneLabel | translate }}</dt>
                                            <dd>{{ control?.value?.phone }}</dd>
                                        </uf-description-list>
                                    </div>
                                </div>
                            </div>
                            <div class="pad-sides user-table-display">
                                <table class="uf-table">
                                    <thead>
                                        <tr>
                                            <th>{{ commonTK.UsernameLabel | translate }}</th>
                                            <th>{{ commonTK.FirstNameLabel | translate }}</th>
                                            <th>{{ commonTK.LastNameLabel | translate }}</th>
                                            <th>{{ commonTK.EmailLabel | translate }}</th>
                                            <th>{{ commonTK.PhoneLabel | translate }}</th>
                                            <th>{{ sharedTermsTK.Error | translate }}</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let control of form.controls; let i = index">
                                            <td>{{ control?.value?.username }}</td>
                                            <td>{{ control?.value?.firstName }}</td>
                                            <td>{{ control?.value?.lastName }}</td>
                                            <td>{{ control?.value?.email }}</td>
                                            <td>{{ control?.value?.phone }}</td>
                                            <td class="error-message">{{ uploadResult.errors[i].errorMessage }}</td>
                                            <td class="pad-right">
                                                <div class="row space-children">
                                                    <button type="button" class="uf-action tertiary small right"
                                                        (click)="editUser(i)"
                                                        [title]="sharedTermsTK.ActionEdit | translate">
                                                        <uf-icon name="edit"></uf-icon>
                                                    </button>
                                                    <button type="button" class="uf-action tertiary small"
                                                        (click)="removeUser(i)"
                                                        [title]="sharedTermsTK.ActionRemove | translate">
                                                        <uf-icon name="delete"></uf-icon>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-12 row">
                <button type="button" class="uf-button tertiary left" (click)="back()">
                    {{sharedTermsTK.ActionCancel | translate}}
                </button>
                <button type="button" class="uf-button primary" (click)="save()" *ngIf="form?.controls?.length">
                    {{sharedTermsTK.ActionCreate | translate}}
                </button>
            </div>
        </div>
    </div>