<form class="uf-grid uf-container-sm" (ngSubmit)="authorize()">

    <div class="col-12 row center-all justify-center">
        <h5 class="no-margin padded--horizontal">{{ tenantSettings?.name }}</h5>

        <div *ngIf="canChangeCompany">
            <button type="button" class="uf-action small tertiary right" (click)="changeTenant()"
                [title]="discoverTK.ActionChangeCompany | translate" [disabled]="inProgress">
                <uf-icon name="change"></uf-icon>
            </button>
        </div>

    </div>

    <uf-text class="col-12" [label]="commonTK.UsernameLabel | translate" name="username" autocomplete="username"
        [control]="formGroup.controls.username | asUfControl">
    </uf-text>

    <uf-password class="col-12" [label]="commonTK.PasswordLabel | translate" name="password" autocomplete="password"
        [showReveal]="true" [control]="formGroup.controls.password | asUfControl">
    </uf-password>

    <ng-template [ngIf]="rememberMeEnabled">

        <uf-checkbox class="col-12" [label]="discoverTK.LoginRemeberMeInputLabel | translate" name="rememberMe"
            [control]="formGroup.controls.rememberMe | asUfControl">
        </uf-checkbox>

    </ng-template>

    <div class="col-12 col center-all space-children">
        <button type="submit" class="uf-button primary" [disabled]="inProgress">
            {{ sharedTermsTK.ActionLogin | translate }}
        </button>
        <a class="uf-button tertiary" [routerLink]="['/login', 'request-password-change']">
            {{discoverTK.ActionRequestPasswordChange | translate}}
        </a>
    </div>

</form>