import { AfterViewInit, Component, HostBinding, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ContextProvider, FileUploader, Modal, ModalData, ModalRuntime, RuntimeDefinition, ToastService, UfControlArray, UfControlGroup, UfFormControl
} from '@unifii/library/common';
import { FormSettings } from '@unifii/library/smart-forms';
import { SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { FormData, generateUUID, TransitionTrigger } from '@unifii/sdk';

import { ShellFormService } from 'shell/form/shell-form.service';
import { ShellTranslationKey } from 'shell/shell.tk';


export interface FormTriggerData extends TransitionTrigger {
    parentBucket: string;
}

@Component({
    templateUrl: './form-trigger.html',
    providers: [ShellFormService],
    styleUrls: ['./form-trigger.less']
})
export class FormTriggerComponent implements Modal<FormTriggerData, boolean>, AfterViewInit {

    @HostBinding('class.ud-form-card') classes = true;
    @ViewChild(UfFormComponent, { static: false }) formComponent: UfFormComponent;

    readonly shellTk = ShellTranslationKey;

    definition: RuntimeDefinition;
    formData: FormData = { id: generateUUID() };
    ready: boolean;

    private parentUploader: FileUploader;
    private parentBucket: string;

    constructor(
        public runtime: ModalRuntime<FormTriggerData, boolean>,
        @Inject(ModalData) public data: FormTriggerData,
        private toastService: ToastService,
        private translate: TranslateService,
        private formService: ShellFormService,
        @Inject(FormSettings) private settings: FormSettings,
        @Inject(ContextProvider) private contextProvider: ContextProvider
    ) { }

    ngAfterViewInit() {
        this.trigger();
    }

    async trigger() {

        try {
            const definition = await this.formService.getFormDefinition(this.data.targetFormIdentifier);

            this.parentBucket = this.formService.bucket;
            this.formService.bucket = definition.bucket as string;

            this.parentUploader = this.settings.uploader;
            this.settings.uploader = this.formService.getFileUploader(this.formData.id as string);

            const context = this.contextProvider.get();
            const parentFormData = context.parentForm as FormData;
            const formData: FormData = {
                id: generateUUID(),
                _parent: {
                    bucket: this.data.parentBucket,
                    id: parentFormData.id as string,
                    seqId: parentFormData._seqId as string,
                    definitionIdentifier: parentFormData._definitionIdentifier as string
                }
            };

            this.definition = definition;
            this.formData = formData;
            this.ready = true;

            setTimeout(() => {

                const action = this.formComponent.workflow.getActionByName(this.data.targetFormAction);

                if (!action) {
                    this.notifyAndClose(this.translate.instant(ShellTranslationKey.FormTriggerErrorTransitionActionNotAvailable));
                    return;
                }

                const executed = this.formComponent.workflow.trigger(action);

                if (!executed) {
                    this.notifyAndClose(this.translate.instant(ShellTranslationKey.FormTriggerErrorTransitionNotExecuted));

                    const errors = [];
                    for (const control of this.controlIterable(this.formComponent.rootControl)) {
                        if (control.errors && control.errors.message) {
                            errors.push(control.errors);
                        }
                    }

                    for (const error of errors) {
                        console.log(`Validation error: "${error}"`);
                    }

                    return;
                }

            }, 500);

        } catch (error) {
            console.warn(`FormTriggerComponent - Form ${this.data.targetFormIdentifier} not found`, error);
            this.notifyAndClose(this.translate.instant(ShellTranslationKey.FormTriggerErrorFormNotFound));
        }
    }

    async save(args: SubmitArgs) {

        try {
            await this.formService.save(args.data, this.definition);
            this.notifyAndClose();
        } catch (error) {
            console.warn('FormTriggerComponent - save form error', error);
            this.notifyAndClose(this.translate.instant(ShellTranslationKey.FormTriggerErrorSaveFailed));
        }
    }

    private notifyAndClose(error?: string) {

        this.settings.uploader = this.parentUploader;
        this.formService.bucket = this.parentBucket;

        if (error) {
            console.warn('FormTriggerComponent - error', error);
            this.toastService.error(this.translate.instant(
                ShellTranslationKey.FormTriggerFeedbackError,
                { name: this.data.label, error })
            );
        } else {
            this.toastService.success(this.translate.instant(
                ShellTranslationKey.FormTriggerFeedbackSaved,
                { name: this.data.label }
            ));
        }

        this.runtime.close(error == null);
    }

    private *controlIterable(control: UfFormControl): Iterable<UfFormControl> {

        if (control instanceof UfControlGroup) {
            for (const key of Object.keys(control.controls)) {
                yield* this.controlIterable(control.controls[key] as UfFormControl);
            }
        }

        if (control instanceof UfControlArray) {
            for (const c of control.controls) {
                yield* this.controlIterable(c as UfFormControl);
            }
        }

        yield control;
    }

}
