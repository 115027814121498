import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@unifii/library/common';
import { ProjectInfo } from '@unifii/sdk';

import { ConfirmExtendedData, ConfirmExtendedModalComponent } from 'shell/common/modals/confirm-extended-modal.component';
import { Features } from 'shell/features';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';

import { DiscoverContext } from 'discover/discover-context';

import { Config } from 'config';

import { DiscoverTranslationKey } from './discover.tk';


/**
 * Responsible for decoding route from route params
 * and redirecting to intended route or redirecting to
 * user access route if needed
 */
@Injectable()
export class ExternalService {

    constructor(
        private context: DiscoverContext,
        @Inject(Config) private config: Config,
        private modalService: ModalService,
        private offlineManager: OfflineManager,
        private features: Features,
        private translate: TranslateService,
        @Inject(Authentication) private auth: Authentication,
    ) { }

    async validateTenant(tenant: string) {
        if (!this.isTenantValid(tenant)) {

            if (!await this.redirectToTenantConsent(tenant)) {
                throw new Error('User Cancelled');
            }

            return false;
        }

        return true;
    }

    async validateProject(projectId: string) {
        if (!this.isValidProject(projectId as string)) {

            if (!await this.redirectToProjectConsent(projectId as string)) {
                throw new Error('User Cancelled');
            }

            return false;
        }

        return true;
    }

    selectProject(projectId: string) {
        const project: ProjectInfo | undefined = (this.auth.allowedProjects || []).find(p => p.id === projectId);

        if (!project) {
            throw new Error('Invalid Project');
        }

        this.context.project = project;

        if (this.features.offline) {
            // Close previous project DB reference
            this.offlineManager.projectChanged();
        }
    }

    private isTenantValid(tenant: string): boolean {
        return this.config.unifii.tenant != null && this.config.unifii.tenant === tenant;
    }

    private isValidProject(projectId: string): boolean {
        return this.context.project != null && this.context.project.id === projectId;
    }

    private redirectToTenantConsent(name: string): Promise<boolean> {

        const title = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongTenantTitle);
        const content = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongTenantMessage, { name });

        return this.modalService.openFit<ConfirmExtendedData, boolean>(ConfirmExtendedModalComponent, { title, content });
    }

    private async redirectToProjectConsent(projectId: string): Promise<boolean> {

        const project: ProjectInfo | undefined = (this.auth.allowedProjects || []).find(p => p.id === projectId);
        const name = project ? project.name : projectId;

        const title = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongProjectTitle);
        const content = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongProjectMessage, { name });

        return this.modalService.openFit<ConfirmExtendedData, boolean>(ConfirmExtendedModalComponent, { title, content });

    }


}
