import { Subscription } from 'rxjs';

import { Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CommonTranslationKey, ModalService, SharedTermsTranslationKey, ToastService, UfControl, UfControlArray, UfControlGroup
} from '@unifii/library/common';
import { Claim, Dictionary, FieldWidth, PermissionAction, UserCreate, UserCreateResult, UserInfo, UserInvite, UsersClient } from '@unifii/sdk';
import {
    BasicUserCreateFormController, BasicUserInviteFormController, ClaimSourceType, FormPermissionController, UserFieldLabelService, UserInfoKey,
    UserInputControlKey, UserProvisioningTranslationKey, UsersCreateFormController, UsersInviteFormController
} from '@unifii/user-provisioning';

import { EditedData } from 'shell/services/unsaved-data-guard';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UserAddComponent } from 'discover/user-management/user-add.component';
import {
    UserFormPermissionConfig, UserFormPermissionController, UserFormResourceType
} from 'discover/user-management/user-form-permission-controller';
import { AddUserModalInfo, UserInputType } from 'discover/user-management/user-types';


@Component({
    selector: 'ud-user-input',
    templateUrl: './user-input.html',
    styleUrls: ['user-management.less']
})
export class UserInputComponent implements EditedData, OnInit, OnDestroy {

    edited: boolean;

    protected readonly commonTK = CommonTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly userProvisioningTK = UserProvisioningTranslationKey;
    protected readonly fieldWidth = FieldWidth;
    protected readonly claimSource = ClaimSourceType.User;
    protected readonly userInputType = UserInputType;
    protected readonly controlKeys = UserInputControlKey;
    protected readonly userInfoKeys = UserInfoKey;
    protected readonly formController: UsersCreateFormController | UsersInviteFormController;
    protected readonly userController: BasicUserCreateFormController | BasicUserInviteFormController;

    protected busy: boolean;
    protected uploadResult: any;
    protected inputType: UserInputType;
    protected abortController: AbortController = new AbortController();
    protected signal: AbortSignal = this.abortController.signal;
    protected form: UfControlGroup;
    protected labelDictionary: Dictionary<string>;
    protected allowedRoles: string[] | undefined;

    private subscriptions = new Subscription();

    constructor(
        public context: DiscoverContext,
        private router: Router,
        private route: ActivatedRoute,
        private usersClient: UsersClient,
        private modalService: ModalService,
        private toastService: ToastService,
        private translate: TranslateService,
        private userFieldLabelService: UserFieldLabelService,
        private inviteFormController: UsersInviteFormController,
        private createFormController: UsersCreateFormController,
        private basicInviteFormController: BasicUserInviteFormController,
        private basicCreateFormController: BasicUserCreateFormController,
        @Inject(FormPermissionController) private permissionCtrl: UserFormPermissionController,
    ) {
        const userFormPermissionConfig = inject(UserFormPermissionConfig);
        userFormPermissionConfig.action = this.route.snapshot.data.inputType === UserInputType.Invite ? PermissionAction.Invite : PermissionAction.Add;
        userFormPermissionConfig.resourceType = UserFormResourceType.Users;

        this.inputType = this.route.snapshot.data.inputType;
        this.labelDictionary = this.userFieldLabelService.labelDictionary;

        if (this.inputType === UserInputType.Invite) {
            this.formController = this.inviteFormController;
            this.userController = this.basicInviteFormController;
        } else {
            this.formController = this.createFormController;
            this.userController = this.basicCreateFormController;
        }
    }

    get saveButtonLabel(): string {
        return this.inputType === UserInputType.Invite ? this.translate.instant(SharedTermsTranslationKey.ActionInvite) : this.translate.instant(SharedTermsTranslationKey.ActionCreate);
    }

    get usersControl(): UfControlArray {
        return this.form.get(UserInputControlKey.Users) as UfControlArray;
    }

    get unitsControl(): UfControl {
        return this.form.get(UserInfoKey.Units) as UfControl;
    }

    get unitPathsControl(): UfControl {
        return this.form.get(UserInfoKey.UnitPaths) as UfControl;
    }

    get claimsControl(): UfControlGroup {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup;
    }

    get rolesControl(): UfControl {
        return this.form.get(UserInfoKey.Roles) as UfControl;
    }

    get companyControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Company) as UfControl | undefined;
    }

    get changePasswordOnNextLoginControl(): UfControl {
        return this.form.get(UserInfoKey.ChangePasswordOnNextLogin) as UfControl;
    }

    async ngOnInit() {
        this.form = await this.formController.buildRoot();
        this.allowedRoles = this.permissionCtrl.allowedRoles();
        this.subscriptions.add(this.form.valueChanges.subscribe(() => this.edited = true));
    }

    ngOnDestroy() {
        this.abortController.abort();
        this.subscriptions.unsubscribe();
    }

    async save() {
        if (this.busy) {
            return;
        }

        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        const users = await this.formController.toDataModel(this.form);

        let responseData: UserCreateResult = {
            successCount: 0,
            errors: []
        };
        try {
            this.busy = true;
            if (this.inputType === UserInputType.Create) {
                (users as UserCreate[]).forEach(user => user.changePasswordOnNextLogin = this.changePasswordOnNextLoginControl.value);
                responseData = await this.usersClient.bulkAdd(users as UserInfo[], { signal: this.signal});
            } else if (this.inputType === UserInputType.Invite) {
                responseData = await this.usersClient.bulkInvite(users as UserInvite[], {signal: this.signal});
            }

            if (!responseData.errors || !responseData.errors.length) {
                this.edited = false;
                if (this.inputType === UserInputType.Create) {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersCreateSuccessToast, { count: responseData.successCount }));
                } else {
                    this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersInviteSuccessToast, { count: responseData.successCount }));
                }

                this.back();
            } else {
                this.uploadResult = responseData;

                if (this.inputType === UserInputType.Create) {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersCreateFailedToast, { count: responseData.successCount, total: users.length }));
                } else {
                    this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersInviteFailedToast, { count: responseData.successCount, total: users.length }));
                }
            }
        } catch (e) {
            console.error(e);
            this.toastService.error(this.translate.instant(SharedTermsTranslationKey.ErrorGenericMessage));
        }
    }

    back(canLeave?: boolean) {
        this.edited = canLeave ? false : this.edited;
        this.router.navigate(['../../'], { relativeTo: this.route });
    }

    async addUser() {
        const addModalInfo: AddUserModalInfo = {
            control: this.userController.buildRoot(),
            inputType: this.inputType
        };

        const userControl = await this.modalService.openMedium<AddUserModalInfo, UfControlGroup>(UserAddComponent, addModalInfo);

        if (userControl) {
            this.usersControl.push(userControl);
        }
        this.usersControl.updateValueAndValidity();
    }

    async editUser(index: number) {
        const control = this.usersControl.controls[index] as UfControlGroup;
        const savedData = this.userController.toDataModel(control);

        const addModalInfo: AddUserModalInfo = {
            control: this.userController.buildRoot(savedData),
            inputType: this.inputType
        };

        const userControl = await this.modalService.openMedium<AddUserModalInfo, UfControlGroup>(UserAddComponent, addModalInfo);

        if (userControl) {
            this.usersControl.controls[index] = userControl;
        }
        this.usersControl.updateValueAndValidity();
    }

    removeUser(i: number) {
        this.usersControl.removeAt(i);
        this.usersControl.updateValueAndValidity();
    }

    claimsChange(claims: Claim[]) {
        this.claimsControl.setValue(claims);
        this.edited = true;
    }

}
