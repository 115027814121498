import { Component } from '@angular/core';


// TODO check if it's available in the library
@Component({
    selector: 'us-loading-spinner',
    template: '<div class="spinner"><div class="dot1"></div><div class="dot2"></div></div>',
    styleUrls: ['./loading-spinner.less']
})
export class LoadingSpinnerComponent {
}
