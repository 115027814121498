import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@unifii/library/common';
import { FormSettings } from '@unifii/library/smart-forms';
import { ContentLinkComponent } from '@unifii/library/smart-forms/input';
import { PublishedContent } from '@unifii/sdk';


@Component({
    templateUrl: './content-link.html',
    styleUrls: ['./content-link.less']
})
export class DiscoverContentLinkComponent extends ContentLinkComponent {

    constructor(
        modalService: ModalService,
        @Inject(FormSettings) settings: FormSettings,
        @Inject(PublishedContent) publishedContent: PublishedContent,
        translate: TranslateService
    ) {
        super(modalService, settings, publishedContent, translate);
    }

}
