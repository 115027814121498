import { from, Observable } from 'rxjs';

import { Inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { loadDictionary, TranslationEntry, WindowWrapper } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';


export class ShellTranslateLoader implements TranslateLoader {

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private translationsUrl: string,
        private entries: TranslationEntry[]
    ) { }

    getTranslation(lang: string): Observable<Dictionary<string>> {
        const urls = this.window.cordova ? [this.translationsUrl, 'assets/i18n'] : [this.translationsUrl];
        return from(loadDictionary(lang, urls, this.entries));
    }
}
