import { Error as sdkError, ErrorType } from '@unifii/sdk';


export class AppError implements sdkError {

    type: ErrorType;
    message: string;
    code?: string;
    data?: any;
    _clientError?: boolean;

    constructor(message: string, type: ErrorType = ErrorType.Unknown, code?: string, data?: any, clientError?: boolean) {
        this.message = message;
        this.type = type;
        this.code = code;
        this.data = data;
        this._clientError = clientError;
    }
}

export class NullReferenceError extends AppError {

    constructor(message: string, code?: string) {
        super(message, ErrorType.NullReference, code);
    }
}

export class NotFoundError extends AppError {

    constructor(message: string, code?: string) {
        super(message, ErrorType.NotFound, code);
    }
}

export class LoadError extends AppError {

    constructor(message: string, code?: string) {
        super(message, undefined, code);
    }
}

export class SaveError extends AppError {

    constructor(message: string, code?: string) {
        super(message, undefined, code);
    }
}