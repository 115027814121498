import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics } from '@capacitor/haptics';

import { DeviceService } from './device.service';


@Injectable()
export class VibrateService {

    constructor(
        private device: DeviceService
    ) {}

    vibrate(ms: number) {

        if (!( this.device.isNative() && Capacitor.isPluginAvailable('Haptics'))) {
            return;
        }

        Haptics.vibrate({ duration: ms });
    }
}
