import { FeatureFlagKey, PermissionAction, Table } from '@unifii/sdk';


export interface TableNodeInfo {
    table: Table;
    pageSize: number;
}

export interface ComponentTitleRouteData {
    titleTranslationKey?: string;
    titleParams?: Record<string, any>;
}

export enum TableDisplayType {
    DashboardTable = 'DashboardTable',
    FormDataTable = 'FormDataTable',
    DetailModuleTable = 'DetailModuleTable'
}

export enum ResourceType {
    Resources = 'Resources',
    Roles = 'Roles',
    Projects = 'Projects',
    Project = 'Project',
    Structure = 'Structure',
    Versions = 'Versions',
    Version = 'Version',
    VersionArtifacts = 'Version Artifacts',
    VersionArtifact = 'Version Artifact',
    VersionArtifactDiff = 'Version Artifact Diff',
    LatestVersion = 'Latest Version',
    Collections = 'Collections',
    Collection = 'Collection',
    CollectionItems = 'Collection Items',
    CollectionItem = 'Collection Item',
    Views = 'Views',
    View = 'View',
    ViewDefinition = 'View Definition',
    Pages = 'Pages',
    Page = 'Page',
    Forms = 'Forms',
    Form = 'Form',
    FormVersions = 'Form Versions',
    FormVersion = 'Form Version',
    FormDataRepositories = 'Form Data Repositories',
    FormDataRepository = 'Form Data Repository',
    FormDataRepositoryDocuments = 'Form Data Repository Documents',
    FormDataRepositoryDocument = 'Form Data Repository Document',
    FormDataTransactionLog = 'Form Data Transaction Log',
    FormDataTranslationLogDocument = 'Form Data Transaction Log Document',
    Schema = 'Schema',
    Revisions = 'Revisions',
    Revision = 'Revision',
    Files = 'Files',
    File = 'File',
    ExternalDataSources = 'External Data Sources',
    ExternalDataSource = 'External Data Source',
    Tables = 'Tables',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Table = 'Table',
    Devices = 'Devices',
    Device = 'Device',
    Users = 'Users',
    User = 'User',
    UserToken = 'User Token',
    UserFiles = 'User Files',
    UserFile = 'User File',
    UserName = 'User Name',
    UserPermissions = 'Permissions',
    TicketProviders = 'Ticket Providers',
    TicketProviderIntegration = 'Ticket Provider Integration',
    TicketProvider = 'Ticket Provider',
    Companies = 'Companies',
    Company = 'Company',
    UserClaims = 'User Claims',
    UserClaim = 'User Claim',
    CompanyClaims = 'Company Claims',
    CompanyClaim = 'Company Claim',
    Me = 'Me',
    Units = 'Units',
    Unit = 'Unit'
}

export interface Resource {
    name: ResourceType;
    segment: string;
    actions?: { name: PermissionAction; description: string }[];
    allowsCondition?: boolean;
    children?: Resource[];
}

export type ShellFeatureFlagKey = FeatureFlagKey | 'desktopPin' | 'translations';