import { Component } from '@angular/core';
import { TableCell } from '@unifii/library/common';
import { ContentLinkFormData } from '@unifii/sdk';


@Component({
    templateUrl: './content-link-cell.html',
    styleUrls: ['./content-link-cell.less']
})
export class ContentLinkCellComponent implements TableCell {
    value: ContentLinkFormData | undefined;
}