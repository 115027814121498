<div class="uf-card">
    <div class="uf-app-bar">
        <button (click)="close()" ufAutofocus [title]="sharedTermsTK.ActionClose | translate" class="uf-action tertiary"
            type="button">
            <uf-icon name="close"></uf-icon>
        </button>
        <h3>{{commonTK.ScanBarcodeLabel | translate }}</h3>
    </div>
    <div class="col">
        <div *ngIf="loading" class="pad center-all">
            <uf-spinner type="dots"></uf-spinner>
        </div>
        <video #video [class.hidden]="loading"></video>
        <canvas #canvas></canvas>
    </div>
</div>