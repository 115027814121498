<ud-user-access-box [backButtonRoute]="backButtonRoute">
    <ng-container content>

        <uf-message class="error gap-sm-bottom gap-sm-top" [style.visibility]="error ? 'visible' : 'hidden'"
            icon="errorSolid">
            <p class="small">{{ error?.message }}</p>
        </uf-message>

        <p *ngIf="message" [innerHTML]="message"></p>

        <router-outlet></router-outlet>

    </ng-container>
</ud-user-access-box>
