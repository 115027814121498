import { Component, HostListener, Inject, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalRuntime, ModalService, SharedTermsTranslationKey } from '@unifii/library/common';
import { UserInfo } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { PasswordCheckComponent } from 'discover/pin/password-check.component';
import { pinHide, pinShake, pinShow } from 'discover/pin/pin-animations';
import { PinSetupComponent } from 'discover/pin/pin-setup.component';
import { PinModalData, PinService, PinState } from 'discover/pin/pin-types';

import { DeviceService } from 'capacitor/device.service';
import { FingerPrintService } from 'capacitor/finger-print.service';
import { VibrateService } from 'capacitor/vibrate.service';

import { Config } from 'config';


@Component({
    selector: 'ud-pin-check',
    templateUrl: './pin-check.html',
    styleUrls: ['./pin-screen.less'],
    animations: [pinShow, pinHide, pinShake],
})

export class PinCheckComponent implements Modal<void, PinState> {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    error: string | null;
    verified: boolean;
    blink = true;
    pin = '';

    private inputValid = false;
    private active = true;

    constructor(
        @Inject(Authentication) private auth: Authentication,
        @Inject(PinService) private pinService: PinService,
        @Inject(Config) private config: Config,
        private modalService: ModalService,
        private fingerPrintService: FingerPrintService,
        public runtime: ModalRuntime<void, PinState>,
        private vibrateService: VibrateService,
        private zone: NgZone,
        private device: DeviceService,
        private translate: TranslateService
    ) {

        const pinResult = this.pinService.getPinDetails();

        if (this.device.isCapacitorIOS()) {
            this.blink = false;
        }

        if (pinResult.fingerprint) {
            this.checkFingerPrint();
            return;
        }

    }

    // used to allow active status on touch events
    @HostListener('touchstart', ['$event'])
    touchEvent() { }

    @HostListener('touchend', ['$event'])
    touchEndEvent() {
        if (this.device.isCapacitorIOS()) {
            this.blink = true;
        }
    }

    // desktop keyboard typing pin
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {

        this.zone.run(() => {

            if (Number(event.key) >= 0 && Number(event.key) <= 9 && this.active) {
                this.add(Number(event.key));
                return;
            }

            if (event.key === 'Backspace' && this.active) {
                this.remove();
            }
        });

    }

    async checkPin(): Promise<void> {

        this.error = null;
        this.verified = await this.pinService.verifyPin(this.pin);

        if (!this.verified) {
            this.pin = '';
            this.inputValid = false;
            this.error = this.translate.instant(DiscoverTranslationKey.PINCheckErrorIncorrectPIN);
            this.vibrateService.vibrate(500);
            return;
        }

        this.runtime.close(PinState.Verified);
    }

    add(num: number) {

        // reset error
        this.error = null;

        if (this.pin.length >= 4) {
            return;
        }

        this.pin = this.pin + String(num);
    }

    // fired by UI after input
    numberAdded() {

        if (this.pin.length < 4 || this.inputValid) {
            return;
        }

        this.inputValid = true;
        this.checkPin();
    }

    remove() {
        if (!this.pin) {
            return;
        }

        this.pin = this.pin.substring(0, this.pin.length - 1);
    }

    async logout() {

        // get values before logout, because they will be gone
        const tenant = this.config.unifii.tenant as string;
        const userId = (this.auth.userInfo as UserInfo).id as string;

        const done = await this.auth.logout({ askConfirmation: true });

        if (!done) {
            return;
        }

        this.pinService.clearPin(tenant, userId);

        this.runtime.close(PinState.Logout);
    }

    async forgotPin() {

        this.active = false;

        const result = await this.modalService.openFullScreen<PinModalData, PinState>(
            PasswordCheckComponent,
            { closeAllowed: true }
        );

        switch (result) {
            case PinState.Verified:
                await this.resetPin();
                break;
            case PinState.Logout:
                this.runtime.close(PinState.Logout);
                break;
            default:
                this.active = true;
                break;
        }

    }

    private async resetPin(): Promise<void> {

        const result = await this.modalService.openFullScreen<PinModalData, PinState>(
            PinSetupComponent,
            { message: 'Reset PIN', closeAllowed: true }
        );

        switch (result) {
            case PinState.Verified:
                this.runtime.close(PinState.Verified);
                break;
            case PinState.Logout:
                this.runtime.close(PinState.Logout);
                break;
            default:
                this.active = true;
                break;
        }
    }

    private async checkFingerPrint(): Promise<void> {

        try {

            const type = await this.fingerPrintService.isAvailable();

            if (!type) {
                return;
            }

            try {
                await this.fingerPrintService.show(this.pinService.fingerprintConfig);
                // fake pin being typed in UI
                this.pin = '****';
                this.verified = true;
                this.runtime.close(PinState.Verified);

            } catch (reason) {

                if (type === 'face') {
                    this.error = this.translate.instant(DiscoverTranslationKey.PINCheckErrorFaceIDFail);
                    return;
                }

                this.error = this.translate.instant(DiscoverTranslationKey.PINCheckErrorFingerprintFail);
            }

        } catch (error) {
            console.error(error);
        }

    }

}
