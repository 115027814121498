import { Inject, Injectable } from '@angular/core';
import { ContextProvider } from '@unifii/library/common';
import { Company, PermissionAction } from '@unifii/sdk';
import { FormPermissionController } from '@unifii/user-provisioning';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';


@Injectable()
export class CompanyFormPermissionController implements FormPermissionController<Company> {

    constructor(
        @Inject(Authentication) private auth: Authentication,
        private contextProvider: ContextProvider
    ) { }

    canListClaims(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted;
    }

    inputEnabled(key: string, company?: Company): boolean {
        // Action Add
        if (company == null) {
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.Add, key).granted;
        }
        // Update
        return this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(company.id), PermissionAction.Update, company, this.contextProvider.get(), key).granted;
    }

}
