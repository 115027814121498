import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CommonTranslationKey, fieldIterator, RuntimeDefinition, RuntimeDefinitionAdapter, RuntimeField, SharedTermsTranslationKey, ToastService
} from '@unifii/library/common';
import { FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { SubmitArgs, UfFormComponent } from '@unifii/library/smart-forms/input';
import { DataSourceType, Dictionary, Error, FormData, generateUUID, PublishedContent } from '@unifii/sdk';

import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { FormContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { SaveOutput, ShellFormService } from 'shell/form/shell-form.service';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';

import { BucketIdentifier, FormIdentifier, LocationFieldIdentifier } from 'discover/components/sign-in/sign-in-constants';
import { SignInHistoryComponent } from 'discover/components/sign-in/sign-in-history.component';


@Component({
    templateUrl: './sign-in.html',
    styleUrls: ['./sign-in.less']
})
export class SignInComponent implements OnInit, OnDestroy, EditedData, FormContent {

    readonly sharedTK = SharedTermsTranslationKey;
    readonly shellTK = ShellTranslationKey;
    readonly commonTK = CommonTranslationKey;

    busy: boolean;
    error: Error;
    edited: boolean;

    definition: RuntimeDefinition;
    formData: FormData;
    formConfig: FormConfiguration;
    title: string;

    private _formComponent: UfFormComponent;
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private toastService: ToastService,
        private translate: TranslateService,
        private errorService: ErrorService,
        private router: Router,
        private formService: ShellFormService,
        private content: PublishedContent,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver,
        @Inject(FormSettings) private settings: FormSettings,
        @Optional() private parent: SignInHistoryComponent
    ) {
        this.formService.bucket = BucketIdentifier;
        this.formConfig = {
            optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel)
        };
    }

    async ngOnInit() {
        const { id, location } = this.route.snapshot.params;

        try {
            await this.configureFormContent(id);

            if (location) {
                const locationData = await this.getLocation(location, this.definition);
                this.formData.location = locationData;
            }

            this.settings.uploader = this.formService.getFileUploader(this.formData.id as string);
        } catch (e) {
            const loadError = this.errorService.createLoadError(FormIdentifier, e);
            this.error = this.errorService.mergeError(e, loadError.message);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    @ViewChild(UfFormComponent, { static: false }) set formComponent(v: UfFormComponent) {

        if (v == null || this.formComponent) {
            return;
        }
        this._formComponent = v;

        this.subscriptions.add(v.rootControl.valueChanges.subscribe(() => {
            if (this._formComponent.rootControl.dirty) {
                this.edited = true;
            }
        }));
    }

    get formComponent(): UfFormComponent {
        return this._formComponent;
    }

    back() {
        if (this.parent != null) {
            this.router.navigate(['..'], { relativeTo: this.route });
        } else {
            this.router.navigate(['/']);
        }
    }

    async save(args: SubmitArgs) {

        if (this.busy) {
            return;
        }

        this.busy = true;
        let savedOutput: SaveOutput;

        try {
            savedOutput = await this.formService.save(args.data, this.definition);
            args.done(savedOutput.data);

            this.edited = false;
            this.busy = false;
            this.toastService.success(this.translate.instant(ShellTranslationKey.FormFeedbackSaved));

            if (this.parent != null) {
                await this.parent.update();
            }
            this.back();

        } catch (error) {
            this.error = this.errorService.createSaveError('form', error);
        } finally {
            this.busy = false;
        }
    }

    private async configureFormContent(formDataId: string) {

        if (formDataId === 'new') {
            this.definition = await this.dataResolver.getForm(FormIdentifier);
            this.formData = { id: generateUUID() };
        } else {
            const content = await this.dataResolver.getFormData(BucketIdentifier, formDataId);
            this.formData = content.formData;
            this.definition = content.definition;
        }
    }

    private async getLocation(id: string, definition: RuntimeDefinition): Promise<Dictionary<any> | undefined> {

        const locationField = this.getLocationField(definition);

        if (locationField?.sourceConfig?.type !== DataSourceType.Collection) {
            return;
        }

        const collectionIdentifier = locationField.sourceConfig.id;
        const mappingsTo = locationField.sourceConfig.mappingsTo;

        const result: Dictionary<any> = {};
        try {
            const locationItem = await this.content.getCollectionItem(collectionIdentifier, id);
            Object.keys(mappingsTo).forEach(key => {
                result[key] = locationItem[mappingsTo[key].from];
            });
        } catch(e) {
            return;
        }

        return result;
    }

    private getLocationField(definition: RuntimeDefinition): RuntimeField | undefined {
        for (const { field } of fieldIterator(definition.fields)) {
            if (field.identifier === LocationFieldIdentifier) {
                return field;
            }
        }
        return;
    }
}
