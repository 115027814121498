<uf-panel class="container" ufMasterDetail>
    <div *ngIf="!busy" class="uf-container-md pad-lg-top pad-sides">

        <a *ngIf="activeSignIn" class="uf-card centered gap-lg-bottom" [routerLink]="[activeSignIn.id]">
            <div class="card-info">
                <uf-icon name="sign-out"></uf-icon> 
            </div>
            <div class="pad">
                <h4 class="gap-none">{{discoverTK.SignInSignOutButtonLabel | translate }}</h4>
                <p class="small gap-none-bottom">
                    <b>{{ activeSignIn.location }}</b><br>
                    {{ discoverTK.SignInSignedInLabel | translate }}: {{ activeSignIn.signIn | momentDate:'EEEE d MMM yyyy' }}<br>
                    {{ activeSignIn.signIn | momentDate:'h:mm a' }}     
                </p>
            </div>
        </a>
        <a class="uf-card centered" (click)="signIn()">
            <div class="card-info" *ngIf="activeSignIn == null">
                <uf-icon name="sign-in" class="large"></uf-icon> 
            </div>
            <div class="pad">
                <h4 class="gap-none">{{discoverTK.SignInSignInButtonLabel | translate }}</h4>
            </div>
        </a>
        <!-- spacer -->
        <div class="pad-top"></div>

        <uf-message *ngIf="error" class="error" icon="error" [content]="error.message"></uf-message>

        <h2 *ngIf="history.length">{{ discoverTK.SignInHistoryHeading | translate  }} </h2>

        <a class="uf-card row gap-bottom pad center-all" *ngFor="let item of history" [routerLink]="[item.id]">
            <div class="uf-avatar gap-right" [class.success]="item.signOut" [class.warning]="!item.signOut"> 
                <uf-icon [name]="item.signOut ? 'tick' : 'warning'"></uf-icon> 
            </div>
            <div class="grow">
                <p class="gap-none small">
                    <ng-container *ngIf="item.location">
                        <b>{{ item.location }}</b><br>
                    </ng-container>
                    <ng-container *ngIf="!item.multiday; else multiday">
                        {{ item.signIn  | momentDate:'EEEE, d MMM yyyy' }}<br>
                        {{ item.signIn | momentDate:'h:mm a' }} - <span *ngIf="item.signOut; else noSignOutMessage">{{ item.signOut | momentDate:'h:mm a' }}</span>
                    </ng-container>
                    
                    <ng-template #noSignOutMessage>
                        {{ discoverTK.SignInSignOutPendingMessage | translate  }}
                    </ng-template>
                    <ng-template #multiday>
                        {{ item.signIn  | momentDate:'EEEE, d MMM yyyy, h:mm a' }}<br>
                        {{ item.signOut  | momentDate:'EEEE, d MMM yyyy, h:mm a' }}<br>
                    </ng-template>
                </p>
            </div>                            
        </a>


    </div>
</uf-panel>
<router-outlet></router-outlet>