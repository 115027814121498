import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ContextProvider } from '@unifii/library/common';
import { getUserStatus, PermissionAction, UserInfo, UserStatus } from '@unifii/sdk';
import { FormPermissionController, UserInfoKey } from '@unifii/user-provisioning';

import { Authentication, PermissionGrantedResult } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

import { Config } from 'config';


export enum UserFormResourceType {
    Me, Users
}

export interface UserFormPermissionConfig {
    resourceType: UserFormResourceType;
    action: PermissionAction.Add | PermissionAction.Invite | PermissionAction.Update;
}

export const UserFormPermissionConfig = new InjectionToken<UserFormPermissionConfig>('UserFormPermissionConfig');

/**
 * UserFieldPermission
 */
@Injectable()
export class UserFormPermissionController implements FormPermissionController<UserInfo> {

    // type must be of type string also to handle claim permissions
    private requiredInputFields: (UserInfoKey | string)[] = [];

    constructor(
        @Inject(UserFormPermissionConfig) private config: UserFormPermissionConfig,
        @Inject(Authentication) private auth: Authentication,
        @Inject(Config) private discoverConfig: Config,
        private contextProvider: ContextProvider
    ) {
        if (this.discoverConfig.unifii.tenantSettings?.isUserEmailRequired === true) {
            this.requiredInputFields.push(UserInfoKey.Email);
        }
    }

    canUpdateUser(userInfo: UserInfo): boolean {
        return this.getAddInviteUpdateUserPermissionResult(userInfo).granted;
    }

    /** In order to verify rights to update user claims, following condition must be matched:
     * - read claims configuration
     * - update the user
     * - no fields limitation or at least one claims.xxxx in the fields limitation list
     */
    canUpdateClaims(userInfo: UserInfo): boolean {

        if (!this.canListClaims()) {
            return false;
        }

        const canUpdate = this.getAddInviteUpdateUserPermissionResult(userInfo);

        if (!canUpdate.granted) {
            return false;
        }

        if (!canUpdate.fields?.length) {
            return true;
        }

        return canUpdate.fields.find(f => f.startsWith('claims.')) != null;
    }

    /** In order to verify rights to update user roles, following condition must be matched:
     * - list roles
     * - update the user
     * - no fields limitation or at least 'roles' or one of roles.xxxx in the fields limitation list
     */
    canUpdateRoles(userInfo: UserInfo): boolean {

        if (!this.canListRoles()) {
            return false;
        }

        const canUpdateUser = this.getAddInviteUpdateUserPermissionResult(userInfo);

        if (!canUpdateUser.granted) {
            return false;
        }

        if (!canUpdateUser.fields?.length) {
            return true;
        }

        return canUpdateUser.fields.find(f => f === 'roles' || f.startsWith('roles.')) != null;
    }

    /** @returns undefined when all roles are allowed, otherwise a specific array of allowed roles and [] means no roles allowed */
    allowedRoles(userInfo?: UserInfo): string[] | undefined {

        const canUpdateUser = this.getAddInviteUpdateUserPermissionResult(userInfo);

        if (!canUpdateUser.granted) {
            return [];
        }

        if (!canUpdateUser.fields?.length || canUpdateUser.fields.includes('roles')) {
            return;
        }

        return canUpdateUser.fields.filter(f => f.startsWith('roles.')).map(r => r.substring('roles.'.length));
    }

    // type must be of type string also to handle claim permissions
    inputEnabled(key: UserInfoKey | string, userInfo?: UserInfo): boolean {
        if (key === UserInfoKey.Units && !this.canListHierarchies()) {
            return false;
        }

        if (this.config.resourceType === UserFormResourceType.Me) {
            // User can always update their own password
            if (key === UserInfoKey.Password) {
                return true;
            }
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), this.config.action, key).granted;
        }

        if (userInfo?.id == null) {
            // Add | Invite
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), this.config.action, key).granted;
        }
        // Update
        return this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id as string)), this.config.action, userInfo, this.contextProvider.get(), key).granted;
    }

    canListUsers(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted;
    }

    canListRoles(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getRolesPath(), PermissionAction.List).granted;
    }

    canListClaims(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted;
    }

    canListCompanies(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.List).granted;
    }

    canListHierarchies(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getHierarchiesPath(), PermissionAction.List).granted;
    }

    canInvite(): boolean {
        if (this.config.resourceType === UserFormResourceType.Me) {
            return false;
        }
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted;
    }

    canDelete(userInfo: UserInfo): boolean {
        if (this.config.resourceType === UserFormResourceType.Me || userInfo?.id == null) {
            return false;
        }
        const status = getUserStatus(userInfo);
        return status === UserStatus.Pending && this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+userInfo.id), PermissionAction.Delete, userInfo, this.contextProvider.get()).granted;
    }

    requiredInputField(key: UserInfoKey | string): boolean {
        return this.requiredInputFields.includes(key);
    }

    /** Execute the correct permission check based on
     *
     * @param userInfo to Update
     * @field config.action configured
     */
    private getAddInviteUpdateUserPermissionResult(userInfo?: UserInfo): PermissionGrantedResult {
        if (this.config.resourceType === UserFormResourceType.Me) {
            return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), this.config.action);
        }

        if (userInfo?.id != null) {
            // With a provided userInfo this action is an Update, path is users/userInfo.id
            return this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id)), this.config.action, userInfo, this.contextProvider.get());
        }

        // Without a provided userInfo this action is an Add/Invite, path is users
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), this.config.action);
    }
}
