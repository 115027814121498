import { format, sub, subDays } from 'date-fns';
import { Subscription } from 'rxjs';

import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    FilterEntry, FilterEntryAdapter, FilterManager, FilterSerializer, FilterValue, FnsDate, HierarchyUnitProvider, SharedTermsTranslationKey,
    SortStatus, WindowWrapper
} from '@unifii/library/common';
import { Dictionary, Option } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';
import { TableSearchMinLength } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ReportFilterSerialzier } from 'discover/reports/report-filter-serializer';
import { cleanObj, getEndOf, getStartOf } from 'discover/reports/report-functions';
import { ReportConfig, ReportCustomFilerConfig, ReportDateFilterConfig, ReportService } from 'discover/reports/report-service';
import { ReportComponent } from 'discover/reports/report.component';


export enum IncrementOption {
    Daily = 'daily',
    Weekly = 'weekly',
    Monthly = 'monthly'
}

@Component({
    templateUrl: './report-page.html',
    providers: [ReportService]
})
export class ReportPageComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChildren(ReportComponent) reportComponents: QueryList<ReportComponent>;

    readonly shellTK = ShellTranslationKey;
    readonly incrementOptions: Option[] = [
        { identifier: IncrementOption.Daily, name: this.translate.instant(ShellTranslationKey.ReportIncrementDaysLabel) },
        { identifier: IncrementOption.Weekly, name: this.translate.instant(ShellTranslationKey.ReportIncrementWeeksLabel) },
        { identifier: IncrementOption.Monthly, name: this.translate.instant(ShellTranslationKey.ReportIncrementMonthsLabel) }
    ];

    reportConfigs: ReportConfig[] = [];

    showFilters = false;
    table = false;
    filterManager: FilterManager<FilterValue, FilterEntry>;
    filterValues: Dictionary<FilterValue>;
    dateFilterValues: Dictionary<FilterValue>;
    currentFilterValues: Dictionary<FilterValue>;
    currentDateFilterValues: Dictionary<FilterValue>;
    firstEntry = true;
    sort: SortStatus | null;
    dateFilterConfigs: ReportDateFilterConfig;

    start: string;
    end: string;
    defaultStart: string;
    defaultEnd: string;
    interval: string = IncrementOption.Daily;

    error: any;

    dateIntervalPresets: { label: string; start: string; end: string }[] = [];

    private reportIds: string[];
    private subscriptions = new Subscription();

    constructor(
        @Inject(WindowWrapper) private window: Window,
        private service: ReportService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        private nav: NavigationService,
        @Inject(HierarchyUnitProvider) hierarchyUnitProvider: HierarchyUnitProvider,
        @Inject(FilterEntryAdapter) private filterEntryAdapter: FilterEntryAdapter,
        @Inject(FilterSerializer) filterSerializer: FilterSerializer<FilterValue, FilterEntry>,
        private reportService: ReportService
    ) {
        const node = this.nav.current;
        if (node && node.tags && node.tags.length) {
            this.reportIds = node.tags.filter(tag => tag !== 'custom-report');
        } else {
            this.error = { message: this.translate.instant(DiscoverTranslationKey.ReportErrorNotConfigured) };
        }

        this.defaultStart = format(subDays(new Date(), 27), FnsDate);
        this.defaultEnd = format(new Date(), FnsDate);
        this.start = this.defaultStart;
        this.end = this.defaultEnd;

        this.filterManager = new FilterManager([], hierarchyUnitProvider, new ReportFilterSerialzier(filterSerializer));
    }

    async ngOnInit() {
        // TODO Should this be wrapped in a try catch? and errors displayed on screen
        for (const reportId of this.reportIds) {
            const reportConfig = await this.service.getConfig(reportId);
            if (reportConfig) {
                this.reportConfigs.push(reportConfig);
            }
        }
        await this.initFilters();

        // enable table scroll & sticky header
        if (this.reportConfigs.find(r => r.chartType === 'table')) {
            this.table = true;
        }

        this.subscriptions.add(this.route.params.subscribe(() => {
            this.routeChanged();
        }));
    }

    async ngAfterViewInit() {
        this.subscriptions.add(this.reportComponents.changes.subscribe(() => {
            if (this.filterValues) { // don't load until filters have initialized
                this.loadReports();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    filtersChange() {
        const serialized = this.filterManager.serializeAll(this.filterValues);
        const dateFilters = cleanObj({ start: this.start, end: this.end, interval: this.interval });
        this.router.navigate([{ ...serialized, ...dateFilters }], { relativeTo: this.route });
    }

    dateIntervalPresetSelected(i: number) {
        this.start = this.dateIntervalPresets[i].start;
        this.end = this.dateIntervalPresets[i].end;
        this.filtersChange();
    }

    incrementChange(v: string) {
        this.interval = v;
        this.populateDateRangeOptions();
        this.filtersChange();
    }

    startChange(v: string) {
        const newValue = v || this.defaultStart;
        if (this.start !== newValue) {
            this.start = newValue;
            this.filtersChange();
        }
    }

    endChange(v: string) {
        const newValue = v || this.defaultEnd;
        if (this.end !== newValue) {
            this.end = newValue;
            this.filtersChange();
        }
    }

    print() {
        (this.window as Window).print();
    }

    protected async routeChanged() {
        const params = Object.assign({}, this.route.snapshot.params);

        this.start = params.start || this.start;
        this.end = params.end || this.end;
        this.interval = params.interval || this.interval;

        const nextFilters = await this.filterManager.deserializeAll(params);
        const nextDateFilters = this.getConfiguredDateFilterValues({ start: this.start, end: this.end, interval: this.interval }, this.dateFilterConfigs);

        const changed =
            JSON.stringify(nextFilters) !== JSON.stringify(this.currentFilterValues)
            || JSON.stringify(nextDateFilters) !== JSON.stringify(this.currentDateFilterValues);

        this.filterValues = nextFilters;
        this.dateFilterValues = nextDateFilters;

        this.currentFilterValues = JSON.parse(JSON.stringify(nextFilters));
        this.currentDateFilterValues = JSON.parse(JSON.stringify(nextDateFilters));

        if (changed || this.firstEntry) {
            this.loadReports();
        }

        this.firstEntry = false;
    }

    private async initFilters() {
        try {
            await this.createFilterEntries();
            await this.routeChanged();
            this.populateDateRangeOptions();
        } catch (e) {
            console.error(e);
            this.error = e.message || this.translate.instant(SharedTermsTranslationKey.ErrorUnknown);
        }
    }

    private populateDateRangeOptions() {

        if (!this.dateFilterConfigs.startDate || !this.dateFilterConfigs.endDate || !this.dateFilterConfigs.presetRanges) {
            return;
        }

        this.dateIntervalPresets = [];
        if (this.interval === IncrementOption.Daily) {
            let date = new Date();
            let start = format(getStartOf(date, 'day'), FnsDate);
            let end = format(getEndOf(date, 'day'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeToday), start, end });

            start = format(getStartOf(sub(date, { days: 6 }), 'day'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange7Days), start, end });

            start = format(getStartOf(sub(date, { days: 13 }), 'day'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange14Days), start, end });

            start = format(getStartOf(sub(date, { days: 20 }), 'day'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange21Days), start, end });

            start = format(getStartOf(sub(date, { days: 27 }), 'day'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange28Days), start, end });

            start = format(getStartOf(date, 'month'), FnsDate);
            end = format(getEndOf(date, 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeThisMonth), start, end });

            date = sub(date, { months: 1 });
            start = format(getStartOf(date, 'month'), FnsDate);
            end = format(getEndOf(date, 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeLastMonth), start, end });
        } else {
            let date = new Date();
            let start = format(getStartOf(date, 'month'), FnsDate);
            let end = format(getEndOf(date, 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeThisMonth), start, end });

            date = sub(date, { months: 1 });
            start = format(getStartOf(date, 'month'), FnsDate);
            end = format(getEndOf(date, 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRangeLastMonth), start, end });

            date = getEndOf(new Date(), 'month');
            end = format(date, FnsDate);
            start = format(getStartOf(sub(date, { months: 2 }), 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange3Months), start, end });

            date = getEndOf(new Date(), 'month');
            start = format(getStartOf(sub(date, { months: 5 }), 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange6Months), start, end });

            date = getEndOf(new Date(), 'month');
            start = format(getStartOf(sub(date, { months: 8 }), 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange9Months), start, end });

            date = getEndOf(new Date(), 'month');
            start = format(getStartOf(sub(date, { months: 11 }), 'month'), FnsDate);
            this.dateIntervalPresets.push({ label: this.translate.instant(DiscoverTranslationKey.ReportDateRange12Months), start, end });
        }
    }

    private loadReports() {
        for (const reportComponent of this.reportComponents) {
            try {
                const filters = { ...this.filterManager.serializeAll(this.filterValues), ...this.dateFilterValues };
                reportComponent.loadData(filters);
            } catch (e) {
                console.error(e);
            }
        }
    }

    private async createFilterEntries() {
        // combine filters from all configs, exclude duplicate ids
        const customFilters = this.reportConfigs
            .reduce((filters: ReportCustomFilerConfig[], config) => {
                if (config.customFilters) {
                    return [...filters, ...config.customFilters.filter(customFilter => !filters.map(filter => filter.identifier).includes(customFilter.identifier))];
                }
                return filters;
            }, []);

        this.dateFilterConfigs = this.reportConfigs
            .reduce((filterConfig: ReportDateFilterConfig, config) => {
                if (config.dateFilters) {
                    return {
                        startDate: filterConfig.startDate || config.dateFilters.startDate,
                        endDate: filterConfig.endDate || config.dateFilters.endDate,
                        presetRanges: filterConfig.presetRanges || config.dateFilters.presetRanges,
                        intervals: filterConfig.intervals || config.dateFilters.intervals
                    };
                }
                return filterConfig;
            }, {});

        for (const customFilter of customFilters) {
            const loader = this.reportService.createFilterLoader(customFilter.loader);
            // TODO Fix the create signature to accept directly a Loader
            const filterEntry = this.filterEntryAdapter.transform({
                type: this.reportService.getFilterType(customFilter.type, loader),
                identifier: customFilter.identifier,
                label: customFilter.label,
                options: customFilter.options,
                loader,
                translateService: this.translate,
                searchMinLength: TableSearchMinLength
            });

            if (filterEntry) {
                this.filterManager.add(filterEntry);
            }
        }
    }

    private getConfiguredDateFilterValues(filterValues: Dictionary<FilterValue>, filterConfig: ReportDateFilterConfig): Dictionary<FilterValue> {
        const dateFilterValues: Dictionary<FilterValue> = {};
        if (filterConfig.startDate) {
            dateFilterValues.start = filterValues.start;
        }
        if (filterConfig.endDate) {
            dateFilterValues.end = filterValues.end;
        }
        if (filterConfig.intervals) {
            dateFilterValues.interval = filterValues.interval;
        }
        return dateFilterValues;
    }

}
