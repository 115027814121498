<div class="col-1of1">
    <div class="ud-card-actions">
        <button class="uf-action tertiary small" (click)="close()">
            <uf-icon name="close"></uf-icon>
        </button>
    </div>

    <h3>{{discoverTK.ContactInfoTitle | translate}}</h3>
    <ng-container *ngIf="!contactDetails">
        <p>{{discoverTK.CompanyHelpMessage | translate}}</p>
    </ng-container>

    <ng-container *ngIf="contactDetails">
        <p>{{discoverTK.ContactInfoMessage | translate}}</p>
        <uf-description-list *ngIf="contactDetails.length" class="small small-label" [items]="contactDetails">
        </uf-description-list>
    </ng-container>

</div>