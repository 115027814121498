<uf-panel class="container bg-gray" ufMasterDetail>
    <div class="uf-container bg-solid">

        <div class="uf-app-bar transparent pad-sm-left">
            <uf-breadcrumbs class="small left" [breadcrumbs]="breadcrumbs" [minimize]="true"></uf-breadcrumbs>
        </div>

        <uf-message *ngIf="error" class="error" icon="error" [content]="error.message"></uf-message>

        <div class="uf-grid pad-sm-sides" *ngIf="form">
            <div class="uf-box col-12 ng-untouched ng-pristine ng-valid ng-star-inserted" [formGroup]="form">
                <div class="uf-app-bar accent medium">
                    <div class="title">
                        {{shellTK.DetailsTitle | translate}}
                    </div>
                </div>
                <div class="gap-top pad-sides pad-bottom">
                    <ud-company-description *ngIf="descriptionFilter?.length && company" [filterBy]="descriptionFilter"
                        [company]="company" class="pad-bottom">
                    </ud-company-description>

                    <uf-text class="pad-bottom" *ngIf="form.controls[controlKeys.Name].enabled"
                        [formControlName]="controlKeys.Name" [label]="commonTK.CompanyNameLabel | translate">
                        <span class="suffix">*</span>
                    </uf-text>
                    <uf-select class="pad-bottom" *ngIf="form.controls[controlKeys.Status].enabled"
                        [label]="commonTK.CompanyStatusLabel | translate" [formControlName]="controlKeys.Status"
                        [options]="companyStatusOptions" nameProperty="name" valueProperty="identifier">
                        <span class="suffix">*</span>
                    </uf-select>
                </div>
            </div>

            <ng-container *ngIf="claimConfig">
                <div class="uf-box gap-top col-12 ng-untouched ng-pristine ng-valid ng-star-inserted">
                    <div class="uf-app-bar accent medium">
                        <div class="title">
                            {{commonTK.ClaimsLabel | translate}}
                        </div>
                    </div>
                    <ud-company-description *ngIf="claimDescriptionFilter?.length && company"
                        [claimConfig]="claimConfig" [filterBy]="claimDescriptionFilter" [company]="company"
                        class="pad-sides pad-top">
                    </ud-company-description>
                    <uf-claim-editor *ngIf="claimControl.enabled" [width]="fieldWidth.Half" [control]="claimControl" [claimSource]="claimSource"
                        class="pad-lg-bottom">
                    </uf-claim-editor>
                </div>

            </ng-container>
        </div>

        <div class="row gap-lg-top">
            <button type="button" class="uf-button tertiary left" (click)="back()">
                {{sharedTK.ActionCancel | translate}}
            </button>
            <button *ngIf="form?.enabled || claimControl?.enabled" type="button" class="uf-button primary"
                (click)="save()">
                {{(this.company ? sharedTK.ActionSave : sharedTK.ActionCreate) | translate}}
            </button>
        </div>



    </div>
</uf-panel>

<router-outlet></router-outlet>