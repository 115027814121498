import { Observable } from 'rxjs';

import { Inject, Injectable, Optional } from '@angular/core';
import { AssetProfile, Definition, Dictionary, ImageProfile, Progress } from '@unifii/sdk';

import { ContentLoader } from 'shell/offline/content-loader';
import { IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { buildOfflineAssetUrl, ContentPackage, ContentStores, getStepDones, TenantStores } from 'shell/offline/offline-model';
import { PostUpdateHook } from 'shell/offline/post-update-hook';


@Injectable()
export class ContentUpdater {

    constructor(
        @Inject(TenantDb) private tenantDb: IndexedDbWrapper,
        @Optional() @Inject(PostUpdateHook) private postUpdateHook: PostUpdateHook
    ) { }

    /** Progress
     * 10% Content stored
     * 80% Assets stored
     * 99% Indexed
     * 100% Completed
     */
    update(nextContentDB: IndexedDbWrapper, content: ContentPackage, loader: ContentLoader): Observable<Progress> {

        return new Observable(observer => {

            (async () => {

                try {

                    let start: number;

                    // Store content
                    start = performance.now();
                    await this.storeContent(nextContentDB, content);
                    observer.next(getStepDones(content.info.name, 0, 10));
                    console.log(`OfflineContent: Compounds update completed in ${(performance.now() - start).toFixed(0)}ms`);

                    // Store assets (diff incremental)
                    start = performance.now();
                    const progress = await this.storeAssets(nextContentDB, content, loader).toPromise();
                    observer.next(getStepDones(content.info.name, 10, 80, progress));
                    console.log(`OfflineContent: Assets update completed in ${(performance.now() - start).toFixed(0)}ms`);

                    // Post update custom actions
                    if (this.postUpdateHook) {
                        start = performance.now();
                        const subProgress = await this.postUpdateHook.run(nextContentDB, content).toPromise();
                        observer.next(getStepDones(content.info.name, 80, 99, subProgress));
                        console.log(`OfflineContent: Indexes generate completed in ${(performance.now() - start).toFixed(0)}ms`);
                    }

                    observer.next(getStepDones(content.info.name, 99, 100));
                    observer.complete();

                } catch (e) {
                    observer.error(e);
                }

            })();
        });
    }

    /** Store the content into IndexedDB */
    private async storeContent(target: IndexedDbWrapper, content: ContentPackage): Promise<void> {
        console.log('OfflineContent: storing started...');

        await target.put(ContentStores.Identifiers, content.identifiers);
        console.log('OfflineContent: Stored content identifiers');

        if (content.structure) {
            await target.put(ContentStores.Structure, content.structure);
        }
        console.log('OfflineContent: Stored structure');

        await target.putAll(ContentStores.Pages, content.pages);
        console.log(`OfflineContent: Stored ${content.pages.length} pages`);

        await target.putAll(ContentStores.Views, content.views.map(view => view.compound));
        console.log(`OfflineContent: Stored ${content.views.length} views`);

        await target.putAll(ContentStores.ViewDefinitions, content.views.map(view => view.definition));
        console.log(`OfflineContent: Stored ${content.views.length} view-definitions`);

        await target.putAll(ContentStores.Collections, content.collections.map(collection => collection.definition));
        console.log(`OfflineContent: Stored ${content.collections.length} collections`);

        const collectionsUpdated = {} as Dictionary<any>;
        for (const collection of content.collections) {
            await target.putAll(collection.definition.identifier, collection.compounds);
            collectionsUpdated[collection.definition.identifier] = collection.compounds.length;
        }
        console.log(`OfflineContent: Stored collections compounds`, collectionsUpdated);

        await target.putAll(ContentStores.Forms, content.forms.map(form => form.definition));
        console.log(`OfflineContent: Stored ${content.forms.length} forms`);

        if (!content.info.preview) {
            const versions = content.forms.reduce((all, form) => all.concat(form.versions), [] as Definition[]);
            await target.putAll<Definition>(ContentStores.FormVersions, versions,
                (version: Definition) => `${version.identifier}.${version.version}`
            );
            console.log(`OfflineContent: Stored ${versions.length} form versions`);
        }

        await target.putAll(ContentStores.Buckets, content.buckets);
        console.log(`OfflineContent: Stored ${content.buckets.length} buckets`);

        await target.putAll(ContentStores.Tables, content.tables);
        console.log(`OfflineContent: Stored ${content.tables.length} tables`);
    }

    /** Store the assets into IndexedDB */
    private storeAssets(target: IndexedDbWrapper, content: ContentPackage, loader: ContentLoader): Observable<Progress> {

        return new Observable(observer => {

            (async () => {

                try {

                    // Clean up duplicates, load and store the asset only once
                    content.assets = content.assets.filter((obj, pos, arr) => arr
                        .map(asset => buildOfflineAssetUrl(asset))
                        .indexOf(buildOfflineAssetUrl(obj)) === pos);

                    // Filter out already stored assets
                    const missings: (ImageProfile | AssetProfile)[] = [];
                    for (const asset of content.assets) {
                        const notFound = await this.tenantDb.get(TenantStores.Assets, buildOfflineAssetUrl(asset)) == null;
                        if (notFound) {
                            missings.push(asset);
                        } else {
                            // Register asset on projectDB
                            await target.put(TenantStores.Assets, buildOfflineAssetUrl(asset));
                        }
                    }

                    console.log(`OfflineContent: ${missings.length} assets to be stored!`);

                    if (missings.length) {

                        const progress: Progress = { total: missings.length, done: 0 };

                        for (const asset of missings) {

                            // Download and store asset on tenantDB
                            // console.log('Asset: download started', asset);
                            const assetBufferInfo = await loader.loadAsset(asset);
                            // console.log('Asset: download ArrayBuffer completed', assetBufferInfo.data.byteLength);

                            await this.tenantDb.put(
                                TenantStores.Assets,
                                { type: assetBufferInfo.type, data: assetBufferInfo.data },
                                buildOfflineAssetUrl(asset)
                            );
                            // console.log('Asset: ArrayBuffer stored');

                            // Register asset on projectDB
                            await target.put(TenantStores.Assets, buildOfflineAssetUrl(asset));
                            // console.log('Asset: project reference stored');

                            // Notify progress
                            observer.next(progress);
                            progress.done++;
                        }
                    }

                    observer.complete();

                } catch (e) {
                    observer.error(e);
                }

            })();
        });
    }
}
