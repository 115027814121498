<div class="uf-grid uf-container-sm">

    <div class="col-12 row center-all justify-center">
        <h5 class="no-margin padded--horizontal">{{ tenantSettings?.name }}</h5>

        <div *ngIf="canChangeCompany">
            <button type="button" class="uf-action small tertiary right" (click)="changeTenant()"
                [title]="discoverTK.ActionChangeCompany | translate" [disabled]="inProgress">
                <uf-icon name="change"></uf-icon>
            </button>
        </div>

    </div>

    <ul class="uf-list small col-12" *ngIf="savedUsersService.enabled && savedUsers.length">
        <ng-template ngFor [ngForOf]="savedUsers" let-user>
            <li class="uf-list-button">
                <button (click)="remeberedUserLogin(user)" [disabled]="inProgress">

                    <div class="uf-avatar info padded" *ngIf="user.provider; else authAvatar">
                        <img [src]="user.provider.loginIcon" />
                    </div>

                    <ng-template #authAvatar>
                        <div class="uf-avatar accent">
                            {{user.username.charAt(0).toUpperCase()}}
                        </div>
                    </ng-template>

                    <ng-container *ngTemplateOutlet="userInfo; context: {user: user}"></ng-container>

                </button>
            </li>

        </ng-template>
    </ul>

    <div class="col-12 auth-providers col space-children">
        <ng-container *ngFor="let provider of providers">
            <div class="col">
                <label *ngIf="provider.providerLoginLabel">{{provider.providerLoginLabel}}</label>
                <button type="button" [disabled]="inProgress" class="authentication-provider grow"
                    (click)="providerSignIn(provider)">
                    <img [src]="provider.loginIcon" />
                    <p class="small">{{provider.loginLabel}}</p>
                </button>
            </div>
        </ng-container>

        <div class="col">
            <label *ngIf="tenantSettings?.usernameLoginLabel">{{tenantSettings?.usernameLoginLabel}}</label>

            <button type="button" [disabled]="inProgress" class="authentication-provider unifii"
                (click)="loginWithUser()">
                <uf-icon class="small" name="user"></uf-icon>
                <p class="small">{{discoverTK.LoginWithUsernameLabel | translate}}</p>
            </button>
        </div>
    </div>

</div>

<ng-template #userInfo let-user="user">
    <div class="grow shrink">
        <p class="no-margin row bold">{{user.username}}</p>
        <p class="no-margin row x-small" *ngIf="user.email !== user.username">{{user.email}}</p>
    </div>

    <button type="button" class="uf-action tertiary small" (click)="removeUser($event, user)"
        [title]="sharedTermsTK.ActionRemove | translate">
        <uf-icon name="delete"></uf-icon>
    </button>
</ng-template>
