import { Component, Input } from '@angular/core';
import { RuntimeField } from '@unifii/library/common';
import { ContentField } from '@unifii/library/smart-forms';
import { FieldTemplate } from '@unifii/sdk';


@Component({
    templateUrl: './file-list.html'
})
export class FileListDisplayComponent implements ContentField {

    @Input() content: any[];
    @Input() field: RuntimeField;

    readonly fieldTemplate = FieldTemplate;
}
