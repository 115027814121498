<uf-panel class="container bg-gray" ufMasterDetail>
    <div class="uf-container bg-solid">
        <div class="uf-app-bar transparent pad-sm-left">
            <a *ngIf="prevUrl" class="uf-button tertiary left align-left" (click)="back(prevUrl)">
                <uf-icon name="arrowBack"></uf-icon>
                {{sharedTK.ActionBack | translate}}
            </a>
            <uf-breadcrumbs class="small left" [breadcrumbs]="breadcrumbs" [minimize]="true"></uf-breadcrumbs>
            <a *ngIf="itemLink" [routerLink]="itemLink.urlSegments" class="uf-button small primary right">
                {{ itemLink.name }}
            </a>
        </div>

        <div class="gap-top gap-bottom">
            <ng-container *ngFor="let detailContent of tableDetailsContent">
                <div *ngIf="detailContent.rows.length > 0" class="uf-box flat">
                    <div *ngIf="detailContent.heading != null" class="uf-app-bar medium">
                        <div class="title">
                            {{detailContent.heading}}
                        </div>
                    </div>
                    <div>
                        <ul class="uf-list">
                            <li *ngFor="let item of detailContent.rows">
                                <ng-template [ngIf]="item?.type === 'description'">
                                    <uf-dl class="pad-sm-top pad-sm-bottom pad-left" [items]="[item]">
                                    </uf-dl>
                                </ng-template>
                                <ng-template [ngIf]="item?.type === 'link'">
                                    <uf-dl class="pad-sm-top pad-sm-bottom pad-left">
                                        <dt>{{ item?.term }}:</dt>
                                        <dd>
                                            <us-content-link-url [content]="$any(item)?.content" [showType]="true"></us-content-link-url>
                                        </dd>
                                    </uf-dl>
                                    
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="tableModules.length" class="uf-grid">
            <div *ngFor="let module of tableModules; let i = index" class="col-12 gap-sm-top">
                <ng-container *ngIf="module.detailModule.type === 'Table'">
                    <us-table-module [module]="module" [item]="item" [detailContextProvider]="detailContextProvider">
                    </us-table-module>
                </ng-container>
            </div>
        </div>
    </div>
</uf-panel>

<router-outlet></router-outlet>