import format from 'date-fns/format';
import set from 'date-fns/set';

import { Inject, Injectable } from '@angular/core';
import { AppContext, ContextProvider, FnsDatetime, mapUserToUserContext } from '@unifii/library/common';
import { UserInfo } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';


/** ContextProvider is responsible for providing context for expression parsing */
@Injectable()
export class ShellContextProvider implements ContextProvider {

    constructor(@Inject(Authentication) private auth: Authentication) { }

    get(): AppContext {
        const now = format(set(new Date(), { seconds: 0 }), FnsDatetime);

        return {
            now,
            user: mapUserToUserContext(this.auth.userInfo as UserInfo)
        };
    }

}
