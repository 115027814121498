import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { Injectable, InjectionToken } from '@angular/core';

import { ErrorService } from 'shell/errors/error.service';


// IE 11 detection and monkey patch all the broken methods
if (IDBObjectStore.prototype.openKeyCursor == null) {

    const oldPut = IDBObjectStore.prototype.put;
    IDBObjectStore.prototype.put = function (value: any, range?: any) {
        return range === undefined ? oldPut.call(this, value) : oldPut.call(this, value, range);
    };

    const oldCount = IDBObjectStore.prototype.count;
    IDBObjectStore.prototype.count = function (range?: KeyRange) {
        return range === undefined ? oldCount.call(this) : oldCount.call(this, range);
    };

    const oldOpenCursor = IDBObjectStore.prototype.openCursor;
    IDBObjectStore.prototype.openCursor = function (range?: KeyRange, direction?: IDBCursorDirection) {
        if (range === undefined) {
            return oldOpenCursor.call(this);
        }

        if (direction === undefined) {
            return oldOpenCursor.call(this, range);
        }

        return oldOpenCursor.call(this, range, direction);
    };

    IDBObjectStore.prototype.openKeyCursor = IDBObjectStore.prototype.openCursor;
}

export type Key = string | number | Date;
export type KeyRange = Key | IDBKeyRange;

@Injectable()
export class IndexedDbWrapper {

    private _db: Promise<IDBDatabase> | null = null;

    constructor(private errorService: ErrorService) { }

    set db(v: Promise<IDBDatabase> | null) {
        if (this._db) {
            this._db.then(db => db.close());
        }

        this._db = v;
    }

    get db(): Promise<IDBDatabase> | null {
        return this._db;
    }

    get<T>(store: string, key: Key, index?: string): Promise<T> {

        if(index){
            return this.tx(store, s => this.promisify(s.index(index).get(key)));
        }

        return this.tx(store, s => this.promisify(s.get(key)));
    }

    put<T>(store: string, item: T, key?: Key): Promise<void> {
        return this.tx(store, s => this.promisify(s.put(item, key)), 'readwrite');
    }

    delete(store: string, key: any): Promise<void> {
        if (Array.isArray(key) && key.length === 0) {
            // no need to hit the DB with empty array, also IE11 whinges
            return Promise.resolve();
        }

        return this.tx(store, s => this.promisify(s.delete(key)), 'readwrite');
    }

    clear(store: string): Promise<void> {
        return this.tx(store, s => this.promisify(s.clear()), 'readwrite');
    }

    count(store: string, range?: KeyRange): Promise<number> {
        return this.tx(store, s => this.promisify(s.count(range)));
    }

    getKeys<T extends Key>(store: string, range?: KeyRange, direction?: IDBCursorDirection): Observable<T> {
        return this.cursorTx(store, s => s.openKeyCursor(range, direction), c => c.key);
    }

    getValues<T>(store: string, range?: KeyRange, direction?: IDBCursorDirection): Observable<T> {
        return this.cursorTx(store, s => s.openCursor(range, direction), c => c.value);
    }

    getAll<K extends Key, V>(store: string, range?: KeyRange, direction?: IDBCursorDirection): Observable<{ key: K; value: V }> {
        return this.cursorTx(store, s => s.openCursor(range, direction), c => ({ key: c.key, value: c.value }));
    }

    async putAll<T>(store: string, items: T[], keySelector?: (t: T) => any, valueSelector?: (t: T) => any): Promise<void> {
        return this.tx(store, async s => {
            for (const item of items) {
                const key = keySelector == null ? undefined : keySelector(item);
                const value = valueSelector == null ? item : valueSelector(item);

                await s.put(value, key);
            }
        }, 'readwrite');
    }

    /**
     * Opens a transaction, resolves when transaction completes
     */
    async tx(store: string, cb: (store: IDBObjectStore) => Promise<any>, mode: IDBTransactionMode = 'readonly'): Promise<any> {
        if (this._db == null) {
            throw this.errorService.createNotFoundError('offline DB');
        }

        const db = await this._db;
        const tx = db.transaction(store, mode); // mode is required in IE11

        const result = await cb(tx.objectStore(store));

        return new Promise<any>((resolve, reject) => {
            tx.onabort = reject;
            tx.oncomplete = () => resolve(result);
        });
    }

    /** Promisifies IDRequest */
    promisify(request: IDBRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            request.onerror = reject;
            request.onsuccess = () => resolve(request.result);
        });
    }

    /**
     * Open transaction with a cursor
     */
    private cursorTx<T>(store: string, cb: (iDBObjectStore: any) => IDBRequest, selector: (iDBCusrsor: any) => T): Observable<T> {

        if (this._db == null) {
            throw this.errorService.createNotFoundError('offline DB');
        }

        return from(this._db).pipe(
            concatMap(db => new Observable<T>(subscriber => {
                const tx = db.transaction(store, 'readonly'); // mode is required in IE11
                let done = false;

                tx.onabort = e => subscriber.error(e);
                tx.oncomplete = () => {
                    done = true;
                    subscriber.complete();
                };

                const request = cb(tx.objectStore(store));

                request.onerror = e => subscriber.error(e);

                request.onsuccess = () => {
                    const cursor: IDBCursor = request.result;
                    if (cursor) {
                        subscriber.next(selector(cursor));
                        cursor.continue();
                    }
                };

                return () => {
                    if (!done) {
                        tx.abort();
                    }
                };
            })));
    }
}

export const TenantDb = new InjectionToken<IndexedDbWrapper>('TenantDb');
export const ContentDb = new InjectionToken<IndexedDbWrapper>('ContentDb');
