import { Injectable, Type } from '@angular/core';
import { ComponentRegistryOptions, ContentField } from '@unifii/library/smart-forms';
import { FieldType } from '@unifii/sdk';

import { UsPageComponentRegistry } from 'shell/content/page-component-registry';

import { FileListDisplayComponent } from './file-list.component';


@Injectable()
export class UdPageComponentRegistry extends UsPageComponentRegistry {

    get(type: FieldType, options?: ComponentRegistryOptions): Type<ContentField> | null {

        if (type === FieldType.FileList) {
            return FileListDisplayComponent;
        }
        return super.get(type, options);
    }

}
