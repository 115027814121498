<uf-progress class="large"></uf-progress>
<div class="uf-app-bar">
    <button *ngIf="data.guard !== true" type="button" class="uf-action tertiary" (click)="close()">
        <uf-icon name="close"></uf-icon>
    </button>
    <h3>{{shellTK.ChangeLanguageTitle | translate}}</h3>
</div>

<div class="uf-grid pad">
    <ul class="uf-list col-12">
        <ng-template ngFor let-language [ngForOf]="data.languages">
            <li class="uf-list-button language" (click)="data.current = language">
                <img src="assets/svg/language-{{language}}.svg">
                <div class="multi-text">
                    {{ languageLabels['language_' + removeTranslationKeyInvalidCharacters(language)]}}
                </div>
                <uf-icon *ngIf="language === data.current" name="tick" class="small">
                </uf-icon>
            </li>
        </ng-template>
    </ul>

    <div class="col-12 error">
        <span *ngIf="error">{{error | translate}}</span>
    </div>
</div>

<div class="uf-form-actions">
    <button *ngIf="data.guard !== true && !loading" type="button" class="uf-button tertiary small left"
        (click)="close()">
        {{sharedTermsTK.ActionCancel | translate}}
    </button>
    <button type="button" class="uf-button primary small" (click)="confirm()" [disabled]="loading">
        {{sharedTermsTK.ActionChange | translate}}
    </button>
</div>