import { Component, HostBinding, Inject, OnInit, Optional, ViewChild, ViewContainerRef } from '@angular/core';

import { IconsComponentSelector } from 'shell/core/icons-selector';


/**
 * Provides an entry point via the selector for icon overrides
 */
@Component({
    selector: 'us-icons',
    template: '<div #container></div>'
})
export class IconsComponent implements OnInit {

    @HostBinding('style.display') hostDisplay = 'none';
    @ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

    constructor(
        @Optional() @Inject(IconsComponentSelector) private selector: IconsComponentSelector
    ) {
    }

    ngOnInit() {

        if (this.selector == null) {
            return;
        }

        const component = this.selector.get();
        this.container.createComponent(component, {index: 0});
    }

}