import { Component, inject, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { TableInputs } from '@unifii/components';
import {
    CellDisplayDescriptor, ContextProvider, FilterValue, SortStatus, TableComponent, TableConfig, TableDataSource
} from '@unifii/library/common';
import { Client, CompaniesClient, Definition, FormData, PermissionAction, TableSourceType, UsersClient } from '@unifii/sdk';

import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellFormService } from 'shell/form/shell-form.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableNodeInfo } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { checkShowCount, getTableCustomColumnsDisplayDescriptors } from 'shell/table/table-functions';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

import { Config } from 'config';


@Component({
    selector: 'us-dashboard-table',
    templateUrl: './dashboard-table.html',
    styleUrls: ['./dashboard-table.less'],
    providers: [ShellFormService, TableInputManagerFactory]
})
export class DashboardTableComponent implements OnInit {

    @ViewChild(TableComponent) pageTable: TableComponent<TableData>;

    @Input() tableInfo: TableNodeInfo;

    readonly shellTK = ShellTranslationKey;

    protected recordCount: number | null;
    protected tableConfig: TableConfig<FormData> | null = null;
    protected error: AppError | undefined;
    protected formsDefinition: Map<string, Definition> = new Map<string, Definition>();
    protected datasource: TableDataSource<TableData> | null = null;
    protected customColumns: CellDisplayDescriptor[] = [];
    protected tableLink: any[];

    constructor(
        private usersClient: UsersClient,
        @Inject(ContentDataResolver) private dataResolver: ContentDataResolver,
        private tableColumnFactory: TableColumnFactory<FormData>,
        @Inject(Authentication) private auth: Authentication,
        private formService: ShellFormService,
        @Inject(Config) private config: Config,
        @Inject(ContextProvider) private contextProvider: ContextProvider,
        private errorService: ErrorService,
        private inputManagerFactory: TableInputManagerFactory
    ) { }

    get showSeeMoreRow() {
        return this.tableLink && this.pageTable?.status.exhausted === false;
    }

    private get canShowRecordCount() {
        return this.datasource && this.config.unifii.tenantSettings?.features.indexing && this.tableInfo.table.showCount;
    }

    async ngOnInit() {

        try {
            this.formService.bucket = this.tableInfo.table.source as string;
            const { tablePageConfig } = await this.dataResolver.getTableData(this.tableInfo.table.identifier);
            const { propertyDescriptors } = tablePageConfig;

            this.tableConfig = {
                columns: this.tableColumnFactory.create(this.tableInfo.table.columns ?? [], propertyDescriptors, this.tableInfo.table.sourceType, false),
                pageSize: this.tableInfo.pageSize ?? 5,
                row: {
                    link: (item: FormData) => {
                        if (this.canRouteToItem(item)) {
                            return ['/', this.tableInfo.table.identifier, item.id];
                        }
                        return [];
                    }
                },
                exhaustAhead: checkShowCount(this.config, this.tableInfo.table)
            };

            this.customColumns = getTableCustomColumnsDisplayDescriptors(propertyDescriptors, tablePageConfig.table.columns);

            // Load data
            this.reload();

            if (this.canShowRecordCount) {
                this.recordCount = (await this.datasource?.count) || null;
            }

            this.tableLink = ['/', this.tableInfo.table.identifier];

        } catch (e) {
            this.error = this.errorService.mergeError(e, this.errorService.unknownErrorMessage);
        }
    }

    reload() {
        this.datasource = this.createDataSource();
    }

    private createDataSource(): TableDataSource<TableData> {
        const tableInputManager = this.inputManagerFactory.create(this.tableInfo.table);

        let inputFilters: TableInputs<FilterValue> | undefined;
        const sort = SortStatus.fromString(this.tableInfo.table?.defaultSort) || undefined;
        if (sort) {
            inputFilters = { sort };
        }

        switch (this.tableInfo.table.sourceType) {
            case TableSourceType.Users:
                return new UsersTableDataSource(this.usersClient, this.tableInfo.table.identifier, tableInputManager, inputFilters);
            case TableSourceType.Company:
                const companiesClient = new CompaniesClient(inject(Client));
                return new CompanyTableDataSource(companiesClient, this.tableInfo.table.identifier, tableInputManager, inputFilters);
            case TableSourceType.Bucket:
                this.formService.bucket = this.tableInfo.table.source as string;
                const showCount = checkShowCount(this.config, this.tableInfo.table);
                return new BucketTableDataSource({
                    shellFormService: this.formService,
                    tableIdentifier: this.tableInfo.table.identifier,
                    tableInputManager,
                    tableInputs: inputFilters,
                    showCount
                });
        }
    }

    private canRouteToItem(item: FormData): boolean {

        switch (this.tableInfo.table.sourceType) {
            case TableSourceType.Bucket:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, this.tableInfo.table.source as string, item.id as string),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get()
                ).granted;
            case TableSourceType.Company:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getCompanyPath(item.id),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get()
                ).granted;
            case TableSourceType.Users:
                return this.auth.getGrantedInfo(
                    PermissionsFunctions.getUserPath(item.id ? +item.id : undefined),
                    PermissionAction.Read,
                    item,
                    this.contextProvider.get()
                ).granted;
        }
    }
}
