import { lastValueFrom } from 'rxjs';
import { flatMap, map, toArray } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    AssetProfile, Compound, ContentType, Definition, Dictionary, FieldType, ImageProfile, NodeType, Page, PublishedContent, Query, QueryOperators,
    Schema, Structure, Table
} from '@unifii/sdk';

import { ContentDb, IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { buildOfflineFileUrl, buildOfflineImageUrl, ContentInfo, ContentStores, TenantStores } from 'shell/offline/offline-model';

import { Config } from 'config';


@Injectable()
export class OfflineContent implements PublishedContent {

    constructor(
        @Inject(Config) private config: Config,
        @Inject(TenantDb) private tenantDb: IndexedDbWrapper,
        @Inject(ContentDb) private contentDb: IndexedDbWrapper,
        private sanitizer: DomSanitizer
    ) { }

    getStructure(): Promise<Structure> {
        return this.contentDb.get<Structure>(ContentStores.Structure, 1);
    }

    async getView(id: string): Promise<Compound> {
        const c = await this.getViewCompound(id);
        const d = await this.getViewDefinition(id);
        return this.updateCompoundImageUrls(c, d);
    }

    getViewDefinition(identifier: string): Promise<Definition> {
        return this.contentDb.get<Definition>(ContentStores.ViewDefinitions, identifier);
    }

    /**
     * @param identifier - backwards compatibility with id
     */
    async getPage(identifier: string): Promise<Page> {

        // TODO remove index lookup after users have upgraded projects
        let page: Page;
        try {
            page = await this.contentDb.get<Page>(ContentStores.Pages, identifier);
            if (!page) {
                throw Error('undefined page');
            }
        } catch (e) {
            console.warn(`failed to get ${identifier} falling back to indexed identifier`);
            page = await this.contentDb.get<Page>(ContentStores.Pages, identifier, 'identifier');
        }


        for (const f of page.fields) {
            if (f.type === FieldType.ImageList) {
                for (const v of f.value) {
                    v.url = await this.replaceImageUrl(v);
                }
            }
            if (f.type === FieldType.FileList) {
                for (const v of f.value) {
                    v.url = await this.replaceAssetUrl(v);
                }
            }
        }

        return page;
    }

    async queryPages(_?: Query): Promise<Page[]> {
        return lastValueFrom(this.contentDb.getValues<Page>(ContentStores.Pages).pipe(toArray()));
    }

    getAssetUrl(id: string): Promise<string> {
        return this.replaceAssetUrl({ id });
    }

    getCollectionDefinition(identifier: string): Promise<Definition> {
        return this.contentDb.get<Definition>(ContentStores.Collections, identifier);
    }

    getCollections(): Promise<Definition[]> {
        return lastValueFrom(this.contentDb.getValues<Definition>(ContentStores.Collections).pipe(toArray()));
    }

    async queryCollection(identifier: string, query?: Query): Promise<Compound[]> {

        const definition = await this.getCollectionDefinition(identifier);
        return lastValueFrom(this.contentDb.getValues<Compound>(identifier)
            .pipe(
                toArray(),
                map(data => this.applyQuery<Compound>(data, query)),
                flatMap(data => Promise.all(data.map(c => this.updateCompoundImageUrls(c, definition))))
            ));
    }

    async getCollectionItem(identifier: string, id: string): Promise<Compound> {

        const definition = await this.getCollectionDefinition(identifier);
        const compound = await this.contentDb.get<Compound>(identifier, id);
        return this.updateCompoundImageUrls(compound, definition);
    }

    getBucket(identifier: string): Promise<Schema> {
        return this.contentDb.get<Schema>(ContentStores.Buckets, identifier);
    }

    queryForms(_?: Query): Promise<Definition[]> {
        return lastValueFrom(this.contentDb.getValues<Definition>(ContentStores.Forms).pipe(toArray()));
    }

    getForm(identifier: string, version?: any): Promise<Definition> {

        if (!this.config.unifii.preview && version) {
            return this.contentDb.get<Definition>(ContentStores.FormVersions, `${identifier}.${version}`);
        }

        return this.contentDb.get<Definition>(ContentStores.Forms, identifier);
    }

    async queryTables(_?: Query): Promise<Table[]> {
        try {
            return lastValueFrom(this.contentDb.getValues<Table>(ContentStores.Tables).pipe(toArray()));
        } catch (e) {
            return [];
        }
    }

    async getTable(id: string): Promise<Table> {
        try {
            return this.contentDb.get<Table>(ContentStores.Tables, id);
        } catch (e) {
            return undefined as any as Table;
        }
    }

    async getIdentifiers(): Promise<Dictionary<{ type: ContentType }>> {
        try {
            return this.contentDb.get<Dictionary<{ type: ContentType }>>(ContentStores.Identifiers, 1);
        } catch (e) {
            return {} as Dictionary<{ type: ContentType }>;
        }
    }

    buildImageUrl(imageProfile: ImageProfile): string | undefined {
        return imageProfile.url;
    }

    getCurrentVersion(): Promise<ContentInfo> {
        return this.tenantDb.get<ContentInfo>(TenantStores.Projects, this.config.unifii.projectId);
    }

    /******************************************** PRIVATE ****************************************************/

    private getViewCompound(id: string): Promise<Compound> {
        return this.contentDb.get<Compound>(ContentStores.Views, id);
    }

    private async updateCompoundImageUrls(compound: Compound, definition: Definition): Promise<Compound> {
        const imageTargets = (definition.fields || [])
            .filter(f => f.type === FieldType.ImageList)
            .map(f => f.identifier as string);

        for (const identifier of imageTargets) {
            const imageList: ImageProfile[] = compound[identifier];
            if (imageList) {
                for (const ip of imageList) {
                    ip.url = await this.replaceImageUrl(ip);
                }
            }
        }

        const assetTargets = (definition.fields || [])
            .filter(f => f.type === FieldType.FileList)
            .map(f => f.identifier as string);

        for (const identifier of assetTargets) {
            const assetList: AssetProfile[] = compound[identifier];
            if (assetList) {
                for (const ip of assetList) {
                    ip.url = await this.replaceAssetUrl(ip);
                }
            }
        }

        return compound;
    }

    private async replaceImageUrl(imageProfile: ImageProfile): Promise<string> {
        const url = buildOfflineImageUrl(imageProfile);

        try {
            const asset = await this.tenantDb.get<{ type: string; data: ArrayBuffer }>(TenantStores.Assets, url);
            const blob = new Blob([asset.data], { type: asset.type });

            return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob)) as string;
        } catch (e) {
            console.warn('Missing image', url);
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==';
        }
    }

    private async replaceAssetUrl(assetProfile: AssetProfile): Promise<string> {
        const url = buildOfflineFileUrl(assetProfile);

        try {
            const asset = await this.tenantDb.get<{ type: string; data: ArrayBuffer }>(TenantStores.Assets, url);
            const blob = new Blob([asset.data], { type: asset.type });

            return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob)) as string;
        } catch (e) {
            console.warn('Missing image', url);
            return '';
        }
    }

    private applyQuery<T extends Dictionary<any>>(data: T[], query?: Query): T[] {
        let filtered = data;
        if (query) {
            // Filter
            for (const arg of (query.args || [])) {
                switch (arg.op) {
                    case QueryOperators.In:
                        if (arg.args[0].type === NodeType.Identifier && arg.args[0].value === 'id') {
                            filtered = filtered.filter(d => (arg.args[1].value as string[]).includes((d as Dictionary<any>).id));
                        }
                        break;
                    case QueryOperators.Equal:
                        if (arg.args[0].type === NodeType.Identifier) {
                            filtered = filtered.filter(d => arg.args[1].value === (d as Dictionary<any>)[arg.args[0].value]);
                        }
                        break;
                }
            }
            // Sort
            const sort = query.args.find(arg => arg.op === 'sort');
            const sortIdentifier = sort?.args.find(node => node.type === NodeType.Identifier)?.value as string | undefined;
            if (sort && sortIdentifier) {
                const asc = sortIdentifier.startsWith('+');
                const key = sortIdentifier.startsWith('+') || sortIdentifier.startsWith('-') ? sortIdentifier.substring(1) : sortIdentifier;
                filtered = filtered.sort((d1, d2) => (asc ? d1[key] > d2[key] : d1[key] < d2[key]) ? 1 : -1);
            }
        }
        return filtered as T[];
    }
}
