<div class="uf-app-bar">
    <h3> {{shellTK.ConflictModalTitle | translate}}</h3>
</div>
<div class="uf-grid pad gaps">
    <div class="col-12">
        <p [innerHTML]="shellTK.ConflictModalMessage | translate"></p>
    </div>
</div>
<div class="uf-form-actions">
    <a (click)="apply('Close')" class="uf-button tertiary">
        {{sharedTK.ActionClose | translate}}
    </a>
    <a (click)="apply('Discard')" class="uf-button primary">
        {{shellTK.ConflictModalActionDiscard | translate}}
    </a>
    <a (click)="apply('Save')" class="uf-button primary">
        {{shellTK.ConflictModalActionSave | translate}}
    </a>
</div>