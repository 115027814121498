<ng-container [ngSwitch]="linkType">
    <a *ngSwitchCase="linkTypes.AttachmentLink" attachmentLink [id]="content.id" (click)="$event.stopPropagation()"><ng-template [ngTemplateOutlet]="label"></ng-template></a>
    <a *ngSwitchCase="linkTypes.PdfViewer" (click)="openPDFViewer($event)"><ng-template [ngTemplateOutlet]="label"></ng-template></a>
    <a *ngSwitchCase="linkTypes.RouterLink" [routerLink]="url" (click)="$event.stopPropagation()"><ng-template [ngTemplateOutlet]="label"></ng-template></a>
    <a *ngSwitchCase="linkTypes.Href" [href]="url" (click)="$event.stopPropagation()" target="_blank"><ng-template [ngTemplateOutlet]="label"></ng-template></a>
    <p *ngSwitchDefault><ng-template [ngTemplateOutlet]="label"></ng-template></p>
</ng-container>

<ng-template #label>
    <ng-template [ngIf]="showType">{{ contentTypeLabel }} / </ng-template>{{ content.label }}
</ng-template>