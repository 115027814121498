<form class="uf-grid" (ngSubmit)="submit()">

    <uf-select ufAutofocus class="col-12" [label]="discoverTK.ProjectLabel | translate" [options]="projects"
        [placeholder]="discoverTK.SelectProjectPlaceholder | translate" [control]="control" nameProperty="name"
        valueProperty="id">
    </uf-select>

    <div class="col-12 col center-all">
        <button class="uf-button primary gap-md-bottom" type="submit">
            {{sharedTermsTK.ActionGo | translate}}
        </button>

        <button type="button" class="uf-button tertiary" (click)="logout()">
            {{sharedTermsTK.ActionLogout | translate}}
        </button>
    </div>

</form>