import { Subject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { DownloadConfig, TableContainerManager, TableInputManager, TableInputs, TableManagerFunctions } from '@unifii/components';
import {
    CellDisplayDescriptor, columnDescriptorToCellDisplayDescriptor, ContextProvider, DataPropertyDescriptor, FilterEntry, FilterValue, isCustomColumn,
    TableConfig, WindowWrapper
} from '@unifii/library/common';
import { CompaniesClient, Company, PermissionAction, Table, TableSourceType } from '@unifii/sdk';

import { TableDisplayMode } from 'shell/content/content-node.component';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableSearchMinLength } from 'shell/shell-constants';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';


@Injectable()
export class CompanyTableContainerManager implements TableContainerManager<Company, FilterValue, FilterEntry> {

    tableConfig: TableConfig<Company>;
    tableIdentifier: string;
    showSearch: boolean;
    searchMinLength = TableSearchMinLength;
    addActionConfig = true;
    defaultSort: string | undefined;
    downloadConfig?: DownloadConfig | undefined;
    customColumns: CellDisplayDescriptor[] = [];
    help?: string;

    reload = new Subject<void>();
    update = new Subject<TableInputs<FilterValue>>();
    updateItem = new Subject<Company>();

    inputManager: TableInputManager<FilterValue, FilterEntry>;

    // dependencies
    private window: Window;
    private auth: Authentication;
    private companiesClient: CompaniesClient;
    private columnFactory: TableColumnFactory<Company>;
    private contextProvider: ContextProvider;
    private moduleInfo: ModuleInfo | undefined;


    constructor() {
        this.window = inject(WindowWrapper) as Window;
        this.auth = inject(Authentication);
        this.companiesClient = inject(CompaniesClient);
        this.columnFactory = inject(TableColumnFactory);
        this.moduleInfo = inject(ModuleInfo);
        this.contextProvider = inject(ContextProvider);

        const { table, propertyDescriptors, isSearchable } = inject(TablePageConfig);

        this.tableIdentifier = table.identifier;
        this.defaultSort = table.defaultSort;
        this.showSearch = isSearchable;
        this.help = table.help;

        this.inputManager = inject(TableInputManagerFactory).create(table, this.moduleInfo?.filter);

        this.setPermissionControls(table);
        this.setManagerConfig(table, propertyDescriptors);
    }

    createDataSource(inputs?: TableInputs<FilterValue>) {
        return new CompanyTableDataSource(this.companiesClient, this.tableIdentifier, this.inputManager, inputs);
    }

    private setManagerConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>) {
        this.customColumns = (table.columns ?? []).map(columnDescriptorToCellDisplayDescriptor).filter(isCustomColumn);

        const id = `table_${table.identifier}`;
        const columns = this.columnFactory.create(table.columns ?? [], propertyDescriptors, TableSourceType.Company, true);
        const tableConfig = TableManagerFunctions.createTableConfig(columns, id);
        tableConfig.row = { link: (item: Company) => this.getRowLink(item, table) };

        this.tableConfig = tableConfig;
    }

    private setPermissionControls(config: Table) {
        this.addActionConfig = config.detail == null && this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.Add).granted;
    }

    private getRowLink(company: Company, table: Table) {
        const isGranted = this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(company.id), PermissionAction.Read, company, this.contextProvider.get()).granted;
        if (isGranted) {
            if (table.detail) {

                // if table detail module
                if (this.moduleInfo) {
                    return ['/', this.moduleInfo.identifier, company.id, { mode: TableDisplayMode.Detail, prevUrl: this.window.location.pathname }];
                }

                return [company.id, { mode: TableDisplayMode.Detail }];
            }

            // if table detail module
            if (this.moduleInfo) {
                return ['/', this.moduleInfo.identifier, company.id, { prevUrl: this.window.location.pathname }];
            }

            return [company.id];
        }
        return [];
    }

}

