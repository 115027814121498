
import { Inject, Injectable } from '@angular/core';
import { AppContext, claimReducer } from '@unifii/library/common';

import { Authentication } from 'shell/services/authentication';
import { ShellContextProvider } from 'shell/services/shell-context-provider';

import { DiscoverContext } from 'discover/discover-context';


/** ContextProvider is responsible for providing context for expression parsing */
@Injectable()
export class DiscoverContextProvider extends ShellContextProvider {

    constructor(@Inject(Authentication) auth: Authentication, private context: DiscoverContext) {
        super(auth);
    }

    get(): AppContext {
        let appContext = super.get();

        // Add company to context if available
        if (this.context.company != null) {
            const claims = claimReducer(this.context.company?.claims);
            const company = { ...this.context.company, claims };

            appContext = { ...appContext, company };
        }
        return appContext;
    }

}
