import { Inject, Injectable } from '@angular/core';
import {
    BucketDataDescriptorPermissionController, CollectionDataDescriptorPermissionController, CompanyDataDescriptorPermissionController,
    UserDataDescriptorPermissionController
} from '@unifii/library/common';
import { PermissionAction } from '@unifii/sdk';

import { Config } from 'config';

import { Authentication } from './authentication';
import { PermissionsFunctions } from './permissions-functions';


@Injectable()
export class ShellDataDescriptorPermissionController implements
    UserDataDescriptorPermissionController,
    CompanyDataDescriptorPermissionController,
    BucketDataDescriptorPermissionController,
    CollectionDataDescriptorPermissionController {

    constructor(@Inject(Authentication) private auth: Authentication, @Inject(Config) private config: Config,) { }

    canLoadRoles(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getRolesPath(), PermissionAction.List).granted;
    }

    canLoadHierarchy(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getHierarchiesPath(), PermissionAction.List).granted &&
            this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getHierarchyUnitPath(), PermissionAction.Read).granted;
    }

    canLoadUserClaims(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted;
    }

    canLoadCompanyClaims(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompanyClaimsPath(), PermissionAction.List).granted;
    }

    canLoadForms(): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getFormsPath(this.config.unifii.projectId), PermissionAction.List).granted;
    }

    canLoadSchema(identifier: string): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketPath(this.config.unifii.projectId, identifier), PermissionAction.Read).granted;
    }

    canLoadCollectionDefinition(identifier: string): boolean {
        return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCollectionPath(this.config.unifii.projectId, identifier), PermissionAction.Read).granted;
    }
}