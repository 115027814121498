import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import {
    Breadcrumb, CommonTranslationKey, DescriptionListItem, FilterEntry, FilterValue, ModalService, SharedTermsTranslationKey, ToastService, UfControl,
    UfControlGroup
} from '@unifii/library/common';
import { sortRoles } from '@unifii/library/smart-forms';
import {
    DataSeed, Dictionary, FieldWidth, Manager, MeClient, Query, Role, TenantSettings, UserAuthProvider, UserInfo, UserInvite, UsersClient, UserStatus
} from '@unifii/sdk';
import {
    ClaimSourceType, FormPermissionController, LockedConfig, UserDescriptionService, UserDescriptionServiceProvider, UserFieldLabelService,
    UserInfoKey, UserUpdateFormController, UserUpdateMeFormController
} from '@unifii/user-provisioning';

import { DiscoverContentType, UserContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { EditedData } from 'shell/services/unsaved-data-guard';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { PinService } from 'discover/pin/pin-types';
import { UserFormPermissionController } from 'discover/user-management/user-form-permission-controller';

import { Config } from 'config';


@Component({
    selector: 'ud-user-details',
    templateUrl: './user-details.html',
    providers: [BreadcrumbsService]
})
export class UserDetailsComponent implements EditedData, OnDestroy, OnInit, UserContent {

    userInfo: UserInfo;
    status: UserStatus | null;
    userAuthProviders: UserAuthProvider[];
    title: string;
    edited: boolean;

    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly commonTK = CommonTranslationKey;
    protected readonly shellTK = ShellTranslationKey;
    protected readonly discoverTK = DiscoverTranslationKey;
    protected readonly userInfoKeys = UserInfoKey;
    protected readonly userStatus = UserStatus;
    protected readonly fieldWidth = FieldWidth;
    protected readonly claimSource = ClaimSourceType.User;

    // Permissions
    protected canUpdate: boolean;
    protected canInvite: boolean;
    protected canListUsers: boolean;
    protected canListClaims: boolean;
    protected canDelete: boolean;

    // Controls
    protected form: UfControlGroup;
    protected labelDictionary: Dictionary<string>;

    // Input Options
    protected roles: Role[];
    protected claimResults: string[];
    protected claimValueResults: string[];
    protected authProviderInfo: Dictionary<{ claims: string[]; roles: string[]; units: string[] }> = {};

    // Page status & info
    protected isMyProfile: boolean;
    protected breadcrumbs: Breadcrumb[] = [];

    protected managerInfo: DescriptionListItem[] | undefined;
    protected lockedProperties: LockedConfig = {
        fields: [],
        claimTypes: [],
        roles: [],
        units: []
    };
    protected pinTimeout: number;
    protected emailRequired: boolean;
    protected disabledLookup: Dictionary<boolean>;
    protected descriptionFilter: string[];
    protected claimDescriptionFilter: (UserInfoKey | string)[];
    protected loading = true; // this is important for refreshing binding of [formGroup] when we create new instance of form after save
    protected allowedRoles: string[] | undefined;
    protected managerOptions: DataSeed[] = [];

    private subscriptions = new Subscription();
    private formController: UserUpdateMeFormController | UserUpdateFormController;
    private userLookup = new Map<string, UserInfo>();

    constructor(
        private cd: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private usersClient: UsersClient,
        private translate: TranslateService,
        private modalService: ModalService,
        private toast: ToastService,
        private meClient: MeClient,
        private breadcrumbsService: BreadcrumbsService,
        private userFieldLabelService: UserFieldLabelService,
        private updateFormController: UserUpdateFormController,
        private updateMeFormController: UserUpdateMeFormController,
        @Inject(Config) private config: Config,
        @Inject(PinService) public pinService: PinService,
        @Inject(UserDescriptionServiceProvider) private userDescriptionService: UserDescriptionService,
        @Inject(FormPermissionController) private permissionCtrl: UserFormPermissionController,
        @Optional() private detailPage: TableDetailComponent | null,
        @Optional() @Inject(TableContainerManager) private tableManager: TableContainerManager<UserInfo, FilterValue, FilterEntry> | null
    ) {
        this.isMyProfile = this.route.snapshot.data.contentType === DiscoverContentType.UserProfile;
        this.formController = this.isMyProfile ? this.updateMeFormController : this.updateFormController;
        this.emailRequired = (this.config.unifii.tenantSettings as TenantSettings).isUserEmailRequired;
        this.labelDictionary = this.userFieldLabelService.labelDictionary;
    }

    async ngOnInit() {
        await this.init();
    }

    get user(): UserInfo | undefined {
        if (!this.form) {
            return;
        }

        return this.updateFormController.toDataModel(this.form);
    }

    get fullName() {
        if (!this.user) {
            return '';
        }

        if (this.user.firstName && this.user.lastName) {
            return `${this.user.firstName} ${this.user.lastName}`;
        }

        return this.user.username;
    }

    get usernameControl(): UfControl {
        return this.form.get(UserInfoKey.Username) as UfControl;
    }

    get unitsControl(): UfControl {
        return this.form.get(UserInfoKey.Units) as UfControl;
    }

    get unitPathsControl(): UfControl {
        return this.form.get(UserInfoKey.UnitPaths) as UfControl;
    }

    get rolesControl(): UfControl {
        return this.form.get(UserInfoKey.Roles) as UfControl;
    }

    get claimsControl(): UfControlGroup {
        return this.form.get(UserInfoKey.Claims) as UfControlGroup;
    }

    get emailControl(): UfControl {
        return this.form.get(UserInfoKey.Email) as UfControl;
    }

    get companyControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.Company) as UfControl | undefined;
    }

    get oldPasswordControl(): UfControl | undefined {
        return this.form.get(UserInfoKey.OldPassword) as UfControl | undefined;
    }

    get passwordControl(): UfControl {
        return this.form.get(UserInfoKey.Password) as UfControl;
    }

    get changePasswordOnNextLoginControl(): UfControl {
        return this.form.get(UserInfoKey.ChangePasswordOnNextLogin) as UfControl;
    }

    get lastActivationReasonControl(): UfControl {
        return this.form.get(UserInfoKey.LastActivationReason) as UfControl;
    }

    get isActiveControl(): UfControl {
        return this.form.get(UserInfoKey.IsActive) as UfControl;
    }

    get managerControl(): UfControl {
        return this.form.get(UserInfoKey.Manager) as UfControl;
    }

    async reInvite() {
        if (!this.user) {
            return;
        }

        const consent = await this.modalService.openConfirm({
            title: this.translate.instant(DiscoverTranslationKey.UserModalResendInviteTitle),
            message: this.translate.instant(DiscoverTranslationKey.UserModalResendInviteMessage, { email: this.user.email })
        });
        if (!consent) {
            return;
        }

        try {
            await this.usersClient.bulkInvite([{ email: this.user.email as string, username: this.user.username as string, company: this.user.company } as UserInvite]);
            this.toast.success(this.translate.instant(DiscoverTranslationKey.UserFeedbackResendInviteSuccess));
        } catch (_e) {
            this.toast.error(this.translate.instant(DiscoverTranslationKey.UserFeedbackResendInviteFail));
        }
    }

    async delete() {
        if (!this.user) {
            return;
        }

        const consent = await this.modalService.openConfirm({
            title: this.translate.instant(ShellTranslationKey.DeleteModalTitle),
            message: this.translate.instant(ShellTranslationKey.DeleteModalMessage, { argument: this.fullName })
        });
        if (!consent) {
            return;
        }

        try {
            await this.usersClient.delete(this.user.id as string);
            this.toast.success(this.translate.instant(ShellTranslationKey.DeleteModalSuccess));
            this.tableManager?.reload?.next();
            this.back();
        } catch (_e) {
            this.toast.error(this.translate.instant(ShellTranslationKey.DeleteModalFail));
        }
    }

    async save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        if (this.status === UserStatus.Pending && this.passwordControl?.enabled && this.usernameControl.value.indexOf('invitation_') === 0) {
            // Ask confirmation to activate the user with the generated username
            const confirmed = await this.modalService.openConfirm({
                title: this.translate.instant(DiscoverTranslationKey.UserDetailsModalCompleteRegistrationTitle),
                message: this.translate.instant(DiscoverTranslationKey.UserDetailsModalCompleteRegistrationMessage, { username: this.userInfo.username })
            });

            if (!confirmed) {
                return;
            }
        }

        const user = this.formController.toDataModel(this.form);

        try {
            if (this.pinTimeout != null) {
                this.pinService.changeTimeout(this.pinTimeout);
            }

            const updatedUser = await this.updateUser(user, this.isMyProfile);

            if (!updatedUser) {
                throw new Error();
            }

            this.userInfo = updatedUser;
            this.edited = false;
            this.toast.success(this.translate.instant(SharedTermsTranslationKey.ActionSaveFeedbackSuccess));

            this.tableManager?.updateItem?.next(updatedUser);
            this.detailPage?.updateDetails(updatedUser);

            if (this.breadcrumbs.length) {
                this.back();
            } else {
                this.subscriptions.unsubscribe();
                this.subscriptions = new Subscription();
                await this.init();
            }
        } catch (e) {
            const saveError = this.errorService.createSaveError(user.username, e);
            const mergedError = this.errorService.mergeError(e, saveError.message);
            this.toast.error(mergedError.message);
        }
    }

    back() {
        this.router.navigate(['..'], { relativeTo: this.route });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected selectManager(seed: DataSeed | null) {
        let user = null;
        this.managerInfo = undefined;

        if (seed != null) {
            user = this.userLookup.get(seed._id);
            this.managerInfo = this.getManagerInfo(user as Manager);
        }

        this.managerControl.setValue(user);
    }

    protected async searchUsers(q: string) {

        try {
            let query: Query | undefined;
            if (q?.trim().length) {
                query = new Query();
                query.q(`${q}*`);
            }

            const users = await this.usersClient.query(query);

            this.managerOptions = users
                .filter(u => u.id != null)
                .map(user => {

                    const seed: DataSeed = {
                        _display: `${user.firstName} ${user.lastName} (${user.username})`,
                        _id: user.id as string
                    };

                    this.userLookup.set(seed._id, user);
                    return seed;
                });
        } catch (e) { }
    }

    private initPermissions() {
        this.canUpdate = this.permissionCtrl.canUpdateUser(this.userInfo);
        this.canInvite = this.permissionCtrl.canInvite();
        this.canListUsers = this.permissionCtrl.canListUsers();
        this.canListClaims = this.permissionCtrl.canListClaims();
        this.canDelete = this.permissionCtrl.canDelete(this.userInfo);
        this.allowedRoles = this.permissionCtrl.allowedRoles(this.userInfo);
    }

    private initProviderAndManagerInfo() {
        if (this.userInfo.manager != null) {
            this.managerInfo = this.getManagerInfo(this.userInfo.manager);
        }

        if (this.userInfo.isExternal === true) {
            for (const provider of this.userAuthProviders) {
                this.lockedProperties.fields = this.lockedProperties.fields.concat(provider.lockedFields);
                this.lockedProperties.roles = this.lockedProperties.roles.concat(provider.lockedRoles).sort(sortRoles);
                this.lockedProperties.claimTypes = this.lockedProperties.claimTypes.concat(provider.lockedClaims);
                this.lockedProperties.units = this.lockedProperties.units.concat(provider.lockedUnits);
            }
            this.setAuthProviderInfo(this.userInfo);
        }
    }

    private async initForm() {

        if (Array.isArray(this.userInfo.roles)) {
            this.userInfo.roles = this.userInfo.roles.sort(sortRoles);
        }

        if (Array.isArray(this.userInfo.systemRoles)) {
            this.userInfo.systemRoles = this.userInfo.systemRoles.sort(sortRoles);
        }

        this.form = await this.formController.buildRoot(this.lockedProperties, this.userInfo, this.userAuthProviders);

        if (this.status === UserStatus.Pending) {
            this.form.removeControl(UserInfoKey.LastActivationReason);
        }

        this.subscriptions.add(this.form.statusChanges.pipe(filter(() => !this.form.pristine)).subscribe(() => {
            this.edited = this.form.dirty;
        }));
    }

    private initPageInfo() {

        this.breadcrumbsService.title = this.title;
        this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();

        this.disabledLookup = Object.keys(this.form.controls).reduce((dictionary, key) => {
            dictionary[key] = this.form.get(key)?.disabled === true;
            return dictionary;
        }, {} as Dictionary<boolean>);

        this.descriptionFilter = this.userDescriptionService.getDescriptionFilter(this.form);
        this.claimDescriptionFilter = this.userDescriptionService.getClaimDescriptionFilter(this.claimsControl, this.lockedProperties.claimTypes);
    }

    private async updateUser(userInfo: UserInfo, isMyProfile: boolean): Promise<UserInfo> {
        /**
         * A password can never be updated using PUT on /me therefore updating
         * properties via me and updating password details need to updated separately
         */
        const passwordDetails = { password: userInfo.password, oldPassword: userInfo.oldPassword };
        if (isMyProfile) {
            if (this.canUpdate) {
                const updatedUser = await this.meClient.update(userInfo);
                if (passwordDetails == null) {
                    return updatedUser;
                }
            }
            if (passwordDetails != null) {
                return await this.meClient.updatePassword(passwordDetails);
            }
        }

        return await this.usersClient.save(userInfo);
    }

    private setAuthProviderInfo(user: UserInfo) {
        for (const provider of this.userAuthProviders) {
            this.authProviderInfo[provider.tenant] = {
                claims: provider.lockedClaims.map(claim => this.claimMapper(claim, user)),
                roles: provider.lockedRoles.filter(role => user.roles?.includes(role)).sort(sortRoles),
                units: user.unitPaths?.filter(paths => paths.find(p => provider.lockedUnits.includes(p.id))).map(paths => paths.map(p => p.label).join(' / ')) ?? []
            };
        }
    }

    private claimMapper(source: string, user: UserInfo): string {
        const claim = user.claims?.find(c => c.type === source);
        return `${source}: ${claim?.value || ''}`;
    }

    private getManagerInfo(manager: Manager): DescriptionListItem[] {
        return [
            { term: this.translate.instant(CommonTranslationKey.UsernameLabel), description: { description: manager.username, routerLink: ['../', `${manager.id}`] } },
            { term: this.translate.instant(CommonTranslationKey.FirstNameLabel), description: { description: manager.firstName } },
            { term: this.translate.instant(CommonTranslationKey.LastNameLabel), description: { description: manager.lastName } },
            { term: this.translate.instant(CommonTranslationKey.EmailLabel), description: { description: manager.email, href: `mailto:${manager.email}` } },
            { term: this.translate.instant(CommonTranslationKey.PhoneLabel), description: { description: manager.phone, href: `tel:${manager.phone}` } },
        ].filter(d => !!d.description && !!d.description.description);
    }

    private async init() {
        this.loading = true;
        this.cd.detectChanges();

        this.initPermissions();
        this.initProviderAndManagerInfo();
        await this.initForm();
        this.initPageInfo();

        this.loading = false;
    }
}
