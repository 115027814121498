import { Inject, Injectable } from '@angular/core';
import { ContentType, Dictionary, PublishedContent } from '@unifii/sdk';


@Injectable()
export class ContentDetails {

    identifiers: Dictionary<{ type: ContentType }> | null;

    constructor(
        @Inject(PublishedContent) private content: PublishedContent
    ) { }

    /*
    * If lookup failed, disable the cache and try once more
    * This to cover the scenario where new content has been published since the last load of identifiers
    */
    async getContentType(identifier: string): Promise<ContentType | undefined> {

        /** Lookup for a specific identifier */
        const lookup = async (id: string): Promise<ContentType | undefined> => {
            const identifiers = await this.content.getIdentifiers();
            return identifiers[id]?.type;
        };

        const result = await lookup(identifier);
        if (result) {
            return result;
        }

        this.identifiers = null;
        return await lookup(identifier);
    }

}
