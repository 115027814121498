import { AfterViewInit, Component, ElementRef, HostBinding, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalConfirmData, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';


export interface ConfirmExtendedData extends ModalConfirmData {
    content: string;
}

@Component({
    selector: 'us-confirm-extended-modal',
    templateUrl: './confirm-extended-modal.html',
    styleUrls: ['./confirm-extended-modal.less']
})
export class ConfirmExtendedModalComponent implements Modal<ConfirmExtendedData, boolean>, AfterViewInit {

    @HostBinding('class.uf-form-card') class = true;
    @ViewChild('content') private content: ElementRef;

    readonly sharedTermsTK = SharedTermsTranslationKey;

    constructor(
        public runtime: ModalRuntime<ConfirmExtendedData, boolean>,
        @Inject(ModalData) public data: ConfirmExtendedData,
        private translate: TranslateService
    ) {
        this.data = Object.assign({
            cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
            confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionConfirm)
        }, data);
    }

    ngAfterViewInit() {
        if (this.content) {
            this.content.nativeElement.innerHTML = this.data.content;
        }
    }

    close(value: boolean) {
        this.runtime.close(value);
    }
}
