import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    claimReducer, ContentParser, DataPropertyInfoService, DateWeekDayAndTimeDisplayFormat, DateWeekDayDisplayFormat, DescriptionListDescription,
    DescriptionListItem, FieldDescriptionService, FieldDisplayPipe, getSafeRuntimeField, RuntimeField, Scope, TemplateStringParser, WindowWrapper
} from '@unifii/library/common';
import { ClaimConfig, Company, DataSourceType, FieldType, PermissionAction } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';

import { DiscoverContext } from 'discover/discover-context';


/**
 * DiscoverFieldDescriptionService provides a way for discover to
 * extend the library field description service and modify or add values that are usefull to the app context
 * eg: Add routerlink to app content
 */
@Injectable()
export class DiscoverFieldDescriptionService extends FieldDescriptionService {

    private fieldDisplayPipe: FieldDisplayPipe;

    constructor(
        @Inject(Authentication) private auth: Authentication,
        private context: DiscoverContext,
        @Inject(WindowWrapper) private window: Window,
        private dataPropertyInfoService: DataPropertyInfoService,
        pipe: FieldDisplayPipe,
        translate: TranslateService,
        templateStringParser: TemplateStringParser
    ) {
        super(pipe, translate, templateStringParser);

        this.fieldDisplayPipe = pipe;
    }

    transform(v: any, field: RuntimeField, scope?: Scope, format?: string): DescriptionListDescription[] {
        /**
         * Access attempt to access child properties if value is undefined and scope is defined
         */
        if (field.identifier?.includes('.') && scope != null && v == null) {
            v = this.childValueAccessor(field.identifier, scope);
        };

        const description = super.transform(v, field, undefined, format);

        const routerLink = this.getRouterLink(field, scope || {}, this.projectId);
        if (routerLink != null && description[0] != null) {
            description[0].routerLink = routerLink;
        }

        return description;
    }

    createCompanyDescription(value: any, descriptor: { identifier: string; label?: string }, claimConfigs: ClaimConfig[] = [], scope?: Company): DescriptionListItem | undefined {
        if (descriptor.identifier.includes('claims.')) {
            const [_, claimType] = descriptor.identifier.split('.');
            const claimConfig = claimConfigs.find(c => c.type === claimType);
            if (claimConfig == null) {
                return;
            }

            if (scope != null) {
                const reducedClaims = claimReducer(scope.claims);
                const contentParser = new ContentParser();
                value = contentParser.parse(reducedClaims[claimType], { type: claimConfig.valueType ?? FieldType.Text });
            }
            return this.createClaimDescription(value, claimConfig);
        }

        const field = this.dataPropertyInfoService.companyReferences.find(f => f.identifier === descriptor.identifier);
        if (field == null) {
            return;
        }

        if ([FieldType.MultiChoice, FieldType.TextArray].includes(field.type) && Array.isArray(value)) {
            value = value.join(', ');
        }

        const description: DescriptionListItem = {
            term: descriptor.label ?? field.label,
            description: this.transform(value, getSafeRuntimeField(field), scope, this.getFormatByFieldType(field.type))
        };

        return description;
    }

    createClaimDescription(claimValue: any, claimConfig: ClaimConfig): DescriptionListItem {
        const field = getSafeRuntimeField({
            type: claimConfig.valueType
        });

        if ([FieldType.MultiChoice, FieldType.TextArray].includes(claimConfig.valueType)) {
            claimValue = this.fieldDisplayPipe.transform(claimValue, claimConfig.valueType, (claimConfig.options ?? []).map(c => ({ identifier: c.id, name: c.display }))) as any;
        }

        if (claimConfig.options) {
            field.options = claimConfig.options.map(c => ({ identifier: c.id, name: c.display }));
        }

        return {
            term: claimConfig.label ?? claimConfig.type,
            description: this.transform(claimValue, field, undefined, this.getFormatByFieldType(field.type))
        };
    }

    /**
     ** This method creates a router link to specific form when identifier is _seqId
     */
    private getRouterLink({ identifier, parent }: RuntimeField, { _id }: Scope, projectId: string): any[] | undefined {

        if (parent?.sourceConfig == null || !_id || identifier !== '_seqId') {
            return;
        }

        if (parent.sourceConfig.type !== DataSourceType.Bucket) {
            return;
        }
        const bucketId = parent.sourceConfig.id;
        const accessPath = PermissionsFunctions.getBucketDocumentPath(projectId, bucketId, _id);
        const allowed = this.auth.getGrantedInfoWithoutCondition(accessPath, PermissionAction.Read).granted;

        if (allowed) {
            // datasource id is bucket, _id is formID
            return [`/`, FormDataPath, bucketId, _id, { prevUrl: this.window.location.pathname }];
        }
        return;
    }

    private getFormatByFieldType(type: FieldType): string | undefined {
        switch (type) {
            case FieldType.Date: return DateWeekDayDisplayFormat;
            case FieldType.DateTime:
            case FieldType.ZonedDateTime: return DateWeekDayAndTimeDisplayFormat;
            default: return;
        }
    }

    /**
     * Recursively attempts to access child propertie values
     */
    private childValueAccessor(property: string, scope: Scope): any {
        return property.split('.').reduce((s, prop) => this.accessUnknownMember(prop, s), scope);
    }

    private get projectId(): string {
        if (this.context.project) {
            return this.context.project.id;
        }
        return '';
    }

    private accessUnknownMember(prop: string, scope: Scope): any {
        try {
            return scope[prop as keyof Scope];
        } catch (e) { }
        return;
    }

}
