import { Subscription } from 'rxjs';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, TenantSettings } from '@unifii/sdk';

import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { SavedUser, SavedUsersService } from 'shell/services/saved-users.service';
import { SSOService } from 'shell/services/sso.service';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { SSOPath, TenantSelectionPath, UnifiiLoginPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';

import { DeviceRegistrationService } from 'capacitor/device-registration.service';
import { DeviceService } from 'capacitor/device.service';
import { InAppBrowserService } from 'capacitor/in-app-browser.service';

import { Config, Environment } from 'config';


interface SavedUserItem extends SavedUser {
    provider?: AppAuthProviderConfiguration;
}

@Component({
    selector: 'ud-login',
    templateUrl: './login.html',
    styleUrls: ['../../../shell/styles/external-branding.less', './login.less'],
})
export class LoginComponent implements OnDestroy, OnInit {

    readonly sharedTermsTK = SharedTermsTranslationKey;
    readonly commonTK = CommonTranslationKey;
    readonly discoverTK = DiscoverTranslationKey;

    providers: AppAuthProviderConfiguration[] = [];
    inProgress: boolean;

    private subscriptions = new Subscription();
    private projectId: string;

    constructor(
        public context: DiscoverContext,
        public savedUsersService: SavedUsersService,
        private router: Router,
        @Inject(Config) private config: Config,
        @Inject(Authentication) private auth: Authentication,
        @Inject(WindowWrapper) private window: Window,
        @Inject(Environment) private env: Config,
        private deviceService: DeviceService,
        private errorService: ErrorService,
        private translate: TranslateService,
        private ssoService: SSOService,
        private inAppBrowser: InAppBrowserService,
        private userAccessManager: UserAccessManager,
        private modalService: ModalService,
        private route: ActivatedRoute,
        private tenantService: TenantSettingsService,
        private deviceRegistrationService: DeviceRegistrationService
    ) {

        this.providers = this.createProvidersLabel(this.ssoService.providers);

        if (this.deviceService.isNative()) {
            this.subscriptions.add(this.inAppBrowser.loadStart.subscribe(url => this.inAppBrowserInterceptor(url)));
        }
    }

    async ngOnInit() {
        // Sign In to Unifii via Okta app buttons
        const { iss } = this.route.snapshot.queryParams;
        if (iss) {
            const provider = this.ssoService.getProviderByUrl(iss);
            if (provider) {
                this.providerSignIn(provider);
            } else {
                this.userAccessManager.showError(this.errorService.createError(this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage)));
            }
            return;
        }

        const { projectId, tenant } = this.route.snapshot.params;
        if (!projectId || !tenant) {
            return;
        }

        try {
            this.projectId = projectId;
            await this.tenantService.setTenant(tenant);
            this.providers = this.createProvidersLabel(this.ssoService.providers);
        } catch (e) {
            this.userAccessManager.showError(e);
            return;
        }

        this.deviceRegistrationService.register();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.userAccessManager.showError(null);
    }

    get canChangeCompany(): boolean {
        return !this.env.unifii.tenant;
    }

    get tenantSettings(): TenantSettings | undefined {
        return this.config.unifii.tenantSettings;
    }

    get savedUsers(): SavedUserItem[] {

        return (this.savedUsersService.users as SavedUserItem[]).map(user => {

            if (user.providerId) {
                user.provider = this.ssoService.getProvider(user.providerId);
            }
            return user;
        });
    }

    async removeUser(event: MouseEvent, user: SavedUser) {

        event.stopPropagation();

        const proceed = await this.modalService.openConfirm({
            title: this.translate.instant(DiscoverTranslationKey.LoginRemoveMeUserTitle),
            message: this.translate.instant(DiscoverTranslationKey.LoginRemoveMeUserMessage),
            confirmLabel: this.translate.instant(CommonTranslationKey.YesLabel),
            cancelLabel: this.translate.instant(CommonTranslationKey.NoLabel)
        });

        if (!proceed) {
            return;
        }

        this.savedUsersService.remove(user.username);
    }

    remeberedUserLogin(user: SavedUserItem) {

        if (user.provider) {
            this.providerSignIn(user.provider, user.username);
            return;
        }
        this.router.navigate([`/${UserAccessRootPath}/${UnifiiLoginPath}`, { username: user.username, rememberMe: true }]);
    }

    async providerSignIn(provider: AppAuthProviderConfiguration, username?: string): Promise<void> {

        this.userAccessManager.showError(null);
        this.inProgress = true;

        try {

            const redirectUri = this.ssoService.loginRedirectUri;
            const providerUrl = await this.ssoService.getProviderUrl(provider, redirectUri, username);

            if (!providerUrl) {
                throw new AppError('');
            }

            if (this.inAppBrowser.isAvaliable) {
                this.inAppBrowser.open(providerUrl);
            } else {
                this.window.location.href = providerUrl;
            }

        } catch (error) {
            /** device registration fail,  */
            this.userAccessManager.showError(this.errorService.createError(error));
        } finally {
            this.inProgress = false;
        }
    }

    async changeTenant() {

        const { tenant } = this.config.unifii;

        // Ensure auth information is clear
        this.auth.clear();

        this.config.unifii.tenantSettings = undefined;
        this.config.unifii.tenant = undefined;

        this.router.navigate([`/${UserAccessRootPath}`, TenantSelectionPath, { tenant }]);
    }

    loginWithUser() {
        const params: { projectId?: string } = {};
        if (this.projectId) {
            params.projectId = this.projectId;
        }
        this.router.navigate(['login', 'form', params]);
    }

    private inAppBrowserInterceptor(url: string) {

        const params = new URL(url).search;
        const urlSearchParams = new URLSearchParams(params);

        if (url.startsWith(this.ssoService.loginRedirectUri)) {
            this.router.navigateByUrl(`/${UserAccessRootPath}/${SSOPath}?${urlSearchParams.toString()}`);
            this.inAppBrowser.close();
        }
    }

    private createProvidersLabel(providers: AppAuthProviderConfiguration[]): AppAuthProviderConfiguration[] {
        return providers.map(provider => {
            let providerLoginLabel = provider.providerLoginLabel;
            if (!providerLoginLabel) {
                const multiplesOfSameProvider = providers.filter(p => p.type === provider.type).length > 1;
                providerLoginLabel = multiplesOfSameProvider ? provider.tenant : '';
            }
            return { ...provider, providerLoginLabel };
        });
    }

}
