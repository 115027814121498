<uf-panel class="container">

    <div class="header-pane">
        <uf-breadcrumbs class="small" [breadcrumbs]="breadcrumbs"></uf-breadcrumbs>
    </div>

    <div class="content-pane">
        <uf-blockquote *ngIf="error" class="col-12 error" icon="errorSolid" [content]="error">
        </uf-blockquote>
        <uf-tabs *ngIf="!error">
            <uf-tab [label]="discoverTK.InputUsersLabel | translate" path="input">
            </uf-tab>
            <uf-tab [label]="discoverTK.UploadBasicCSVLabel | translate" path="upload-basic-csv">
            </uf-tab>
            <uf-tab [label]="discoverTK.UploadAdvancedCSVLabel | translate" path="upload-advanced-csv">
            </uf-tab>
        </uf-tabs>
    </div>

</uf-panel>