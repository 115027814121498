import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WindowWrapper } from '@unifii/library/common';

import { LegacyRouteAdapter } from 'shell/services/legacy-route-adapter';
import { UserAccessManager } from 'shell/services/user-access-manager';

import { DeviceService } from 'capacitor/device.service';

import { Authentication } from '../shell/services/authentication';

import { DiscoverTranslationKey } from './discover.tk';
import { ExternalService } from './external.service';


/**
 * Responsible for decoding route from route params
 * and redirecting to intended route or redirecting to
 * user access route if needed
 */
export const externalRouteGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        
    const router = inject(Router);
    const accessManager = inject(UserAccessManager);
    const externalService = inject(ExternalService);
    const translate = inject(TranslateService);
    const device = inject(DeviceService);
    const legacyRouteAdapter = inject(LegacyRouteAdapter);
    const auth = inject(Authentication);
    const window = inject(WindowWrapper) as Window;
    const { tenant, projectId } = route.params;

    try {

        if (!auth.isAuthenticated) {

            accessManager.deny({
                grantedRedirectURL: state.url
            });

            return false;
        }

        if (!(await externalService.validateTenant(tenant))) {
            accessManager.deny({
                message: translate.instant(DiscoverTranslationKey.LoginUseCompanyMessage, { company: tenant }),
                grantedRedirectURL: state.url,
                deniedRedirectURL: ['/login', { tenant, projectId }]
            });

            return false;
        }

        if (!(await externalService.validateProject(projectId))) {
            externalService.selectProject(projectId);

            if (device.isCapacitorIOS() || device.isCapacitorAndroid()) {
                window.location.hash = state.url;
                location.reload();
                // stops the guard continuing the route while reloading the page.
                throw new Error('Project Changed');
            }
        }

        const legacyUrl = '/' + swapQueryForMatrix(state.url);
        const transformed = legacyRouteAdapter.transform(legacyUrl);

        router.navigateByUrl(transformed.url);

    } catch (err) {
        router.navigate(['/']);
        return false;
    }

    return false;
}

function swapQueryForMatrix(url: string) {
    let redirectPath = url.split('/').filter(Boolean).splice(3).join('/');
    const parts = redirectPath.split('?');

    if (parts.length === 2) {
        const path = parts.shift() as string;
        const params = parts.shift() as string;
        redirectPath = `${path};${params.split('&').join(';')}`;
    }

    return redirectPath ?? url;
}
