import { TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { FormData, Query, RequestAnalyticsOrigin } from '@unifii/sdk';

import { ShellFormService } from 'shell/form/shell-form.service';


export class BucketTableDataSource extends TableDataSource<FormData> {

    private _countPromise: Promise<number | undefined>;
    private shellFormService: ShellFormService;
    private tableIdentifier: string;
    private tableInputManager: TableInputManager<FilterValue, FilterEntry>;
    private tableInputs?: TableInputs<FilterValue>;
    private showCount?: boolean;
    private include?: string[];

    constructor(options: {
        shellFormService: ShellFormService;
        tableIdentifier: string;
        tableInputManager: TableInputManager<FilterValue, FilterEntry>;
        tableInputs?: TableInputs<FilterValue>;
        showCount?: boolean;
        include?: string[];
    }) {
        super();

        this.shellFormService = options.shellFormService;
        this.tableIdentifier = options.tableIdentifier;
        this.tableInputManager = options.tableInputManager;
        this.tableInputs = options.tableInputs;
        this.showCount = options.showCount;
        this.include = options.include;

        this.loadCount();
    }

    get filtered(): boolean {
        return !!this.tableInputs?.q?.trim();
    }

    get sorted(): boolean {
        return this.tableInputs?.sort != null;
    }

    get count() {
        return this._countPromise;
    }

    load(options?: TableDataSourceQueryOptions) {
        const query = this.buildQuery(options, this.include);
        return this.shellFormService.query(query, {
            signal: this.loadController?.signal,
            analytics: { origin: RequestAnalyticsOrigin.Table, id: this.tableIdentifier }
        }).then(
            data => this.stream.next({ data }),
            error => this.stream.next({ error })
        );
    }

    getDownloadUrl(options?: TableDataSourceQueryOptions): string | undefined {
        const query = this.buildQuery({ ...options, limit: options?.limit ?? 10000 });
        return this.shellFormService.getDownloadUrl(query);
    }

    private buildQuery(options?: TableDataSourceQueryOptions, _include?: string[]): Query {
        const query = this.tableInputManager
            .createQuery(this.tableInputs)
            .limit(options?.limit, options?.offset);

        // TODO UNIFII-5557 re-nabme once the API support it
        // if (include) {
        //     query = query.include(include);
        // }
        return query;
    }

    private loadCount() {
        if (!this.showCount) {
            return;
        }

        this._countPromise = this.shellFormService.count(this.buildQuery(), {
            signal: this.loadController?.signal,
            analytics: { origin: RequestAnalyticsOrigin.Table, id: this.tableIdentifier }
        });
    }

}
