import { TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { Query, RequestAnalyticsOrigin, UserInfo, UsersClient } from '@unifii/sdk';


export class UsersTableDataSource extends TableDataSource<UserInfo> {

    constructor(
        private usersClient: UsersClient,
        private tableIdentifier: string,
        private tableInputManager: TableInputManager<FilterValue, FilterEntry>,
        private tableInputs?: TableInputs<FilterValue>,
    ) {
        super();
    }

    get filtered(): boolean {
        return !!this.tableInputs?.q?.trim();
    }

    get sorted(): boolean {
        return this.tableInputs?.sort != null;
    }

    load(options?: TableDataSourceQueryOptions) {
        const query = this.buildQuery(options);

        return this.usersClient.query(query, {
            signal: this.loadController?.signal,
            analytics: { origin: RequestAnalyticsOrigin.Table, id: this.tableIdentifier }
        }).then(
            data => this.stream.next({ data }),
            error => this.stream.next({ error })
        );
    }

    getDownloadUrl(options?: TableDataSourceQueryOptions): string | undefined {
        const query = this.buildQuery(options);
        return this.usersClient.getDownloadUrl(query);
    }

    private buildQuery(options?: TableDataSourceQueryOptions): Query {
        return this.tableInputManager.createQuery(this.tableInputs).limit(options?.limit, options?.offset);
    }

}