<a *ngIf="menuDrawer" class="uf-action tertiary" (click)="shell.toggleMenuDrawer()">
    <uf-icon [name]="menuDrawer.icon"></uf-icon>
</a>
<a *ngIf="leftDrawer" class="uf-action tertiary" (click)="shell.openLeftDrawer()">
    <uf-icon [name]="leftDrawer.icon"></uf-icon>
</a>

<div class="title primary">{{title}}</div>

<ng-content></ng-content>

<button *ngIf="previewMode === mode" (click)="shell.toggleMode.next()" [disabled]="disablePreviewToggle" type="button"
    class="uf-action tertiary preview-icon">
    <uf-icon name="preview"></uf-icon>
</button>

<a *ngIf="rightDrawer" class="uf-action tertiary rightDrawer-wrap" (click)="shell.openRightDrawer()">
    <uf-icon [name]="rightDrawer.icon"></uf-icon>
    <uf-icon name="warning--solid" class="badge" *ngIf="notify" [@grow]></uf-icon>
</a>