import {
    CellDisplayDescriptor, claimReducer, columnDescriptorToCellDisplayDescriptor, DataPropertyDescriptor, ExpressionParser, FieldDisplayPipe,
    HierarchyFunctions, isCustomColumn, TemplateStringParser, UserInfoIdentifiers
} from '@unifii/library/common';
import { sortRoles } from '@unifii/library/smart-forms';
import { ColumnDescriptor, FieldType, getUserStatus, HierarchyStep, Option, Table, TableSourceType, UserInfo } from '@unifii/sdk';
import { UserInfoKey } from '@unifii/user-provisioning';

import { Config } from 'config';

import { ContentLinkCellComponent } from './content-link-cell/content-link-cell.component';
import { TableData } from './models';


export const getTablePropertyDisplayValue = (
    expressionParser: ExpressionParser,
    templateStringParser: TemplateStringParser,
    displayPipe: FieldDisplayPipe,
    sourceType: TableSourceType,
    data: TableData,
    identifier: string,
    type: FieldType,
    options?: Option[],
    format?: string,
    itemTemplate?: string
): string | null | undefined => {

    if ((sourceType === TableSourceType.Company || sourceType === TableSourceType.Users) && identifier.startsWith('claims.')) {
        const claimValues = claimReducer((data as any)?.claims);
        const claimType = identifier.replace('claims.', '');
        return displayPipe.transform(claimValues[claimType], type, options);
    }

    if (sourceType === TableSourceType.Users) {
        return getUserInfoCellValue(displayPipe, data as UserInfo, identifier, type, options);
    }

    let value: any = data[identifier as keyof TableData];

    if (sourceType === TableSourceType.Bucket) {

        if (expressionParser != null) {
            const formData = data as FormData;
            value = expressionParser.resolve(identifier, { self: null, root: formData }, formData, `FormDataTable: failed to parse ${identifier}`);
        }

        if(type === FieldType.Link){
            return value;
        }

        if (templateStringParser && itemTemplate && type === FieldType.Repeat && Array.isArray(value)) {
            return value.map(v => templateStringParser.parse(itemTemplate, v, v)).join('\r\n');
        }
    }

    return displayPipe.transform(value, type, options, format);
};

const getUserInfoCellValue = (
    displayPipe: FieldDisplayPipe,
    userInfo: UserInfo,
    identifier: string,
    type: FieldType,
    options?: Option[],
    format?: string
): string | null | undefined => {
    if (identifier === UserInfoIdentifiers.CompanyName) {
        return userInfo.company?.name;
    }

    if (identifier === UserInfoIdentifiers.CompanyId) {
        return userInfo.company?.id;
    }

    if (identifier === 'status') {
        return getUserStatus(userInfo);
    }

    if (identifier === UserInfoIdentifiers.UnitPaths) {
        const units = userInfo.unitPaths ?? [] as HierarchyStep[][];
        return (units).map(unitPath => HierarchyFunctions.pathToDisplay(unitPath)).join(', ');
    }

    let value = (userInfo as any)[identifier];

    if (identifier === UserInfoIdentifiers.Roles && Array.isArray(value)) {
        return value.sort(sortRoles).join(', ');
    }

    if (identifier === UserInfoKey.IsActive) {
        value = getUserStatus(userInfo);
    }

    return displayPipe.transform(value, type, options, format);
};

export const checkDownloadRoles = (table: Table, roles: string[]) => {
    if (table.exportVisibleTo && table.exportVisibleTo.length > 0) {
        return roles.some(role => table.exportVisibleTo?.includes(role));
    }
    return true;
};

export const checkShowCount = (config: Config, table: Table): boolean =>
    !!(config.unifii.tenantSettings?.features.indexing && table.showCount);

export const getTableCustomColumnsDisplayDescriptors = (propertyDescriptors: Map<string, DataPropertyDescriptor>, columns?: ColumnDescriptor[]): CellDisplayDescriptor[] => {

    if (!columns) {
        return [];
    }

    return [
        // Configured custom columns
        ...columns
            .map(columnDescriptorToCellDisplayDescriptor)
            .filter(isCustomColumn),
        // ContentLink as custom columns
        ...columns
            .filter(c => propertyDescriptors.get(c.identifier)?.type === FieldType.Link)
            .map(columnDescriptorToCellDisplayDescriptor).map(c => {
                c.component = ContentLinkCellComponent;
                return c;
            })
    ];

};
