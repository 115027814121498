<div class="skeleton-container dashboard" *ngIf="!ready" @fade></div>
<uf-panel class="container" *ngIf="ready">
    <div class="header-pane">
        <span class="left"></span>
        <button class="uf-action" (click)="refreshBuckets()">
            <uf-icon name="refreshApp"></uf-icon>
        </button>
    </div>
    <div *ngIf="error">

    </div>
    <ng-template [ngIf]="error" [ngIfElse]="noError">
        <uf-message class="col-1of1 error padded" icon="errorSolid" [content]="error.message">
            <h3>{{error.code}}</h3>
        </uf-message>
    </ng-template>
    <ng-template #noError>
        <us-dashboard-table class="content-pane" *ngFor="let tableInfo of tableInfos" [tableInfo]="tableInfo">
        </us-dashboard-table>
    </ng-template>
</uf-panel>