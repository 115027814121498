import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';

import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { ModalService } from '@unifii/library/common';

import { Features } from 'shell/features';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';

import { UpdateProgressComponent, UpdateProgressData } from 'discover/offline/update-progress.component';

import { Config } from 'config';


export const offlineContentGuard: CanActivateFn = () => {

    const config = inject(Config);
    const modalService = inject(ModalService);
    const offlineManager = inject(OfflineManager);
    const features = inject(Features);
    const authentication = inject(Authentication);

    if (!features.offline || !config.unifii.offline) {
        return of(true);
    }

    return of(null).pipe(
        flatMap(() => offlineManager.openContentDB()),
        map(() => true),
        catchError(error => {
            console.info('OfflineGuard: no content available, update needed', error);
            return updateContent(modalService, offlineManager, authentication);
        })
    );
}

function updateContent(modalService: ModalService, offlineManager: OfflineManager, authentication: Authentication): Observable<boolean> {

    return from(modalService.openFit<UpdateProgressData, boolean>(
        UpdateProgressComponent,
        { progress: offlineManager.updateContent() } as UpdateProgressData,
        { guard: true }
    )).pipe(flatMap(updated => {

        if (!updated) {
            // If no existing database we have no choice but to logout
            return from(authentication.logout());
        }

        return of(updated);
    }));
}
